import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Stack,
  Tooltip,
  Typography,
  keyframes,
  useTheme,
} from '@mui/material'
import { noop } from 'lodash'
import { useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useNotifications } from '../AppNotifications'
import { Delete, UploadFile } from '@mui/icons-material'
import { ObjectValues } from '@xsonline/common'
import { useFileUpload } from '../../hooks/useFileUpload'
import { UploadTarget } from '../../schema/base.types'
import { HelpButton } from '../HelpButton'

export interface FileUploadDialogProps {
  getUploadTarget: () => Promise<UploadTarget>
  onComplete?: () => void
  onClose: () => void
  open: boolean
  title: React.ReactNode
  helper?: React.ReactNode | React.ReactNode[]
  helpPage: string
  helpTooltip: string
}

export const UploadStateEnum = {
  // These are the initial states
  NO_FILE: 'NO_FILE',
  FILE_DROPPED: 'FILE_DROPPED',
  // These are the action states
  PREPARING: 'PREPARING',
  UPLOADING: 'UPLOADING',
  // These are the result states
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
} as const
export type UploadStateEnum = ObjectValues<typeof UploadStateEnum>

export const FileUploadDialog: React.FC<FileUploadDialogProps> = ({
  getUploadTarget,
  onClose,
  onComplete,
  open,
  title,
  helper,
  helpPage,
  helpTooltip,
}) => {
  const theme = useTheme()
  const uploadFile = useFileUpload()
  // const { getImageUploadStatus, putFile } = lookups
  const { show, catchError } = useNotifications()
  const [droppedFile, setDroppedFile] = useState<File>()
  const [uploadState, setUploadState] = useState<UploadStateEnum>(UploadStateEnum.NO_FILE)

  // HANDLERS

  const handleDrop = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles.length === 0) {
      show('No file found', { variant: 'error' })
      return
    }
    if (acceptedFiles.length > 1) {
      show('Only a single file is allowed.', { variant: 'error' })
      return
    }
    setDroppedFile(acceptedFiles[0])
    setUploadState(UploadStateEnum.FILE_DROPPED)
  }, [])

  const handleUploadClick = async () => {
    try {
      if (!droppedFile) show('No file found', { variant: 'error' })
      setUploadState(UploadStateEnum.PREPARING)
      const { url, fields = {} } = await getUploadTarget()

      setUploadState(UploadStateEnum.UPLOADING)
      await uploadFile(url, { file: droppedFile as File, ...fields })
      onComplete && onComplete()
      setUploadState(UploadStateEnum.SUCCESS)
      setTimeout(onClose, 1500)
    } catch (err) {
      catchError('Failed to upload file', false)(err as Error)
      setUploadState(UploadStateEnum.ERROR)
      setDroppedFile(undefined)
      setTimeout(() => {
        // Wait a little then reset
        setUploadState(UploadStateEnum.NO_FILE)
        setDroppedFile(undefined)
      }, 1500)
    }
  }

  const handleDelete = () => {
    setDroppedFile(undefined)
    setUploadState(UploadStateEnum.NO_FILE)
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
    accept: {
      'image/*': ['.zip'],
    },
    maxFiles: 1,
  })

  // RENDER

  const isUploading = uploadState === UploadStateEnum.UPLOADING || uploadState === UploadStateEnum.PREPARING
  const handleClose = isUploading ? noop : onClose

  const pulse = keyframes`
    0% { background-color: transparent; }
    50% { background-color:  ${theme.palette.secondary.light}44; }
    100% { background-color: transparent; }
  `

  const pulseCss = {
    animation: `${pulse} 3s infinite ease`,
    backgroundColor: 'transparent',
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth disableEscapeKeyDown={isUploading}>
      <DialogTitle
        sx={{
          backgroundColor: theme.palette.secondary.main,
          color: theme.palette.secondary.contrastText,
        }}
      >
        {title}
      </DialogTitle>
      <DialogContent>
        <Box sx={{ py: 1 }}>{helper}</Box>
        <Box>
          {/* If there's no file then show the drag and drop control */}
          {uploadState === UploadStateEnum.NO_FILE ? (
            <Box
              {...getRootProps()}
              sx={{
                borderRadius: 1,
                border: isDragActive ? `3px solid ${theme.palette.primary.main}` : '3px dashed grey',
                background: isDragActive ? theme.palette.primary.light : 'none',
                display: 'flex',
                minHeight: 200,
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                p: 2,
                cursor: 'pointer',
              }}
            >
              <input {...getInputProps()} />

              {isDragActive ? (
                <Typography>drop file here</Typography>
              ) : (
                <>
                  <Typography sx={{ fontSize: '1.2rem' }}>No file</Typography>
                  <Typography>Drag file here or click to select file</Typography>
                </>
              )}
            </Box>
          ) : (
            <Box
              sx={{
                ...(uploadState === UploadStateEnum.FILE_DROPPED && pulseCss),
                borderRadius: 1,
                border: `3px solid ${theme.palette.primary.main}`,
                display: 'flex',
                minHeight: 200,
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                p: 2,
              }}
            >
              <Stack
                direction={'row'}
                sx={{
                  width: '100%',
                  textAlign: 'left',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  // width: '60%',
                }}
                alignItems="center"
                justifyContent="center"
              >
                <UploadFile sx={{ mr: 2 }} />
                <Typography variant="caption">{droppedFile && droppedFile.name}</Typography>
              </Stack>
              <Box sx={{ height: 30, display: 'flex', justifyContent: 'center' }}>
                {uploadState === UploadStateEnum.UPLOADING && <Typography variant="h4">Uploading...</Typography>}
                {uploadState === UploadStateEnum.PREPARING && (
                  <Typography variant="h4">Fetching Upload URL...</Typography>
                )}
                {uploadState === UploadStateEnum.SUCCESS && (
                  <Typography variant="h4" color="success">
                    Upload successful!
                  </Typography>
                )}
                {uploadState === UploadStateEnum.ERROR && (
                  <Typography variant="h4" color="error">
                    Upload failed!
                  </Typography>
                )}
                {uploadState === UploadStateEnum.FILE_DROPPED && (
                  <Button startIcon={<Delete />} onClick={handleDelete} disabled={isUploading}>
                    Remove
                  </Button>
                )}
              </Box>
            </Box>
          )}
        </Box>

        <Box sx={{ height: 10 }}>{uploadState === UploadStateEnum.UPLOADING && <LinearProgress />}</Box>
      </DialogContent>
      <DialogActions>
        <HelpButton toolTip={helpTooltip} pageName={helpPage} />
        <Button onClick={handleClose} disabled={isUploading}>
          Cancel
        </Button>
        <Box sx={{ flexGrow: 1 }} />
        <Tooltip
          title={uploadState !== UploadStateEnum.FILE_DROPPED ? 'You must choose a zip file to upload' : undefined}
          placement="top"
        >
          <Box>
            <Button
              startIcon={<UploadFile />}
              onClick={handleUploadClick}
              disabled={uploadState !== UploadStateEnum.FILE_DROPPED}
              variant="contained"
            >
              Upload
            </Button>
          </Box>
        </Tooltip>
      </DialogActions>
    </Dialog>
  )
}
