import { useState, useRef, useEffect, useCallback } from 'react'

export const useDebounce = <T>(
  input: T,
  delayMs: number,
  skipDebounce?: boolean | (() => boolean)
): [T, (setValue: T) => void] => {
  const debounceTimerRef = useRef<NodeJS.Timeout>()
  const [debounced, setDebounced] = useState<T>(input)

  const setDebouncedImmediately = useCallback((value: T) => {
    if (debounceTimerRef.current) clearTimeout(debounceTimerRef.current)
    setDebounced(value)
  }, [])

  useEffect(() => {
    if (debounceTimerRef.current) clearTimeout(debounceTimerRef.current)
    if (skipDebounce && (typeof skipDebounce === 'function' ? skipDebounce() : skipDebounce)) {
      setDebounced(input)
    } else {
      debounceTimerRef.current = setTimeout(() => {
        setDebounced(input)
      }, delayMs)
    }
  }, [input])

  return [debounced, setDebouncedImmediately]
}
