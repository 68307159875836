import * as React from 'react'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { Alert, Container, Paper, Stack, Tooltip, alpha, useTheme } from '@mui/material'
import { ChartButtonTabs } from './ChartButtonTabs'
import { GenericList } from '../DataLibrary/GenericList'
import { ContentCopy, Delete, Edit, Share } from '@mui/icons-material'
import {
  CreateLongProfileInput,
  CreateReferencePlaneInput,
  FileStateEnum,
  Project,
  ProjectUpdate,
  SelectionInput,
  Survey,
  SurveyInput,
  UploadTarget,
} from '../../schema/base.types'
import { CreateLongChangeInput } from '../../schema/base.types'
import { CopyProjectDialog } from '../CopyProjectDialog'
import { GenericDBItem } from '../../types'
import { ConfirmDelete } from '../ConfirmDelete'
import { CreateSurveyDialog } from '../Survey/CreateSurveyDialog'
import { FileUploadDialog } from '../FileUpload/FileUploadDialog'
import { CreateSelectionDialog } from '../Selection/CreateSelectionDialog'
import { CreateLongProfileDialog } from '../LongProfile/CreateLongProfileDialog'
import { CreateLongChangeDialog } from '../LongChange/CreateLongChangeDialog'
import { CreateReferencePlaneDialog } from '../ReferencePlane/CreateReferencePlaneDialog'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import { HelpButton } from '../HelpButton'
import { ShareProject } from './ShareProject'
import { EditProjectDialog } from './EditProjectDialog'
import { UploadDownloadButton } from '../FileUpload/UploadDownloadButton'
import { ProjectDetailsCard } from './ProjectDetailsCard'
import { UnitsSettings } from '../../lib/formats'

export interface ProjectDetailsListProps {
  project: Project
  canEdit: boolean
  onNavigate: (string) => void

  onDeleteProject: () => void
  onUpdateProject: (dbItem: ProjectUpdate) => void
  onCopyProject: (name: string, description: string) => void

  onCreateSurvey(dbItem: SurveyInput): void
  onEditSurvey: (dbItem: GenericDBItem) => void
  onDeleteSurvey: (dbItem: GenericDBItem) => void

  onCreateSelection: (dbItem: SelectionInput) => void
  onEditSelection: (dbItem: GenericDBItem) => void
  onDeleteSelection: (dbItem: GenericDBItem) => void

  onCreateReferencePlane: (dbItem: CreateReferencePlaneInput) => void
  onEditReferencePlane: (dbItem: GenericDBItem) => void
  onDeleteReferencePlane: (dbItem: GenericDBItem) => void
  onReferencePlaneUploadComplete: (projectId: string, referencePlaneId: string) => void
  getReferencePlaneUploadUrl: (projectId: string, referencePlaneId: string) => Promise<UploadTarget>

  onCreateLongProfile: (dbItem: CreateLongProfileInput) => void
  onEditLongProfile: (dbItem: GenericDBItem) => void
  onDeleteLongProfile: (dbItem: GenericDBItem) => void

  onCreateLongChange: (dbItem: CreateLongChangeInput) => void
  onEditLongChange: (dbItem: GenericDBItem) => void
  onDeleteLongChange: (dbItem: GenericDBItem) => void
  // URL Upload Queries
  getUploadCenterlineUrl: () => Promise<UploadTarget>
  getUploadCrossSectionUrl: () => Promise<UploadTarget>
  onUploadCenterlineComplete: () => Promise<void>
  onUploadCrossSectionsComplete: () => Promise<void>
}

export const ProjectDetails: React.FC<ProjectDetailsListProps> = ({
  project,
  canEdit,

  onNavigate,
  onUpdateProject,
  onDeleteProject,
  onCopyProject,

  onCreateSurvey,
  onEditSurvey,
  onDeleteSurvey,

  onCreateSelection,
  onEditSelection,
  onDeleteSelection,

  onCreateReferencePlane,
  onEditReferencePlane,
  onDeleteReferencePlane,
  onReferencePlaneUploadComplete,
  getReferencePlaneUploadUrl,

  onCreateLongProfile,
  onEditLongProfile,
  onDeleteLongProfile,

  onCreateLongChange,
  onEditLongChange,
  onDeleteLongChange,

  getUploadCenterlineUrl,
  getUploadCrossSectionUrl,

  onUploadCenterlineComplete,
  onUploadCrossSectionsComplete,
}) => {
  const theme = useTheme()
  const [EditProjectOpen, setEditProjectOpen] = React.useState(false)
  const [openConfirmDelete, setOpenConfirmDelete] = React.useState(false)
  const [surveyOpen, setSurveyOpen] = React.useState(false)
  const [longProfileOpen, setLongProfileOpen] = React.useState(false)
  const [longChangeOpen, setLongChangeOpen] = React.useState(false)
  const [referencePlaneOpen, setReferencePlaneOpen] = React.useState(false)
  const [selectionOpen, setSelectionOpen] = React.useState(false)
  const [copyProjectOpen, setCopyProjectOpen] = React.useState(false)
  const [shareProjectOpen, setShareProjectOpen] = React.useState(false)

  const [uploadCenterlineOpen, setUploadCenterlineOpen] = React.useState(false)
  const [uploadCrossSectionsOpen, setUploadCrossSectionsOpen] = React.useState(false)

  const projectHasFiles =
    project.centerline &&
    project.crossSections &&
    project.centerline.state === FileStateEnum.Complete &&
    project.crossSections.state === FileStateEnum.Complete

  return (
    <Container maxWidth="md">
      <Paper
        sx={{
          overflowY: 'scroll',
          my: 2,
          pb: 5,
          backgroundColor: alpha(theme.palette.background.paper, 0.8),
          backdropFilter: 'blur(10px)',
        }}
        elevation={5}
      >
        <Box
          sx={{
            background: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            px: 2,
            py: 1,
          }}
        >
          <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
            <Box>
              <Typography variant="overline">project</Typography>
              <Typography variant="h5">{project.name}</Typography>
            </Box>
            <Box sx={{ flexGrow: 1 }} />
            <Stack>
              <Stack direction="row">
                <HelpButton toolTip="Learn more about projects" pageName="projects" color="inherit" />
                <Tooltip
                  title={
                    project.isPrivate
                      ? 'Make the project public so that it can be shared with others.'
                      : 'Share Project'
                  }
                >
                  <IconButton
                    color="inherit"
                    aria-label="upload picture"
                    component="label"
                    disabled={project.isPrivate}
                    onClick={() => setShareProjectOpen(true)}
                  >
                    <Share />
                  </IconButton>
                </Tooltip>
                {canEdit && (
                  <Tooltip title="Edit Project">
                    <IconButton
                      color="inherit"
                      aria-label="Edit Project"
                      component="label"
                      onClick={() => setEditProjectOpen(true)}
                    >
                      <Edit />
                    </IconButton>
                  </Tooltip>
                )}
                <Tooltip title="Copy Project (Not Implemented)">
                  <span>
                    <IconButton
                      color="inherit"
                      aria-label="copy project"
                      component="label"
                      // disabled={!projectHasFiles}
                      // NOTE: DISABLED PERMANENTLY BECAUSE NOT WORKING
                      disabled
                      onClick={() => setCopyProjectOpen(true)}
                    >
                      <ContentCopy />
                    </IconButton>
                  </span>
                </Tooltip>
                {canEdit && (
                  <Tooltip title="Delete Project">
                    <IconButton
                      color="inherit"
                      aria-label="delete project"
                      component="label"
                      onClick={() => setOpenConfirmDelete(true)}
                    >
                      <Delete />
                    </IconButton>
                  </Tooltip>
                )}
              </Stack>
            </Stack>
          </Stack>
        </Box>
        <Box sx={{ p: 2 }}>
          <ChartButtonTabs projectId={project.id} onNavigate={onNavigate} />
          <ProjectDetailsCard project={project} />
          <Grid2 container spacing={1}>
            <Grid2 xs={12} sm={6}>
              <Typography variant="overline" component="div">
                Centerline:
              </Typography>
              <UploadDownloadButton
                label="Centerline"
                state={project.centerline.state}
                processingMessages={project.centerline.processingMessages}
                downloadUrl={project.centerline.downloadUrl || undefined}
                setUploadOpen={() => setUploadCenterlineOpen(true)}
              />
            </Grid2>
            <Grid2 xs={12} sm={6}>
              <Typography variant="overline" component="div">
                Cross Sections:
              </Typography>
              {project.centerline.state !== FileStateEnum.Complete && (
                <Typography variant="body2" component="div">
                  Upload a centerline to enable cross sections.
                </Typography>
              )}
              {project.centerline.state === FileStateEnum.Complete && (
                <UploadDownloadButton
                  label="Cross Sections"
                  state={project.crossSections.state}
                  processingMessages={project.crossSections.processingMessages}
                  downloadUrl={project.crossSections.downloadUrl || undefined}
                  setUploadOpen={() => setUploadCrossSectionsOpen(true)}
                />
              )}
            </Grid2>
          </Grid2>

          {!projectHasFiles && (
            <Box sx={{ m: 2, p: 2 }}>
              <Alert severity="info">
                <Typography variant="body1">Please upload a centerline and cross section to continue.</Typography>
              </Alert>
            </Box>
          )}
          {projectHasFiles && (
            <>
              <GenericList
                items={project.surveys.map((survey: Survey) => {
                  const genericItem: GenericDBItem = {
                    name: survey.name,
                    id: survey.id,
                    description: survey.description as string | undefined,
                    fargateItem: {
                      state: survey.state,
                      processingMessages: survey.processingMessages,
                    },
                  }
                  return genericItem
                })}
                heading="Surveys"
                canEdit={canEdit}
                onDeleteListItem={onDeleteSurvey}
                onEditListItem={onEditSurvey}
                allowNew={project.owner.pointClouds.filter((x) => x.state === FileStateEnum.Complete).length > 0}
                onCreateNew={() => {
                  setSurveyOpen(true)
                }}
                createTooltip={
                  project.owner.pointClouds.filter((x) => x.state === FileStateEnum.Complete).length > 0
                    ? 'Create a new survey'
                    : 'At least one processed point cloud is required before adding a survey.'
                }
              />
              <GenericList
                items={project.selections.map((item) => {
                  const genericItem: GenericDBItem = {
                    name: item.name,
                    id: item.id,
                    description: item.description as string | undefined,
                    // Note there's no fargate processing for selection
                  }
                  return genericItem
                })}
                heading="Selections"
                canEdit={canEdit}
                onDeleteListItem={onDeleteSelection}
                onEditListItem={onEditSelection}
                allowNew={project.surveys.length > 0}
                onCreateNew={() => {
                  setSelectionOpen(true)
                }}
                createTooltip={
                  project.surveys.length > 0
                    ? 'Create a new selection'
                    : 'You must add at least one survey before creating a selection.'
                }
              />

              <GenericList
                items={project.referencePlanes.map((item) => {
                  const genericItem: GenericDBItem = {
                    name: item.name,
                    id: item.id,
                    description: item.description as string | undefined,
                    fargateItem: {
                      state: item.state,
                      processingMessages: item.processingMessages,
                      downloadUrl: item.downloadUrl ? item.downloadUrl : undefined,
                    },
                  }
                  return genericItem
                })}
                heading="Reference Planes"
                canEdit={canEdit}
                onDeleteListItem={onDeleteReferencePlane}
                onEditListItem={onEditReferencePlane}
                allowNew={true}
                onCreateNew={() => {
                  setReferencePlaneOpen(true)
                }}
                createTooltip="Upload a new reference plane"
                getUploadTargetUrl={(id: string) => getReferencePlaneUploadUrl(project.id, id)}
                onUploadComplete={(id: string) => onReferencePlaneUploadComplete(project.id, id)}
              />

              <GenericList
                items={project.longProfiles.map((item) => {
                  const genericItem: GenericDBItem = {
                    name: item.name,
                    id: item.id,
                    description: item.description as string | undefined,
                    fargateItem: {
                      state: item.state,
                      processingMessages: item.processingMessages,
                    },
                  }
                  return genericItem
                })}
                heading="Long Profiles"
                onDeleteListItem={onDeleteLongProfile}
                onEditListItem={onEditLongProfile}
                canEdit={canEdit}
                allowNew={project.surveys.length > 0}
                onCreateNew={() => {
                  setLongProfileOpen(true)
                }}
                createTooltip={
                  project.surveys.filter((x) => x.state === FileStateEnum.Complete).length > 1 &&
                  project.selections.length > 0
                    ? 'Create a new long profile'
                    : 'You must add at least one survey and one selection before creating a long profile.'
                }
              />

              <GenericList
                items={project.longChanges.map((item) => {
                  const genericItem: GenericDBItem = {
                    name: item.name,
                    id: item.id,
                    description: item.description as string | undefined,
                    fargateItem: {
                      state: item.state,
                      processingMessages: item.processingMessages,
                    },
                  }
                  return genericItem
                })}
                heading="Longitudinal Changes"
                canEdit={canEdit}
                onDeleteListItem={onDeleteLongChange}
                onEditListItem={onEditLongChange}
                allowNew={
                  project.surveys.filter((x) => x.state === FileStateEnum.Complete).length > 1 &&
                  project.selections.length > 0
                }
                onCreateNew={() => {
                  setLongChangeOpen(true)
                }}
                createTooltip={
                  project.surveys.length > 1 && project.selections.length > 0
                    ? 'Create a new longitudinal change'
                    : 'You must add at least two surveys and one selection before creating a longitudinal change.'
                }
              />

              {/* Our dialogs go below */}

              <CreateReferencePlaneDialog
                open={referencePlaneOpen}
                onClose={() => {
                  setReferencePlaneOpen(false)
                }}
                onSubmit={(item: CreateReferencePlaneInput) => {
                  onCreateReferencePlane(item)
                  setReferencePlaneOpen(false)
                }}
                riverDistanceUnits={project.riverDistanceUnits}
                elevationUnits={project.surveyDistanceUnits}
              />
              {project.surveys.filter((x) => x.state === FileStateEnum.Complete).length > 1 &&
                project.selections.length > 0 && (
                  <CreateLongChangeDialog
                    open={longChangeOpen}
                    onClose={() => {
                      setLongChangeOpen(false)
                    }}
                    onSubmit={(item: CreateLongChangeInput) => {
                      onCreateLongChange(item)
                      setLongChangeOpen(false)
                    }}
                    surveys={project.surveys.filter((x) => x.state === FileStateEnum.Complete)}
                    selections={project.selections}
                    referencePlanes={project.referencePlanes.filter((x) => x.state === FileStateEnum.Complete)}
                    referenceStations={project.crossSections.referenceStationFields || []}
                    riverDistanceUnitsAbbr={project.riverDistanceUnits}
                    surveyDistanceUnitsAbbr={project.surveyDistanceUnits}
                  />
                )}
              {surveyOpen && (
                <CreateSurveyDialog
                  open
                  onClose={() => setSurveyOpen(false)}
                  onSubmit={(item: SurveyInput) => {
                    onCreateSurvey(item)
                    setSurveyOpen(false)
                  }}
                  unitsAbbreviation={project.surveyDistanceUnits}
                  pointClouds={project.owner.pointClouds.filter((x) => x.state === FileStateEnum.Complete)}
                />
              )}
              {selectionOpen && (
                <CreateSelectionDialog
                  open
                  onClose={() => {
                    setSelectionOpen(false)
                  }}
                  onSubmit={(item: SelectionInput) => {
                    onCreateSelection(item)
                    setSelectionOpen(false)
                  }}
                  riverDistanceUnitsAbbr={project.riverDistanceUnits}
                  surveys={project.surveys.filter((x) => x.state === FileStateEnum.Complete)}
                  unitSettings={project as UnitsSettings}
                />
              )}
              {project.surveys.length > 0 && longProfileOpen && (
                <CreateLongProfileDialog
                  open
                  referenceStations={project.crossSections.referenceStationFields || []}
                  onClose={() => {
                    setLongProfileOpen(false)
                  }}
                  onSubmit={(item: CreateLongProfileInput) => {
                    onCreateLongProfile(item)
                    setLongProfileOpen(false)
                  }}
                  surveys={project.surveys.filter((x) => x.state === FileStateEnum.Complete)}
                  selections={project.selections}
                />
              )}
            </>
          )}

          <ConfirmDelete
            itemId={project.id}
            dialogTitle="Delete Project"
            message={`Are you sure that you want to delete the ${project.name} project?`}
            open={openConfirmDelete}
            onClose={() => setOpenConfirmDelete(false)}
            onSubmit={onDeleteProject}
          />

          <ShareProject open={shareProjectOpen} onClose={() => setShareProjectOpen(false)} />
          {copyProjectOpen && (
            <CopyProjectDialog
              open={copyProjectOpen}
              onClose={() => setCopyProjectOpen(false)}
              onSubmit={(name: string, description: string) => {
                onCopyProject(name, description)
                setCopyProjectOpen(false)
              }}
            />
          )}

          {canEdit && (
            <EditProjectDialog
              project={project}
              open={EditProjectOpen}
              onCancel={() => {
                setEditProjectOpen(false)
              }}
              onSubmit={(updatedProject: ProjectUpdate) => {
                onUpdateProject(updatedProject)
                setEditProjectOpen(false)
              }}
            />
          )}

          {uploadCenterlineOpen && (
            <FileUploadDialog
              title="Upload Centerline"
              helper={
                <Typography>
                  Upload a zip file containing a single polyline ShapeFile that represents the river centerline. The
                  ShapeFile must have a spatial reference system and contain only one single part polyine feature. Click
                  the help icon for more details.
                </Typography>
              }
              open
              onClose={() => setUploadCenterlineOpen(false)}
              onComplete={onUploadCenterlineComplete}
              getUploadTarget={getUploadCenterlineUrl}
              helpPage="projects.html#centerlines"
              helpTooltip="Learn more about centerlines"
            />
          )}
          {uploadCrossSectionsOpen && (
            <FileUploadDialog
              title="Upload CrossSections"
              helper={
                <Typography>
                  Upload a zip file containing a single polyline ShapeFile that represents the river cross sections. The
                  ShapeFile must have a spatial reference system and contain one single part polyine feature for each
                  cross section. Floating point attribute column valuess will be stored as reference stations. Click the
                  help icon for more details.
                </Typography>
              }
              open
              onClose={() => setUploadCrossSectionsOpen(false)}
              onComplete={onUploadCrossSectionsComplete}
              getUploadTarget={getUploadCrossSectionUrl}
              helpPage="projects.html#cross_sections"
              helpTooltip="Learn more about cross sections"
            />
          )}
        </Box>
      </Paper>
    </Container>
  )
}
