import React, { useMemo, useState } from 'react'
import { Box, Container, Paper, Stack, SxProps, Theme, Toolbar, Typography, useTheme } from '@mui/material'

import { useNavigate } from 'react-router-dom'
import ReactMapGL, { CircleLayer, Layer, NavigationControl, Popup, Source } from 'react-map-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import { Feature, FeatureCollection, GeoJsonProperties, Geometry, Point } from 'geojson'
import { useGetProjectMapQuery } from '../../schema/operations'
import { GetProjectMapQuery } from '@xsonline/common'
import { ChartButtonBar } from '../Project/ChartButtonBar'
import { formatISODate } from '../../lib/dates'

const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN
if (!MAPBOX_TOKEN) {
  throw new Error('Mapbox token not set. Need to set REACT_APP_MAPBOX_TOKEN in .env file')
}

export interface MapPageProps {
  navigate: (uri: string) => void
  data: FeatureCollection<Geometry, GeoJsonProperties>
}

const stylesThunk = (theme: Theme): Record<string, SxProps<Theme>> => ({
  container: {
    py: 4,
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    // Square is the maximum height we can do
    height: 800,
    maxHeight: theme.breakpoints.values.lg,
    // width: '100%',
  },
  mapContainer: {
    flex: '1 1',
    position: 'relative',
  },
})

/**
 * The container component. This is where we handle all the logic for the component.
 * @returns
 */
export const MapPageContainer: React.FC = () => {
  const navigate = useNavigate()

  const projectMapQuery = useGetProjectMapQuery({
    onError: (error) => {
      console.log('onError projectDetailsQuery', error)
    },
    onCompleted: (data) => {
      if (data.getProjectsMap?.nextToken) {
        projectMapQuery.fetchMore({
          variables: {
            nextToken: data.getProjectsMap?.nextToken,
          },
        })
      }
    },
  })

  // Reformat this as geojson
  const data = useMemo(
    () =>
      ({
        type: 'FeatureCollection',
        features: (projectMapQuery?.data?.getProjectsMap?.items || [])
          // .filter(({ centroid }) => !centroid)
          .map((item) => {
            const { centroid } = item
            const typedCentroid = {
              ...centroid,
              // NOTE: WE REVERSE THE COORDINATES BECAUSE GDAL IS DOING SOMETHIGN WEIRD
              coordinates: [...(centroid.coordinates || [0, 0])].reverse(),
            } as Point
            return {
              type: 'Feature',
              geometry: typedCentroid,
              properties: item,
            }
          }),
      } as FeatureCollection<Point, GeoJsonProperties>),
    [projectMapQuery?.data?.getProjectsMap?.items]
  )
  return <MapPage navigate={navigate} data={data} />
}

// For more information on data-driven styles, see https://www.mapbox.com/help/gl-dds-ref/
export const dataLayer = (theme: Theme): CircleLayer => ({
  id: 'data',
  type: 'circle',
  paint: {
    'circle-radius': 5,
    'circle-color': theme.palette.primary.light, // set fill color to light blue
    'circle-stroke-color': theme.palette.secondary.light, // set border color to black
    'circle-stroke-width': 1.5, // set border width to 1 pixel
    'circle-opacity': 1,
  },
})

type PopupStateType = {
  longitude: number
  latitude: number
  feature: Feature
}

/**
 * This is the presentation component. It should be as dumb as possible.
 */
export const MapPage: React.FC<MapPageProps> = ({ data, navigate }) => {
  const theme = useTheme()
  const [cursor, setCursor] = useState<string>('auto')
  const styles = stylesThunk(theme)
  const [popupInfo, setPopupInfo] = useState<PopupStateType | null>(null)
  const [stickyPopupInfo, setStickyPopupInfo] = useState<PopupStateType | null>(null)

  const handleMouseMove = (event: mapboxgl.MapLayerMouseEvent) => {
    const map = event.target
    // FInd geojson features under the mouse
    const features = map.queryRenderedFeatures(event.point, {
      layers: ['data'],
    })
    if (features.length > 0) {
      setCursor('pointer')
      const feature = features[0]
      const [longitude, latitude] = (feature.geometry as Point).coordinates
      if (!stickyPopupInfo)
        setPopupInfo({
          longitude,
          latitude,
          feature,
        })
    } else {
      setCursor('auto')
      setPopupInfo(null)
    }
  }
  const handleClick = (event: mapboxgl.MapLayerMouseEvent) => {
    const map = event.target
    // FInd geojson features under the mouse
    const features = map.queryRenderedFeatures(event.point, {
      layers: ['data'],
    })
    if (features.length > 0) {
      const feature = features[0]
      console.log('features', features)
      const [longitude, latitude] = (feature.geometry as Point).coordinates
      setPopupInfo(null)
      setStickyPopupInfo({
        longitude,
        latitude,
        feature,
      })
    } else {
      setStickyPopupInfo(null)
    }
  }
  return (
    <Container maxWidth="lg" sx={styles.container}>
      <Box sx={styles.contentContainer}>
        <Toolbar
          sx={{
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
          }}
        >
          <Typography variant="h5">Project Explorer Map</Typography>
        </Toolbar>
        <Box sx={styles.mapContainer}>
          <ReactMapGL
            initialViewState={{
              latitude: 40,
              longitude: -100,
              zoom: 4,
            }}
            // minZoom={zoomBounds[0] || 4}
            // maxZoom={zoomBounds[1] || 20}
            clickTolerance={5}
            onMouseMove={handleMouseMove}
            onClick={handleClick}
            cursor={cursor}
            // NOTE: All styles here: https://docs.mapbox.com/api/maps/styles/
            mapStyle="mapbox://styles/mapbox/satellite-streets-v12" // set map style to satellite
            mapboxAccessToken={MAPBOX_TOKEN}
            interactive={true}
          >
            <NavigationControl />
            <Source type="geojson" data={data}>
              <Layer {...dataLayer(theme)} />
            </Source>
            {popupInfo && (
              <Popup
                closeButton={false}
                longitude={popupInfo.longitude}
                latitude={popupInfo.latitude}
                // offset={[0, 20] as Offset}
              >
                <PopupContents feature={popupInfo.feature} navigate={navigate} />
              </Popup>
            )}
            {stickyPopupInfo && (
              <Popup
                longitude={stickyPopupInfo.longitude}
                latitude={stickyPopupInfo.latitude}
                // offset={[0, 20] as Offset}
                onClose={() => setStickyPopupInfo(null)}
                closeOnClick={false}
                closeOnMove={false}
              >
                <PopupContents feature={stickyPopupInfo.feature} navigate={navigate} isSticky />
              </Popup>
            )}
          </ReactMapGL>
        </Box>
        <Paper sx={{ m: 4, p: 3 }}>
          <Typography variant="h6">Projects</Typography>
          <Typography variant="body2">Click on a project to see more details.</Typography>
        </Paper>
      </Box>
    </Container>
  )
}

interface PopupContentProps {
  feature: Feature
  isSticky?: boolean
  navigate: (uri: string) => void
}

const PopupContents: React.FC<PopupContentProps> = ({ feature, navigate, isSticky }) => {
  const { id, name, description, createdOn, updatedOn, owner } = feature.properties as NonNullable<
    GetProjectMapQuery['getProjectsMap']['items'][0]
  >
  const formattedDescription = description && description.length > 255 ? `${description.slice(0, 255)}...` : description
  return (
    <Stack height="100%">
      <Typography variant="subtitle2">{name}</Typography>
      {owner?.name && (
        <Typography variant="body2" color="text.secondary">
          <strong>Owner</strong>: {owner.name}
        </Typography>
      )}
      {createdOn && (
        <Typography variant="body2" color="text.secondary">
          <strong>Created</strong>: {formatISODate(createdOn)}
        </Typography>
      )}
      {updatedOn && (
        <Typography variant="body2" color="text.secondary">
          <strong>Updated</strong>: {formatISODate(updatedOn)}
        </Typography>
      )}
      {formattedDescription && (
        <Typography
          variant="caption"
          color="text.secondary"
          sx={{
            whiteSpace: 'pre-wrap',
            fontStyle: 'italic',
          }}
        >
          {formattedDescription}
        </Typography>
      )}
      <Box sx={{ flexGrow: 1 }} />
      {isSticky && <ChartButtonBar projectId={id} onNavigate={navigate} />}
    </Stack>
  )
}
