import { useNavigate, useParams } from 'react-router-dom'
import { useNotifications } from '../../AppNotifications'
import { useState } from 'react'
import { useGetCrossSectionChartOverviewQuery, useGetXsDefinitionsQuery } from '../../../schema/operations'
import { AreaChangeChart } from './AreaChangeChart'
import { XSInstanceDataQuery } from '../XSInstanceDataQuery'
import { GetReferencePlaneDataQuery, GetXsInstanceDataQuery } from '../../../schema/base.types'
import { ReferencePlaneDataQuery } from '../ReferencePlaneDataQuery'

export const AreaChangeChartContainer: React.FC = () => {
  const { projIdParam } = useParams()
  const { show } = useNotifications()
  const navigate = useNavigate()

  const [newSurveyId, setNewSurveyId] = useState<string | null>(null)
  const [oldSurveyId, setOldSurveyId] = useState<string | null>(null)
  const [referencePlaneId, setReferencePlaneId] = useState<string | null>(null)
  const [selectedXSId, setXsId] = useState<number | null>(null)

  const [newXSInstance, setNewXSInstance] = useState<GetXsInstanceDataQuery['getXSInstance'] | null>(null)
  const [oldXSInstance, setOldXsInstance] = useState<GetXsInstanceDataQuery['getXSInstance'] | null>(null)

  const [referencePlane, setReferencePlane] = useState<GetReferencePlaneDataQuery['getReferencePlane'] | null>(null)

  const projectId = projIdParam as string

  const xsDefinitionsDataQuery = useGetXsDefinitionsQuery({
    variables: { projectId },
    onCompleted: (data) => {
      if (data.getProject?.xsDefinitions.nextToken) {
        xsDefinitionsDataQuery.fetchMore({
          variables: {
            nextToken: data.getProject.xsDefinitions.nextToken,
          },
        })
      }
    },
    onError: (error) => {
      console.log('onError GetXsDefinitionsQuery', error)
      show(`Error retrieving project cross section definitions: ${error.message}`, { variant: 'error' })
    },
    skip: !projectId,
  })

  const { data } = useGetCrossSectionChartOverviewQuery({
    variables: { projectId },
    onCompleted: (data) => {
      console.log('onCompleted GetCrossSectionChartOverviewQuery', data)
    },
    onError: (error) => {
      console.log('onError GetCrossSectionChartOverviewQuery', error)
      show(`Error retrieving project cross section chart overview data: ${error.message}`, { variant: 'error' })
    },
    skip: !projectId,
  })

  const project = data?.getProject
  if (!project) return null // TODO: loading screen

  if (!newSurveyId && project.surveys.length > 0) {
    setNewSurveyId(project.surveys[0].id)
  }

  if (!oldSurveyId && project.surveys.length > 1) {
    setOldSurveyId(project.surveys[1].id)
  }

  return (
    <>
      {oldSurveyId && selectedXSId && projectId && (
        <XSInstanceDataQuery
          projectId={projectId}
          surveyId={oldSurveyId}
          xsId={selectedXSId}
          onData={(data) => {
            setOldXsInstance(data as GetXsInstanceDataQuery['getXSInstance'])
          }}
        />
      )}
      {newSurveyId && selectedXSId && projectId && (
        <XSInstanceDataQuery
          projectId={projectId}
          surveyId={newSurveyId}
          xsId={selectedXSId}
          onData={(data) => {
            setNewXSInstance(data as GetXsInstanceDataQuery['getXSInstance'])
          }}
        />
      )}
      {referencePlaneId && projectId && (
        <ReferencePlaneDataQuery
          projectId={projectId}
          referencePlaneId={referencePlaneId}
          onData={(data) => {
            setReferencePlane(data as GetReferencePlaneDataQuery['getReferencePlane'])
          }}
        />
      )}

      <AreaChangeChart
        project={project}
        newXSInstance={newXSInstance}
        oldXSInstance={oldXSInstance}
        newSurveyId={newSurveyId}
        oldSurveyId={oldSurveyId}
        selectedXSId={selectedXSId}
        referencePlaneId={referencePlaneId}
        xsDefinitions={xsDefinitionsDataQuery.data?.getProject || null}
        referencePlane={referencePlane}
        onNewSurveyChange={(surveyId: string) => setNewSurveyId(surveyId)}
        onOldSurveyChange={(surveyId: string) => setOldSurveyId(surveyId)}
        onXsDefinitionChange={(xsId: number) => setXsId(xsId)}
        onReferencePlaneChange={(referencePlaneId: string) => setReferencePlaneId(referencePlaneId)}
        onNavigate={navigate}
      />
    </>
  )
}
