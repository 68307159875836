import { FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material'
import React from 'react'

export interface ReferenceStationsProps {
  disabled: boolean
  fields: string[]
  leftStation: string | null
  rightStation: string | null
  onChangeLeftStation: (string) => void
  onChangeRightStation: (string) => void
}

export const ReferenceStations: React.FC<ReferenceStationsProps> = ({
  disabled,
  fields,
  leftStation,
  rightStation,
  onChangeLeftStation,
  onChangeRightStation,
}) => {
  // onChangeLeftStation(leftStation)
  // onChangeRightStation(rightStation)

  return (
    <Grid container spacing={2} pt={1}>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <InputLabel id="leftStation">Left Station</InputLabel>
          <Select
            label="leftStation"
            fullWidth
            disabled={disabled}
            value={leftStation ? leftStation : ''}
            error={leftStation === rightStation}
            onChange={(e) => {
              onChangeLeftStation(e.target.value)
            }}
            size="small"
          >
            {fields.map((field) => (
              <MenuItem key={field} value={field}>
                {field}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <InputLabel id="rightStation">Right Station</InputLabel>
          <Select
            label="RightStation"
            fullWidth
            disabled={disabled}
            value={rightStation ? rightStation : ''}
            error={leftStation === rightStation}
            onChange={(e) => {
              onChangeRightStation(e.target.value)
            }}
            size="small"
          >
            {fields.map((field) => (
              <MenuItem key={field} value={field}>
                {field}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  )
}
