import React from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import { DESCRIPTION_MAX_LENGTH, NAME_MAX_LENGTH, validateFieldName, validateName } from '../componentHelpers'
import { CreatePointCloudInput } from '../../schema/base.types'
import { HelpButton } from '../HelpButton'
// import { FileUpload } from '../FileUpload/FileUploadDialog'

export interface PropertyEditorDialogProps {
  open: boolean
  onSubmit: (pointCloud: CreatePointCloudInput) => void
  onClose: () => void
}

export const CreatePointCloudDialog: React.FC<PropertyEditorDialogProps> = ({ open, onSubmit, onClose }) => {
  const theme = useTheme()
  const [[name, nameValid], setName] = React.useState<[string, boolean | null]>(['', false])
  const [[zField, zFieldValid], setZField] = React.useState<[string, boolean | null]>(['', null])
  const [zFieldUnits, setZFieldUnits] = React.useState('m')
  const [description, setDescription] = React.useState('')
  const [validated, setValidated] = React.useState(false)
  const [colors, setColors] = React.useState<[string, string]>(['#000000', '#ffffff'])

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle
        sx={{
          backgroundColor: theme.palette.secondary.main,
          color: theme.palette.secondary.contrastText,
        }}
      >
        Create New Point Cloud
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" sx={{ fontSize: '0.8rem', pt: 1 }}>
          Select a zip file that contains a point feature class. Specify the floating point field in the feature class
          that contains the elevation values of each point, together with the units of the elevation values. The
          ShapeFile must possess a projection file and the feature class must possess at least one point.
        </Typography>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Name"
          type="text"
          fullWidth
          variant="outlined"
          required
          inputProps={{ maxLength: NAME_MAX_LENGTH }}
          onChange={(e) => setName([e.target.value, validateName(e.target.value)])}
          error={!nameValid}
        />
        <Grid container>
          <Grid item xs={6}>
            <TextField
              autoFocus
              margin="dense"
              id="elevationField"
              label="Elevation Field"
              type="text"
              fullWidth
              variant="outlined"
              required
              inputProps={{ maxLength: 50 }}
              onChange={(e) => setZField([e.target.value, validateFieldName(e.target.value)])}
              error={!zFieldValid}
              sx={{ pr: 1 }}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth sx={{ pt: 1 }}>
              <InputLabel id="demo-simple-select-standard-label" sx={{ pt: 1 }}>
                Elevation Field Units
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={zFieldUnits}
                onChange={(e) => setZFieldUnits(e.target.value as string)}
                label="Elevation Field Units"
                fullWidth
                required
                size="small"
              >
                <MenuItem value={'ft'}>Feet</MenuItem>
                <MenuItem value={'km'}>Kilometers</MenuItem>
                <MenuItem value={'m'}>Meters</MenuItem>
                <MenuItem value={'mile'}>Miles</MenuItem>
                <MenuItem value={'usft'}>US Survey Feet</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        {/* <FileUpload /> */}
        <TextField
          id="outlined-multiline-flexible"
          label="Description"
          multiline
          rows={3}
          fullWidth
          margin="normal"
          value={description}
          inputProps={{ maxLength: DESCRIPTION_MAX_LENGTH }}
          onChange={(e) => setDescription(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <HelpButton toolTip="Learn more about point clouds" pageName="point_clouds" />
        <Button onClick={onClose}>Cancel</Button>
        <Box sx={{ flexGrow: 1 }} />
        <Button
          onClick={() => {
            onSubmit({
              name: name.trim(),
              description: description.slice(0, DESCRIPTION_MAX_LENGTH),
              meta: [],
              zField,
              zFieldUnits,
            })
            onClose()
          }}
          variant="contained"
          disabled={!(nameValid && zFieldValid)}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}
