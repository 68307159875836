import { GetXsInstanceDataQuery } from '@xsonline/common'
import { useGetXsInstanceDataQuery } from '../../schema/operations'
import { useNotifications } from '../AppNotifications'
import log from 'loglevel'
export interface XSInstanceQueryProps {
  projectId: string
  surveyId: string
  xsId: number
  onData: (data: GetXsInstanceDataQuery['getXSInstance'] | null) => void
  onFinished?: () => void
}

export const XSInstanceDataQuery: React.FC<XSInstanceQueryProps> = ({
  projectId,
  surveyId,
  xsId,
  onData,
  onFinished,
}) => {
  const { show } = useNotifications()

  const longProfileDataQuery = useGetXsInstanceDataQuery({
    variables: { projectId, surveyId, xsId },
    onCompleted: (data) => {
      onFinished && onFinished()
      // Ensure the survey points are sorted by station ascending
      if (data.getXSInstance?.surveyPoints.items)
        data.getXSInstance.surveyPoints.items = (data.getXSInstance?.surveyPoints.items || []).sort((a, b) => a.x - b.x)

      // Signal that there's data to be integrated
      onData(data.getXSInstance || null)

      // If there's another page to fetch go get it
      if (data.getXSInstance?.surveyPoints.nextToken) {
        longProfileDataQuery.fetchMore({
          variables: {
            nextToken: data.getXSInstance.surveyPoints.nextToken,
          },
        })
      }
    },
    onError: (error) => {
      log.error('onError GetXSInstanceData', error)
      show(`Error retrieving project xs instance data: ${error.message}`, { variant: 'error' })
      onFinished && onFinished()
    },
  })

  return null
}
