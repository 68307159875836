export const supportedDistanceUnits = [
  'mm', //
  'cm',
  'm',
  'km',
  'in',
  'ft',
  'ft-us',
  'yd',
  'mi',
] as const
export const supportedAreaUnits = [
  'mm2',
  'cm2',
  'm2',
  'ha',
  'km2',
  'in2',
  'ft2',
  'ft2-us', // custom
  'yd2',
  'ac',
  'ac-us', // custom
  'mi2',
] as const
export const supportedVolumeUnits = [
  'mm3',
  'cm3',
  'm3',
  'km3',
  'in3',
  'ft3',
  'ft3-us', // custom
  'yd3',
  'af', // custom
  'af-us', // custom
  'mi3', // custom
] as const

// TODO:
// Decimeter
// SquareDecimeter
// CubicDecimeter

export interface UnitsSettings {
  surveyDistanceUnits: (typeof supportedDistanceUnits)[number]
  riverDistanceUnits: (typeof supportedDistanceUnits)[number]
  areaUnits: (typeof supportedAreaUnits)[number]
  volumeUnits: (typeof supportedVolumeUnits)[number]
}

export const SYSTEM_DATA_UNITS: UnitsSettings = {
  surveyDistanceUnits: 'm',
  riverDistanceUnits: 'm',
  areaUnits: 'm2',
  volumeUnits: 'm3',
}
