import React from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import { DESCRIPTION_MAX_LENGTH, linearUnits, validateFieldName, validateName } from '../componentHelpers'
import { CreateReferencePlaneInput } from '../../schema/base.types'
import { HelpButton } from '../HelpButton'

export interface PropertyEditorDialogProps {
  open: boolean
  onClose: () => void
  onSubmit: (item: CreateReferencePlaneInput) => void
  riverDistanceUnits: string
  elevationUnits: string
}

export const CreateReferencePlaneDialog: React.FC<PropertyEditorDialogProps> = ({
  open,
  onSubmit,
  onClose,
  riverDistanceUnits,
  elevationUnits,
}) => {
  const theme = useTheme()
  const [[name, nameValid], setName] = React.useState<[string, boolean | null]>(['', false])
  const [[xField, xFieldValid], setXField] = React.useState<[string, boolean | null]>(['', false])
  const [xFieldUnit, setXFieldUnit] = React.useState(riverDistanceUnits)
  const [[zField, zFieldValid], setZField] = React.useState<[string, boolean | null]>(['', false])
  const [zFieldUnit, setZFieldUnit] = React.useState(elevationUnits)
  const [description, setDescription] = React.useState('')
  const [validated, setValidated] = React.useState(false)
  const [colors, setColors] = React.useState<[string, string]>(['#000000', '#ffffff'])

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle
        sx={{
          backgroundColor: theme.palette.secondary.main,
          color: theme.palette.secondary.contrastText,
          mb: 2,
        }}
        color={theme.palette.primary.main}
      >
        Create New Reference Plane
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" sx={{ fontSize: '0.8rem', pt: 1 }}>
          A reference plane consists of inflection points along the project centerline. Each inflection point is defined
          by a river distance and an elevation. The river distance and elevation are defined by the fields you specify
          below. The river distance and elevation fields must be numeric fields. The river distance field must be in{' '}
          {riverDistanceUnits} and the elevation field must be in {elevationUnits}. Once you have created a reference
          plane, you must upload a zip file containing a CSV file with the river distance and elevation fields.
        </Typography>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Name"
          type="text"
          fullWidth
          variant="outlined"
          required
          value={name}
          onChange={(e) => setName([e.target.value, validateName(e.target.value)])}
          inputProps={{ maxLength: 255 }}
          error={nameValid === false}
        />
        <Grid container>
          <Grid item xs={6}>
            <TextField
              autoFocus
              sx={{ paddingBottom: 1, paddingRight: 1 }}
              margin="dense"
              id="xField"
              label="River distance field"
              type="text"
              fullWidth
              variant="outlined"
              required
              value={xField}
              onChange={(e) => setXField([e.target.value, validateFieldName(e.target.value)])}
              inputProps={{ maxLength: 255 }}
              error={xFieldValid === false}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth sx={{ marginTop: 1 }}>
              <InputLabel>River Distance Units</InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={xFieldUnit}
                onChange={(e) => setXFieldUnit(e.target.value as string)}
                label="River Distance Units"
                fullWidth
                required
                size="small"
                defaultValue={riverDistanceUnits}
              >
                {linearUnits.map((unit, idx) => {
                  return (
                    <MenuItem key={`menuitem-${idx}`} value={unit.abbreviation}>
                      {unit.name}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField
              sx={{ paddingRight: 1 }}
              autoFocus
              margin="dense"
              id="zField"
              label="Elevation field"
              type="text"
              fullWidth
              variant="outlined"
              required
              value={zField}
              onChange={(e) => setZField([e.target.value, validateFieldName(e.target.value)])}
              inputProps={{ maxLength: 255 }}
              error={zFieldValid === false}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth sx={{ marginTop: 1 }}>
              <InputLabel>Elevation Field Units</InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={zFieldUnit}
                onChange={(e) => setZFieldUnit(e.target.value as string)}
                label="Elevation Field Units"
                required
                size="small"
                defaultValue={elevationUnits}
              >
                {linearUnits.map((unit, idx) => {
                  return (
                    <MenuItem key={`menuItem-${idx}`} value={unit.abbreviation}>
                      {unit.name}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <TextField
          id="outlined-multiline-flexible"
          label="Description"
          multiline
          rows={3}
          fullWidth
          margin="normal"
          inputProps={{ maxLength: 4000 }}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <HelpButton toolTip="Learn more about reference planes" pageName="reference_planes" />
        <Button onClick={onClose}>Cancel</Button>
        <Box sx={{ flexGrow: 1 }} />
        <Button
          onClick={() => {
            onSubmit({
              name: name.trim(),
              description: description.slice(0, DESCRIPTION_MAX_LENGTH),
              meta: [],
              xField: xField,
              xFieldUnits: xFieldUnit,
              zField: zField,
              zFieldUnits: zFieldUnit,
            })
          }}
          disabled={!(nameValid && xFieldValid && zFieldValid)}
          variant="contained"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}
