import { useEffect, useState } from 'react'
import { DataGrid, DataGridApis } from '../../DataGrid'
import { riverDistanceColumnDefs } from '../long_profile/columnDefs'
import { Box, Typography } from '@mui/material'
import { ColDef } from 'ag-grid-community'

const MAX_SELECTED_ROWS = 5

export type RiverDistance = {
  id: string
  xsId: number
  distance: number
  unit: string
  name?: string | null
  surveyCount: string
}

export interface CrossSectionDistanceGridProps {
  riverDistances: RiverDistance[]
  riverDistanceUnit: string
  onRiverDistancesChange: (xsIds: RiverDistance[]) => void
  onGridApisReady?: (apis: DataGridApis) => void
}

export const CrossSectionDistanceGrid: React.FC<CrossSectionDistanceGridProps> = ({
  riverDistances,
  riverDistanceUnit,
  onRiverDistancesChange,
  onGridApisReady,
}) => {
  const [distanceGridApi, setDistanceGridApi] = useState<DataGridApis>()
  const [errorText, setErrorText] = useState<string | null>(null)

  const handleGridReady = (apis: DataGridApis) => {
    onGridApisReady && onGridApisReady(apis)
    setDistanceGridApi(apis)
  }

  // errorText should disappear after a few seconds
  useEffect(() => {
    if (!errorText) return
    const timer = setTimeout(() => {
      setErrorText(null)
    }, 3000)
    return () => clearTimeout(timer)
  })

  const handleRowSelected = (event: any) => {
    if (!distanceGridApi) return
    const newRows = distanceGridApi.api?.getSelectedRows() as RiverDistance[]
    if (newRows.length > MAX_SELECTED_ROWS) {
      event.node.setSelected(false)
      setErrorText(`You can only select up to ${MAX_SELECTED_ROWS} cross sections`)
      return
    }
    onRiverDistancesChange(newRows)
  }

  const columnDefs: ColDef[] = riverDistanceColumnDefs.map((col) => ({
    ...col,
    headerName:
      col.field === 'distance'
        ? col.headerName?.replace('Distance', `Distance (${riverDistanceUnit})`)
        : col.headerName,
  }))

  return (
    <>
      <Box flex={1}>
        <DataGrid<RiverDistance>
          columnDefs={columnDefs}
          rowData={riverDistances}
          // onSelectedRowsChanged={(rows) => onRiverDistancesChange(rows.map(({ xsId }) => xsId))}
          rowSelection="multiple"
          onGridApisReady={handleGridReady}
          onRowSelected={handleRowSelected}
        />
      </Box>
      <Typography variant="caption" color="error" sx={{ mb: 2 }} component="div" flexGrow={0}>
        {errorText || ' '}
      </Typography>
    </>
  )
}
