import * as React from 'react'
import { Box, Button, IconButton, Tooltip, Typography } from '@mui/material'
import { Download, UploadFile } from '@mui/icons-material'
import { FileStateEnum } from '../../schema/base.types'
import { FargateItem } from '../../types'

export interface UploadDownloadButtonProps extends FargateItem {
  label: string
  setUploadOpen?: () => void
}

export const UploadDownloadButton: React.FC<UploadDownloadButtonProps> = ({
  state,
  downloadUrl,
  processingMessages,
  setUploadOpen,
  label,
}) => {
  return (
    <>
      {state === FileStateEnum.None && setUploadOpen && (
        <Button variant="contained" startIcon={<UploadFile />} onClick={setUploadOpen} color="secondary">
          Upload {label}
        </Button>
      )}
      {(state === FileStateEnum.Uploaded || state === FileStateEnum.Processing) && (
        <Typography color="secondary.dark" sx={{ fontSize: '0.7em' }}>
          Status: {state === FileStateEnum.Uploaded ? 'Awaiting processing...' : 'Processing...'}
        </Typography>
      )}
      {state === FileStateEnum.Complete && downloadUrl && (
        <Tooltip title="Download">
          <Button
            variant="contained"
            startIcon={<Download />}
            href={downloadUrl as string}
            target="_blank"
            color="primary"
          >
            Download {label}
          </Button>
        </Tooltip>
      )}
      {state === FileStateEnum.Error && processingMessages && processingMessages.length > 0 && (
        <Box>
          <Typography color="error">Error:</Typography>
          {processingMessages.map((message, index) => (
            <Typography key={index} color="error" sx={{ fontFamily: 'monospace', fontSize: '0.7rem' }}>
              {message}
            </Typography>
          ))}
        </Box>
      )}
    </>
  )
}
