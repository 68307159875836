import MuiChip from '@mui/material/Chip'
import SvgIcon from '@mui/material/SvgIcon'
import { Avatar, useTheme } from '@mui/material'
import { PushPin } from '@mui/icons-material'

export interface ChipProps {
  variant?: 'standard' | 'reference'
  label: string
  color: string
  pinned?: boolean
  onDelete?: () => void
}

const RoundedSquareIcon = ({ color }: { color: string; pinned?: boolean }) => (
  <SvgIcon>
    <rect x="4" y="2" width="20" height="20" rx="2" fill={color} />
  </SvgIcon>
)

export const Chip = ({ variant = 'standard', label, color, pinned = false, onDelete }: ChipProps): JSX.Element => {
  const theme = useTheme()
  return (
    <MuiChip
      sx={{ margin: '0.2em', borderRadius: variant === 'standard' ? undefined : 1 }}
      label={label}
      icon={
        variant === 'standard' ? (
          <Avatar
            sx={{
              width: '20px',
              height: '20px',
              backgroundColor: color,
              color: '#fff',
              fontSize: '1.5em',
              fontWeight: 'bold',
              border: '1px solid #fff',
            }}
          >
            {pinned && (
              <PushPin
                sx={{
                  width: '15px',
                  height: '15px',
                  color: theme.palette.getContrastText(color),
                }}
              />
            )}
          </Avatar>
        ) : (
          <RoundedSquareIcon color={color} />
        )
      }
      variant={pinned ? 'filled' : 'outlined'}
      onDelete={onDelete}
    />
  )
}
