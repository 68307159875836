import { ApolloLink, Operation, NextLink, Observable, FetchResult } from '@apollo/client'
import produce from 'immer'
// import log from 'loglevel'

export interface AuthLinkOptions {
  filter?: (context: Record<string, unknown>) => boolean
  onError?: (error: unknown) => void
}

export class AuthLink extends ApolloLink {
  private _getToken: () => Promise<string>

  constructor(
    public auth0config: {
      domain: string
      clientId: string
      audience: string
    },
    private options: AuthLinkOptions
  ) {
    super()
    this._getToken = async () => {
      throw new Error('getToken not set')
    }
  }

  set getToken(getter: () => Promise<string>) {
    this._getToken = getter
  }

  request(operation: Operation, forward: NextLink): Observable<FetchResult> {
    return new Observable((observer) => {
      ;(async () => {
        const { filter, onError } = this.options
        if (!filter || filter(operation.getContext())) {
          try {
            const token = await this._getToken()
            operation.setContext(
              produce((draft) => {
                draft.headers = draft.headers ?? {}
                draft.headers.authorization = `Bearer ${token}`
              })
            )
          } catch (err) {
            if (onError) onError(err)
          }
        }
        forward(operation).subscribe(observer)
      })()
    })
  }
}
