import React from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  useTheme,
} from '@mui/material'

export interface ConfirmDeleteProps {
  dialogTitle: string
  message: string
  open: boolean
  onClose: () => void
  onSubmit: (id: string) => void
  itemId: string
}

export const ConfirmDelete: React.FC<ConfirmDeleteProps> = ({
  open,
  onClose,
  onSubmit,
  dialogTitle,
  message,
  itemId,
}) => {
  const theme = useTheme()

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle
        sx={{
          backgroundColor: theme.palette.error.main,
          color: theme.palette.error.contrastText,
        }}
      >
        {dialogTitle}
      </DialogTitle>
      <DialogContent>
        <Typography paragraph sx={{ py: 2 }}>
          {message}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="text" color="error">
          Cancel
        </Button>
        <Box sx={{ flexGrow: 1 }} />
        <Button onClick={() => onSubmit(itemId)} variant="contained" color="error">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  )
}
