import * as React from 'react'
import Box from '@mui/material/Box'
import List, { ListProps } from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import DeleteIcon from '@mui/icons-material/Delete'
import { Button, ListItemButton, ListItemSecondaryAction, Stack, Tooltip, alpha, useTheme } from '@mui/material'
import { Add, Edit } from '@mui/icons-material'
import { PropertyEditorDialog } from '../PropertyEditorDialog'
import { GenericDBItem } from '../../types'
import { ConfirmDelete } from '../ConfirmDelete'
import { HelpButton } from '../HelpButton'
import { FileUploadDialog } from '../FileUpload/FileUploadDialog'
import { UploadTarget } from '../../schema/base.types'
import { UploadDownloadButton } from '../FileUpload/UploadDownloadButton'

export interface GenericListProps {
  heading: string
  items: GenericDBItem[]
  canEdit: boolean
  onCreateNew?: () => void
  onDeleteListItem?: (item: GenericDBItem) => void
  onEditListItem?: (item: GenericDBItem) => void
  onItemClick?: (item: GenericDBItem) => void
  allowNew?: boolean
  createTooltip?: string
  listProps?: ListProps
  helpPageName?: string
  onUploadComplete?: (id: string) => void
  getUploadTargetUrl?: (id: string) => Promise<UploadTarget>
}

export const GenericList: React.FC<GenericListProps> = ({
  heading,
  items,
  canEdit,
  onCreateNew,
  onDeleteListItem,
  onEditListItem,
  allowNew,
  onItemClick,
  createTooltip,
  listProps,
  helpPageName,
  onUploadComplete,
  getUploadTargetUrl,
}) => {
  const theme = useTheme()
  const [openEditor, setOpenEditor] = React.useState(false)
  const [activeItem, setActiveItem] = React.useState<GenericDBItem>()
  const [openDelete, setOpenDelete] = React.useState(false)
  const [uploadOpen, setUploadOpen] = React.useState(false)

  return (
    <Box>
      <Stack
        direction="row"
        alignItems="center"
        sx={{ mt: 2, borderBottom: `1px solid ${theme.palette.text.secondary}` }}
      >
        <Typography variant="overline" component="div" color={theme.palette.text.secondary}>
          {heading}
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        {canEdit && onCreateNew && (
          <Tooltip title={createTooltip}>
            <Box>
              <Button startIcon={<Add />} size="small" onClick={onCreateNew} disabled={!allowNew}>
                Add New
              </Button>
            </Box>
          </Tooltip>
        )}
        {helpPageName && <HelpButton toolTip={`Learn more about ${heading.toLowerCase()}`} pageName={helpPageName} />}
      </Stack>
      <List {...listProps}>
        {(items || []).map((dbItem, idx) => {
          const itemContents = (
            <>
              <ListItemText primary={dbItem.name} secondary={dbItem.description && `${dbItem.description}`} />

              {canEdit && (
                <ListItemSecondaryAction>
                  {dbItem.fargateItem && (
                    <UploadDownloadButton
                      label={heading.replace(/s+$/, '')}
                      state={dbItem.fargateItem.state}
                      processingMessages={dbItem.fargateItem.processingMessages}
                      downloadUrl={dbItem.fargateItem.downloadUrl}
                      setUploadOpen={() => {
                        setActiveItem(dbItem)
                        setUploadOpen(true)
                      }}
                    />
                  )}
                  {onEditListItem && (
                    <IconButton
                      edge="end"
                      size="small"
                      color="primary"
                      aria-label="edit"
                      onClick={() => {
                        setActiveItem(dbItem)
                        setOpenEditor(true)
                      }}
                    >
                      <Edit />
                    </IconButton>
                  )}
                  {onDeleteListItem && (
                    <IconButton
                      edge="end"
                      size="small"
                      color="primary"
                      aria-label="delete"
                      onClick={() => {
                        setActiveItem(dbItem)
                        setOpenDelete(true)
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </ListItemSecondaryAction>
              )}
            </>
          )
          // Do things - code logic can go here
          if (onItemClick)
            return (
              <ListItemButton key={`list-item-${idx}`} onClick={() => onItemClick(dbItem)}>
                {itemContents}
              </ListItemButton>
            )
          return (
            <ListItem
              key={`list-item-${idx}`}
              sx={{ '&:hover': canEdit ? { backgroundColor: alpha(theme.palette.background.paper, 0.3) } : {} }}
            >
              {itemContents}
            </ListItem>
          )
        })}
      </List>
      {uploadOpen && getUploadTargetUrl && activeItem && onUploadComplete && (
        <FileUploadDialog
          title={`Upload  ${heading.replace(/s+$/, '')}`}
          helper={
            <Box>
              Select a zip file that contains a {heading.replace(/s+$/, '').toLowerCase()}. Click the help icon for more
              details.
            </Box>
          }
          helpPage={helpPageName as string}
          helpTooltip={`Learn more about ${heading.replace(/s+$/, '').toLowerCase()}.`}
          open
          onComplete={() => {
            setUploadOpen(false)
            onUploadComplete(activeItem.id)
          }}
          onClose={() => {
            setUploadOpen(false)
          }}
          getUploadTarget={() => getUploadTargetUrl(activeItem.id)}
        />
      )}

      {activeItem && (
        <>
          <PropertyEditorDialog
            open={openEditor}
            onClose={() => setOpenEditor(false)}
            typeName={heading.replace(/s+$/, '')}
            onSubmit={(changedItem) => {
              setOpenEditor(false)
              onEditListItem && onEditListItem(changedItem)
            }}
            dbItem={activeItem as GenericDBItem}
          />
          <ConfirmDelete
            open={openDelete}
            message={`Are you sure that you want to delete the ${heading.replace(/s+$/, '').toLowerCase()} ${
              activeItem?.name
            }?`}
            dialogTitle={`Delete ${heading.replace(/s+$/, '')}`}
            onClose={() => setOpenDelete(false)}
            onSubmit={() => {
              setOpenDelete(false)
              onDeleteListItem && onDeleteListItem(activeItem)
            }}
            itemId={activeItem.id}
          />
        </>
      )}
    </Box>
  )
}
