import React, { useState } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import { validateDescription, validateName, DESCRIPTION_MAX_LENGTH } from './componentHelpers'
import { Help } from '@mui/icons-material'
import { HelpButton } from './HelpButton'

export interface CopyProjectDialogProps {
  open: boolean
  onClose: () => void
  onSubmit: (name: string, description: string) => void
}

export const CopyProjectDialog: React.FC<CopyProjectDialogProps> = ({ open, onClose, onSubmit }) => {
  const theme = useTheme()
  const [[name, nameValid], setName] = useState<[string, boolean | null]>(['', false])
  const [[description, descriptionValid], setDescription] = useState(['', true])

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle
        sx={{
          backgroundColor: theme.palette.secondary.main,
          color: theme.palette.secondary.contrastText,
        }}
      >
        Copy Project
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" sx={{ fontSize: '0.8rem', pt: 1 }}>
          Copying a project replicates the entire project contents and stores the result as a new project in your user
          account. This includes the underlying point clouds, centerline, cross section definitions, selections and
          reference planes. It does not include long profiles and longitudinal changes.
        </Typography>
        <TextField
          autoFocus
          required
          margin="normal"
          id="name"
          label="New Project Name"
          type="text"
          fullWidth
          onChange={(e) => setName([e.target.value, validateName(e.target.value)])}
          error={nameValid === false}
        />
        <TextField
          id="outlined-multiline-flexible"
          label="Description"
          multiline
          rows={3}
          fullWidth
          margin="normal"
          error={descriptionValid === false}
          inputProps={{ maxLength: DESCRIPTION_MAX_LENGTH }}
          onChange={(e) => setDescription([e.target.value, validateDescription(e.target.value)])}
        />
      </DialogContent>
      <DialogActions>
        <HelpButton toolTip="Learn more about projects" pageName="projects" />
        <Button onClick={onClose}>Cancel</Button>
        <Box sx={{ flexGrow: 1 }} />
        <Button
          onClick={() => {
            onSubmit(name.trim(), description.slice(0, DESCRIPTION_MAX_LENGTH))
            onClose()
          }}
          variant="contained"
          disabled={!nameValid}
        >
          Copy Project
        </Button>
      </DialogActions>
    </Dialog>
  )
}
