import React, { useEffect } from 'react'
import { Grid, InputAdornment, TextField } from '@mui/material'

export interface UpstreamDownstreamProps {
  riverDistanceUnitsAbbr: string
  onDistancesChange: (upstream: number | null, downstream: number | null) => void
  onValidateChange: (boolean) => void
}

export const UpstreamDownstreamTextFields: React.FC<UpstreamDownstreamProps> = ({
  riverDistanceUnitsAbbr,
  onDistancesChange,
  onValidateChange,
}) => {
  const [[upstream, upstreamValid], setUpstream] = React.useState<[number | null, boolean]>([null, false])
  const [[downstream, downstreamValid], setDownstream] = React.useState<[number | null, boolean]>([null, false])

  useEffect(() => {
    onDistancesChange(upstream, downstream)
  }, [upstream, downstream])

  useEffect(() => {
    onValidateChange(upstreamValid && downstreamValid)
  }, [upstreamValid, downstreamValid])

  const validateUpstream = (value: string) => {
    if (value === null || value == '') {
      setUpstream([null, false])
      return
    }

    const newUpstreamVal = parseFloat(value)
    if (downstream !== null) {
      setUpstream([newUpstreamVal, newUpstreamVal > downstream])
      setDownstream([downstream, newUpstreamVal > downstream])
    }

    setUpstream([newUpstreamVal, true])
  }

  const validateDownstream = (value: string) => {
    if (value === null || value == '') {
      setDownstream([null, false])
      return
    }

    const newDownstreamVal = parseFloat(value)
    if (upstream !== null) {
      setUpstream([upstream, upstream > newDownstreamVal])
      setDownstream([newDownstreamVal, upstream > newDownstreamVal])
    }

    setDownstream([newDownstreamVal, true])
  }

  return (
    <Grid container>
      <Grid item xs={6}>
        <TextField
          autoFocus
          sx={{ pr: 1, pb: 1 }}
          margin="dense"
          id="name"
          label="Upstream"
          type="number"
          fullWidth
          variant="outlined"
          required
          inputProps={{ maxLength: 20 }}
          onChange={(e) => validateUpstream(e.target.value)}
          error={upstreamValid === false}
          InputProps={{
            endAdornment: <InputAdornment position="end">{riverDistanceUnitsAbbr}</InputAdornment>,
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Downstream"
          type="number"
          fullWidth
          variant="outlined"
          required
          inputProps={{ maxLength: 20 }}
          onChange={(e) => validateDownstream(e.target.value)}
          error={downstreamValid === false}
          InputProps={{
            endAdornment: <InputAdornment position="end">{riverDistanceUnitsAbbr}</InputAdornment>,
          }}
        />
      </Grid>
    </Grid>
  )
}
