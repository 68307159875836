import { useEffect, useMemo, useRef } from 'react'
import { useSnackbar, SnackbarKey, OptionsObject } from 'notistack'
import MuiIconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { uniqueId } from 'lodash'
import log from 'loglevel'
import { useSetRecoilState } from 'recoil'
import { elementsAtom } from './AppNotificationsProvider'

interface Notifications {
  show: (node: React.ReactNode, options?: OptionsObject) => void
  create: (options?: OptionsObject) => {
    render: (node: React.ReactNode) => void
    close: () => void
    release: () => void
  }
  catchError: (message: string, reThrow: boolean) => (err: Error) => void
}

const MessageContainer = ({ onMount, onUnmount }: { onMount: (el: HTMLDivElement) => void; onUnmount: () => void }) => {
  const ref = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (!ref.current) return
    onMount(ref.current)
    return onUnmount
  }, [])
  return <div ref={ref}></div>
}

const CloseButton = ({ onClick }: { onClick: () => void }) => (
  <MuiIconButton size="small" aria-label="close" color="inherit" onClick={onClick}>
    <CloseIcon fontSize="small" />
  </MuiIconButton>
)

export const useNotifications = (): Notifications => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const setElements = useSetRecoilState(elementsAtom)

  return useMemo(() => {
    const create = (options: OptionsObject = {}) => {
      const key = options.key ?? `notification-${uniqueId()}`

      let isOpen = false
      let isReleased = false

      const destroyIfReleased = () => {
        if (!isReleased) return
        window.setTimeout(() => {
          setElements((currentElements) => {
            const newElements = { ...currentElements }
            delete newElements[key]
            return newElements
          })
        }, 1000)
      }

      const release = () => {
        isReleased = true
        if (!isOpen) destroyIfReleased()
      }

      const onCloseClick = () => {
        closeSnackbar(key)
      }

      const handleUnmount = () => {
        isOpen = false
        destroyIfReleased()
      }

      const render = (node: React.ReactNode) => {
        if (isReleased) throw new Error('Cannot render notification: already released')
        setElements((currentElements) => ({
          ...currentElements,
          [key]: {
            el: currentElements[key]?.el || null,
            node,
          },
        }))
        if (!isOpen) {
          isOpen = true
          enqueueSnackbar(
            <MessageContainer
              onMount={(containerEl) => {
                setElements((currentElements) => ({
                  ...currentElements,
                  [key]: {
                    node: currentElements[key].node,
                    el: containerEl,
                  },
                }))
              }}
              onUnmount={handleUnmount}
            />,
            {
              action: () => <CloseButton onClick={onCloseClick} />,
              ...options,
              key,
            }
          )
        }
      }

      return {
        render,
        close: onCloseClick,
        release,
      }
    }

    const show = (node: React.ReactNode, options?: OptionsObject) => {
      enqueueSnackbar(node, {
        action: (key: SnackbarKey) => <CloseButton onClick={() => closeSnackbar(key)} />,
        autoHideDuration: 4000,
        ...options,
      })
    }

    const catchError = (message: string, reThrow?: boolean) => (err: Error) => {
      const errorString = (err.toString ? err.toString() : '').replace(/^Error: /, '')

      enqueueSnackbar(
        <div>
          {message}: {errorString}
        </div>,
        {
          action: (key: SnackbarKey) => <CloseButton onClick={() => closeSnackbar(key)} />,
          autoHideDuration: 4000,
          variant: 'error',
        }
      )

      log.warn(message, '\n', errorString)
      if (reThrow) throw err
    }

    return {
      show,
      create,
      catchError,
    }
  }, [enqueueSnackbar, closeSnackbar])
}
