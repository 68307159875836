import {
  Autocomplete,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import {
  FileStateEnum,
  GetCrossSectionChartOverviewQuery,
  GetReferencePlaneDataQuery,
  GetXsDefinitionsQuery,
  GetXsInstanceDataQuery,
  Project,
} from '../../../schema/base.types'
import { ViewerHeader } from '../ViewerHeader'
import { Column, Row } from '../../Layout'
import { ChartableDataSeries, SurveyDataChart } from '../../SurveyDataChart'
import { useMemo } from 'react'
import { UnitsSettings, useFormats } from '../../../lib/formats'
import { useFields } from '../../../lib/useFields'
import { ReferenceStations } from '../../referenceStations'
import { Transect } from '../../../lib/transectAreaChart/transect'
import { getElevationAtDistance } from '../../../lib/transectAreaChart/mathHelpers'
import { SurveyPoint } from '../../../lib/transectAreaChart/surveyPoint'

export interface AreaChangeChartProps {
  project: NonNullable<GetCrossSectionChartOverviewQuery['getProject']>
  xsDefinitions: NonNullable<GetXsDefinitionsQuery['getProject']> | null

  newXSInstance: GetXsInstanceDataQuery['getXSInstance'] | null
  oldXSInstance: GetXsInstanceDataQuery['getXSInstance'] | null
  newSurveyId: string | null
  oldSurveyId: string | null
  selectedXSId: number | null
  referencePlaneId: string | null
  referencePlane: GetReferencePlaneDataQuery['getReferencePlane'] | null

  onOldSurveyChange: (string) => void
  onNewSurveyChange: (string) => void
  onXsDefinitionChange: (string) => void
  onNavigate: (string) => void
  onReferencePlaneChange: (string) => void
}

export const AreaChangeChart: React.FC<AreaChangeChartProps> = ({
  project,

  newXSInstance,
  oldXSInstance,
  newSurveyId,
  oldSurveyId,
  selectedXSId,
  referencePlaneId,
  referencePlane,
  xsDefinitions,
  onNewSurveyChange,
  onOldSurveyChange,
  onXsDefinitionChange,
  onReferencePlaneChange,

  onNavigate,
}) => {
  const { convertSurveyDistance, convertRiverDistance, formatRiverDistanceHeader, formatSurveyDistanceHeader } =
    useFormats(project as UnitsSettings)

  const [fields, setFields] = useFields({
    stationFiltering: 'original',
    leftStation:
      project.crossSections.referenceStationFields.length > 0 ? project.crossSections.referenceStationFields[0] : null,
    rightStation:
      project.crossSections.referenceStationFields.length > 1 ? project.crossSections.referenceStationFields[1] : null,
    insideStations: true,
    verticalDatum: 'maximum',
    useVerticalOffset: false,
    verticalOffsetValue: 0.0,
  })

  // const newPoints = newXSInstance?.surveyPoints.items || []
  // newPoints.sort((a, b) => a.x - b.x)

  // const oldPoints = oldXSInstance?.surveyPoints.items || []
  // oldPoints.sort((a, b) => a.x - b.x)

  const activeTransects: Transect[] = [
    new Transect(
      newXSInstance?.surveyPoints.items.map((point) => {
        return new SurveyPoint(convertSurveyDistance(point.x), convertSurveyDistance(point.y))
      }) || []
    ),
    new Transect(
      oldXSInstance?.surveyPoints.items.map((point) => {
        return new SurveyPoint(convertSurveyDistance(point.x), convertSurveyDistance(point.y))
      }) || []
    ),
  ]

  const sortedXS = useMemo(() => {
    const xs = xsDefinitions?.xsDefinitions.items || []
    const sortedXs = [...xs].sort((a, b) => a.distance - b.distance)
    return sortedXs
  }, [xsDefinitions?.xsDefinitions.items])

  const [chartData, xScaleMin, xScaleMax] = useMemo<
    [ChartableDataSeries[], number | undefined, number | undefined]
  >(() => {
    const originalNewXS = activeTransects[0]
    const originalOldXS = activeTransects[1]

    // Initialize with original transect extents
    let newXS: Transect = originalNewXS
    let oldXS: Transect = originalOldXS

    // Either use the max transect elevation or the elevation of the reference plane at this distance
    const oldMaxElevation = originalOldXS.maxElevation()
    const newMaxElevation = originalNewXS.maxElevation()
    let maxElevation = Math.max(oldMaxElevation, newMaxElevation)
    if (fields.verticalDatum !== 'maximum') {
      maxElevation = 0
    }

    // Apply the optional vertical offset
    if (fields.useVerticalOffset && fields.verticalOffsetValue !== 0) {
      maxElevation += fields.verticalOffsetValue
    }

    // Get the river distance of the selected cross section
    const xsDefinition = xsDefinitions?.xsDefinitions.items.find((x) => x.xsId === selectedXSId)
    const xsDistance = xsDefinition ? xsDefinition.distance : 0

    let refPlaneElevation = 0
    if (fields.verticalDatum === 'referencePlane' && referencePlane) {
      const refPoints =
        referencePlane?.points.items.map((point) => {
          return new SurveyPoint(convertSurveyDistance(point.x), convertSurveyDistance(point.y))
        }) || []

      refPlaneElevation = getElevationAtDistance(refPoints, xsDistance)
      maxElevation = refPlaneElevation

      if (fields.useVerticalOffset && fields.verticalOffsetValue !== 0) {
        maxElevation += fields.verticalOffsetValue
      }
    }

    if (fields.stationFiltering === 'common') {
      // Find the common stations between the two transects
      const minStation = Math.max(newXS.minStation(), oldXS.minStation())
      const maxStation = Math.min(newXS.maxStation(), oldXS.maxStation())

      newXS = originalNewXS.trimBetweenStations(minStation, maxStation)
      oldXS = originalOldXS.trimBetweenStations(minStation, maxStation)
    } else if (fields.stationFiltering === 'referenceStations') {
      // Reference Stations
      const leftOriginalValue = xsDefinition?.referenceStations.find((x) => x.name === fields.leftStation)?.value
      const leftStation = leftOriginalValue ? convertSurveyDistance(leftOriginalValue) : null

      const rightOriginalValue = xsDefinition?.referenceStations.find((x) => x.name === fields.rightStation)?.value
      const rightStation = rightOriginalValue ? convertSurveyDistance(rightOriginalValue) : null

      if (leftStation && rightStation) {
        if (fields.insideStations === true) {
          // First trim
          newXS = originalNewXS.trimBetweenStations(leftStation, rightStation)
          oldXS = originalOldXS.trimBetweenStations(leftStation, rightStation)
        } else {
          // trim and cap using the reference stations
          newXS = originalNewXS.trimOutsideReferenceStations(leftStation, rightStation, maxElevation)
          oldXS = originalOldXS.trimOutsideReferenceStations(leftStation, rightStation, maxElevation)
        }
      }
    }
    // Now apply the vertical cap
    newXS = newXS.capAtElevation(maxElevation)
    oldXS = oldXS.capAtElevation(maxElevation)

    const tempSeries: ChartableDataSeries[] = [
      {
        id: 'new-original',
        label: `${project.surveys.find((s) => s.id === newSurveyId)?.name} - Original`,
        color: '#caa9eb',
        lineWidth: 6,
        data: originalNewXS.surveyPoints.map((point) => {
          return { x: point.station, y: point.elevation }
        }),
      },
      {
        id: 'old-original',
        label: `${project.surveys.find((s) => s.id === oldSurveyId)?.name} - Original`,
        color: '#33dc33',
        lineWidth: 6,
        data: originalOldXS.surveyPoints.map((point) => {
          return { x: point.station, y: point.elevation }
        }),
      },
      {
        id: 'new-adjusted',
        label: `${project.surveys.find((s) => s.id === newSurveyId)?.name} - Adjusted`,
        color: 'purple',
        lineWidth: 2,
        data: newXS.surveyPoints.map((point) => {
          return { x: point.station, y: point.elevation }
        }),
      },
      {
        id: 'old-adjusted',
        label: `${project.surveys.find((s) => s.id === oldSurveyId)?.name} - Adjusted`,
        color: '#0d440d',
        lineWidth: 2,
        data: oldXS.surveyPoints.map((p) => {
          return { x: p.station, y: p.elevation }
        }),
      },
    ]

    const chartAreaXValues = tempSeries.reduce<number[]>((acc, { data }) => [...acc, ...data.map(({ x }) => x)], [])
    const xScaleMin = Math.min(...chartAreaXValues)
    const xScaleMax = Math.max(...chartAreaXValues)

    if (fields.verticalDatum === 'referencePlane' && referencePlane) {
      const refPoints =
        referencePlane?.points.items.map((point) => {
          return new SurveyPoint(convertSurveyDistance(point.x), convertSurveyDistance(point.y))
        }) || []

      // Get the river distance of the selected cross section
      const xsDistance = xsDefinitions?.xsDefinitions.items.find((x) => x.xsId === selectedXSId)?.distance || 0
      const refPlaneElevation = getElevationAtDistance(refPoints, xsDistance)

      // Add the reference plane data to the series array
      const series = [
        ...tempSeries,
        {
          id: 'reference-plane',
          label: 'Reference Plane',
          color: 'red',
          lineWidth: 2,
          data: [
            { x: xScaleMin, y: refPlaneElevation },
            { x: xScaleMax, y: refPlaneElevation },
          ],
        },
      ]
      return [series, xScaleMin, xScaleMax]
    } else {
      return [tempSeries, xScaleMin, xScaleMax]
    }
  }, [
    activeTransects,
    convertSurveyDistance,
    convertRiverDistance,
    fields.stationFiltering,
    fields.verticalDatum,
    fields.stationFiltering,
    fields.leftStation,
    fields.rightStation,
    fields.insideStations,
    referencePlane,
  ])

  const referenceStationsValid =
    project.crossSections.referenceStationFields.length > 0 && fields.stationFiltering === 'referenceStations'

  return (
    // Since this lives inside a flex container, we need to set the flex property to 1 so that it fills the available space
    <>
      <ViewerHeader
        title="Area Change Viewer"
        helpPage="cross_sections.html#area_change"
        project={project as Project}
        onNavigate={onNavigate}
      />
      <Column height="100%" width="100%" p={2}>
        <Row flex={1} spacing={2}>
          <Column width="20em" spacing={2}>
            <Box flex={1}>
              <FormControl fullWidth>
                <InputLabel>New Survey</InputLabel>
                <Select
                  fullWidth
                  label="New Survey"
                  value={newSurveyId || ''}
                  onChange={(e) => onNewSurveyChange(e.target.value)}
                >
                  {project.surveys
                    .filter((survey) => survey.state === FileStateEnum.Complete)
                    .map((survey, idx) => (
                      <MenuItem key={idx} value={survey.id}>
                        {survey.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <Box paddingTop={1}>
                <FormControl fullWidth>
                  <InputLabel>Old Survey</InputLabel>
                  <Select
                    fullWidth
                    label="Old Survey"
                    value={oldSurveyId || ''}
                    onChange={(e) => onOldSurveyChange(e.target.value)}
                  >
                    {project.surveys.map((survey, idx) => (
                      <MenuItem key={idx} value={survey.id}>
                        {survey.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box paddingTop={1}>
                <Autocomplete
                  fullWidth
                  value={sortedXS.find((xsDef) => xsDef.xsId === selectedXSId) || null}
                  options={sortedXS || []}
                  getOptionLabel={(xsDef) => (xsDef.name ? xsDef.name : `${xsDef.distance}`)}
                  onChange={(_, value) => {
                    console.log('value', value)
                    onXsDefinitionChange(value?.xsId || null)
                  }}
                  renderInput={(params) => <TextField {...params} label="Cross Section" />}
                  renderOption={(props, option) => (
                    <Stack component="li" {...props} direction="row">
                      <Typography>{option.name || option.distance}</Typography>
                      <Box flexGrow={1} />
                      <Typography variant="caption">(xsid:{option.xsId})</Typography>
                    </Stack>
                  )}
                />
              </Box>
              <Box flex={1} border={1} padding={1} marginTop={1} borderRadius={1} borderColor={'darkgray'}>
                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={fields.stationFiltering}
                    onChange={(e) => setFields.$.stationFiltering(e.target.value)}
                    name="radio-buttons-group"
                  >
                    <FormControlLabel value="original" control={<Radio />} label="Original cross section extents" />
                    <FormControlLabel value="common" control={<Radio />} label="Common cross section extents" />
                    <FormControlLabel
                      value="referenceStations"
                      control={<Radio />}
                      label="Use reference stations"
                      disabled={project.crossSections.referenceStationFields.length < 2}
                    />
                  </RadioGroup>
                </FormControl>
                <Box marginLeft={3} padding={1} border={0} borderColor={'darkgray'} borderRadius={1}>
                  <ReferenceStations
                    disabled={!referenceStationsValid}
                    fields={project.crossSections.referenceStationFields}
                    leftStation={fields.leftStation}
                    rightStation={fields.rightStation}
                    onChangeLeftStation={setFields.$.leftStation}
                    onChangeRightStation={setFields.$.rightStation}
                  />
                  <FormControl disabled={!referenceStationsValid}>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      value={fields.insideStations ? 'inside' : 'outside'}
                      onChange={(e) => setFields.$.insideStations(e.target.value === 'inside')}
                      name="radio-buttons-group"
                    >
                      <FormControlLabel value="inside" control={<Radio />} label="Inside" />
                      <FormControlLabel value="outside" control={<Radio />} label="Outside" />
                    </RadioGroup>
                  </FormControl>
                </Box>
              </Box>
              <Box flex={1} border={1} padding={1} marginTop={1} borderRadius={1} borderColor={'darkgray'}>
                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue={'maximum'}
                    onChange={(e) => setFields.$.verticalDatum(e.target.value)}
                    name="radio-buttons-group"
                  >
                    <FormControlLabel value="maximum" control={<Radio />} label="Max cross section elevation" />
                    <FormControlLabel
                      value="referencePlane"
                      control={<Radio />}
                      label="Reference Plane"
                      disabled={project.referencePlanes.length < 1}
                    />
                  </RadioGroup>
                </FormControl>
                <Box marginLeft={3}>
                  <FormControl fullWidth disabled={fields.verticalDatum !== 'referencePlane'}>
                    <InputLabel>Reference Plane</InputLabel>
                    <Select
                      fullWidth
                      label="Reference Plane"
                      value={referencePlaneId || ''}
                      onChange={(e) => onReferencePlaneChange(e.target.value)}
                    >
                      {project.referencePlanes
                        .filter((x) => x.state == FileStateEnum.Complete)
                        .map((plane, idx) => (
                          <MenuItem key={idx} value={plane.id}>
                            {plane.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Box>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={fields.verticalDatum !== 'referencePlane'}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setFields.$.useVerticalOffset(event.target.checked)
                      }}
                    />
                  }
                  label="Vertical offset"
                  value={fields.useVerticalOffset}
                />
                <Box marginLeft={3}>
                  <TextField
                    sx={{ pr: 1, pb: 1 }}
                    margin="dense"
                    id="name"
                    type="number"
                    fullWidth
                    variant="outlined"
                    required
                    disabled={fields.verticalDatum !== 'referencePlane' || !fields.useVerticalOffset}
                    inputProps={{ maxLength: 20 }}
                    value={fields.verticalOffsetValue}
                    onChange={(e) =>
                      // check that the value is a number
                      !isNaN(parseFloat(e.target.value)) && setFields.$.verticalOffsetValue(parseFloat(e.target.value))
                    }
                    InputProps={{
                      endAdornment: <InputAdornment position="end">{project.surveyDistanceUnits}</InputAdornment>,
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Column>
          <Column flex={2}>
            <Box flex={1}>
              <SurveyDataChart
                data={chartData}
                // onChartMethodsReady={setChartMethods}
                xLegend={formatSurveyDistanceHeader('Station')}
                yLegend={formatSurveyDistanceHeader('Elevation')}
                xScaleMin={xScaleMin}
                xScaleMax={xScaleMax}
                showPlotsLegend
                showSlices
              />
            </Box>
          </Column>
        </Row>
      </Column>
    </>
  )
}
