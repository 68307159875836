import React from 'react'
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  ListItemText,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import { SelectionInput, Survey } from '../../schema/base.types'
import { validateName } from '../componentHelpers'
import { UpstreamDownstreamTextFields } from '../UpstreamDownstreamTextFields'
import { HelpButton } from '../HelpButton'
import { UnitsSettings, useFormats } from '../../lib/formats'

export interface PropertyEditorDialogProps {
  open: boolean
  onClose: () => void
  onSubmit: (item: SelectionInput) => void
  surveys: Survey[]
  riverDistanceUnitsAbbr: string
  unitSettings: UnitsSettings
}

export const CreateSelectionDialog: React.FC<PropertyEditorDialogProps> = ({
  open,
  onClose,
  onSubmit,
  surveys,
  riverDistanceUnitsAbbr,
  unitSettings,
}) => {
  const theme = useTheme()

  const [[name, nameValid], setName] = React.useState<[string, boolean | null]>(['', false])
  const [allSurveys, setAllSurveys] = React.useState('allSurveys')
  const [checkedItems, setCheckedItems] = React.useState<string[]>([])
  const [description, setDescription] = React.useState('')
  const [[upstream, downstream], setUpstreamDownstream] = React.useState<[number | null, number | null]>([null, null])
  const [distancesValid, setDistancesValid] = React.useState(false)

  const { convertProjectRiverDistanceToSystemDataUnits } = useFormats(unitSettings)

  const handleToggle = (id: string, checked: boolean) => {
    if (!checked && checkedItems.includes(id)) {
      setCheckedItems(checkedItems.filter((checkedItem) => checkedItem !== id))
    } else if (checked && !checkedItems.includes(id)) {
      setCheckedItems([...checkedItems, id])
    }
  }

  const isFormValid = checkedItems.length > 0 && nameValid && distancesValid

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle
        sx={{
          backgroundColor: theme.palette.secondary.main,
          color: theme.palette.secondary.contrastText,
        }}
      >
        Create New Selection
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" sx={{ fontSize: '0.8rem', pt: 1 }}>
          Selections are subsets of project cross sections. Each selection is defined between two river distances and
          filtered to include only cross sections that occur in one or more surveys. Once created, selections can be
          used to filter which cross sections are incorporated in other tools.
        </Typography>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Name"
          type="text"
          fullWidth
          variant="outlined"
          required
          inputProps={{ maxLength: 255 }}
          onChange={(e) => setName([e.target.value, validateName(e.target.value)])}
          error={nameValid === false}
        />
        <UpstreamDownstreamTextFields
          riverDistanceUnitsAbbr={riverDistanceUnitsAbbr}
          onDistancesChange={(upstream: number | null, downstream: number | null) => {
            setUpstreamDownstream([upstream, downstream])
          }}
          onValidateChange={setDistancesValid}
        />
        <Box border={1} borderColor="primary.main" borderRadius={1} padding={2}>
          <FormControl component="fieldset">
            <FormLabel id="demo-radio-buttons-group-label">Include cross sections that occur in:</FormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue={allSurveys}
              name="radio-buttons-group"
              onChange={(e) => setAllSurveys(e.target.value)}
            >
              <FormControlLabel value="allSurveys" control={<Radio />} label="all selected surveys" />
              <FormControlLabel value="anySurvey" control={<Radio />} label="any selected survey" />
            </RadioGroup>
          </FormControl>
          <Box
            border={1}
            borderColor="primary.main"
            borderRadius={1}
            padding={1}
            sx={{
              maxHeight: 200,
              overflowX: 'hidden',
              overflowY: 'scroll',
            }}
          >
            {surveys.map((item, idx) => (
              <Box key={idx}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkedItems.includes(item.id)}
                      onChange={(event) => {
                        handleToggle(item.id, event.target.checked)
                      }}
                    />
                  }
                  label={<ListItemText primary={item.name} />}
                />
              </Box>
            ))}
          </Box>
        </Box>

        <TextField
          id="outlined-multiline-flexible"
          label="Description"
          multiline
          rows={3}
          fullWidth
          margin="normal"
          inputProps={{ maxLength: 4000 }}
          onChange={(e) => setDescription(e.target.value)}
        />
        <Paper></Paper>
      </DialogContent>
      <DialogActions>
        <HelpButton toolTip="Learn more about selections" pageName="selections" />
        <Button onClick={onClose}>Cancel</Button>
        <Box sx={{ flexGrow: 1 }} />
        <Button
          onClick={() => {
            onSubmit({
              name: name.trim(),
              description,
              upstream: convertProjectRiverDistanceToSystemDataUnits(upstream as number),
              downstream: convertProjectRiverDistanceToSystemDataUnits(downstream as number),
              surveyIds: checkedItems,
              allSurveys: allSurveys === 'allSurveys',
            })
          }}
          variant="contained"
          disabled={!isFormValid}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}
