export const useFileUpload = (): ((url: string, data: Record<string, string | Blob | File>) => Promise<Response>) => {
  /**
   * This is a little redundant
   * @param url
   * @param fields
   * @returns
   */
  const uploadFile = async (url: string, fields: Record<string, string | Blob | File>): Promise<Response> => {
    const { file, ...restFields } = fields
    const formData = new FormData()
    Object.entries(restFields).forEach(([key, value]) => {
      formData.append(key, value)
    })
    formData.append('file', file) // N.B. AWS requires that file is last!
    return fetch(url, {
      method: 'POST',
      body: formData,
      mode: 'no-cors',
    }).then((res) => {
      return res
    })
  }

  // RETURN
  return uploadFile
}
