import React from 'react'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, useTheme } from '@mui/material'

import { useNavigate } from 'react-router-dom'
import { useUpsertUserMutation } from '../../schema/operations'
import { UserInput } from '../../schema/base.types'
import { GetUserProfileLibrary } from '@xsonline/common'
import { UserProfileForm, UserProfileChange } from './UserProfileForm'
import { useNotifications } from '../AppNotifications'
import { HelpButton } from '../HelpButton'

export interface AppProps {
  navigate: (uri: string) => void
  onSubmit: (user: UserInput) => void
  isLoading: boolean
}

/**
 * The container component. This is where we handle all the logic for the component.
 * @returns
 */
export const NewUserDialogContainer: React.FC = () => {
  const navigate = useNavigate()
  const { show } = useNotifications()
  const [upsertUserMutation, { loading }] = useUpsertUserMutation({
    refetchQueries: [GetUserProfileLibrary],
    onCompleted: () => {
      show(`User Created!`, { variant: 'success' })
    },
    onError: (err) => {
      show(`Error creating user: ${err.message}`, { variant: 'error' })
    },
  })

  const onSubmit = (user: UserInput) => {
    upsertUserMutation({
      variables: {
        user,
      },
    })
  }

  return <NewUserDialog navigate={navigate} onSubmit={onSubmit} isLoading={loading} />
}

/**
 * This is the presentation component. It should be as dumb as possible.
 */
export const NewUserDialog: React.FC<AppProps> = ({ navigate, onSubmit, isLoading }) => {
  const theme = useTheme()
  const [profileState, setProfileState] = React.useState<UserProfileChange>({
    user: {
      name: '',
      affiliation: '',
      description: '',
      meta: [],
      socialUrl: '',
    },
    valid: {},
    allValid: false,
    allMandatory: false,
  })

  return (
    <Dialog
      open
      disableEscapeKeyDown
      // onClose={() => navigate('route1')}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle
        sx={{
          backgroundColor: theme.palette.secondary.main,
          color: theme.palette.secondary.contrastText,
        }}
      >
        Welcome to RiverXS!
      </DialogTitle>
      <DialogContent>
        <Typography paragraph>
          Please enter a name that will be used to identify you throughout the system. Optionally you can include the
          organization with which you are affiliated and a short bio.
        </Typography>
        <UserProfileForm onChange={setProfileState} profileState={profileState} />
      </DialogContent>
      <DialogActions>
        {/* <Button onClick={() => navigate('route1')}>Cancel</Button> */}
        <HelpButton toolTip="Learn more about user accounts" pageName="users" />
        <Box sx={{ flexGrow: 1 }} />
        <Button
          disabled={!profileState.allMandatory || !profileState.allValid || isLoading}
          onClick={() => onSubmit(profileState.user)}
          variant="contained"
        >
          {isLoading ? 'Working...' : 'Submit'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
