import React, { useMemo, memo, useEffect, useRef, useState } from 'react'
import { ResponsiveLine, LineProps } from '@nivo/line'
import { CartesianMarkerProps } from '@nivo/core'
import { Box } from '@mui/material'
import { SurveyChartSlice } from './SurveyChartSlice'

export interface ChartMethods {
  getImageDataBlobUrl: (params: { width: number; height: number }) => Promise<string>
}

export interface ChartableDataSeries {
  id: string | number
  label: string
  color: string
  lineWidth?: number
  data: {
    x: number
    y: number
  }[]
}

export type DataMap = Record<string, { id: string; label: string; color?: string; lineWidth?: number }>

export interface ChartableReferenceValue {
  id: number
  label: string
  color?: string
  value: number
}

export interface SurveyDataChartProps {
  data: ChartableDataSeries[]
  xReferences?: ChartableReferenceValue[]
  yReferences?: ChartableReferenceValue[]
  xScaleMin?: number
  xScaleMax?: number
  yScaleMin?: number
  yScaleMax?: number
  xLegend?: string
  yLegend?: string
  lineWidth?: number
  showPlotsLegend?: boolean
  showSlices?: boolean
  xScaleDescends?: boolean
  onChartMethodsReady?: (methods: ChartMethods) => void
}

export const SurveyDataChart: React.FC<SurveyDataChartProps> = memo(
  ({
    data,
    xReferences = [],
    yReferences = [],
    xScaleMin,
    xScaleMax,
    yScaleMin,
    yScaleMax,
    xLegend,
    yLegend,
    lineWidth = 2,
    showPlotsLegend,
    showSlices,
    xScaleDescends,
    onChartMethodsReady,
  }) => {
    const instance = useRef<{ resolveImageDataBlobUrl?: (string) => void }>({
      resolveImageDataBlobUrl: undefined,
    }).current
    const extraRenderingDivRef = useRef<HTMLDivElement>(null)

    // COLORS

    const dataMap: DataMap = useMemo(() => {
      const retVal = {}
      data.forEach(({ id, label, color, lineWidth }) => {
        retVal[id] = { id, label, color, lineWidth }
      })
      return retVal
    }, [data])

    // SCALE

    const { xScaleProperties, yScaleProperties } = useMemo(() => {
      const allPoints = data.reduce<{ x: number; y: number }[]>((acc, section) => [...acc, ...section.data], [])
      return {
        xScaleProperties: {
          [xScaleDescends ? 'max' : 'min']: xScaleMin ?? Math.min(...allPoints.map((point) => point.x)),
          [xScaleDescends ? 'min' : 'max']: xScaleMax ?? Math.max(...allPoints.map((point) => point.x)),
        },
        yScaleProperties: {
          min: yScaleMin ?? Math.min(...allPoints.map((point) => point.y)),
          max: yScaleMax ?? Math.max(...allPoints.map((point) => point.y)),
        },
      }
    }, [data, xScaleDescends])

    // REFERENCE LINES

    const markers = useMemo<CartesianMarkerProps[]>(() => {
      if (data.length === 0) return []

      return [
        ...xReferences.map(({ label: legend, value, color }, idx) => ({
          axis: 'x' as const,
          value,
          lineStyle: { stroke: color || '#888888', strokeWidth: lineWidth + idx },
          legend,
          legendOrientation: 'vertical' as const,
        })),
        ...yReferences.map(({ label: legend, value, color }, idx) => ({
          axis: 'y' as const,
          value,
          lineStyle: { stroke: color || '#888888', strokeWidth: lineWidth + idx },
          legend,
          legendPosition: 'top-left' as const,
        })),
      ]
    }, [data.length > 0, xReferences, yReferences, lineWidth])

    // CHART METHODS, IMAGE SAVING

    const [extraRenderingDimensions, setExtraRenderingDimensions] = useState<{ width: number; height: number }>()

    const chartMethods = useMemo<ChartMethods>(
      () => ({
        getImageDataBlobUrl: ({ width, height }) => {
          return new Promise((resolve) => {
            setExtraRenderingDimensions({ width, height })
            instance.resolveImageDataBlobUrl = resolve
          })
        },
      }),
      []
    )

    useEffect(() => {
      window.setTimeout(() => {
        // wrap in setTimeout to ensure extra chart rendered
        if (!extraRenderingDimensions || !instance.resolveImageDataBlobUrl || !extraRenderingDivRef.current) return

        const { outerHTML } = extraRenderingDivRef.current.querySelector('svg') as SVGGraphicsElement
        const blob = new window.Blob([outerHTML], { type: 'image/svg+xml;charset=utf-8' })
        const blobUrl = window.URL.createObjectURL(blob)

        instance.resolveImageDataBlobUrl(blobUrl)
        instance.resolveImageDataBlobUrl = undefined
        setExtraRenderingDimensions(undefined)
      }, 0)
    }, [extraRenderingDimensions])

    useEffect(() => {
      if (onChartMethodsReady) onChartMethodsReady(chartMethods)
    }, [])

    // RENDER
    const theme: LineProps['theme'] = {
      textColor: '#333333',
      fontSize: 12,
      axis: {
        domain: {
          line: {
            stroke: '#777777',
            strokeWidth: 2,
          },
        },
        ticks: {
          line: {
            stroke: '#777777',
            strokeWidth: 2,
          },
        },
      },
      grid: {
        line: {
          stroke: '#dddddd',
          strokeWidth: 0.5,
        },
      },
    }

    const CustomLinesSVG = ({ series, lineGenerator, xScale, yScale }) => {
      const retVal = series.map(({ id, data, color, lineWidth }, idx) => (
        <path
          key={id}
          d={lineGenerator(
            data.map((d) => ({
              x: xScale(d.data.x),
              y: yScale(d.data.y),
            }))
          )}
          fill="none"
          stroke={dataMap[id]?.color || color}
          style={{
            strokeWidth: dataMap[id]?.lineWidth || lineWidth || 2,
          }}
        />
      ))
      return retVal
    }

    const props: LineProps = {
      data,
      margin: { top: 50, right: showPlotsLegend ? 160 : 30, bottom: 50, left: 60 },
      xScale: { type: 'linear', ...xScaleProperties },
      yScale: { type: 'linear', ...yScaleProperties },
      yFormat: ' >-.2f',
      curve: 'linear',
      markers: markers,
      axisBottom: {
        legend: xLegend,
        legendOffset: 36,
        legendPosition: 'middle',
      },
      axisLeft: {
        legend: yLegend,
        legendOffset: -40,
        legendPosition: 'middle',
      },
      enableGridX: true,
      enableSlices: showSlices ? 'x' : false,
      enableCrosshair: showSlices,
      pointSize: 0,
      theme,
      xFormat: (value) => {
        if (typeof value === 'number') return value.toFixed(2)
        return `${value}`
      },
      sliceTooltip: showSlices
        ? ({ slice }) => (
            <SurveyChartSlice points={slice.points} dataMap={dataMap} xLegend={xLegend} yLegend={yLegend} />
          )
        : undefined,
      // pointLabelYOffset: -12,
      legends: showPlotsLegend
        ? [
            {
              anchor: 'top-right',
              direction: 'column',
              justify: false,
              translateX: 140,
              translateY: 0,
              itemsSpacing: 2,
              itemDirection: 'left-to-right',
              itemWidth: 90,
              itemHeight: 12,
              itemOpacity: 0.75,
              symbolSize: 12,
              symbolShape: 'circle',
              symbolBorderColor: 'rgba(0, 0, 0, .5)',
              data: data.map(({ id, label, color }) => ({
                id,
                label,
                color: dataMap[id]?.color || color,
              })),
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemBackground: 'rgba(0, 0, 0, .03)',
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]
        : undefined,
      layers: ['grid', 'markers', 'areas', CustomLinesSVG, 'slices', 'axes', 'points', 'crosshair', 'legends'],
    }

    const renderChart = <ResponsiveLine {...props} />

    return (
      <Box
        sx={{
          height: '100%',
          position: 'relative',
          overflow: 'hidden',
          background: 'white',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
          }}
        >
          {renderChart}
        </Box>
        {extraRenderingDimensions && (
          <Box
            sx={{
              position: 'absolute',
              width: extraRenderingDimensions.width,
              height: extraRenderingDimensions.height,
              visibility: 'hidden',
            }}
            ref={extraRenderingDivRef}
          >
            {renderChart}
          </Box>
        )}
      </Box>
    )
  }
)

SurveyDataChart.displayName = 'SurveyDataChart'
