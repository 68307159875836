import React, { useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  useTheme,
} from '@mui/material'
import { logoColor } from '../theme'

export interface AboutDialogProps {
  open: boolean
  navigate?: (path: string) => void
  onClose: () => void
}

export const AboutDialog: React.FC<AboutDialogProps> = ({ open, onClose, navigate }) => {
  const theme = useTheme()
  const [[name, nameValid], setName] = useState<[string, boolean | null]>(['', false])
  const [[description, descriptionValid], setDescription] = useState(['', true])

  return (
    <Dialog open={open} onClose={onClose} sx={{ minWidth: '400' }}>
      <DialogTitle
        sx={{
          backgroundColor: theme.palette.secondary.main,
          color: theme.palette.secondary.contrastText,
        }}
      >
        <IconButton
          color="inherit"
          onClick={() => navigate && navigate('/')}
          sx={{
            color: logoColor,
          }}
        >
          <img src="/logo.svg" alt="logo" width="80px" />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" sx={{ fontSize: '0.8rem', pt: 1 }}>
          <p>
            River XS is a joint effort between the{' '}
            <a href="http://www.nwk.usace.army.mil/">Kansas City District of the U.S. Army Corps of Engineers</a>{' '}
            (USACE) and <a href="https://northarrowresearch.com">North Arrow Research Ltd</a>. It builds on over a
            decade of successful collaboration on the <a href="xsviewer.northarrowrearch.com">Cross Section Viewer</a>,
            a desktop application for viewing and analyzing bathymetric cross section data.
          </p>
          <p>
            Several of the most popular features from the desktop software are now available in River XS on the web.
            Users can upload cross sections and 3D point clouds and view the resultant cross section profiles. Long
            profiles and longitudinal changes can be generated from the data and exported to CSV files.
          </p>
          <p>
            Learn more on the <a href="https://docs.riverxs.net">documentation site</a>.
          </p>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}
