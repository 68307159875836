import React from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import { CopyAll } from '@mui/icons-material'
import { HelpButton } from '../HelpButton'

export interface ShareProjectProps {
  open: boolean
  onClose: () => void
}

export const ShareProject: React.FC<ShareProjectProps> = ({ open, onClose }) => {
  const theme = useTheme()

  const copyURLToClipboard = () => {
    const currentURL = window.location.href
    navigator.clipboard
      .writeText(currentURL)
      .then(() => {
        console.log('URL copied to clipboard')
      })
      .catch((error) => {
        console.error('Failed to copy URL to clipboard:', error)
      })
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle
        sx={{
          backgroundColor: theme.palette.secondary.main,
          color: theme.palette.secondary.contrastText,
        }}
      >
        Share Project
      </DialogTitle>
      <DialogContent>
        <Typography paragraph sx={{ py: 2 }}>
          The following URL can be used to share your project with others. Remember that only public projects will be
          accessible by other users.
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={10}>
            <TextField value={window.location.href} aria-readonly fullWidth />
          </Grid>
          <Grid item xs={1}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                copyURLToClipboard()
              }}
            >
              <CopyAll />
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <HelpButton toolTip="Learn more about projects" pageName="projects" />
        <Button onClick={onClose}>Cancel</Button>
        <Box sx={{ flexGrow: 1 }} />
      </DialogActions>
    </Dialog>
  )
}
