import { useCallback, useRef } from 'react'

// hastily generated using https://medialab.github.io/iwanthue/
const palettes = {
  default: [
    '#c14c47',
    '#58d24b',
    '#674adc',
    '#dc48bd',
    '#d7c640',
    '#b147d9',
    '#66d08a',
    '#ca713b',
    '#468534',
    '#9bce3e',
    '#6665d3',
    '#93a542',
    '#9f3f94',
    '#e95086',
    '#cc9532',
    '#618de3',
    '#e1442d',
    '#7958a6',
    '#cf87da',
    '#b23368',
  ],
  references: ['#90e3df', '#dfdb83', '#ddc5ea', '#9be8a6', '#e6d2b3'],
}

export const useStableColor = (paletteName: keyof typeof palettes = 'default') => {
  const initialState = {
    colorsById: {} as Record<string, string>,
    nextColorIndex: 0,
  }

  const instance = useRef(initialState).current

  const assignStableColor = useCallback(
    (id: string) => {
      const palette = palettes[paletteName]

      if (!instance.colorsById[id]) {
        instance.colorsById[id] = palette[instance.nextColorIndex++]
        if (instance.nextColorIndex === palette.length) instance.nextColorIndex = 0
      }
      return instance.colorsById[id]
    },
    [paletteName]
  )

  const resetStableColors = useCallback(() => {
    Object.assign(instance, initialState)
  }, [])

  return [assignStableColor, resetStableColors] as const
}
