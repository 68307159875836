import * as types from './base.types'
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export const DbFieldsFragmentDoc = gql`
    fragment DBFields on DBObj {
  id
  name
  meta {
    key
    type
    value
  }
  description
  createdOn
  updatedOn
}
    `;
export const UserFragmentFragmentDoc = gql`
    fragment UserFragment on User {
  ...DBFields
  socialUrl
  affiliation
}
    ${DbFieldsFragmentDoc}`;
export const DbListFieldsFragmentDoc = gql`
    fragment DBListFields on DBObj {
  id
  name
  createdOn
  updatedOn
}
    `;
export const UserListFragmentFragmentDoc = gql`
    fragment UserListFragment on User {
  ...DBListFields
}
    ${DbListFieldsFragmentDoc}`;
export const ProjectFragmentFragmentDoc = gql`
    fragment ProjectFragment on Project {
  ...DBFields
  owner {
    ...UserListFragment
  }
  centerline {
    updatedOn
    state
    downloadUrl
    processingMessages
  }
  crossSections {
    state
    downloadUrl
    labelField
    fieldUnits
    processingMessages
    referenceStationFields
    referenceStationUnits
  }
}
    ${DbFieldsFragmentDoc}
${UserListFragmentFragmentDoc}`;
export const ProjectListFragmentFragmentDoc = gql`
    fragment ProjectListFragment on Project {
  ...DBListFields
  isPrivate
  owner {
    ...UserListFragment
  }
  centerline {
    updatedOn
    state
  }
  crossSections {
    state
    labelField
    fieldUnits
    referenceStationFields
    referenceStationUnits
  }
}
    ${DbListFieldsFragmentDoc}
${UserListFragmentFragmentDoc}`;
export const PointCloudFragmentFragmentDoc = gql`
    fragment PointCloudFragment on PointCloud {
  ...DBFields
  zField
  zFieldUnits
  geometry
  originalSRS
}
    ${DbFieldsFragmentDoc}`;
export const PointCloudListFragmentFragmentDoc = gql`
    fragment PointCloudListFragment on PointCloud {
  ...DBListFields
}
    ${DbListFieldsFragmentDoc}`;
export const UnitsFragmentFragmentDoc = gql`
    fragment UnitsFragment on Project {
  surveyDistanceUnits
  riverDistanceUnits
  areaUnits
  volumeUnits
}
    `;
export const XsDefinitionListFragmentFragmentDoc = gql`
    fragment XSDefinitionListFragment on XSDefinition {
  xsId
  name
  createdOn
  updatedOn
  distance
  lbx
  lby
  rbx
  rby
}
    `;
export const GenericUpdateFragmentFragmentDoc = gql`
    fragment GenericUpdateFragment on DBObj {
  name
  description
}
    `;
export const LongChangeDataFragmentFragmentDoc = gql`
    fragment LongChangeDataFragment on LCBin {
  downstreamRiverDistance
  upstreamRiverDistance
  newArea
  oldArea
  newDownstreamTopWdth
  oldDownstreamTopWidth
  controlVolumeChange
  downstreamBedElevationChange
  averageBedElevationChange
  weightedBedChange
}
    `;
export const GetLongChangeChartOverviewDocument = gql`
    query getLongChangeChartOverview($projectId: ID!) {
  getProject(projectId: $projectId) {
    id
    name
    ...UnitsFragment
    longChanges {
      state
      ...DBListFields
      newSurveyName
      oldSurveyName
      selectionName
      referencePlaneName
      referencePlaneOffset
      coincident
      originalExtents
      leftReferenceStation
      rightReferenceStation
      referenceStationFilterInside
      upstream
      downstream
      binSize
    }
  }
}
    ${UnitsFragmentFragmentDoc}
${DbListFieldsFragmentDoc}`;

/**
 * __useGetLongChangeChartOverviewQuery__
 *
 * To run a query within a React component, call `useGetLongChangeChartOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLongChangeChartOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLongChangeChartOverviewQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetLongChangeChartOverviewQuery(baseOptions: Apollo.QueryHookOptions<types.GetLongChangeChartOverviewQuery, types.GetLongChangeChartOverviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetLongChangeChartOverviewQuery, types.GetLongChangeChartOverviewQueryVariables>(GetLongChangeChartOverviewDocument, options);
      }
export function useGetLongChangeChartOverviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetLongChangeChartOverviewQuery, types.GetLongChangeChartOverviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetLongChangeChartOverviewQuery, types.GetLongChangeChartOverviewQueryVariables>(GetLongChangeChartOverviewDocument, options);
        }
export type GetLongChangeChartOverviewQueryHookResult = ReturnType<typeof useGetLongChangeChartOverviewQuery>;
export type GetLongChangeChartOverviewLazyQueryHookResult = ReturnType<typeof useGetLongChangeChartOverviewLazyQuery>;
export type GetLongChangeChartOverviewQueryResult = Apollo.QueryResult<types.GetLongChangeChartOverviewQuery, types.GetLongChangeChartOverviewQueryVariables>;
export const GetLongChangeDataDocument = gql`
    query getLongChangeData($projectId: ID!, $longChangeId: ID!, $nextToken: String) {
  getLongChange(projectId: $projectId, longChangeId: $longChangeId) {
    id
    name
    newSurveyName
    oldSurveyName
    selectionName
    referencePlaneName
    referencePlaneOffset
    coincident
    originalExtents
    leftReferenceStation
    rightReferenceStation
    referenceStationFilterInside
    upstream
    downstream
    description
    createdOn
    points(nextToken: $nextToken) {
      items {
        ...LongChangeDataFragment
      }
      nextToken
    }
  }
}
    ${LongChangeDataFragmentFragmentDoc}`;

/**
 * __useGetLongChangeDataQuery__
 *
 * To run a query within a React component, call `useGetLongChangeDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLongChangeDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLongChangeDataQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      longChangeId: // value for 'longChangeId'
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useGetLongChangeDataQuery(baseOptions: Apollo.QueryHookOptions<types.GetLongChangeDataQuery, types.GetLongChangeDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetLongChangeDataQuery, types.GetLongChangeDataQueryVariables>(GetLongChangeDataDocument, options);
      }
export function useGetLongChangeDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetLongChangeDataQuery, types.GetLongChangeDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetLongChangeDataQuery, types.GetLongChangeDataQueryVariables>(GetLongChangeDataDocument, options);
        }
export type GetLongChangeDataQueryHookResult = ReturnType<typeof useGetLongChangeDataQuery>;
export type GetLongChangeDataLazyQueryHookResult = ReturnType<typeof useGetLongChangeDataLazyQuery>;
export type GetLongChangeDataQueryResult = Apollo.QueryResult<types.GetLongChangeDataQuery, types.GetLongChangeDataQueryVariables>;
export const CreateLongChangeDocument = gql`
    mutation createLongChange($projectId: ID!, $longChange: CreateLongChangeInput!) {
  createLongChange(projectId: $projectId, longChange: $longChange) {
    ...DBListFields
  }
}
    ${DbListFieldsFragmentDoc}`;
export type CreateLongChangeMutationFn = Apollo.MutationFunction<types.CreateLongChangeMutation, types.CreateLongChangeMutationVariables>;

/**
 * __useCreateLongChangeMutation__
 *
 * To run a mutation, you first call `useCreateLongChangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLongChangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLongChangeMutation, { data, loading, error }] = useCreateLongChangeMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      longChange: // value for 'longChange'
 *   },
 * });
 */
export function useCreateLongChangeMutation(baseOptions?: Apollo.MutationHookOptions<types.CreateLongChangeMutation, types.CreateLongChangeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.CreateLongChangeMutation, types.CreateLongChangeMutationVariables>(CreateLongChangeDocument, options);
      }
export type CreateLongChangeMutationHookResult = ReturnType<typeof useCreateLongChangeMutation>;
export type CreateLongChangeMutationResult = Apollo.MutationResult<types.CreateLongChangeMutation>;
export type CreateLongChangeMutationOptions = Apollo.BaseMutationOptions<types.CreateLongChangeMutation, types.CreateLongChangeMutationVariables>;
export const UpdateLongChangeDocument = gql`
    mutation updateLongChange($projectId: ID!, $longChangeId: ID!, $genericInput: GenericUpdateInput!) {
  updateLongChange(
    projectId: $projectId
    longChangeId: $longChangeId
    genericInput: $genericInput
  ) {
    ...GenericUpdateFragment
  }
}
    ${GenericUpdateFragmentFragmentDoc}`;
export type UpdateLongChangeMutationFn = Apollo.MutationFunction<types.UpdateLongChangeMutation, types.UpdateLongChangeMutationVariables>;

/**
 * __useUpdateLongChangeMutation__
 *
 * To run a mutation, you first call `useUpdateLongChangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLongChangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLongChangeMutation, { data, loading, error }] = useUpdateLongChangeMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      longChangeId: // value for 'longChangeId'
 *      genericInput: // value for 'genericInput'
 *   },
 * });
 */
export function useUpdateLongChangeMutation(baseOptions?: Apollo.MutationHookOptions<types.UpdateLongChangeMutation, types.UpdateLongChangeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.UpdateLongChangeMutation, types.UpdateLongChangeMutationVariables>(UpdateLongChangeDocument, options);
      }
export type UpdateLongChangeMutationHookResult = ReturnType<typeof useUpdateLongChangeMutation>;
export type UpdateLongChangeMutationResult = Apollo.MutationResult<types.UpdateLongChangeMutation>;
export type UpdateLongChangeMutationOptions = Apollo.BaseMutationOptions<types.UpdateLongChangeMutation, types.UpdateLongChangeMutationVariables>;
export const DeleteLongChangeDocument = gql`
    mutation deleteLongChange($projectId: ID!, $longChangeId: ID!) {
  deleteLongChange(projectId: $projectId, longChangeId: $longChangeId) {
    id
  }
}
    `;
export type DeleteLongChangeMutationFn = Apollo.MutationFunction<types.DeleteLongChangeMutation, types.DeleteLongChangeMutationVariables>;

/**
 * __useDeleteLongChangeMutation__
 *
 * To run a mutation, you first call `useDeleteLongChangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLongChangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLongChangeMutation, { data, loading, error }] = useDeleteLongChangeMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      longChangeId: // value for 'longChangeId'
 *   },
 * });
 */
export function useDeleteLongChangeMutation(baseOptions?: Apollo.MutationHookOptions<types.DeleteLongChangeMutation, types.DeleteLongChangeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.DeleteLongChangeMutation, types.DeleteLongChangeMutationVariables>(DeleteLongChangeDocument, options);
      }
export type DeleteLongChangeMutationHookResult = ReturnType<typeof useDeleteLongChangeMutation>;
export type DeleteLongChangeMutationResult = Apollo.MutationResult<types.DeleteLongChangeMutation>;
export type DeleteLongChangeMutationOptions = Apollo.BaseMutationOptions<types.DeleteLongChangeMutation, types.DeleteLongChangeMutationVariables>;
export const GetLongProfileChartOverviewDocument = gql`
    query getLongProfileChartOverview($projectId: ID!) {
  getProject(projectId: $projectId) {
    id
    name
    ...UnitsFragment
    longProfiles {
      state
      ...DBListFields
    }
    referencePlanes {
      state
      ...DBListFields
    }
  }
}
    ${UnitsFragmentFragmentDoc}
${DbListFieldsFragmentDoc}`;

/**
 * __useGetLongProfileChartOverviewQuery__
 *
 * To run a query within a React component, call `useGetLongProfileChartOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLongProfileChartOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLongProfileChartOverviewQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetLongProfileChartOverviewQuery(baseOptions: Apollo.QueryHookOptions<types.GetLongProfileChartOverviewQuery, types.GetLongProfileChartOverviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetLongProfileChartOverviewQuery, types.GetLongProfileChartOverviewQueryVariables>(GetLongProfileChartOverviewDocument, options);
      }
export function useGetLongProfileChartOverviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetLongProfileChartOverviewQuery, types.GetLongProfileChartOverviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetLongProfileChartOverviewQuery, types.GetLongProfileChartOverviewQueryVariables>(GetLongProfileChartOverviewDocument, options);
        }
export type GetLongProfileChartOverviewQueryHookResult = ReturnType<typeof useGetLongProfileChartOverviewQuery>;
export type GetLongProfileChartOverviewLazyQueryHookResult = ReturnType<typeof useGetLongProfileChartOverviewLazyQuery>;
export type GetLongProfileChartOverviewQueryResult = Apollo.QueryResult<types.GetLongProfileChartOverviewQuery, types.GetLongProfileChartOverviewQueryVariables>;
export const GetLongProfileDataDocument = gql`
    query getLongProfileData($projectId: ID!, $longProfileId: ID!, $nextToken: String) {
  getLongProfile(projectId: $projectId, longProfileId: $longProfileId) {
    id
    name
    points(nextToken: $nextToken) {
      items {
        x
        y
      }
      nextToken
    }
  }
}
    `;

/**
 * __useGetLongProfileDataQuery__
 *
 * To run a query within a React component, call `useGetLongProfileDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLongProfileDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLongProfileDataQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      longProfileId: // value for 'longProfileId'
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useGetLongProfileDataQuery(baseOptions: Apollo.QueryHookOptions<types.GetLongProfileDataQuery, types.GetLongProfileDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetLongProfileDataQuery, types.GetLongProfileDataQueryVariables>(GetLongProfileDataDocument, options);
      }
export function useGetLongProfileDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetLongProfileDataQuery, types.GetLongProfileDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetLongProfileDataQuery, types.GetLongProfileDataQueryVariables>(GetLongProfileDataDocument, options);
        }
export type GetLongProfileDataQueryHookResult = ReturnType<typeof useGetLongProfileDataQuery>;
export type GetLongProfileDataLazyQueryHookResult = ReturnType<typeof useGetLongProfileDataLazyQuery>;
export type GetLongProfileDataQueryResult = Apollo.QueryResult<types.GetLongProfileDataQuery, types.GetLongProfileDataQueryVariables>;
export const CreateLongProfileDocument = gql`
    mutation createLongProfile($projectId: ID!, $longProfile: CreateLongProfileInput!) {
  createLongProfile(projectId: $projectId, longProfile: $longProfile) {
    ...DBListFields
  }
}
    ${DbListFieldsFragmentDoc}`;
export type CreateLongProfileMutationFn = Apollo.MutationFunction<types.CreateLongProfileMutation, types.CreateLongProfileMutationVariables>;

/**
 * __useCreateLongProfileMutation__
 *
 * To run a mutation, you first call `useCreateLongProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLongProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLongProfileMutation, { data, loading, error }] = useCreateLongProfileMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      longProfile: // value for 'longProfile'
 *   },
 * });
 */
export function useCreateLongProfileMutation(baseOptions?: Apollo.MutationHookOptions<types.CreateLongProfileMutation, types.CreateLongProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.CreateLongProfileMutation, types.CreateLongProfileMutationVariables>(CreateLongProfileDocument, options);
      }
export type CreateLongProfileMutationHookResult = ReturnType<typeof useCreateLongProfileMutation>;
export type CreateLongProfileMutationResult = Apollo.MutationResult<types.CreateLongProfileMutation>;
export type CreateLongProfileMutationOptions = Apollo.BaseMutationOptions<types.CreateLongProfileMutation, types.CreateLongProfileMutationVariables>;
export const UpdateLongProfileDocument = gql`
    mutation updateLongProfile($projectId: ID!, $longProfileId: ID!, $genericInput: GenericUpdateInput!) {
  updateLongProfile(
    projectId: $projectId
    longProfileId: $longProfileId
    genericInput: $genericInput
  ) {
    ...GenericUpdateFragment
  }
}
    ${GenericUpdateFragmentFragmentDoc}`;
export type UpdateLongProfileMutationFn = Apollo.MutationFunction<types.UpdateLongProfileMutation, types.UpdateLongProfileMutationVariables>;

/**
 * __useUpdateLongProfileMutation__
 *
 * To run a mutation, you first call `useUpdateLongProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLongProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLongProfileMutation, { data, loading, error }] = useUpdateLongProfileMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      longProfileId: // value for 'longProfileId'
 *      genericInput: // value for 'genericInput'
 *   },
 * });
 */
export function useUpdateLongProfileMutation(baseOptions?: Apollo.MutationHookOptions<types.UpdateLongProfileMutation, types.UpdateLongProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.UpdateLongProfileMutation, types.UpdateLongProfileMutationVariables>(UpdateLongProfileDocument, options);
      }
export type UpdateLongProfileMutationHookResult = ReturnType<typeof useUpdateLongProfileMutation>;
export type UpdateLongProfileMutationResult = Apollo.MutationResult<types.UpdateLongProfileMutation>;
export type UpdateLongProfileMutationOptions = Apollo.BaseMutationOptions<types.UpdateLongProfileMutation, types.UpdateLongProfileMutationVariables>;
export const DeleteLongProfileDocument = gql`
    mutation deleteLongProfile($projectId: ID!, $longProfileId: ID!) {
  deleteLongProfile(projectId: $projectId, longProfileId: $longProfileId) {
    id
  }
}
    `;
export type DeleteLongProfileMutationFn = Apollo.MutationFunction<types.DeleteLongProfileMutation, types.DeleteLongProfileMutationVariables>;

/**
 * __useDeleteLongProfileMutation__
 *
 * To run a mutation, you first call `useDeleteLongProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLongProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLongProfileMutation, { data, loading, error }] = useDeleteLongProfileMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      longProfileId: // value for 'longProfileId'
 *   },
 * });
 */
export function useDeleteLongProfileMutation(baseOptions?: Apollo.MutationHookOptions<types.DeleteLongProfileMutation, types.DeleteLongProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.DeleteLongProfileMutation, types.DeleteLongProfileMutationVariables>(DeleteLongProfileDocument, options);
      }
export type DeleteLongProfileMutationHookResult = ReturnType<typeof useDeleteLongProfileMutation>;
export type DeleteLongProfileMutationResult = Apollo.MutationResult<types.DeleteLongProfileMutation>;
export type DeleteLongProfileMutationOptions = Apollo.BaseMutationOptions<types.DeleteLongProfileMutation, types.DeleteLongProfileMutationVariables>;
export const CreatePointCloudDocument = gql`
    mutation createPointCloud($pointCloud: CreatePointCloudInput!) {
  createPointCloud(pointCloud: $pointCloud) {
    ...PointCloudFragment
  }
}
    ${PointCloudFragmentFragmentDoc}`;
export type CreatePointCloudMutationFn = Apollo.MutationFunction<types.CreatePointCloudMutation, types.CreatePointCloudMutationVariables>;

/**
 * __useCreatePointCloudMutation__
 *
 * To run a mutation, you first call `useCreatePointCloudMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePointCloudMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPointCloudMutation, { data, loading, error }] = useCreatePointCloudMutation({
 *   variables: {
 *      pointCloud: // value for 'pointCloud'
 *   },
 * });
 */
export function useCreatePointCloudMutation(baseOptions?: Apollo.MutationHookOptions<types.CreatePointCloudMutation, types.CreatePointCloudMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.CreatePointCloudMutation, types.CreatePointCloudMutationVariables>(CreatePointCloudDocument, options);
      }
export type CreatePointCloudMutationHookResult = ReturnType<typeof useCreatePointCloudMutation>;
export type CreatePointCloudMutationResult = Apollo.MutationResult<types.CreatePointCloudMutation>;
export type CreatePointCloudMutationOptions = Apollo.BaseMutationOptions<types.CreatePointCloudMutation, types.CreatePointCloudMutationVariables>;
export const UpdatePointCloudDocument = gql`
    mutation updatePointCloud($pointCloudId: ID!, $genericInput: GenericUpdateInput) {
  updatePointCloud(pointCloudId: $pointCloudId, genericInput: $genericInput) {
    ...GenericUpdateFragment
  }
}
    ${GenericUpdateFragmentFragmentDoc}`;
export type UpdatePointCloudMutationFn = Apollo.MutationFunction<types.UpdatePointCloudMutation, types.UpdatePointCloudMutationVariables>;

/**
 * __useUpdatePointCloudMutation__
 *
 * To run a mutation, you first call `useUpdatePointCloudMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePointCloudMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePointCloudMutation, { data, loading, error }] = useUpdatePointCloudMutation({
 *   variables: {
 *      pointCloudId: // value for 'pointCloudId'
 *      genericInput: // value for 'genericInput'
 *   },
 * });
 */
export function useUpdatePointCloudMutation(baseOptions?: Apollo.MutationHookOptions<types.UpdatePointCloudMutation, types.UpdatePointCloudMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.UpdatePointCloudMutation, types.UpdatePointCloudMutationVariables>(UpdatePointCloudDocument, options);
      }
export type UpdatePointCloudMutationHookResult = ReturnType<typeof useUpdatePointCloudMutation>;
export type UpdatePointCloudMutationResult = Apollo.MutationResult<types.UpdatePointCloudMutation>;
export type UpdatePointCloudMutationOptions = Apollo.BaseMutationOptions<types.UpdatePointCloudMutation, types.UpdatePointCloudMutationVariables>;
export const DeletePointCloudDocument = gql`
    mutation deletePointCloud($pointCloudId: ID!) {
  deletePointCloud(pointCloudId: $pointCloudId) {
    id
  }
}
    `;
export type DeletePointCloudMutationFn = Apollo.MutationFunction<types.DeletePointCloudMutation, types.DeletePointCloudMutationVariables>;

/**
 * __useDeletePointCloudMutation__
 *
 * To run a mutation, you first call `useDeletePointCloudMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePointCloudMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePointCloudMutation, { data, loading, error }] = useDeletePointCloudMutation({
 *   variables: {
 *      pointCloudId: // value for 'pointCloudId'
 *   },
 * });
 */
export function useDeletePointCloudMutation(baseOptions?: Apollo.MutationHookOptions<types.DeletePointCloudMutation, types.DeletePointCloudMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.DeletePointCloudMutation, types.DeletePointCloudMutationVariables>(DeletePointCloudDocument, options);
      }
export type DeletePointCloudMutationHookResult = ReturnType<typeof useDeletePointCloudMutation>;
export type DeletePointCloudMutationResult = Apollo.MutationResult<types.DeletePointCloudMutation>;
export type DeletePointCloudMutationOptions = Apollo.BaseMutationOptions<types.DeletePointCloudMutation, types.DeletePointCloudMutationVariables>;
export const UploadPointCloudDocument = gql`
    mutation uploadPointCloud($pointCloudId: ID!) {
  uploadPointCloud(pointCloudId: $pointCloudId) {
    fields
    url
  }
}
    `;
export type UploadPointCloudMutationFn = Apollo.MutationFunction<types.UploadPointCloudMutation, types.UploadPointCloudMutationVariables>;

/**
 * __useUploadPointCloudMutation__
 *
 * To run a mutation, you first call `useUploadPointCloudMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadPointCloudMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadPointCloudMutation, { data, loading, error }] = useUploadPointCloudMutation({
 *   variables: {
 *      pointCloudId: // value for 'pointCloudId'
 *   },
 * });
 */
export function useUploadPointCloudMutation(baseOptions?: Apollo.MutationHookOptions<types.UploadPointCloudMutation, types.UploadPointCloudMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.UploadPointCloudMutation, types.UploadPointCloudMutationVariables>(UploadPointCloudDocument, options);
      }
export type UploadPointCloudMutationHookResult = ReturnType<typeof useUploadPointCloudMutation>;
export type UploadPointCloudMutationResult = Apollo.MutationResult<types.UploadPointCloudMutation>;
export type UploadPointCloudMutationOptions = Apollo.BaseMutationOptions<types.UploadPointCloudMutation, types.UploadPointCloudMutationVariables>;
export const UploadPointCloudCompleteDocument = gql`
    mutation uploadPointCloudComplete($pointCloudId: ID!) {
  uploadPointCloudComplete(pointCloudId: $pointCloudId) {
    ...PointCloudFragment
  }
}
    ${PointCloudFragmentFragmentDoc}`;
export type UploadPointCloudCompleteMutationFn = Apollo.MutationFunction<types.UploadPointCloudCompleteMutation, types.UploadPointCloudCompleteMutationVariables>;

/**
 * __useUploadPointCloudCompleteMutation__
 *
 * To run a mutation, you first call `useUploadPointCloudCompleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadPointCloudCompleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadPointCloudCompleteMutation, { data, loading, error }] = useUploadPointCloudCompleteMutation({
 *   variables: {
 *      pointCloudId: // value for 'pointCloudId'
 *   },
 * });
 */
export function useUploadPointCloudCompleteMutation(baseOptions?: Apollo.MutationHookOptions<types.UploadPointCloudCompleteMutation, types.UploadPointCloudCompleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.UploadPointCloudCompleteMutation, types.UploadPointCloudCompleteMutationVariables>(UploadPointCloudCompleteDocument, options);
      }
export type UploadPointCloudCompleteMutationHookResult = ReturnType<typeof useUploadPointCloudCompleteMutation>;
export type UploadPointCloudCompleteMutationResult = Apollo.MutationResult<types.UploadPointCloudCompleteMutation>;
export type UploadPointCloudCompleteMutationOptions = Apollo.BaseMutationOptions<types.UploadPointCloudCompleteMutation, types.UploadPointCloudCompleteMutationVariables>;
export const UpdatePointCloudStatusDocument = gql`
    mutation updatePointCloudStatus($pointCloudId: ID!, $fargateInput: FargateStatusInput) {
  updatePointCloud(pointCloudId: $pointCloudId, fargateInput: $fargateInput) {
    id
    updatedOn
    state
  }
}
    `;
export type UpdatePointCloudStatusMutationFn = Apollo.MutationFunction<types.UpdatePointCloudStatusMutation, types.UpdatePointCloudStatusMutationVariables>;

/**
 * __useUpdatePointCloudStatusMutation__
 *
 * To run a mutation, you first call `useUpdatePointCloudStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePointCloudStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePointCloudStatusMutation, { data, loading, error }] = useUpdatePointCloudStatusMutation({
 *   variables: {
 *      pointCloudId: // value for 'pointCloudId'
 *      fargateInput: // value for 'fargateInput'
 *   },
 * });
 */
export function useUpdatePointCloudStatusMutation(baseOptions?: Apollo.MutationHookOptions<types.UpdatePointCloudStatusMutation, types.UpdatePointCloudStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.UpdatePointCloudStatusMutation, types.UpdatePointCloudStatusMutationVariables>(UpdatePointCloudStatusDocument, options);
      }
export type UpdatePointCloudStatusMutationHookResult = ReturnType<typeof useUpdatePointCloudStatusMutation>;
export type UpdatePointCloudStatusMutationResult = Apollo.MutationResult<types.UpdatePointCloudStatusMutation>;
export type UpdatePointCloudStatusMutationOptions = Apollo.BaseMutationOptions<types.UpdatePointCloudStatusMutation, types.UpdatePointCloudStatusMutationVariables>;
export const GetProjectDetailsDocument = gql`
    query getProjectDetails($projectId: ID!) {
  getProject(projectId: $projectId) {
    ...ProjectFragment
    isPrivate
    website
    centroid
    ...UnitsFragment
    owner {
      ...UserListFragment
      pointClouds {
        ...DBListFields
        state
      }
    }
    surveys {
      ...DBListFields
      state
      processingMessages
      pointCloud {
        ...PointCloudListFragment
      }
    }
    selections {
      ...DBListFields
    }
    longProfiles {
      state
      processingMessages
      ...DBListFields
    }
    longChanges {
      state
      processingMessages
      ...DBListFields
    }
    referencePlanes {
      state
      processingMessages
      ...DBListFields
    }
  }
}
    ${ProjectFragmentFragmentDoc}
${UnitsFragmentFragmentDoc}
${UserListFragmentFragmentDoc}
${DbListFieldsFragmentDoc}
${PointCloudListFragmentFragmentDoc}`;

/**
 * __useGetProjectDetailsQuery__
 *
 * To run a query within a React component, call `useGetProjectDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectDetailsQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetProjectDetailsQuery(baseOptions: Apollo.QueryHookOptions<types.GetProjectDetailsQuery, types.GetProjectDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetProjectDetailsQuery, types.GetProjectDetailsQueryVariables>(GetProjectDetailsDocument, options);
      }
export function useGetProjectDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetProjectDetailsQuery, types.GetProjectDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetProjectDetailsQuery, types.GetProjectDetailsQueryVariables>(GetProjectDetailsDocument, options);
        }
export type GetProjectDetailsQueryHookResult = ReturnType<typeof useGetProjectDetailsQuery>;
export type GetProjectDetailsLazyQueryHookResult = ReturnType<typeof useGetProjectDetailsLazyQuery>;
export type GetProjectDetailsQueryResult = Apollo.QueryResult<types.GetProjectDetailsQuery, types.GetProjectDetailsQueryVariables>;
export const GetProjectMapDocument = gql`
    query getProjectMap($nextToken: String) {
  getProjectsMap(nextToken: $nextToken) {
    items {
      ...DBFields
      centroid
      isPrivate
      owner {
        ...DBListFields
      }
    }
    nextToken
  }
}
    ${DbFieldsFragmentDoc}
${DbListFieldsFragmentDoc}`;

/**
 * __useGetProjectMapQuery__
 *
 * To run a query within a React component, call `useGetProjectMapQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectMapQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectMapQuery({
 *   variables: {
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useGetProjectMapQuery(baseOptions?: Apollo.QueryHookOptions<types.GetProjectMapQuery, types.GetProjectMapQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetProjectMapQuery, types.GetProjectMapQueryVariables>(GetProjectMapDocument, options);
      }
export function useGetProjectMapLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetProjectMapQuery, types.GetProjectMapQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetProjectMapQuery, types.GetProjectMapQueryVariables>(GetProjectMapDocument, options);
        }
export type GetProjectMapQueryHookResult = ReturnType<typeof useGetProjectMapQuery>;
export type GetProjectMapLazyQueryHookResult = ReturnType<typeof useGetProjectMapLazyQuery>;
export type GetProjectMapQueryResult = Apollo.QueryResult<types.GetProjectMapQuery, types.GetProjectMapQueryVariables>;
export const GetCrossSectionChartOverviewDocument = gql`
    query getCrossSectionChartOverview($projectId: ID!) {
  getProject(projectId: $projectId) {
    name
    id
    ...UnitsFragment
    surveys {
      ...DBListFields
      state
    }
    selections {
      ...DBListFields
    }
    referencePlanes {
      state
      ...DBListFields
    }
    crossSections {
      referenceStationFields
    }
  }
}
    ${UnitsFragmentFragmentDoc}
${DbListFieldsFragmentDoc}`;

/**
 * __useGetCrossSectionChartOverviewQuery__
 *
 * To run a query within a React component, call `useGetCrossSectionChartOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCrossSectionChartOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCrossSectionChartOverviewQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetCrossSectionChartOverviewQuery(baseOptions: Apollo.QueryHookOptions<types.GetCrossSectionChartOverviewQuery, types.GetCrossSectionChartOverviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetCrossSectionChartOverviewQuery, types.GetCrossSectionChartOverviewQueryVariables>(GetCrossSectionChartOverviewDocument, options);
      }
export function useGetCrossSectionChartOverviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetCrossSectionChartOverviewQuery, types.GetCrossSectionChartOverviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetCrossSectionChartOverviewQuery, types.GetCrossSectionChartOverviewQueryVariables>(GetCrossSectionChartOverviewDocument, options);
        }
export type GetCrossSectionChartOverviewQueryHookResult = ReturnType<typeof useGetCrossSectionChartOverviewQuery>;
export type GetCrossSectionChartOverviewLazyQueryHookResult = ReturnType<typeof useGetCrossSectionChartOverviewLazyQuery>;
export type GetCrossSectionChartOverviewQueryResult = Apollo.QueryResult<types.GetCrossSectionChartOverviewQuery, types.GetCrossSectionChartOverviewQueryVariables>;
export const GetXsDefinitionsDocument = gql`
    query getXSDefinitions($projectId: ID!, $nextToken: String) {
  getProject(projectId: $projectId) {
    id
    xsDefinitions(nextToken: $nextToken) {
      items {
        xsId
        name
        distance
        referenceStations {
          name
          value
        }
      }
      nextToken
    }
  }
}
    `;

/**
 * __useGetXsDefinitionsQuery__
 *
 * To run a query within a React component, call `useGetXsDefinitionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetXsDefinitionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetXsDefinitionsQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useGetXsDefinitionsQuery(baseOptions: Apollo.QueryHookOptions<types.GetXsDefinitionsQuery, types.GetXsDefinitionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetXsDefinitionsQuery, types.GetXsDefinitionsQueryVariables>(GetXsDefinitionsDocument, options);
      }
export function useGetXsDefinitionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetXsDefinitionsQuery, types.GetXsDefinitionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetXsDefinitionsQuery, types.GetXsDefinitionsQueryVariables>(GetXsDefinitionsDocument, options);
        }
export type GetXsDefinitionsQueryHookResult = ReturnType<typeof useGetXsDefinitionsQuery>;
export type GetXsDefinitionsLazyQueryHookResult = ReturnType<typeof useGetXsDefinitionsLazyQuery>;
export type GetXsDefinitionsQueryResult = Apollo.QueryResult<types.GetXsDefinitionsQuery, types.GetXsDefinitionsQueryVariables>;
export const CreateProjectDocument = gql`
    mutation createProject($project: ProjectInput!) {
  createProject(project: $project) {
    ...ProjectFragment
  }
}
    ${ProjectFragmentFragmentDoc}`;
export type CreateProjectMutationFn = Apollo.MutationFunction<types.CreateProjectMutation, types.CreateProjectMutationVariables>;

/**
 * __useCreateProjectMutation__
 *
 * To run a mutation, you first call `useCreateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectMutation, { data, loading, error }] = useCreateProjectMutation({
 *   variables: {
 *      project: // value for 'project'
 *   },
 * });
 */
export function useCreateProjectMutation(baseOptions?: Apollo.MutationHookOptions<types.CreateProjectMutation, types.CreateProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.CreateProjectMutation, types.CreateProjectMutationVariables>(CreateProjectDocument, options);
      }
export type CreateProjectMutationHookResult = ReturnType<typeof useCreateProjectMutation>;
export type CreateProjectMutationResult = Apollo.MutationResult<types.CreateProjectMutation>;
export type CreateProjectMutationOptions = Apollo.BaseMutationOptions<types.CreateProjectMutation, types.CreateProjectMutationVariables>;
export const UpdateProjectDocument = gql`
    mutation updateProject($projectId: ID!, $project: ProjectUpdate!) {
  updateProject(projectId: $projectId, project: $project) {
    ...ProjectFragment
  }
}
    ${ProjectFragmentFragmentDoc}`;
export type UpdateProjectMutationFn = Apollo.MutationFunction<types.UpdateProjectMutation, types.UpdateProjectMutationVariables>;

/**
 * __useUpdateProjectMutation__
 *
 * To run a mutation, you first call `useUpdateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectMutation, { data, loading, error }] = useUpdateProjectMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      project: // value for 'project'
 *   },
 * });
 */
export function useUpdateProjectMutation(baseOptions?: Apollo.MutationHookOptions<types.UpdateProjectMutation, types.UpdateProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.UpdateProjectMutation, types.UpdateProjectMutationVariables>(UpdateProjectDocument, options);
      }
export type UpdateProjectMutationHookResult = ReturnType<typeof useUpdateProjectMutation>;
export type UpdateProjectMutationResult = Apollo.MutationResult<types.UpdateProjectMutation>;
export type UpdateProjectMutationOptions = Apollo.BaseMutationOptions<types.UpdateProjectMutation, types.UpdateProjectMutationVariables>;
export const DeleteProjectDocument = gql`
    mutation deleteProject($projectId: ID!) {
  deleteProject(projectId: $projectId) {
    id
  }
}
    `;
export type DeleteProjectMutationFn = Apollo.MutationFunction<types.DeleteProjectMutation, types.DeleteProjectMutationVariables>;

/**
 * __useDeleteProjectMutation__
 *
 * To run a mutation, you first call `useDeleteProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectMutation, { data, loading, error }] = useDeleteProjectMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useDeleteProjectMutation(baseOptions?: Apollo.MutationHookOptions<types.DeleteProjectMutation, types.DeleteProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.DeleteProjectMutation, types.DeleteProjectMutationVariables>(DeleteProjectDocument, options);
      }
export type DeleteProjectMutationHookResult = ReturnType<typeof useDeleteProjectMutation>;
export type DeleteProjectMutationResult = Apollo.MutationResult<types.DeleteProjectMutation>;
export type DeleteProjectMutationOptions = Apollo.BaseMutationOptions<types.DeleteProjectMutation, types.DeleteProjectMutationVariables>;
export const CopyProjectDocument = gql`
    mutation copyProject($projectId: ID!, $name: String!, $description: String) {
  copyProject(projectId: $projectId, name: $name, description: $description) {
    ...ProjectFragment
  }
}
    ${ProjectFragmentFragmentDoc}`;
export type CopyProjectMutationFn = Apollo.MutationFunction<types.CopyProjectMutation, types.CopyProjectMutationVariables>;

/**
 * __useCopyProjectMutation__
 *
 * To run a mutation, you first call `useCopyProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyProjectMutation, { data, loading, error }] = useCopyProjectMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useCopyProjectMutation(baseOptions?: Apollo.MutationHookOptions<types.CopyProjectMutation, types.CopyProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.CopyProjectMutation, types.CopyProjectMutationVariables>(CopyProjectDocument, options);
      }
export type CopyProjectMutationHookResult = ReturnType<typeof useCopyProjectMutation>;
export type CopyProjectMutationResult = Apollo.MutationResult<types.CopyProjectMutation>;
export type CopyProjectMutationOptions = Apollo.BaseMutationOptions<types.CopyProjectMutation, types.CopyProjectMutationVariables>;
export const UploadProjectCenterlineCompleteDocument = gql`
    mutation uploadProjectCenterlineComplete($projectId: ID!) {
  uploadProjectCenterlineComplete(projectId: $projectId) {
    ...ProjectFragment
  }
}
    ${ProjectFragmentFragmentDoc}`;
export type UploadProjectCenterlineCompleteMutationFn = Apollo.MutationFunction<types.UploadProjectCenterlineCompleteMutation, types.UploadProjectCenterlineCompleteMutationVariables>;

/**
 * __useUploadProjectCenterlineCompleteMutation__
 *
 * To run a mutation, you first call `useUploadProjectCenterlineCompleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadProjectCenterlineCompleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadProjectCenterlineCompleteMutation, { data, loading, error }] = useUploadProjectCenterlineCompleteMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useUploadProjectCenterlineCompleteMutation(baseOptions?: Apollo.MutationHookOptions<types.UploadProjectCenterlineCompleteMutation, types.UploadProjectCenterlineCompleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.UploadProjectCenterlineCompleteMutation, types.UploadProjectCenterlineCompleteMutationVariables>(UploadProjectCenterlineCompleteDocument, options);
      }
export type UploadProjectCenterlineCompleteMutationHookResult = ReturnType<typeof useUploadProjectCenterlineCompleteMutation>;
export type UploadProjectCenterlineCompleteMutationResult = Apollo.MutationResult<types.UploadProjectCenterlineCompleteMutation>;
export type UploadProjectCenterlineCompleteMutationOptions = Apollo.BaseMutationOptions<types.UploadProjectCenterlineCompleteMutation, types.UploadProjectCenterlineCompleteMutationVariables>;
export const UploadProjectCrossSectionsCompleteDocument = gql`
    mutation uploadProjectCrossSectionsComplete($projectId: ID!) {
  uploadProjectCrossSectionsComplete(projectId: $projectId) {
    ...ProjectFragment
  }
}
    ${ProjectFragmentFragmentDoc}`;
export type UploadProjectCrossSectionsCompleteMutationFn = Apollo.MutationFunction<types.UploadProjectCrossSectionsCompleteMutation, types.UploadProjectCrossSectionsCompleteMutationVariables>;

/**
 * __useUploadProjectCrossSectionsCompleteMutation__
 *
 * To run a mutation, you first call `useUploadProjectCrossSectionsCompleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadProjectCrossSectionsCompleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadProjectCrossSectionsCompleteMutation, { data, loading, error }] = useUploadProjectCrossSectionsCompleteMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useUploadProjectCrossSectionsCompleteMutation(baseOptions?: Apollo.MutationHookOptions<types.UploadProjectCrossSectionsCompleteMutation, types.UploadProjectCrossSectionsCompleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.UploadProjectCrossSectionsCompleteMutation, types.UploadProjectCrossSectionsCompleteMutationVariables>(UploadProjectCrossSectionsCompleteDocument, options);
      }
export type UploadProjectCrossSectionsCompleteMutationHookResult = ReturnType<typeof useUploadProjectCrossSectionsCompleteMutation>;
export type UploadProjectCrossSectionsCompleteMutationResult = Apollo.MutationResult<types.UploadProjectCrossSectionsCompleteMutation>;
export type UploadProjectCrossSectionsCompleteMutationOptions = Apollo.BaseMutationOptions<types.UploadProjectCrossSectionsCompleteMutation, types.UploadProjectCrossSectionsCompleteMutationVariables>;
export const UploadProjectCenterlineDocument = gql`
    mutation uploadProjectCenterline($projectId: ID!) {
  uploadProjectCenterline(projectId: $projectId) {
    fields
    url
  }
}
    `;
export type UploadProjectCenterlineMutationFn = Apollo.MutationFunction<types.UploadProjectCenterlineMutation, types.UploadProjectCenterlineMutationVariables>;

/**
 * __useUploadProjectCenterlineMutation__
 *
 * To run a mutation, you first call `useUploadProjectCenterlineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadProjectCenterlineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadProjectCenterlineMutation, { data, loading, error }] = useUploadProjectCenterlineMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useUploadProjectCenterlineMutation(baseOptions?: Apollo.MutationHookOptions<types.UploadProjectCenterlineMutation, types.UploadProjectCenterlineMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.UploadProjectCenterlineMutation, types.UploadProjectCenterlineMutationVariables>(UploadProjectCenterlineDocument, options);
      }
export type UploadProjectCenterlineMutationHookResult = ReturnType<typeof useUploadProjectCenterlineMutation>;
export type UploadProjectCenterlineMutationResult = Apollo.MutationResult<types.UploadProjectCenterlineMutation>;
export type UploadProjectCenterlineMutationOptions = Apollo.BaseMutationOptions<types.UploadProjectCenterlineMutation, types.UploadProjectCenterlineMutationVariables>;
export const UploadProjectCrossSectionsDocument = gql`
    mutation uploadProjectCrossSections($projectId: ID!) {
  uploadProjectCrossSections(projectId: $projectId) {
    fields
    url
  }
}
    `;
export type UploadProjectCrossSectionsMutationFn = Apollo.MutationFunction<types.UploadProjectCrossSectionsMutation, types.UploadProjectCrossSectionsMutationVariables>;

/**
 * __useUploadProjectCrossSectionsMutation__
 *
 * To run a mutation, you first call `useUploadProjectCrossSectionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadProjectCrossSectionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadProjectCrossSectionsMutation, { data, loading, error }] = useUploadProjectCrossSectionsMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useUploadProjectCrossSectionsMutation(baseOptions?: Apollo.MutationHookOptions<types.UploadProjectCrossSectionsMutation, types.UploadProjectCrossSectionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.UploadProjectCrossSectionsMutation, types.UploadProjectCrossSectionsMutationVariables>(UploadProjectCrossSectionsDocument, options);
      }
export type UploadProjectCrossSectionsMutationHookResult = ReturnType<typeof useUploadProjectCrossSectionsMutation>;
export type UploadProjectCrossSectionsMutationResult = Apollo.MutationResult<types.UploadProjectCrossSectionsMutation>;
export type UploadProjectCrossSectionsMutationOptions = Apollo.BaseMutationOptions<types.UploadProjectCrossSectionsMutation, types.UploadProjectCrossSectionsMutationVariables>;
export const UpdateProjectCenterlineDocument = gql`
    mutation updateProjectCenterline($projectId: ID!, $fargateInput: FargateStatusInput!) {
  updateProjectCenterline(projectId: $projectId, fargateInput: $fargateInput) {
    id
    centerline {
      updatedOn
      state
    }
  }
}
    `;
export type UpdateProjectCenterlineMutationFn = Apollo.MutationFunction<types.UpdateProjectCenterlineMutation, types.UpdateProjectCenterlineMutationVariables>;

/**
 * __useUpdateProjectCenterlineMutation__
 *
 * To run a mutation, you first call `useUpdateProjectCenterlineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectCenterlineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectCenterlineMutation, { data, loading, error }] = useUpdateProjectCenterlineMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      fargateInput: // value for 'fargateInput'
 *   },
 * });
 */
export function useUpdateProjectCenterlineMutation(baseOptions?: Apollo.MutationHookOptions<types.UpdateProjectCenterlineMutation, types.UpdateProjectCenterlineMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.UpdateProjectCenterlineMutation, types.UpdateProjectCenterlineMutationVariables>(UpdateProjectCenterlineDocument, options);
      }
export type UpdateProjectCenterlineMutationHookResult = ReturnType<typeof useUpdateProjectCenterlineMutation>;
export type UpdateProjectCenterlineMutationResult = Apollo.MutationResult<types.UpdateProjectCenterlineMutation>;
export type UpdateProjectCenterlineMutationOptions = Apollo.BaseMutationOptions<types.UpdateProjectCenterlineMutation, types.UpdateProjectCenterlineMutationVariables>;
export const UpdateProjectCrossSectionsDocument = gql`
    mutation updateProjectCrossSections($projectId: ID!, $fargateInput: FargateStatusInput!) {
  updateProjectCrossSections(projectId: $projectId, fargateInput: $fargateInput) {
    id
    crossSections {
      updatedOn
      state
    }
  }
}
    `;
export type UpdateProjectCrossSectionsMutationFn = Apollo.MutationFunction<types.UpdateProjectCrossSectionsMutation, types.UpdateProjectCrossSectionsMutationVariables>;

/**
 * __useUpdateProjectCrossSectionsMutation__
 *
 * To run a mutation, you first call `useUpdateProjectCrossSectionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectCrossSectionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectCrossSectionsMutation, { data, loading, error }] = useUpdateProjectCrossSectionsMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      fargateInput: // value for 'fargateInput'
 *   },
 * });
 */
export function useUpdateProjectCrossSectionsMutation(baseOptions?: Apollo.MutationHookOptions<types.UpdateProjectCrossSectionsMutation, types.UpdateProjectCrossSectionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.UpdateProjectCrossSectionsMutation, types.UpdateProjectCrossSectionsMutationVariables>(UpdateProjectCrossSectionsDocument, options);
      }
export type UpdateProjectCrossSectionsMutationHookResult = ReturnType<typeof useUpdateProjectCrossSectionsMutation>;
export type UpdateProjectCrossSectionsMutationResult = Apollo.MutationResult<types.UpdateProjectCrossSectionsMutation>;
export type UpdateProjectCrossSectionsMutationOptions = Apollo.BaseMutationOptions<types.UpdateProjectCrossSectionsMutation, types.UpdateProjectCrossSectionsMutationVariables>;
export const GetReferencePlaneDataDocument = gql`
    query getReferencePlaneData($projectId: ID!, $referencePlaneId: ID!, $nextToken: String) {
  getReferencePlane(projectId: $projectId, referencePlaneId: $referencePlaneId) {
    id
    name
    points(nextToken: $nextToken) {
      items {
        x
        y
      }
      nextToken
    }
  }
}
    `;

/**
 * __useGetReferencePlaneDataQuery__
 *
 * To run a query within a React component, call `useGetReferencePlaneDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReferencePlaneDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReferencePlaneDataQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      referencePlaneId: // value for 'referencePlaneId'
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useGetReferencePlaneDataQuery(baseOptions: Apollo.QueryHookOptions<types.GetReferencePlaneDataQuery, types.GetReferencePlaneDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetReferencePlaneDataQuery, types.GetReferencePlaneDataQueryVariables>(GetReferencePlaneDataDocument, options);
      }
export function useGetReferencePlaneDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetReferencePlaneDataQuery, types.GetReferencePlaneDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetReferencePlaneDataQuery, types.GetReferencePlaneDataQueryVariables>(GetReferencePlaneDataDocument, options);
        }
export type GetReferencePlaneDataQueryHookResult = ReturnType<typeof useGetReferencePlaneDataQuery>;
export type GetReferencePlaneDataLazyQueryHookResult = ReturnType<typeof useGetReferencePlaneDataLazyQuery>;
export type GetReferencePlaneDataQueryResult = Apollo.QueryResult<types.GetReferencePlaneDataQuery, types.GetReferencePlaneDataQueryVariables>;
export const CreateReferencePlaneDocument = gql`
    mutation createReferencePlane($projectId: ID!, $referencePlane: CreateReferencePlaneInput!) {
  createReferencePlane(projectId: $projectId, referencePlane: $referencePlane) {
    ...DBListFields
  }
}
    ${DbListFieldsFragmentDoc}`;
export type CreateReferencePlaneMutationFn = Apollo.MutationFunction<types.CreateReferencePlaneMutation, types.CreateReferencePlaneMutationVariables>;

/**
 * __useCreateReferencePlaneMutation__
 *
 * To run a mutation, you first call `useCreateReferencePlaneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReferencePlaneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReferencePlaneMutation, { data, loading, error }] = useCreateReferencePlaneMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      referencePlane: // value for 'referencePlane'
 *   },
 * });
 */
export function useCreateReferencePlaneMutation(baseOptions?: Apollo.MutationHookOptions<types.CreateReferencePlaneMutation, types.CreateReferencePlaneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.CreateReferencePlaneMutation, types.CreateReferencePlaneMutationVariables>(CreateReferencePlaneDocument, options);
      }
export type CreateReferencePlaneMutationHookResult = ReturnType<typeof useCreateReferencePlaneMutation>;
export type CreateReferencePlaneMutationResult = Apollo.MutationResult<types.CreateReferencePlaneMutation>;
export type CreateReferencePlaneMutationOptions = Apollo.BaseMutationOptions<types.CreateReferencePlaneMutation, types.CreateReferencePlaneMutationVariables>;
export const UpdateReferencePlaneDocument = gql`
    mutation updateReferencePlane($projectId: ID!, $referencePlaneId: ID!, $genericInput: GenericUpdateInput!) {
  updateReferencePlane(
    projectId: $projectId
    referencePlaneId: $referencePlaneId
    genericInput: $genericInput
  ) {
    ...GenericUpdateFragment
  }
}
    ${GenericUpdateFragmentFragmentDoc}`;
export type UpdateReferencePlaneMutationFn = Apollo.MutationFunction<types.UpdateReferencePlaneMutation, types.UpdateReferencePlaneMutationVariables>;

/**
 * __useUpdateReferencePlaneMutation__
 *
 * To run a mutation, you first call `useUpdateReferencePlaneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReferencePlaneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReferencePlaneMutation, { data, loading, error }] = useUpdateReferencePlaneMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      referencePlaneId: // value for 'referencePlaneId'
 *      genericInput: // value for 'genericInput'
 *   },
 * });
 */
export function useUpdateReferencePlaneMutation(baseOptions?: Apollo.MutationHookOptions<types.UpdateReferencePlaneMutation, types.UpdateReferencePlaneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.UpdateReferencePlaneMutation, types.UpdateReferencePlaneMutationVariables>(UpdateReferencePlaneDocument, options);
      }
export type UpdateReferencePlaneMutationHookResult = ReturnType<typeof useUpdateReferencePlaneMutation>;
export type UpdateReferencePlaneMutationResult = Apollo.MutationResult<types.UpdateReferencePlaneMutation>;
export type UpdateReferencePlaneMutationOptions = Apollo.BaseMutationOptions<types.UpdateReferencePlaneMutation, types.UpdateReferencePlaneMutationVariables>;
export const DeleteReferencePlaneDocument = gql`
    mutation deleteReferencePlane($projectId: ID!, $referencePlaneId: ID!) {
  deleteReferencePlane(projectId: $projectId, referencePlaneId: $referencePlaneId) {
    id
  }
}
    `;
export type DeleteReferencePlaneMutationFn = Apollo.MutationFunction<types.DeleteReferencePlaneMutation, types.DeleteReferencePlaneMutationVariables>;

/**
 * __useDeleteReferencePlaneMutation__
 *
 * To run a mutation, you first call `useDeleteReferencePlaneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteReferencePlaneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteReferencePlaneMutation, { data, loading, error }] = useDeleteReferencePlaneMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      referencePlaneId: // value for 'referencePlaneId'
 *   },
 * });
 */
export function useDeleteReferencePlaneMutation(baseOptions?: Apollo.MutationHookOptions<types.DeleteReferencePlaneMutation, types.DeleteReferencePlaneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.DeleteReferencePlaneMutation, types.DeleteReferencePlaneMutationVariables>(DeleteReferencePlaneDocument, options);
      }
export type DeleteReferencePlaneMutationHookResult = ReturnType<typeof useDeleteReferencePlaneMutation>;
export type DeleteReferencePlaneMutationResult = Apollo.MutationResult<types.DeleteReferencePlaneMutation>;
export type DeleteReferencePlaneMutationOptions = Apollo.BaseMutationOptions<types.DeleteReferencePlaneMutation, types.DeleteReferencePlaneMutationVariables>;
export const UploadReferencePlaneDocument = gql`
    mutation uploadReferencePlane($projectId: ID!, $referencePlaneId: ID!) {
  uploadReferencePlane(projectId: $projectId, referencePlaneId: $referencePlaneId) {
    fields
    url
  }
}
    `;
export type UploadReferencePlaneMutationFn = Apollo.MutationFunction<types.UploadReferencePlaneMutation, types.UploadReferencePlaneMutationVariables>;

/**
 * __useUploadReferencePlaneMutation__
 *
 * To run a mutation, you first call `useUploadReferencePlaneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadReferencePlaneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadReferencePlaneMutation, { data, loading, error }] = useUploadReferencePlaneMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      referencePlaneId: // value for 'referencePlaneId'
 *   },
 * });
 */
export function useUploadReferencePlaneMutation(baseOptions?: Apollo.MutationHookOptions<types.UploadReferencePlaneMutation, types.UploadReferencePlaneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.UploadReferencePlaneMutation, types.UploadReferencePlaneMutationVariables>(UploadReferencePlaneDocument, options);
      }
export type UploadReferencePlaneMutationHookResult = ReturnType<typeof useUploadReferencePlaneMutation>;
export type UploadReferencePlaneMutationResult = Apollo.MutationResult<types.UploadReferencePlaneMutation>;
export type UploadReferencePlaneMutationOptions = Apollo.BaseMutationOptions<types.UploadReferencePlaneMutation, types.UploadReferencePlaneMutationVariables>;
export const UploadReferencePlaneCompleteDocument = gql`
    mutation uploadReferencePlaneComplete($projectId: ID!, $referencePlaneId: ID!) {
  uploadReferencePlaneComplete(
    projectId: $projectId
    referencePlaneId: $referencePlaneId
  ) {
    ...DBListFields
  }
}
    ${DbListFieldsFragmentDoc}`;
export type UploadReferencePlaneCompleteMutationFn = Apollo.MutationFunction<types.UploadReferencePlaneCompleteMutation, types.UploadReferencePlaneCompleteMutationVariables>;

/**
 * __useUploadReferencePlaneCompleteMutation__
 *
 * To run a mutation, you first call `useUploadReferencePlaneCompleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadReferencePlaneCompleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadReferencePlaneCompleteMutation, { data, loading, error }] = useUploadReferencePlaneCompleteMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      referencePlaneId: // value for 'referencePlaneId'
 *   },
 * });
 */
export function useUploadReferencePlaneCompleteMutation(baseOptions?: Apollo.MutationHookOptions<types.UploadReferencePlaneCompleteMutation, types.UploadReferencePlaneCompleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.UploadReferencePlaneCompleteMutation, types.UploadReferencePlaneCompleteMutationVariables>(UploadReferencePlaneCompleteDocument, options);
      }
export type UploadReferencePlaneCompleteMutationHookResult = ReturnType<typeof useUploadReferencePlaneCompleteMutation>;
export type UploadReferencePlaneCompleteMutationResult = Apollo.MutationResult<types.UploadReferencePlaneCompleteMutation>;
export type UploadReferencePlaneCompleteMutationOptions = Apollo.BaseMutationOptions<types.UploadReferencePlaneCompleteMutation, types.UploadReferencePlaneCompleteMutationVariables>;
export const GetSelectionsDocument = gql`
    query GetSelections($projectId: ID!, $selectionId: ID!, $nextToken: String) {
  getSelection(projectId: $projectId, selectionId: $selectionId) {
    id
    name
    xsDefinitions(nextToken: $nextToken) {
      items {
        xsId
        distance
        name
      }
      nextToken
    }
  }
}
    `;

/**
 * __useGetSelectionsQuery__
 *
 * To run a query within a React component, call `useGetSelectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSelectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSelectionsQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      selectionId: // value for 'selectionId'
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useGetSelectionsQuery(baseOptions: Apollo.QueryHookOptions<types.GetSelectionsQuery, types.GetSelectionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetSelectionsQuery, types.GetSelectionsQueryVariables>(GetSelectionsDocument, options);
      }
export function useGetSelectionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetSelectionsQuery, types.GetSelectionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetSelectionsQuery, types.GetSelectionsQueryVariables>(GetSelectionsDocument, options);
        }
export type GetSelectionsQueryHookResult = ReturnType<typeof useGetSelectionsQuery>;
export type GetSelectionsLazyQueryHookResult = ReturnType<typeof useGetSelectionsLazyQuery>;
export type GetSelectionsQueryResult = Apollo.QueryResult<types.GetSelectionsQuery, types.GetSelectionsQueryVariables>;
export const CreateSelectionDocument = gql`
    mutation createSelection($projectId: ID!, $selection: SelectionInput!) {
  createSelection(projectId: $projectId, selection: $selection) {
    ...DBListFields
  }
}
    ${DbListFieldsFragmentDoc}`;
export type CreateSelectionMutationFn = Apollo.MutationFunction<types.CreateSelectionMutation, types.CreateSelectionMutationVariables>;

/**
 * __useCreateSelectionMutation__
 *
 * To run a mutation, you first call `useCreateSelectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSelectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSelectionMutation, { data, loading, error }] = useCreateSelectionMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      selection: // value for 'selection'
 *   },
 * });
 */
export function useCreateSelectionMutation(baseOptions?: Apollo.MutationHookOptions<types.CreateSelectionMutation, types.CreateSelectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.CreateSelectionMutation, types.CreateSelectionMutationVariables>(CreateSelectionDocument, options);
      }
export type CreateSelectionMutationHookResult = ReturnType<typeof useCreateSelectionMutation>;
export type CreateSelectionMutationResult = Apollo.MutationResult<types.CreateSelectionMutation>;
export type CreateSelectionMutationOptions = Apollo.BaseMutationOptions<types.CreateSelectionMutation, types.CreateSelectionMutationVariables>;
export const UpdateSelectionDocument = gql`
    mutation updateSelection($projectId: ID!, $selectionId: ID!, $genericInput: GenericUpdateInput!) {
  updateSelection(
    projectId: $projectId
    selectionId: $selectionId
    genericInput: $genericInput
  ) {
    ...GenericUpdateFragment
  }
}
    ${GenericUpdateFragmentFragmentDoc}`;
export type UpdateSelectionMutationFn = Apollo.MutationFunction<types.UpdateSelectionMutation, types.UpdateSelectionMutationVariables>;

/**
 * __useUpdateSelectionMutation__
 *
 * To run a mutation, you first call `useUpdateSelectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSelectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSelectionMutation, { data, loading, error }] = useUpdateSelectionMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      selectionId: // value for 'selectionId'
 *      genericInput: // value for 'genericInput'
 *   },
 * });
 */
export function useUpdateSelectionMutation(baseOptions?: Apollo.MutationHookOptions<types.UpdateSelectionMutation, types.UpdateSelectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.UpdateSelectionMutation, types.UpdateSelectionMutationVariables>(UpdateSelectionDocument, options);
      }
export type UpdateSelectionMutationHookResult = ReturnType<typeof useUpdateSelectionMutation>;
export type UpdateSelectionMutationResult = Apollo.MutationResult<types.UpdateSelectionMutation>;
export type UpdateSelectionMutationOptions = Apollo.BaseMutationOptions<types.UpdateSelectionMutation, types.UpdateSelectionMutationVariables>;
export const DeleteSelectionDocument = gql`
    mutation deleteSelection($projectId: ID!, $selectionId: ID!) {
  deleteSelection(projectId: $projectId, selectionId: $selectionId) {
    id
  }
}
    `;
export type DeleteSelectionMutationFn = Apollo.MutationFunction<types.DeleteSelectionMutation, types.DeleteSelectionMutationVariables>;

/**
 * __useDeleteSelectionMutation__
 *
 * To run a mutation, you first call `useDeleteSelectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSelectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSelectionMutation, { data, loading, error }] = useDeleteSelectionMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      selectionId: // value for 'selectionId'
 *   },
 * });
 */
export function useDeleteSelectionMutation(baseOptions?: Apollo.MutationHookOptions<types.DeleteSelectionMutation, types.DeleteSelectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.DeleteSelectionMutation, types.DeleteSelectionMutationVariables>(DeleteSelectionDocument, options);
      }
export type DeleteSelectionMutationHookResult = ReturnType<typeof useDeleteSelectionMutation>;
export type DeleteSelectionMutationResult = Apollo.MutationResult<types.DeleteSelectionMutation>;
export type DeleteSelectionMutationOptions = Apollo.BaseMutationOptions<types.DeleteSelectionMutation, types.DeleteSelectionMutationVariables>;
export const GetSurveyXsInstancesDocument = gql`
    query getSurveyXsInstances($projectId: ID!, $surveyId: ID!, $nextToken: String) {
  getSurvey(projectId: $projectId, surveyId: $surveyId) {
    id
    xsInstances(nextToken: $nextToken) {
      items {
        xsDefinition {
          xsId
        }
      }
      nextToken
    }
  }
}
    `;

/**
 * __useGetSurveyXsInstancesQuery__
 *
 * To run a query within a React component, call `useGetSurveyXsInstancesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSurveyXsInstancesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSurveyXsInstancesQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      surveyId: // value for 'surveyId'
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useGetSurveyXsInstancesQuery(baseOptions: Apollo.QueryHookOptions<types.GetSurveyXsInstancesQuery, types.GetSurveyXsInstancesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetSurveyXsInstancesQuery, types.GetSurveyXsInstancesQueryVariables>(GetSurveyXsInstancesDocument, options);
      }
export function useGetSurveyXsInstancesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetSurveyXsInstancesQuery, types.GetSurveyXsInstancesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetSurveyXsInstancesQuery, types.GetSurveyXsInstancesQueryVariables>(GetSurveyXsInstancesDocument, options);
        }
export type GetSurveyXsInstancesQueryHookResult = ReturnType<typeof useGetSurveyXsInstancesQuery>;
export type GetSurveyXsInstancesLazyQueryHookResult = ReturnType<typeof useGetSurveyXsInstancesLazyQuery>;
export type GetSurveyXsInstancesQueryResult = Apollo.QueryResult<types.GetSurveyXsInstancesQuery, types.GetSurveyXsInstancesQueryVariables>;
export const CreateSurveyDocument = gql`
    mutation createSurvey($projectId: ID!, $survey: SurveyInput!) {
  createSurvey(projectId: $projectId, survey: $survey) {
    ...DBListFields
  }
}
    ${DbListFieldsFragmentDoc}`;
export type CreateSurveyMutationFn = Apollo.MutationFunction<types.CreateSurveyMutation, types.CreateSurveyMutationVariables>;

/**
 * __useCreateSurveyMutation__
 *
 * To run a mutation, you first call `useCreateSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSurveyMutation, { data, loading, error }] = useCreateSurveyMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      survey: // value for 'survey'
 *   },
 * });
 */
export function useCreateSurveyMutation(baseOptions?: Apollo.MutationHookOptions<types.CreateSurveyMutation, types.CreateSurveyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.CreateSurveyMutation, types.CreateSurveyMutationVariables>(CreateSurveyDocument, options);
      }
export type CreateSurveyMutationHookResult = ReturnType<typeof useCreateSurveyMutation>;
export type CreateSurveyMutationResult = Apollo.MutationResult<types.CreateSurveyMutation>;
export type CreateSurveyMutationOptions = Apollo.BaseMutationOptions<types.CreateSurveyMutation, types.CreateSurveyMutationVariables>;
export const UpdateSurveyDocument = gql`
    mutation updateSurvey($projectId: ID!, $surveyId: ID!, $genericInput: GenericUpdateInput) {
  updateSurvey(
    projectId: $projectId
    surveyId: $surveyId
    genericInput: $genericInput
  ) {
    ...GenericUpdateFragment
  }
}
    ${GenericUpdateFragmentFragmentDoc}`;
export type UpdateSurveyMutationFn = Apollo.MutationFunction<types.UpdateSurveyMutation, types.UpdateSurveyMutationVariables>;

/**
 * __useUpdateSurveyMutation__
 *
 * To run a mutation, you first call `useUpdateSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSurveyMutation, { data, loading, error }] = useUpdateSurveyMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      surveyId: // value for 'surveyId'
 *      genericInput: // value for 'genericInput'
 *   },
 * });
 */
export function useUpdateSurveyMutation(baseOptions?: Apollo.MutationHookOptions<types.UpdateSurveyMutation, types.UpdateSurveyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.UpdateSurveyMutation, types.UpdateSurveyMutationVariables>(UpdateSurveyDocument, options);
      }
export type UpdateSurveyMutationHookResult = ReturnType<typeof useUpdateSurveyMutation>;
export type UpdateSurveyMutationResult = Apollo.MutationResult<types.UpdateSurveyMutation>;
export type UpdateSurveyMutationOptions = Apollo.BaseMutationOptions<types.UpdateSurveyMutation, types.UpdateSurveyMutationVariables>;
export const DeleteSurveyDocument = gql`
    mutation deleteSurvey($projectId: ID!, $surveyId: ID!) {
  deleteSurvey(projectId: $projectId, surveyId: $surveyId) {
    id
  }
}
    `;
export type DeleteSurveyMutationFn = Apollo.MutationFunction<types.DeleteSurveyMutation, types.DeleteSurveyMutationVariables>;

/**
 * __useDeleteSurveyMutation__
 *
 * To run a mutation, you first call `useDeleteSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSurveyMutation, { data, loading, error }] = useDeleteSurveyMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      surveyId: // value for 'surveyId'
 *   },
 * });
 */
export function useDeleteSurveyMutation(baseOptions?: Apollo.MutationHookOptions<types.DeleteSurveyMutation, types.DeleteSurveyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.DeleteSurveyMutation, types.DeleteSurveyMutationVariables>(DeleteSurveyDocument, options);
      }
export type DeleteSurveyMutationHookResult = ReturnType<typeof useDeleteSurveyMutation>;
export type DeleteSurveyMutationResult = Apollo.MutationResult<types.DeleteSurveyMutation>;
export type DeleteSurveyMutationOptions = Apollo.BaseMutationOptions<types.DeleteSurveyMutation, types.DeleteSurveyMutationVariables>;
export const GetUserProfileLibraryDocument = gql`
    query getUserProfileLibrary {
  getUserProfile {
    ...UserFragment
    pointClouds {
      ...DBListFields
      state
      description
      processingMessages
    }
    projects {
      ...ProjectListFragment
      description
    }
  }
}
    ${UserFragmentFragmentDoc}
${DbListFieldsFragmentDoc}
${ProjectListFragmentFragmentDoc}`;

/**
 * __useGetUserProfileLibraryQuery__
 *
 * To run a query within a React component, call `useGetUserProfileLibraryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserProfileLibraryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserProfileLibraryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserProfileLibraryQuery(baseOptions?: Apollo.QueryHookOptions<types.GetUserProfileLibraryQuery, types.GetUserProfileLibraryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetUserProfileLibraryQuery, types.GetUserProfileLibraryQueryVariables>(GetUserProfileLibraryDocument, options);
      }
export function useGetUserProfileLibraryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetUserProfileLibraryQuery, types.GetUserProfileLibraryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetUserProfileLibraryQuery, types.GetUserProfileLibraryQueryVariables>(GetUserProfileLibraryDocument, options);
        }
export type GetUserProfileLibraryQueryHookResult = ReturnType<typeof useGetUserProfileLibraryQuery>;
export type GetUserProfileLibraryLazyQueryHookResult = ReturnType<typeof useGetUserProfileLibraryLazyQuery>;
export type GetUserProfileLibraryQueryResult = Apollo.QueryResult<types.GetUserProfileLibraryQuery, types.GetUserProfileLibraryQueryVariables>;
export const UpsertUserDocument = gql`
    mutation upsertUser($user: UserInput!) {
  upsertUser(user: $user) {
    ...UserFragment
  }
}
    ${UserFragmentFragmentDoc}`;
export type UpsertUserMutationFn = Apollo.MutationFunction<types.UpsertUserMutation, types.UpsertUserMutationVariables>;

/**
 * __useUpsertUserMutation__
 *
 * To run a mutation, you first call `useUpsertUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertUserMutation, { data, loading, error }] = useUpsertUserMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useUpsertUserMutation(baseOptions?: Apollo.MutationHookOptions<types.UpsertUserMutation, types.UpsertUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.UpsertUserMutation, types.UpsertUserMutationVariables>(UpsertUserDocument, options);
      }
export type UpsertUserMutationHookResult = ReturnType<typeof useUpsertUserMutation>;
export type UpsertUserMutationResult = Apollo.MutationResult<types.UpsertUserMutation>;
export type UpsertUserMutationOptions = Apollo.BaseMutationOptions<types.UpsertUserMutation, types.UpsertUserMutationVariables>;
export const GetXsInstanceDataDocument = gql`
    query getXSInstanceData($projectId: ID!, $surveyId: ID!, $xsId: Int!, $nextToekn: String) {
  getXSInstance(projectId: $projectId, surveyId: $surveyId, xsId: $xsId) {
    id
    surveyId
    xsDefinition {
      name
      distance
      xsId
      referenceStations {
        name
        value
      }
    }
    surveyPoints(nextToken: $nextToekn) {
      items {
        x
        y
      }
      nextToken
    }
  }
}
    `;

/**
 * __useGetXsInstanceDataQuery__
 *
 * To run a query within a React component, call `useGetXsInstanceDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetXsInstanceDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetXsInstanceDataQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      surveyId: // value for 'surveyId'
 *      xsId: // value for 'xsId'
 *      nextToekn: // value for 'nextToekn'
 *   },
 * });
 */
export function useGetXsInstanceDataQuery(baseOptions: Apollo.QueryHookOptions<types.GetXsInstanceDataQuery, types.GetXsInstanceDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetXsInstanceDataQuery, types.GetXsInstanceDataQueryVariables>(GetXsInstanceDataDocument, options);
      }
export function useGetXsInstanceDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetXsInstanceDataQuery, types.GetXsInstanceDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetXsInstanceDataQuery, types.GetXsInstanceDataQueryVariables>(GetXsInstanceDataDocument, options);
        }
export type GetXsInstanceDataQueryHookResult = ReturnType<typeof useGetXsInstanceDataQuery>;
export type GetXsInstanceDataLazyQueryHookResult = ReturnType<typeof useGetXsInstanceDataLazyQuery>;
export type GetXsInstanceDataQueryResult = Apollo.QueryResult<types.GetXsInstanceDataQuery, types.GetXsInstanceDataQueryVariables>;