import { Person } from '@mui/icons-material'
import { AppBar, Toolbar, IconButton, useTheme, Box, Avatar, Button, Tooltip, Stack } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../api/AuthProvider'
import { User } from '../schema/base.types'
import { useProfile } from '../hooks/useProfile'
import { logoColor } from '../theme'
import { useAuth0 } from '@auth0/auth0-react'
import { AboutDialog } from './AboutDialog'
import { useState } from 'react'

export interface TopbarProps {
  navigate?: (path: string) => void
  signIn?: () => void
  signOut?: () => void
  isAuthenticated?: boolean
  myProfile?: User
  pictureUrl?: string
}

/**
 * Here is the presentational component. It should be as dumb as possible.
 * @param param0
 * @returns
 */
export const Topbar: React.FC<TopbarProps> = ({
  navigate,
  signIn,
  signOut,
  isAuthenticated,
  myProfile,
  pictureUrl,
}): JSX.Element => {
  const theme = useTheme()
  const [aboutOpen, setAboutOpen] = useState(false)

  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: theme.palette.primary.dark,
      }}
    >
      <Toolbar sx={{ color: theme.palette.primary.contrastText }}>
        <Stack
          direction="row"
          sx={{
            '& svg': {
              fill: logoColor,
            },
          }}
        >
          <img src="/logo.svg" alt="logo" width="150px" style={{ fill: 'red' }} />
        </Stack>

        {navigate && (
          <>
            <Button color="inherit" onClick={() => navigate('/map')}>
              Explore
            </Button>
            <Button color="inherit" onClick={() => navigate('/')}>
              Data Library
            </Button>
          </>
        )}

        <Box sx={{ flexGrow: 1 }} />
        {isAuthenticated === true ? (
          <>
            <Button
              color="inherit"
              onClick={() => {
                setAboutOpen(true)
              }}
            >
              About
            </Button>
            <Button color="inherit" onClick={signOut}>
              Log Out
            </Button>
            <Tooltip title={myProfile?.name || 'Profile'}>
              <IconButton onClick={() => navigate && navigate('/profile')}>
                <Avatar src={pictureUrl}>
                  <Person />
                </Avatar>
              </IconButton>
            </Tooltip>
          </>
        ) : (
          signIn && (
            <Button color="inherit" onClick={signIn}>
              Login
            </Button>
          )
        )}
      </Toolbar>
      <AboutDialog open={aboutOpen} onClose={() => setAboutOpen(false)} />
    </AppBar>
  )
}

/**
 * The purpose of the container component is to provide the data and behavior to the presentational component.
 * Put anything here that needs to be passed to the presentational component.
 *
 * This will make using Storybook easier.
 *
 * If this grows too big consider splitting it into its own file
 * @returns
 */
export const TopbarContainer: React.FC = () => {
  const navigate = useNavigate()
  const { hostedSignIn, isAuthenticated, signOut } = useAuth()
  const { myProfile } = useProfile()
  const { user } = useAuth0()

  return (
    <Topbar
      navigate={navigate}
      signIn={hostedSignIn}
      signOut={signOut}
      isAuthenticated={Boolean(isAuthenticated)}
      myProfile={myProfile}
      pictureUrl={user?.picture}
    />
  )
}
