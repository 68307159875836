import { useNavigate, useParams } from 'react-router-dom'
import { useNotifications } from '../../AppNotifications'
import { useCallback, useState } from 'react'
import { useGetCrossSectionChartOverviewQuery, useGetSelectionsQuery } from '../../../schema/operations'
import { CrossSectionChart } from './CrossSectionChart'
import { GetReferencePlaneDataQuery, GetXsInstanceDataQuery } from '../../../schema/base.types'
import { XSInstanceDataQuery } from '../XSInstanceDataQuery'
import { noop } from 'lodash'
import produce from 'immer'
import { ObjectValues } from '@xsonline/common'
import { ReferencePlaneDataQuery } from '../ReferencePlaneDataQuery'

export type XSQueryVars = { xsId: number; surveyId: string }

export const QueryStateEnum = {
  LOADING: 'loading',
  FOUND: 'found',
  MISSING: 'missing',
  ERROR: 'error',
} as const
export type XSStateEnum = ObjectValues<typeof QueryStateEnum>

// We tack on a clientState to the XSInstance type in order to track its lifecycle better
export type XSWithStateType = NonNullable<GetXsInstanceDataQuery['getXSInstance'] & { clientState: XSStateEnum }>
export type RefPlaneWithStateType = NonNullable<GetReferencePlaneDataQuery['getReferencePlane']> & {
  clientState: XSStateEnum
}

/**
 * We use a placeholder state while things are loading
 * @param xsId
 * @param surveyId
 * @param clientState
 * @returns
 */
const makeDummyXSState = (xsId: number, surveyId: string, clientState: XSStateEnum): XSWithStateType => {
  return {
    __typename: 'XSInstance',
    clientState,
    id: makeXsInstanceId(xsId, surveyId),
    surveyId,
    xsDefinition: {
      __typename: 'XSDefinition',
      xsId,
      name: 'Loading...',
      referenceStations: [],
      distance: 0,
    },
    surveyPoints: {
      __typename: 'PaginatedPoint2D',
      items: [],
      nextToken: null,
    },
  }
}
const makeDummyRefPlaneState = (id: string, clientState: XSStateEnum): RefPlaneWithStateType => {
  return {
    __typename: 'ReferencePlane',
    id,
    name: 'Loading...',
    clientState,
    points: {
      __typename: 'PaginatedPoint2D',
      items: [],
      nextToken: null,
    },
  }
}

/**
 * Incidentally, this is the same structure the server uses for xsInstanceId
 * @param xsId
 * @param surveyId
 * @returns
 */
export const makeXsInstanceId = (xsId: number, surveyId: string): string => {
  return `${surveyId}-${xsId}`
}

export const CrossSectionChartContainer: React.FC = () => {
  const { projIdParam } = useParams()
  const { show } = useNotifications()
  const navigate = useNavigate()

  const [crossSections, setCrossSections] = useState<XSWithStateType[]>([])
  const [selectionId, setSelectionId] = useState<string | null>(null)

  const [refPlanes, setRefPlanes] = useState<RefPlaneWithStateType[]>([])
  const [fetchReferencePlaneIds, setFetchReferencePlaneIds] = useState<string[]>([])

  const projectId = projIdParam as string

  const handleActiveXSChange = useCallback(
    (fetchVars: XSQueryVars[]) => {
      setCrossSections(
        produce((draft) => {
          // Create a stub record we will fill in later
          fetchVars.forEach(({ surveyId, xsId }) => {
            const index = draft.findIndex((xs) => xs.xsDefinition?.xsId === xsId && xs.surveyId === surveyId)
            if (index === -1) {
              draft.push(makeDummyXSState(xsId, surveyId, QueryStateEnum.LOADING))
            }
          })
          // Now clean up anything that shouldn't be there
          draft.forEach((xs, idx) => {
            const shouldExist = fetchVars.some(({ xsId }) => xsId === xs.xsDefinition?.xsId)
            if (!shouldExist) {
              draft.splice(idx, 1)
            }
          })
        })
      )
    },
    [crossSections]
  )

  const handleReferencePlaneChange = useCallback(
    (fetchVars: string[]) => {
      setRefPlanes(
        produce((draft) => {
          // Create a stub record we will fill in later
          fetchVars.forEach((id) => {
            const index = draft.findIndex((rp) => rp?.id === id)
            if (index === -1) {
              draft.push(makeDummyRefPlaneState(id, QueryStateEnum.LOADING))
            }
          })
          // Now clean up anything that shouldn't be there
          draft.forEach((rp, idx) => {
            const shouldExist = fetchVars.some((id) => id === rp?.id)
            if (!shouldExist) {
              draft.splice(idx, 1)
            }
          })
        })
      )
    },
    [refPlanes]
  )

  const crossSectionOverviewQuery = useGetCrossSectionChartOverviewQuery({
    variables: { projectId },
    onCompleted: (data) => {
      console.log('onCompleted GetCrossSectionChartOverviewQuery', data)
    },
    onError: (error) => {
      console.log('onError GetCrossSectionChartOverviewQuery', error)
      show(`Error retrieving project cross section chart overview data: ${error.message}`, { variant: 'error' })
    },
    skip: !projectId,
  })

  /**
   * The GetSelectionsQuery
   */
  const selectionsQuery = useGetSelectionsQuery({
    variables: { projectId, selectionId: selectionId as string },
    skip: !projectId || !selectionId,
    onCompleted: (data) => {
      if (
        data.getSelection &&
        data.getSelection.xsDefinitions.nextToken &&
        data.getSelection.xsDefinitions.items.length > 0
      ) {
        selectionsQuery.fetchMore({
          variables: {
            projectId,
            nextToken: data.getSelection.xsDefinitions.nextToken,
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult || !fetchMoreResult.getSelection || !prev.getSelection) return prev

            // xsDefinitions Array
            const newXSDefinitionsData = fetchMoreResult?.getSelection?.xsDefinitions?.items || []
            const oldXSDefinitionsData = prev?.getSelection?.xsDefinitions?.items || []
            const newXSDefinitions = [...oldXSDefinitionsData, ...newXSDefinitionsData]
            return {
              ...prev,
              getSelection: {
                ...prev.getSelection,
                xsDefinitions: {
                  ...prev.getSelection?.xsDefinitions,
                  nextToken: fetchMoreResult.getSelection.xsDefinitions.nextToken,
                  items: newXSDefinitions,
                },
              },
            }
          },
        })
      }
    },
    onError: (error) => {
      console.log('onError GetSelectionsQuery', error)
      show(`Error retrieving project selection data: ${error.message}`, { variant: 'error' })
    },
  })

  const project = crossSectionOverviewQuery.data?.getProject
  if (!project) return null // TODO: loading screen
  // Now filter out any crossections that have the state of MISSING or ERROR
  // We keep the loading ones as a placeholder for now
  const finalCrossSections = crossSections.filter(
    (xs) => xs.clientState === QueryStateEnum.FOUND || xs.clientState === QueryStateEnum.LOADING
  )
  return (
    <>
      {crossSections.map((xsObj) => {
        const surveyId = xsObj.surveyId
        const xsId = xsObj.xsDefinition?.xsId as number
        const uniqueId = makeXsInstanceId(xsId, surveyId)
        return (
          <XSInstanceDataQuery
            key={uniqueId}
            projectId={projectId}
            surveyId={surveyId}
            xsId={xsId}
            onData={(data) => {
              setCrossSections(
                // Update the crossSections array with the new data
                produce((draft) => {
                  if (data) {
                    const dataVal = data as NonNullable<GetXsInstanceDataQuery['getXSInstance']>
                    const index = draft.findIndex((xs) => xs.xsDefinition?.xsId === xsId && xs.surveyId === surveyId)
                    if (index === -1) {
                      draft.push({ ...dataVal, clientState: QueryStateEnum.FOUND })
                    } else {
                      draft[index] = { ...dataVal, clientState: QueryStateEnum.FOUND }
                    }
                  } else {
                    // If we're here it means that the query returned null for this crossSection
                    // Clean up the crossSections array that returned nothing
                    const index = draft.findIndex((xs) => xs.xsDefinition?.xsId === xsId && xs.surveyId === surveyId)
                    if (index !== -1) {
                      draft[index].clientState = QueryStateEnum.MISSING
                    } else {
                      // Put a placeholder in the array if it's not there already
                      draft.push(makeDummyXSState(xsId, surveyId, QueryStateEnum.MISSING))
                    }
                  }
                })
              )
            }}
          />
        )
      })}
      {refPlanes.map((referencePlane) => {
        return (
          <ReferencePlaneDataQuery
            projectId={projectId}
            key={referencePlane.id}
            referencePlaneId={referencePlane.id}
            onData={(data) => {
              setRefPlanes(
                // Update the crossSections array with the new data
                produce((draft) => {
                  if (data) {
                    const dataVal = data as NonNullable<GetReferencePlaneDataQuery['getReferencePlane']>
                    const index = draft.findIndex((rp) => rp.id === referencePlane.id)
                    if (index === -1) {
                      draft.push({ ...dataVal, clientState: QueryStateEnum.FOUND })
                    } else {
                      draft[index] = { ...dataVal, clientState: QueryStateEnum.FOUND }
                    }
                  } else {
                    // If we're here it means that the query returned null for this crossSection
                    // Clean up the crossSections array that returned nothing
                    const index = draft.findIndex((rp) => rp.id === referencePlane.id)
                    if (index !== -1) {
                      draft[index].clientState = QueryStateEnum.MISSING
                    } else {
                      // Put a placeholder in the array if it's not there already
                      draft.push(makeDummyRefPlaneState(referencePlane.id, QueryStateEnum.MISSING))
                    }
                  }
                })
              )
            }}
          />
        )
      })}
      <CrossSectionChart
        project={project}
        loading={crossSectionOverviewQuery.loading || selectionsQuery.loading}
        crossSections={finalCrossSections}
        activeSelection={selectionsQuery.data?.getSelection || null}
        referencePlanes={refPlanes}
        onSelectionChange={setSelectionId}
        onActiveReferencePlanesChange={handleReferencePlaneChange}
        onDistanceFilterChange={() => {
          noop
        }}
        onActiveXSChange={handleActiveXSChange}
        onNavigate={navigate}
      />
    </>
  )
}
