import { convert } from './convert'
import { SYSTEM_DATA_UNITS } from './supportedUnits'
import { UnitsSettings } from './supportedUnits'

export interface FormatFunctions {
  convertSurveyDistance: (distance: number) => number
  convertRiverDistance: (distance: number) => number
  convertArea: (area: number) => number
  convertVolume: (volume: number) => number
  convertAndFormatSurveyDistance: (distance: number) => string
  convertAndFormatRiverDistance: (distance: number) => string
  convertAndFormatArea: (area: number) => string
  convertAndFormatVolume: (volume: number) => string
  formatSurveyDistanceHeader: (headerText: string) => string
  formatRiverDistanceHeader: (headerText: string) => string
  formatAreaHeader: (headerText: string) => string
  formatVolumeHeader: (heaerText: string) => string
}

export const makeHeaderFormatter = (unit: string) => (headerText: string) => {
  const unitAbbreviation = (() => {
    switch (unit) {
      case 'ft-us':
        return 'USft'
      case 'ft2-us':
        return 'USft²'
      case 'ac-us':
        return 'USac'
      case 'ft3-us':
        return 'USft³'
      case 'af':
        return 'af'
      case 'af-us':
        return 'USaf'
      default:
        return unit.replace('2', '²').replace('3', '³')
    }
  })()
  return `${headerText} (${unitAbbreviation})`
}

export const makeFormatFunctions = (unitSettings: UnitsSettings) => {
  const convertSurveyDistance = (distance: number) =>
    convert(distance).from(SYSTEM_DATA_UNITS.surveyDistanceUnits).to(unitSettings.surveyDistanceUnits)
  const convertProjectRiverDistanceToSystemDataUnits = (distance: number) =>
    convert(distance).from(unitSettings.riverDistanceUnits).to(SYSTEM_DATA_UNITS.riverDistanceUnits)
  const convertRiverDistance = (distance: number) =>
    convert(distance).from(SYSTEM_DATA_UNITS.riverDistanceUnits).to(unitSettings.riverDistanceUnits)
  const convertArea = (area: number) =>
    convert(area)
      .from(SYSTEM_DATA_UNITS.areaUnits as any)
      .to(unitSettings.areaUnits as any)
  const convertVolume = (volume: number) =>
    convert(volume)
      .from(SYSTEM_DATA_UNITS.volumeUnits as any)
      .to(unitSettings.volumeUnits as any)

  return {
    convertSurveyDistance,
    convertRiverDistance,
    convertArea,
    convertVolume,
    convertProjectRiverDistanceToSystemDataUnits,
    convertAndFormatSurveyDistance: (distance: number) => convertSurveyDistance(distance).toFixed(2),
    convertAndFormatRiverDistance: (distance: number) => convertRiverDistance(distance).toFixed(2),
    convertAndFormatArea: (area: number) => convertArea(area).toFixed(2),
    convertAndFormatVolume: (volume: number) => convertVolume(volume).toFixed(2),
    formatSurveyDistanceHeader: makeHeaderFormatter(unitSettings.surveyDistanceUnits),
    formatRiverDistanceHeader: makeHeaderFormatter(unitSettings.riverDistanceUnits),
    formatAreaHeader: makeHeaderFormatter(unitSettings.areaUnits),
    formatVolumeHeader: makeHeaderFormatter(unitSettings.volumeUnits),
  }
}
