import { useRef } from 'react'

type FilterFn<TIn, TOut, TArgs> = (input: TIn, args: TArgs) => TOut

const sameArgs = (a: any, b: any) => {
  if (typeof a !== typeof b) return false
  if (typeof a === 'object')
    return (
      Object.entries(a).every(([key, value]) => b[key] === value) && Object.keys(a).length === Object.keys(b).length
    )
  return a === b
}

export class FilterPipeline<TValue> {
  protected _value: TValue
  protected logger?: ((...args: any[]) => void) | null = null
  protected cacheMultiplier = 2
  protected cache: {
    input: any
    filterFn: FilterFn<any, any, any>
    args: any
    output: any
  }[] = []
  protected filterCount = 0

  constructor(value: TValue, cacheMultiplier?: number) {
    this._value = value
    if (cacheMultiplier) this.cacheMultiplier = cacheMultiplier
  }

  get value() {
    return this._value
  }

  set value(value: TValue) {
    this.filterCount = 0
    this.logger?.('set value', value)
    this._value = value
  }

  filter<TOut, TArgs>(filterFn: FilterFn<TValue, TOut, TArgs>, args: TArgs): FilterPipeline<TOut> {
    this.filterCount++

    const cached = this.cache.find(
      (entry) => entry.input === this._value && entry.filterFn === filterFn && sameArgs(entry.args, args)
    )

    if (cached) {
      const { output } = cached
      this._value = output as unknown as TValue
      this.logger?.('cache hit', filterFn.name, args, output)
      return this as unknown as FilterPipeline<TOut>
    }

    const input = this._value
    const output = filterFn(input, args as TArgs)
    this._value = output as unknown as TValue
    this.cache.push({
      input,
      filterFn,
      args,
      output,
    })
    this.logger?.('filtered', filterFn.name, args, output)
    return this as unknown as FilterPipeline<TOut>
  }

  output() {
    const cacheSize = this.filterCount * this.cacheMultiplier
    if (this.cache.length > cacheSize) this.cache = this.cache.slice(-cacheSize)
    return this._value
  }

  setLogger(logger: ((...args: any[]) => void) | null) {
    this.logger = logger
    return this
  }
}

export const useFilterPipeline = <TIn>(value: TIn) => {
  const filterPipeline = useRef(new FilterPipeline(value))
  filterPipeline.current.value = value
  return filterPipeline.current
}
