import * as React from 'react'
import Typography from '@mui/material/Typography'
import { Card, Link, SxProps, Theme, alpha, useTheme } from '@mui/material'
import { Project } from '../../schema/base.types'
import { formatISODate } from '../../lib/dates'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'

export interface ProjectDetailsProps {
  project: Project
}

export interface CardStatProps {
  label: string | React.ReactNode
  value: string | React.ReactNode
  noWrap?: boolean
  truncate?: boolean
}

const CartStat: React.FC<CardStatProps> = ({ label, value, truncate, noWrap }) => {
  const truncateStyle: SxProps<Theme> = truncate
    ? {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }
    : {}
  const noWrapStyle: SxProps<Theme> = noWrap ? { whiteSpace: 'pre-wrap' } : {}

  return (
    <>
      <Typography variant="overline" component="div" color="grey">
        {label}
      </Typography>
      <Typography variant="body2" component="div" sx={{ ...truncateStyle, ...noWrapStyle }}>
        {value}
      </Typography>
    </>
  )
}

export const ProjectDetailsCard: React.FC<ProjectDetailsProps> = ({ project }) => {
  const theme = useTheme()

  const stats: Record<string, { value: string | React.ReactNode; columns?: number }> = {
    Visibility: { value: project.isPrivate ? 'Private' : 'Public' },
    'Created On': { value: formatISODate(project.createdOn) },
    'Updated On': { value: formatISODate(project.updatedOn) },
    Website: {
      value: project.website ? (
        <Link href={project.website} target="_blank">
          {project.website}
        </Link>
      ) : (
        <Typography variant="body2" component="div" />
      ),
    },
    'Survey Units': { value: project.surveyDistanceUnits },
    'River Units': { value: project.riverDistanceUnits },
    'Area Units': { value: project.areaUnits },
    'Volume Units': { value: project.volumeUnits },
    Description: { value: project.description, columns: 12 },
  }

  return (
    <Card sx={{ my: 2, p: 1, backgroundColor: alpha(theme.palette.background.paper, 0.4) }}>
      <Grid2 container spacing={1}>
        {Object.entries(stats).map(([label, { value, columns }], idx) => (
          <Grid2 xs={columns || 2} key={`${label}-${idx}`}>
            <CartStat label={label} value={value} truncate={label === 'Website'} noWrap={label === 'Description'} />
          </Grid2>
        ))}
      </Grid2>
    </Card>
  )
}
