import React, { useMemo, useEffect } from 'react'
import {
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from '@mui/material'
import { DESCRIPTION_MAX_LENGTH, NAME_MAX_LENGTH, validateName, validateUrl } from '../componentHelpers'
import { Project, ProjectInput } from '../../schema/base.types'
import { someValues, useFields } from '../../lib/useFields'

export interface ProjectPropertiesProps {
  project?: Project
  onChange: (project: ProjectInput) => void
  onValidationChange: (isValid: boolean) => void
}

export const ProjectProperties: React.FC<ProjectPropertiesProps> = ({ project, onChange, onValidationChange }) => {
  const [fields, setFields, projectInput] = useFields(
    {
      name: project?.name ?? '',
      isPrivate: project?.isPrivate ?? true,
      surveyDistanceUnits: project?.surveyDistanceUnits ?? 'ft',
      riverDistanceUnits: project?.riverDistanceUnits ?? 'mi',
      areaUnits: project?.areaUnits ?? 'ft2',
      volumeUnits: project?.volumeUnits ?? 'ft3',
      website: project?.website ?? '',
      description: project?.description ?? '',
    },
    ({ surveyDistanceUnits, riverDistanceUnits, areaUnits, volumeUnits, ...rest }): ProjectInput => ({
      meta: [],
      units: {
        surveyDistanceUnits,
        riverDistanceUnits,
        areaUnits,
        volumeUnits,
      },
      startDistance: 0,
      ...rest,
    })
  )

  const errors = useMemo(
    () =>
      someValues({
        name: !validateName(fields.name),
        // Validate that this is a url using regex validator
        website: fields.website.trim().length > 0 && !validateUrl(fields.website),
      }),
    [fields]
  )

  useEffect(() => {
    onChange(projectInput)
    onValidationChange(!errors)
  }, [projectInput, errors])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          autoFocus
          margin="dense"
          label="Name"
          type="text"
          fullWidth
          variant="outlined"
          required
          value={fields.name}
          onChange={(e) => setFields.$.name(e.target.value)}
          error={errors?.name}
          inputProps={{ maxLength: NAME_MAX_LENGTH }}
        />
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <InputLabel>Survey Distance Units</InputLabel>
          <Select
            value={fields.surveyDistanceUnits}
            onChange={(e) => setFields.$.surveyDistanceUnits(e.target.value as string)}
            label="Survey Distance Units"
            fullWidth
            required
            size="small"
          >
            <MenuItem value={'ft'}>Feet</MenuItem>
            <MenuItem value={'km'}>Kilometers</MenuItem>
            <MenuItem value={'m'}>Meters</MenuItem>
            <MenuItem value={'mi'}>Miles</MenuItem>
            <MenuItem value={'ft-us'}>US Survey Feet</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <InputLabel>Survey Area Units</InputLabel>
          <Select
            value={fields.areaUnits}
            onChange={(e) => setFields.$.areaUnits(e.target.value as string)}
            label="Survey Area Units"
            fullWidth
            required
            size="small"
          >
            <MenuItem value={'ac'}>Acres</MenuItem>
            <MenuItem value={'ha'}>Hectare</MenuItem>
            <MenuItem value={'ft2'}>Square Feet</MenuItem>
            <MenuItem value={'km2'}>Square Kilometers</MenuItem>
            <MenuItem value={'m2'}>Square Meters</MenuItem>
            <MenuItem value={'mi2'}>Square Miles</MenuItem>
            <MenuItem value={'ft2-us'}>Square US Survey Feet</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <InputLabel>River Distance Units</InputLabel>
          <Select
            value={fields.riverDistanceUnits}
            onChange={(e) => setFields.$.riverDistanceUnits(e.target.value as string)}
            label="River Distance Units"
            fullWidth
            required
            size="small"
          >
            <MenuItem value={'ft'}>Feet</MenuItem>
            <MenuItem value={'km'}>Kilometers</MenuItem>
            <MenuItem value={'m'}>Meters</MenuItem>
            <MenuItem value={'mi'}>Miles</MenuItem>
            <MenuItem value={'ft-us'}>US Survey Feet</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <InputLabel id="SurveyVolumeUnits">Survey Volume Units</InputLabel>
          <Select
            value={fields.volumeUnits}
            onChange={(e) => setFields.$.volumeUnits(e.target.value as string)}
            label="Survey Volume Units"
            fullWidth
            required
            size="small"
          >
            <MenuItem value={'af'}>Acre Feet</MenuItem>
            <MenuItem value={'ft3'}>Cubic Feet</MenuItem>
            <MenuItem value={'yd3'}>Cubic Yards</MenuItem>
            <MenuItem value={'km3'}>Cubic Kilometers</MenuItem>
            <MenuItem value={'m3'}>Cubic Meters</MenuItem>
            <MenuItem value={'mi3'}>Cubic Miles</MenuItem>
            <MenuItem value={'ft3-us'}>Cubic US Survey Feet</MenuItem>
            <MenuItem value={'af-us'}>US Survey Acre Feet</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Switch
              checked={!fields.isPrivate}
              onChange={(e) => {
                setFields.$.isPrivate(!e.target.checked)
              }}
            />
          }
          label="Visible to all users to see and reuse data"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="External Website"
          type="url"
          fullWidth
          variant="outlined"
          error={errors?.website}
          helperText={errors?.website ? 'Please enter a valid URL' : ''}
          value={fields.website}
          onChange={(e) => setFields.$.website(e.target.value)}
          placeholder="https://www.example.com"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="outlined-multiline-flexible"
          label="Description"
          multiline
          rows={6}
          fullWidth
          margin="dense"
          value={fields.description}
          inputProps={{ maxLength: DESCRIPTION_MAX_LENGTH }}
          onChange={(e) => setFields.$.description(e.target.value)}
        />
      </Grid>
    </Grid>
  )
}
