import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import produce from 'immer'
import { noop, uniq } from 'lodash'

import { LongProfileChart } from './LongProfileChart'
import { useGetLongProfileChartOverviewQuery } from '../../../schema/operations'
import { useNotifications } from '../../AppNotifications'
import { LongProfileDataQuery } from './LongProfileDataQuery'
import { GetLongProfileDataQuery, GetReferencePlaneDataQuery } from '../../../schema/base.types'
import { ReferencePlaneDataQuery } from '../ReferencePlaneDataQuery'

export const LongProfileContainer: React.FC = () => {
  const { projIdParam } = useParams()
  const { show } = useNotifications()
  const navigate = useNavigate()

  const [longProfiles, setLongProfiles] = useState<NonNullable<GetLongProfileDataQuery['getLongProfile']>[]>([])
  const [fetchLongProfileIds, setFetchLongProfileIds] = useState<string[]>([])

  const [referencePlanes, setReferencePlanes] = useState<
    NonNullable<GetReferencePlaneDataQuery['getReferencePlane']>[]
  >([])
  const [fetchReferencePlaneIds, setFetchReferencePlaneIds] = useState<string[]>([])

  const projectId = projIdParam as string

  const { data } = useGetLongProfileChartOverviewQuery({
    variables: { projectId },
    onCompleted: (data) => {
      console.log('onCompleted GetLongProfileChartOverviewQuery', data)
    },
    onError: (error) => {
      console.log('onError GetLongProfileChartOverviewQuery', error)
      show(`Error retrieving project long profile overview data: ${error.message}`, { variant: 'error' })
    },
    skip: !projectId,
  })

  const project = data?.getProject
  if (!project) return null // TODO: loading screen

  return (
    <>
      {fetchLongProfileIds.map((longProfileId) => (
        <LongProfileDataQuery
          key={longProfileId}
          projectId={projectId}
          longProfileId={longProfileId}
          onData={(data) => {
            setLongProfiles(
              produce((draft) => {
                const index = draft.findIndex((existing) => existing.id === data.id)
                draft[index === -1 ? draft.length : index] = data
              })
            )
          }}
        />
      ))}
      {fetchReferencePlaneIds &&
        projectId &&
        fetchReferencePlaneIds.map((referencePlaneId) => (
          <ReferencePlaneDataQuery
            key={referencePlaneId}
            projectId={projectId}
            referencePlaneId={referencePlaneId}
            onData={(data) => {
              if (!data) return
              setReferencePlanes(
                produce((draft) => {
                  const index = draft.findIndex((existing) => existing.id === data.id)
                  draft[index === -1 ? draft.length : index] = data
                })
              )
            }}
          />
        ))}
      <LongProfileChart
        project={project}
        longProfiles={longProfiles}
        referencePlanes={referencePlanes}
        onActiveLongProfilesChange={(longProfileIds) => {
          setFetchLongProfileIds(uniq([...fetchLongProfileIds, ...longProfileIds]))
        }}
        onActiveReferencePlanesChange={(referencePlaneIds) =>
          setFetchReferencePlaneIds(uniq([...fetchReferencePlaneIds, ...referencePlaneIds]))
        }
        onNavigate={navigate}
      />
    </>
  )
}
