import { Box, Button, FormControl, InputLabel, MenuItem, Select, Stack, Tab, Tabs, useTheme } from '@mui/material'
import { GetCrossSectionChartOverviewQuery, Point2D, FileStateEnum, Project } from '../../../schema/base.types'
import { ViewerHeader } from '../ViewerHeader'
import { Column, Row } from '../../Layout'
import { ChartableDataSeries, SurveyDataChart } from '../../SurveyDataChart'
import { UnitsSettings, useFormats } from '../../../lib/formats'
import { useEffect, useMemo, useState } from 'react'
import { useStableColor } from '../../../lib/useStableColor'
import { DataGrid, DataGridApis } from '../../DataGrid'
import { useFields } from '../../../lib/useFields'
import { Chip } from '../../Chip'
import {
  EMPTY_ARRAY_INPUT,
  addColorFilterFn,
  includePinnedIdsFilterFn,
  includeUnpinnedIdsFilterFn,
  useFilterPipeline,
} from '../../../lib/filtering'
import { noop, sortBy, uniq, without } from 'lodash'
import { downloadDataFile } from '../../../lib/downloadDataFile'
import {
  crossSectionMetricColumnDefs,
  referencePlanesColumnDefs,
  referenceStationColumnDefs,
  surveyColumnDefs,
} from '../long_profile/columnDefs'
import { CrossSectionDistanceGrid, RiverDistance } from './CrossSectionDistanceGrid'
import { DownloadButton } from '../../DownloadButton'
import { XSQueryVars, QueryStateEnum, XSWithStateType, RefPlaneWithStateType } from './CrossSectionChartContainer'
import { GetSelectionsQuery, ObjectValues } from '@xsonline/common'
import { Cancel, PushPin } from '@mui/icons-material'
import { Transect } from '../../../lib/transectAreaChart/transect'
import { SurveyPoint } from '../../../lib/transectAreaChart/surveyPoint'
import { getElevationAtDistance } from '../../../lib/transectAreaChart/mathHelpers'
import { ColDef } from 'ag-grid-community'

export interface CrossSectionChartProps {
  project: NonNullable<GetCrossSectionChartOverviewQuery['getProject']>
  loading?: boolean
  crossSections: XSWithStateType[]
  referencePlanes: RefPlaneWithStateType[]
  activeSelection: NonNullable<GetSelectionsQuery['getSelection']> | null
  onSelectionChange: (selectionId: string | null) => void
  onActiveReferencePlanesChange: (activeReferencePlanes: string[]) => void
  onDistanceFilterChange: (upstream: number | null, downstream: number | null) => void
  // THis will trigger with every possible combination of xsId and surveyId that we need to keep track of
  onActiveXSChange: (activeXs: XSQueryVars[]) => void
  onNavigate: (path: string) => void
}

type ChartDataMemoType = [ChartableDataSeries[], number | undefined, number | undefined]

const TabsEnum = {
  CROSSSECTIONS: 'Cross Sections',
  REFERENCE: 'Reference',
} as const
type TabsEnum = ObjectValues<typeof TabsEnum>

type SurveyMetricProperties = {
  id: string
  xsId: number
  distance: number
  survey: string
  pointCount: number
  straightLength: number
  profileArea: number
  minElevation: number
  maxElevation: number
}

export const CrossSectionChart: React.FC<CrossSectionChartProps> = ({
  project,
  loading,
  crossSections,
  activeSelection,
  referencePlanes,
  onSelectionChange,
  onActiveXSChange,
  onActiveReferencePlanesChange,
  onDistanceFilterChange,
  onNavigate,
}) => {
  const {
    convertSurveyDistance,
    convertRiverDistance,
    formatRiverDistanceHeader,
    formatSurveyDistanceHeader,
    formatAreaHeader,
    convertArea,
  } = useFormats(project as UnitsSettings)

  const theme = useTheme()
  // AgGrid APIs
  const [surveyGridApis, setSurveyGridApis] = useState<DataGridApis>()
  const [riverDistanceGridApis, setRiverDistanceGridApis] = useState<DataGridApis>()
  const [referenceStationGridApis, setReferenceStationGridApis] = useState<DataGridApis>()

  // Selected grid items
  const [activeSelectionId, setActiveSelectionId] = useState<string | null>(null)
  const [selectedSurveys, setSelectedSurveys] = useState<(typeof project.surveys)[number][]>([])

  const [selectedReferenceStations, setSelectedReferenceStations] = useState<string[]>([])

  const [selectedRiverDistances, setSelectedRiverdDisances] = useState<RiverDistance[]>([])
  const [referencePlaneGridApis, setReferencePlaneGridApis] = useState<DataGridApis>()
  const [assignStableLongProfileColor] = useStableColor()
  const [assignStableReferencePlaneColor] = useStableColor('references')
  const [currentTab, setCurrentTab] = useState<TabsEnum>(TabsEnum.CROSSSECTIONS)

  const [fields, setFields] = useFields({
    pinnedXSInstanceIds: [] as string[],
    pinnedReferencePlaneIds: [] as string[],
  })

  const { pinnedXSInstanceIds, pinnedReferencePlaneIds } = fields
  const unpinnedReferencePlaneIds = useMemo(
    () => referencePlanes.filter(({ id }) => !pinnedReferencePlaneIds.includes(id)).map(({ id }) => id),
    [pinnedReferencePlaneIds, referencePlanes]
  )

  const onValidateDistanceFilter = () => {
    noop
  }

  // XS INSTANCE DATA COMPUTATION
  const unpinnedXSInstanceIds: string[] = useMemo(
    () =>
      crossSections
        .filter(
          (xsObj) =>
            selectedSurveys.map(({ id }) => id).includes(xsObj.surveyId) &&
            selectedRiverDistances.map(({ xsId }) => xsId).includes(xsObj.xsDefinition?.xsId as number)
        )
        .filter(({ id }) => !pinnedXSInstanceIds.includes(id))
        .map(({ id }) => id),
    [pinnedXSInstanceIds, selectedRiverDistances, selectedSurveys, crossSections]
  )

  const activeCrossSections = useFilterPipeline(EMPTY_ARRAY_INPUT)
    .filter(includePinnedIdsFilterFn, {
      source: crossSections,
      ids: pinnedXSInstanceIds,
    })
    .filter(includeUnpinnedIdsFilterFn, {
      source: crossSections,
      ids: unpinnedXSInstanceIds,
    })
    .filter(addColorFilterFn, {
      assignColorFn: assignStableLongProfileColor,
    })
    // .filter(relativeToReferencePlaneFilterFn, {
    //   referencePlane: elevationRelativeToReferencePlane,
    // })
    .output()

  // REFERENCE PLANE DATA COMPUTATION

  const activeReferencePlanes = useFilterPipeline(EMPTY_ARRAY_INPUT)
    .filter(includePinnedIdsFilterFn, {
      source: referencePlanes,
      ids: pinnedReferencePlaneIds,
    })
    .filter(includeUnpinnedIdsFilterFn, {
      source: referencePlanes.map((i) => ({ ...i })),
      ids: unpinnedReferencePlaneIds,
    })
    .filter(addColorFilterFn, {
      assignColorFn: assignStableReferencePlaneColor,
    })
    // .filter(densifyReferencePlanesFilterFn, {
    //   crossSection: activeCrossSections,
    // })
    // .filter(riverDistanceFilterFn, {
    //   min: debouncedRiverDistanceMin,
    //   max: debouncedRiverDistanceMax,
    //   convertRiverDistance,
    // })
    // .filter(relativeToReferencePlaneFilterFn, {
    //   referencePlane: elevationRelativeToReferencePlane,
    // })
    .output()

  // RECALCULATE EVERY COMBINATION OF XSID AND SURVEYID WE NEED AND SEND IT BACK TO THE CONTAINER FOR QUERYING
  useEffect(() => {
    const everyCombination = [
      ...selectedSurveys.reduce((acc, { id: surveyId }) => {
        return [
          ...acc,
          ...selectedRiverDistances.map(({ xsId }) => ({
            xsId,
            surveyId,
          })),
        ]
      }, [] as XSQueryVars[]),
      ...activeCrossSections.map(
        ({ xsDefinition, surveyId }) => ({ xsId: xsDefinition?.xsId, surveyId } as XSQueryVars)
      ),
    ]

    onActiveXSChange(everyCombination)
  }, [activeCrossSections, selectedSurveys, selectedRiverDistances])

  /**
   * Here's where we combine the list of cross section instances with the list of cross section definitions
   */
  const riverDistanceList = useMemo(() => {
    const rawXsDefs = activeSelection?.xsDefinitions.items || []
    // These conditions will just give us an empty array
    if (selectedSurveys.length === 0 || rawXsDefs.length === 0) return []

    // Count all the cross sections for each xsId
    const xsCountMap = crossSections.reduce((acc, xs) => {
      const xsId = xs.xsDefinition?.xsId
      const isFound = xs.clientState === QueryStateEnum.FOUND
      if (!xsId || !isFound) return acc
      const count = acc.get(xsId) || 0
      acc.set(xsId, count + 1)
      return acc
    }, new Map<number, number>())

    const riverDistances: RiverDistance[] = rawXsDefs.map(({ distance, xsId, name }) => {
      const surveyCount = xsCountMap.get(xsId)

      // We're only dealing with the active crosssection Ids here
      const isActive = selectedRiverDistances.some((rDist) => rDist.xsId === xsId)
      const isLoading =
        crossSections.filter((xs) => xs.xsDefinition?.xsId === xsId && xs.clientState === QueryStateEnum.LOADING)
          .length > 0

      let surveyCountStr = '-'
      if (isLoading) surveyCountStr = 'Loading...'
      else if (isActive) surveyCountStr = surveyCount ? `${surveyCount}` : '0'

      return {
        distance: convertRiverDistance(distance),
        unit: project.riverDistanceUnits,
        name,
        xsId,
        id: `xsId:${xsId}::surveyId:${activeSelectionId}`,
        surveyCount: surveyCountStr,
      }
    })
    riverDistances.sort((a, b) => a.distance - b.distance)
    return riverDistances
  }, [activeSelection, crossSections, selectedSurveys, selectedRiverDistances])

  // PINNING EVENTS

  const handlePinAllData = () => {
    setFields.$.pinnedXSInstanceIds([...pinnedXSInstanceIds, ...unpinnedXSInstanceIds])
    setFields.$.pinnedReferencePlaneIds([...pinnedReferencePlaneIds, ...unpinnedReferencePlaneIds])
    // surveyGridApis?.api?.deselectAll()
    // riverDistanceGridApis?.api?.deselectAll()
    // referencePlaneGridApis?.api?.deselectAll()
  }
  const handleUnPinAllData = () => {
    setFields.$.pinnedXSInstanceIds([])
    setFields.$.pinnedReferencePlaneIds([])
  }

  // CHART DATA

  const [chartData, xScaleMin, xScaleMax] = useMemo<ChartDataMemoType>(() => {
    const transformCrossSectionToChartData = ({
      id,
      xsDefinition,
      surveyId,
      color,
      surveyPoints,
    }: (typeof activeCrossSections)[number]): ChartableDataSeries => {
      const surveyName = project.surveys.find(({ id }) => id === surveyId)?.name || '??'
      return {
        id,
        label: `${surveyName} ${xsDefinition?.name || '??'}`,
        color,
        data: surveyPoints.items
          .map(({ x, y }) => ({
            x: convertSurveyDistance(x),
            y: convertSurveyDistance(y),
          }))
          .sort((a, b) => a.x - b.x),
      }
    }

    const transformReferencePlaneToChartData = (
      { id, name: label, color, points }: (typeof activeReferencePlanes)[number],
      riverDistance: number,
      xScaleMin: number,
      xScaleMax: number
    ): ChartableDataSeries => {
      if (points.items.length === 0) return { id, label, color, data: [] }
      const yVal = convertSurveyDistance(
        getElevationAtDistance(
          points.items.map((p) => new SurveyPoint(p.x, p.y)),
          riverDistance
        )
      )
      return {
        id,
        label,
        color,
        data: [
          {
            x: xScaleMin,
            y: yVal,
          },
          {
            x: xScaleMax,
            y: yVal,
          },
        ],
      }
    }

    const transformReferenceStationToChartData = (
      stationName: string,
      stationVal: number,
      yScaleMin: number,
      yScaleMax: number
    ): ChartableDataSeries => {
      return {
        id: `${stationVal}-${stationName}`,
        label: stationName,
        color: '#FF0000',
        data: [
          {
            x: stationVal,
            y: yScaleMin,
          },
          {
            x: stationVal,
            y: yScaleMax,
          },
        ],
      }
    }

    const xsInstanceChartData = activeCrossSections.map(transformCrossSectionToChartData)

    const chartAreaXValues = xsInstanceChartData.reduce<number[]>(
      (acc, { data }) => [...acc, ...data.map(({ x }) => x)],
      []
    )
    const chartAreaYValues = xsInstanceChartData.reduce<number[]>(
      (acc, { data }) => [...acc, ...data.map(({ y }) => y)],
      []
    )
    const xScaleMin = Math.min(...chartAreaXValues)
    const xScaleMax = Math.max(...chartAreaXValues)
    const yScaleMin = Math.min(...chartAreaYValues)
    const yScaleMax = Math.max(...chartAreaYValues)

    // We need to get the min and max of the xScale for the reference planes so we use reduce
    // to get all the x values and then get the min and max and output them as an array of graphable data series
    const referencePlanesChartData: ChartableDataSeries[] = activeReferencePlanes.reduce((acc, refPlane) => {
      const riverDistancesConverted = riverDistanceList.map(({ distance }) => distance)
      return [
        ...acc,
        ...riverDistancesConverted.map((rd) => transformReferencePlaneToChartData(refPlane, rd, xScaleMin, xScaleMax)),
      ]
    }, [] as ChartableDataSeries[])

    const referenceStationChartData: ChartableDataSeries[] = selectedReferenceStations.reduce((acc, stationName) => {
      const stationDistancesConverted: number[] = activeCrossSections
        .map(({ xsDefinition }) => xsDefinition?.referenceStations.find(({ name }) => name === stationName)?.value)
        .filter((x) => x)
        .map((x) => convertSurveyDistance(x as number))

      return [
        ...acc,
        ...stationDistancesConverted.map((stationValue) =>
          transformReferenceStationToChartData(stationName, stationValue, yScaleMin, yScaleMax)
        ),
      ]
    }, [] as ChartableDataSeries[])

    const finalChartData = [...xsInstanceChartData, ...referencePlanesChartData, ...referenceStationChartData]

    return [finalChartData, xScaleMin, xScaleMax]
  }, [selectedRiverDistances, activeCrossSections, activeReferencePlanes, convertSurveyDistance, convertRiverDistance])
  // USEMEMO END /CHART DATA

  // DOWNLOAD

  // console.log('ALMONDS', activeReferencePlanes)

  const handleDownloadDataFile = (fileType: 'xlsx' | 'csv') => {
    const allLongProfileRiverDistancesSorted = sortBy(
      uniq(
        activeCrossSections.reduce<number[]>(
          (acc, { surveyPoints: { items } }) => [...acc, ...items.map(({ x }) => x)],
          []
        )
      )
    )

    const getPropertyAtRiverDistance = (points: Point2D[], targetX: number, key: string) => {
      const value = points.find(({ x }) => x === targetX)
      return value?.[key]
    }

    const columnGroups = [
      { headerFormatter: (sourceTitle: string) => formatSurveyDistanceHeader(sourceTitle), propertyKey: 'y' }, // column group for elevation
    ]

    const data = allLongProfileRiverDistancesSorted.map<Record<string, number>>((riverDistance) => {
      const row = { [formatSurveyDistanceHeader('Station')]: convertRiverDistance(riverDistance) }

      // columnGroups.forEach(({ headerFormatter, propertyKey }) => {
      //   ;[...activeCrossSections, ...activeReferencePlanes].forEach((activeSource) => {
      //     const propertyValue = getPropertyAtRiverDistance(activeSource.points.items, riverDistance, propertyKey)
      //     row[headerFormatter(activeSource.name)] = propertyValue && convertSurveyDistance(propertyValue)
      //   })
      // })

      return row
    })
    downloadDataFile({ data, fileName: 'CrossSection_Viewer_Export', fileType })
  }

  const formattedColDefs = crossSectionMetricColumnDefs.map<ColDef>((colDef) => ({
    ...colDef,
    headerName: ['straightLength', 'minElevation', 'maxElevation'].find((x) => x === colDef.field)
      ? formatSurveyDistanceHeader(colDef.headerName as string)
      : ['distance'].find((x) => x === colDef.field)
      ? formatRiverDistanceHeader(colDef.headerName as string)
      : colDef.field == 'profileArea'
      ? formatAreaHeader(colDef.headerName as string)
      : colDef.headerName,
  }))

  // RENDER

  return (
    <>
      <ViewerHeader
        title="Cross Section Viewer"
        helpPage="cross_sections.html#viewer"
        project={project as Project}
        loading={loading}
        onNavigate={onNavigate}
      />
      <Column height="100%" width="100%" p={2}>
        <Row flex={1} spacing={2}>
          <Column width="20em" spacing={2}>
            <Tabs value={currentTab} onChange={(e, value) => setCurrentTab(value)}>
              <Tab label="Cross Sections" value={TabsEnum.CROSSSECTIONS} />
              <Tab label="Reference" value={TabsEnum.REFERENCE} />
            </Tabs>

            {currentTab === TabsEnum.CROSSSECTIONS && (
              <>
                <Box flex={1}>
                  <DataGrid<(typeof project.surveys)[number]>
                    columnDefs={surveyColumnDefs}
                    rowData={project.surveys.filter((survey) => survey.state === FileStateEnum.Complete)}
                    onSelectedRowsChanged={setSelectedSurveys}
                    rowSelection="multiple"
                    onGridApisReady={setSurveyGridApis}
                  />
                </Box>
                <Box>
                  <FormControl fullWidth>
                    <InputLabel>Saved Selection</InputLabel>
                    <Select
                      fullWidth
                      label="Saved Selection"
                      value={activeSelectionId || ''}
                      onChange={(e) => {
                        const selectionId =
                          !e.target.value || e.target.value.length === 0 ? null : (e.target.value as string)
                        // We need to send this back up to the container to see if we need to download some new XSs
                        onSelectionChange(selectionId)
                        setActiveSelectionId(selectionId)
                      }}
                    >
                      <MenuItem value="">
                        <em>-- None --</em>
                      </MenuItem>
                      {project.selections.map((selection, idx) => (
                        <MenuItem key={idx} value={selection.id}>
                          {selection.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                {/* REMOVE FOR NOW */}
                {/* <Box>
                  <UpstreamDownstreamTextFields
                    onDistancesChange={onDistanceFilterChange}
                    onValidateChange={onValidateDistanceFilter}
                    riverDistanceUnitsAbbr={project.riverDistanceUnits}
                  />
                </Box> */}
                <CrossSectionDistanceGrid
                  riverDistances={riverDistanceList}
                  riverDistanceUnit={project.riverDistanceUnits}
                  onGridApisReady={setRiverDistanceGridApis}
                  onRiverDistancesChange={(riverDistances) => {
                    // This is for the local state
                    setSelectedRiverdDisances(riverDistances)
                  }}
                />
              </>
            )}

            {currentTab === TabsEnum.REFERENCE && (
              <>
                <Box flex={1}>
                  <DataGrid<(typeof project.referencePlanes)[number]>
                    columnDefs={referencePlanesColumnDefs}
                    rowData={project.referencePlanes.filter((survey) => survey.state === FileStateEnum.Complete)}
                    onSelectedRowsChanged={(data) => {
                      onActiveReferencePlanesChange(data.map(({ id }) => id))
                    }}
                    rowSelection="multiple"
                    onGridApisReady={setReferencePlaneGridApis}
                  />
                </Box>
                <Box flex={1}>
                  <DataGrid<any[number]>
                    columnDefs={referenceStationColumnDefs}
                    rowData={project.crossSections.referenceStationFields.map((station) => ({
                      id: station,
                      name: station,
                    }))}
                    onSelectedRowsChanged={(selectedRows) => {
                      setSelectedReferenceStations(selectedRows.map(({ id }) => id))
                    }}
                    rowSelection="multiple"
                    onGridApisReady={setReferenceStationGridApis}
                  />
                </Box>
              </>
            )}
            <Stack direction="row" spacing={2}>
              <Button
                size="small"
                fullWidth
                variant="contained"
                onClick={handlePinAllData}
                startIcon={<PushPin />}
                disabled={unpinnedXSInstanceIds.length + unpinnedReferencePlaneIds.length === 0}
              >
                Pin All
              </Button>
              <Button
                size="small"
                fullWidth
                variant="contained"
                onClick={handleUnPinAllData}
                startIcon={<Cancel />}
                disabled={pinnedXSInstanceIds.length + pinnedReferencePlaneIds.length === 0}
              >
                Clear Pinned
              </Button>
            </Stack>
          </Column>
          <Column flex={2}>
            <Row>
              <Box minHeight="3em" flex={1}>
                {activeReferencePlanes.map(({ id, name, color, pinned }) => {
                  return (
                    <Chip
                      key={id}
                      variant="reference"
                      label={name}
                      color={color}
                      pinned={pinned}
                      onDelete={
                        pinned
                          ? () => {
                              setFields.$.pinnedReferencePlaneIds(without(pinnedReferencePlaneIds, id))
                            }
                          : undefined
                      }
                    />
                  )
                })}
                {activeCrossSections.map(({ id, surveyId, color, pinned, xsDefinition }) => {
                  const surveyName = project.surveys.find(({ id }) => id === surveyId)?.name || '??'
                  const label = `${surveyName} ${xsDefinition?.name || '??'}`
                  return (
                    <Chip
                      key={id}
                      label={label}
                      color={color}
                      pinned={pinned}
                      onDelete={
                        pinned
                          ? () => {
                              setFields.$.pinnedXSInstanceIds(without(pinnedXSInstanceIds, id))
                            }
                          : undefined
                      }
                    />
                  )
                })}
              </Box>
              {crossSections.length > 0 && (
                <DownloadButton
                  sx={{ color: theme.palette.primary.main }}
                  onDownloadXlsx={() => handleDownloadDataFile('xlsx')}
                  onDownloadCsv={() => handleDownloadDataFile('csv')}
                />
              )}
            </Row>

            <Box flex={1}>
              <SurveyDataChart
                data={chartData}
                // onChartMethodsReady={setChartMethods}
                xLegend={formatSurveyDistanceHeader('Station')}
                yLegend={formatSurveyDistanceHeader('Elevation')}
                xScaleMin={xScaleMin}
                xScaleMax={xScaleMax}
              />
            </Box>
          </Column>
        </Row>
        <Row flex={1} maxHeight={200} paddingTop={2}>
          <DataGrid<SurveyMetricProperties>
            columnDefs={formattedColDefs}
            rowData={activeCrossSections.map((xs) => {
              const transect = new Transect(xs.surveyPoints.items.map((sp) => new SurveyPoint(sp.x, sp.y)))
              return {
                id: xs.id,
                xsId: xs.xsDefinition?.xsId as number,
                distance: convertRiverDistance(xs.xsDefinition?.distance as number),
                survey: project.surveys.find(({ id }) => id === xs.surveyId)?.name || '??',
                pointCount: transect.surveyPoints.length,
                profileArea: convertArea(transect.area()),
                straightLength: convertSurveyDistance(transect.horizontalLength()),
                minElevation: convertSurveyDistance(transect.minElevation()),
                maxElevation: convertSurveyDistance(transect.maxElevation()),
              }
            })}
          />
        </Row>
      </Column>
    </>
  )
}
