import * as React from 'react'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItemText from '@mui/material/ListItemText'
import Typography from '@mui/material/Typography'
import { Button, Chip, ListItemButton, Stack, Tooltip, useTheme } from '@mui/material'
import { Add, Public } from '@mui/icons-material'
import { Project, ProjectInput, ProjectUpdate } from '../../schema/base.types'
import { PropertyEditorDialog } from '../PropertyEditorDialog'
import { ConfirmDelete } from '../ConfirmDelete'
import { noop } from 'lodash'
import { CreateProjectDialog } from '../Project/CreateProjectDialog'
import { HelpButton } from '../HelpButton'

export interface ProjectListProps {
  navigate: (path: string) => void
  projects: Project[]
  onCreateProject: (dbItem: ProjectInput) => void
  onEditProject: (projectId: string, project: ProjectUpdate) => void
  onDeleteProject: (projectId: string) => void
}

export const ProjectList: React.FC<ProjectListProps> = ({
  projects,
  navigate,
  onCreateProject,
  onEditProject,
  onDeleteProject,
}) => {
  const theme = useTheme()

  const [createOpen, setCreateOpen] = React.useState(false)
  const [activeItem, setActiveItem] = React.useState<Project | null>(null)
  const [confirmOpen, setConfirmOpen] = React.useState(false)
  const [editOpen, setEditOpen] = React.useState(false)

  const [[project, projectDetailsOpen], setProjectDetailsOpen] = React.useState<[Project | null, boolean]>([
    null,
    false,
  ])

  const onCreateSubmit = () => {
    noop
  }

  const onProjectDetailsClose = () => {
    console.log('close')
  }

  return (
    <>
      <Box>
        <Stack
          direction="row"
          spacing={2}
          sx={{ mt: 2, borderBottom: `1px solid ${theme.palette.text.secondary}` }}
          alignItems="baseline"
        >
          <Typography variant="overline" color={theme.palette.text.secondary}>
            Projects
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Box>
            <Tooltip title="Create New Project">
              <Button startIcon={<Add />} variant="text" size="small" onClick={() => setCreateOpen(true)}>
                Add New
              </Button>
            </Tooltip>
            <HelpButton toolTip="Learn more about projects" pageName="projects" />
          </Box>
        </Stack>
        <List>
          {(projects || []).map((value, idx) => {
            // Do things - code logic can go here
            return (
              <ListItemButton key={`list-item-${idx}`} onClick={() => navigate(`/projects/${value.id}`)}>
                <ListItemText
                  primary={value.name}
                  secondary={value.description}
                  secondaryTypographyProps={{
                    whiteSpace: 'pre-wrap',
                  }}
                  sx={{
                    flex: '1 1 75%',
                  }}
                />
                <Box sx={{ pl: 1 }}>
                  {!value.isPrivate && (
                    <Chip
                      label="PUBLIC"
                      size="small"
                      color="success"
                      icon={<Public />}
                      sx={{
                        borderRadius: '1em',
                        fontSize: '0.6rem',
                        fontWeight: 'bold',
                        color: theme.palette.success.contrastText,
                        backgroundColor: theme.palette.success.main,
                        border: `1px solid ${theme.palette.success.dark}`,
                      }}
                    />
                  )}
                </Box>
              </ListItemButton>
            )
          })}
        </List>
      </Box>
      {activeItem && (
        <>
          <PropertyEditorDialog
            open={editOpen}
            typeName="Project"
            onSubmit={noop}
            onClose={() => setEditOpen(false)}
            dbItem={{
              id: activeItem.id,
              name: activeItem.name,
              description: activeItem.description || undefined,
            }}
          />
          <ConfirmDelete
            dialogTitle="Confirm Delete"
            message={`Are you sure that you want to delete the ${activeItem.name} project?`}
            open={confirmOpen}
            onClose={() => setConfirmOpen(false)}
            onSubmit={() => {
              onDeleteProject(activeItem.id)
              setConfirmOpen(false)
            }}
            itemId={activeItem.id}
          />
        </>
      )}
      <CreateProjectDialog
        open={createOpen}
        onCancel={() => setCreateOpen(false)}
        onSubmit={(item: ProjectInput) => {
          onCreateProject(item)
          setCreateOpen(false)
        }}
        dialogTitle="Create New Project"
      />
    </>
  )
}
