import React from 'react'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, useTheme } from '@mui/material'
import { Project, ProjectInput, ProjectUpdate } from '../../schema/base.types'
import { HelpButton } from '../HelpButton'
import { ProjectProperties } from './ProjectProperties'
import { Save } from '@mui/icons-material'

export interface EditEditorDialogProps {
  project: Project
  open: boolean
  onCancel: () => void
  onSubmit: (dbItem: ProjectUpdate) => void
}

export const EditProjectDialog: React.FC<EditEditorDialogProps> = ({ open, onCancel, onSubmit, project }) => {
  const theme = useTheme()
  const [innerValidation, setInnerValidation] = React.useState(false)
  const [innerObject, setInnerObject] = React.useState<ProjectInput>({
    ...project,
  })

  const formValid = innerValidation

  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle
        sx={{
          backgroundColor: theme.palette.secondary.main,
          color: theme.palette.secondary.contrastText,
          mb: 2,
        }}
        color={theme.palette.primary.main}
      >
        Edit Project
      </DialogTitle>
      <DialogContent>
        <ProjectProperties project={project} onValidationChange={setInnerValidation} onChange={setInnerObject} />
      </DialogContent>
      <DialogActions>
        <HelpButton toolTip="Learn more about projects" pageName="projects" />
        <Button onClick={onCancel}>Cancel</Button>
        <Box sx={{ flexGrow: 1 }} />
        <Button
          onClick={() => {
            onSubmit({
              ...innerObject,
            })
          }}
          variant="contained"
          disabled={!formValid}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}
