import React from 'react'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, useTheme } from '@mui/material'
import { FargateItem, GenericDBItem } from '../types'
import { DESCRIPTION_MAX_LENGTH, NAME_MAX_LENGTH, validateName } from './componentHelpers'
import { HelpButton } from './HelpButton'

export interface PropertyEditorDialogProps {
  open: boolean
  typeName: string
  onSubmit: (dbItem: GenericDBItem) => void
  onClose: () => void
  dbItem: GenericDBItem
  helpUrl?: string
}

export const PropertyEditorDialog: React.FC<PropertyEditorDialogProps> = ({
  open,
  dbItem,
  typeName,
  onSubmit,
  onClose,
  helpUrl,
}) => {
  const theme = useTheme()
  const [name, setName] = React.useState(dbItem.name)
  const [description, setDescription] = React.useState<string>(dbItem.description || '')

  const isValid = validateName(name)

  return (
    <Dialog open={open}>
      <DialogTitle
        sx={{
          backgroundColor: theme.palette.secondary.main,
          color: theme.palette.secondary.contrastText,
          mb: 2,
        }}
      >{`Edit ${typeName}`}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Name"
          type="text"
          fullWidth
          variant="outlined"
          required
          value={name}
          error={!isValid}
          onChange={(e) => setName(e.target.value)}
          inputProps={{ maxLength: NAME_MAX_LENGTH }}
        />
        <TextField
          id="outlined-multiline-flexible"
          label="Description"
          multiline
          rows={4}
          fullWidth
          margin="normal"
          variant="outlined"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          inputProps={{ maxLength: DESCRIPTION_MAX_LENGTH }}
        />
      </DialogContent>
      <DialogActions>
        {helpUrl && <HelpButton toolTip={`Learn more about ${typeName}`} pageName={helpUrl} />}
        <Button onClick={onClose}>Cancel</Button>
        <Box sx={{ flexGrow: 1 }} />
        <Button
          onClick={() =>
            onSubmit({
              id: dbItem.id,
              name: name.trim(),
              description: description.slice(0, DESCRIPTION_MAX_LENGTH),
            })
          }
          disabled={!isValid}
          variant="contained"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}
