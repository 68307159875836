import * as React from 'react'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import DeleteIcon from '@mui/icons-material/Delete'
import { Box, Button, Stack, Tooltip, alpha, useTheme } from '@mui/material'
import { Add, Edit } from '@mui/icons-material'
import { CreatePointCloudDialog } from '../PointCloud/CreatePointCloudDialog'
import { PropertyEditorDialog } from '../PropertyEditorDialog'
import { ConfirmDelete } from '../ConfirmDelete'
import { CreatePointCloudInput, PointCloud, UploadTarget } from '../../schema/base.types'
import { GenericDBItem } from '../../types'
import { HelpButton } from '../HelpButton'
import { FileUploadDialog } from '../FileUpload/FileUploadDialog'
import { UploadDownloadButton } from '../FileUpload/UploadDownloadButton'

export interface PointCloudListProps {
  pointClouds: PointCloud[]
  onCreatePointCloud: (pointCloud: CreatePointCloudInput) => void
  onEditPointCloud: (dbItem: GenericDBItem) => void
  onDeletePointCloud: (pointCloudId: string) => void
  getPointCloudUrl: (pointCloudId: string) => Promise<UploadTarget>
  onUploadPointCloudComplete: (pointCloudId: string) => void
}

export const PointCloudList: React.FC<PointCloudListProps> = ({
  pointClouds,
  onCreatePointCloud,
  onEditPointCloud,
  onDeletePointCloud,
  getPointCloudUrl,
  onUploadPointCloudComplete,
}) => {
  const theme = useTheme()
  const [createOpen, setCreateOpen] = React.useState(false)
  const [editOpen, setEditOpen] = React.useState(false)
  const [activeItem, setActiveItem] = React.useState<PointCloud | null>(null)
  const [confirmOpen, setConfirmOpen] = React.useState(false)
  const [uploadOpen, setUploadOpen] = React.useState(false)

  return (
    <>
      <Box>
        <Stack
          direction="row"
          spacing={2}
          sx={{ mt: 2, borderBottom: `1px solid ${theme.palette.text.secondary}` }}
          alignItems="baseline"
        >
          <Typography variant="overline" color={theme.palette.text.secondary}>
            Point Clouds
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Box>
            <Tooltip title="Create New Point Cloud">
              <Button startIcon={<Add />} size="small" variant="text" onClick={() => setCreateOpen(true)}>
                Add New
              </Button>
            </Tooltip>
            <HelpButton toolTip="Learn more about point clouds" pageName="point_clouds" />
          </Box>
        </Stack>
        <List>
          {(pointClouds || []).map((pc, idx) => (
            <ListItem
              key={`list-item-${idx}`}
              sx={{ '&:hover': { backgroundColor: alpha(theme.palette.background.paper, 0.3) } }}
            >
              <ListItemText
                primary={`${pc.name}`}
                secondary={pc.description && `${pc.description}`}
                sx={{
                  flex: '1 1 60%',
                }}
              />
              <Box sx={{ pl: 1 }}>
                <UploadDownloadButton
                  label="Point Cloud"
                  state={pc.state}
                  downloadUrl={pc.downloadUrl || undefined}
                  processingMessages={pc.processingMessages}
                  setUploadOpen={() => {
                    setActiveItem(pc)
                    setUploadOpen(true)
                  }}
                />
                <Tooltip title="Edit">
                  <IconButton
                    edge="end"
                    aria-label="edit"
                    color="primary"
                    onClick={() => {
                      setActiveItem(pc)
                      setEditOpen(true)
                    }}
                  >
                    <Edit />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    color="primary"
                    onClick={() => {
                      setActiveItem(pc)
                      setConfirmOpen(true)
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </ListItem>
          ))}
        </List>
        {uploadOpen && activeItem && (
          <FileUploadDialog
            title="Upload Point Cloud"
            helper={<Typography>Select a zip file that contains a point cloud ShapeFile.</Typography>}
            helpPage="point_clouds.html"
            helpTooltip="Learn more about point clouds"
            open
            onComplete={() => {
              setUploadOpen(false)
              onUploadPointCloudComplete(activeItem.id)
            }}
            onClose={() => {
              setUploadOpen(false)
            }}
            getUploadTarget={() => getPointCloudUrl(activeItem.id)}
          />
        )}
        <CreatePointCloudDialog
          open={createOpen}
          onSubmit={(pointCloud: CreatePointCloudInput) => {
            onCreatePointCloud(pointCloud)
            setCreateOpen(false)
          }}
          onClose={() => {
            setCreateOpen(false)
          }}
        />
        {activeItem && (
          <>
            <PropertyEditorDialog
              typeName="Point Cloud"
              open={editOpen}
              onClose={() => setEditOpen(false)}
              onSubmit={(item) => {
                setEditOpen(false)
                onEditPointCloud(item)
              }}
              dbItem={activeItem as GenericDBItem}
            />
            <ConfirmDelete
              dialogTitle="Confirm Delete"
              message={`Are you sure that you want to delete the ${activeItem.name} point cloud?`}
              open={confirmOpen}
              onClose={() => setConfirmOpen(false)}
              onSubmit={() => {
                onDeletePointCloud(activeItem.id)
                setConfirmOpen(false)
              }}
              itemId={activeItem.id}
            />
          </>
        )}
      </Box>
    </>
  )
}
