import { useAuth } from '../api/AuthProvider'
import { User } from '../schema/base.types'
import { useGetUserProfileLibraryQuery } from '../schema/operations'

export interface UserProfileReturn {
  myProfile: User
  loading: boolean
  error?: Error
}

export const useProfile = (): UserProfileReturn => {
  const { isAuthenticated } = useAuth()
  const userDataLibraryQry = useGetUserProfileLibraryQuery({
    skip: !isAuthenticated,
  })
  // Not getting a user profile is serious enough to warrant a global crash
  if (userDataLibraryQry.error) {
    throw userDataLibraryQry.error
  }

  return {
    myProfile: userDataLibraryQry.data?.getUserProfile as User,
    loading: userDataLibraryQry.loading,
    error: userDataLibraryQry.error,
  }
}
