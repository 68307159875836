import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react'
import { AuthLink } from './apollo/authLink'
import { createContext } from 'react'
import log from 'loglevel'
import { noop } from 'lodash'
import axios from 'axios'

export interface UseAuthReturn {
  hostedSignIn: () => void
  signOut: () => Promise<true>
  changePassword: () => Promise<boolean>
  isLoading: boolean
  isAuthenticated: boolean | null
}

const AuthContext = createContext<UseAuthReturn>({
  hostedSignIn: () => undefined,
  signOut: () => Promise.reject(),
  changePassword: () => Promise.reject(),
  isLoading: false,
  isAuthenticated: null,
})

/**
 * Simple hook for us to use when we need to access the auth context
 * Things like: hostedSignIn, signOut, changePassword, isAuthenticated
 * @returns
 */
export const useAuth = (): UseAuthReturn => {
  return useContext(AuthContext)
}

export interface AuthProviderProps {
  children: React.ReactNode
  authLink: AuthLink
  loader?: React.ReactNode
}

export const AuthProvider: React.FC<AuthProviderProps> = (props) => {
  const { domain, clientId, audience } = props.authLink.auth0config
  log.debug('AuthProvider', { redirectUrl: window.location.origin, domain, clientId, audience })
  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience,
        scope: 'offline_access openid profile email',
      }}
    >
      <Inner {...props} />
    </Auth0Provider>
  )
}

const Inner: React.FC<AuthProviderProps> = ({ children, authLink, loader }) => {
  const auth0 = useAuth0()

  const [authReady, setAuthReady] = useState(false)
  const { isAuthenticated, isLoading, user } = auth0

  log.debug('user', user)

  const contextValue = useMemo<UseAuthReturn>(
    () => ({
      hostedSignIn: () => {
        auth0.loginWithRedirect({})
      },
      isLoading,
      signOut: async () => {
        auth0.logout({
          logoutParams: {
            returnTo: window.location.origin,
          },
        })
        return true
      },
      changePassword: async () => {
        return axios
          .post(
            `https://${authLink.auth0config.domain}/dbconnections/change_password`,
            {
              client_id: authLink.auth0config.clientId,
              email: user?.email,
              connection: 'Username-Password-Authentication',
            },
            {
              headers: { 'content-type': 'application/json' },
            }
          )
          .then((response) => {
            log.debug(response.data)
            return true
          })
          .catch((error) => {
            log.error(error)
            return false
          })
      },
      isAuthenticated,
    }),
    [isAuthenticated]
  )
  useEffect(() => {
    authLink.getToken = auth0.getAccessTokenSilently /// replace accessToken.get for authLink
    auth0
      .getAccessTokenSilently({}) // changes value of auth0.isAuthenticated
      .catch(noop) // ignore error that may occur if not logged in
      .finally(async () => {
        setAuthReady(true)
      })
  }, [])

  if (!authReady) return <>{loader || 'Loading...'}</>

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
}
