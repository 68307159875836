import React, { useMemo } from 'react'
import { ApolloCache, ApolloClient, ApolloProvider, from, HttpLink, NormalizedCacheObject } from '@apollo/client'
import { logLink } from './apollo/logLink'
import { AuthLink } from './apollo/authLink'
import { apiUrl } from '../config'

export interface ApiProviderProps {
  cache: ApolloCache<NormalizedCacheObject>
  children: React.ReactNode
  authLink: AuthLink
}

export const ApiProvider: React.FC<ApiProviderProps> = ({ cache, children, authLink }) => {
  const client = useMemo(() => {
    return new ApolloClient({
      cache,
      link: from([logLink, authLink, new HttpLink({ uri: apiUrl })]),
      connectToDevTools: process.env.NODE_ENV !== 'production',
    })
  }, [])

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}
