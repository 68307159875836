import { Help } from '@mui/icons-material'
import { IconButton, IconButtonProps, Tooltip } from '@mui/material'

export interface HelpButtonProps extends Pick<IconButtonProps, 'color' | 'size'> {
  toolTip: string
  pageName: string
}

export const HelpButton: React.FC<HelpButtonProps> = ({ toolTip, pageName, ...props }) => {
  return (
    <Tooltip title={toolTip}>
      <IconButton
        color="primary"
        href={`https://northarrowresearch.github.io/xsonline-monorepo/${pageName}`}
        target="_blank"
        {...props}
      >
        <Help />
      </IconButton>
    </Tooltip>
  )
}
