import gql from 'graphql-tag'

export default gql`
schema {
  query: Query
  mutation: Mutation
}

enum CalculationStateEnum {
  COMPLETE
  ERROR
  INIT
  PROCESSING
}

type Centerline {
  downloadUrl: String
  geoJSON: JSONObject
  processingMessages: [String!]!
  state: FileStateEnum!
  updatedOn: DateTime!
}

input CreateLongChangeInput {
  """Binsize for binning"""
  binSize: Float!
  """
  If coincident is true then only include cross sections that occur
  in both surveys. If false then include all cross sections, even if
  they only occur in one of the surveys. When this is the case a cross
  section will be interpolated in the other survey.
  """
  coincident: Boolean!
  description: String
  downstream: Float!
  """
  The names of the left and right references to constrain the cross sections.
  These correspond to reference stations defined on each cross section definition.
  """
  leftReferenceStation: String
  meta: [MetaDataInput!]
  name: String!
  newSurveyId: ID!
  oldSurveyId: ID!
  """
  If originalExtents is true then then original cross section points 
  are used. If false then the cross sections are trimmed to the common
  extent.
  """
  originalExtents: Boolean!
  """
  If a reference plane is provided then it will be used to vertically
  filter the cross section elevations (using the reference plane offset).
  If no reference plane is provided then the maximum cross section elevations
  will be used.
  """
  referencePlaneId: ID
  """
  If a reference plan is provided then this vertical offset in metres is
  applied to the elevations of the cross section survey points. If no
  reference plane is provided then this field is ignored.
  """
  referencePlaneOffset: Float
  """
  If a referenceStationSet is provided then this field is used to determine
  whether to filteer inside (True) or outside (False) the reference stations.
  If a referenceStationSet is not provided then this field is ignored.
  """
  referenceStationFilterInside: Boolean
  rightReferenceStation: String
  selectionId: ID!
  """The upstream and downstream extents of the analysis"""
  upstream: Float!
}

input CreateLongProfileInput {
  description: String
  elevationType: String!
  """
  If the user chooses to only consider certain stations along cross section
  then they can specify the name of the left and right reference station here. This will
  be used to look up the referenceStations on the XSDefinition for each cross
  section being considered. Leave both blank to use entire cross section lengths.
  """
  leftStation: String
  meta: [MetaDataInput!]
  name: String!
  rightStation: String
  """
  If a selection is provided then only the cross sections in the selection
  will be used in the long profile. If the ID is left blank then all cross
  sections will be used.
  """
  selectionId: ID
  surveyId: ID!
}

"""
This is the the input type for creating a point cloud.
See GenericInput for the common fields that the UI should
use for simply editing the basic properties (name etc).
"""
input CreatePointCloudInput {
  description: String
  meta: [MetaDataInput!]
  name: String!
  zField: String!
  zFieldUnits: String!
}

"""
This is the the input type for creating a reference plane.
See GenericInput for the common fields that the UI should
use for simply editing the basic properties (name etc).
"""
input CreateReferencePlaneInput {
  description: String
  meta: [MetaDataInput!]
  name: String!
  xField: String!
  xFieldUnits: String!
  zField: String!
  zFieldUnits: String!
}

type CrossSections {
  downloadUrl: String
  fieldUnits: String
  geoJSON: JSONObject
  labelField: String
  processingMessages: [String!]!
  referenceStationFields: [String!]!
  referenceStationUnits: String
  state: FileStateEnum!
  updatedOn: DateTime!
}

"""Base information for all objects in the database"""
interface DBObj {
  createdOn: DateTime!
  description: String
  id: ID!
  meta: [MetaData!]!
  name: String!
  updatedOn: DateTime!
}

scalar DateTime

"""
this is the input for FarGate processing to use to update
the state before, during and after processing.
"""
input FargateStatusInput {
  geometry: JSONObject
  originalSRS: String
  processingMessages: [String!]
  state: FileStateEnum!
  userId: ID!
}

enum FileStateEnum {
  COMPLETE
  ERROR
  NONE
  PROCESSING
  UPLOADED
}

input GenericUpdateInput {
  description: String
  meta: [MetaDataInput!]
  name: String
}

interface GeoObj {
  downloadUrl: URL
  geometry: JSONObject
  originalSRS: String
  state: FileStateEnum!
}

scalar JSONObject

type LCBin {
  averageBedElevationChange: Float!
  controlVolumeChange: Float!
  downstreamBedElevationChange: Float!
  downstreamRiverDistance: Float!
  newArea: Float!
  newDownstreamTopWdth: Float!
  oldArea: Float!
  oldDownstreamTopWidth: Float!
  upstreamRiverDistance: Float!
  weightedBedChange: Float!
}

"""
A longitudinal change is an anlysis of two cross section surveys within a project
The new and old survey are stored as fields while all other input properties are
stored in the meta field.
"""
type LongChange implements DBObj {
  """Binsize for binning"""
  binSize: Float!
  """
  If coincident is true then only include cross sections that occur
  in both surveys. If false then include all cross sections, even if
  they only occur in one of the surveys. When this is the case a cross
  section will be interpolated in the other survey.
  """
  coincident: Boolean!
  createdOn: DateTime!
  description: String
  downstream: Float!
  id: ID!
  """
  The names of the left and right references to constrain the cross sections.
  These correspond to reference stations defined on each cross section definition.
  """
  leftReferenceStation: String
  meta: [MetaData!]!
  name: String!
  newSurvey: Survey
  newSurveyName: String!
  oldSurvey: Survey
  oldSurveyName: String!
  """
  If originalExtents is true then then original cross section points 
  are used. If false then the cross sections are trimmed to the common
  extent.
  """
  originalExtents: Boolean!
  points(nextToken: String): PaginatedLCBin!
  processingMessages: [String!]!
  project: Project!
  """
  If a reference plane is provided then it will be used to vertically
  filter the cross section elevations (using the reference plane offset).
  If no reference plane is provided then the maximum cross section elevations
  will be used.
  """
  referencePlane: ReferencePlane
  referencePlaneName: String
  """
  If a reference plan is provided then this vertical offset in metres is
  applied to the elevations of the cross section survey points. If no
  reference plane is provided then this field is ignored.
  """
  referencePlaneOffset: Float
  """
  If a referenceStationSet is provided then this field is used to determine
  whether to filteer inside (True) or outside (False) the reference stations.
  If a referenceStationSet is not provided then this field is ignored.
  """
  referenceStationFilterInside: Boolean
  rightReferenceStation: String
  selection: Selection
  selectionName: String!
  state: FileStateEnum!
  updatedOn: DateTime!
  """The upstream and downstream extents of the analysis"""
  upstream: Float!
}

type LongProfile implements DBObj {
  createdOn: DateTime!
  description: String
  elevationType: String!
  id: ID!
  leftStation: String
  meta: [MetaData!]!
  name: String!
  points(nextToken: String): PaginatedPoint2D!
  processingMessages: [String!]!
  project: Project!
  rightStation: String
  selection: Selection
  selectionName: String
  state: FileStateEnum!
  survey: Survey
  surveyName: String!
  updatedOn: DateTime!
}

type MetaData {
  key: String!
  type: MetaDataTypeEnum
  value: String!
}

input MetaDataInput {
  key: String!
  type: MetaDataTypeEnum
  value: String!
}

enum MetaDataTypeEnum {
  BOOLEAN
  FILEPATH
  FLOAT
  GUID
  IMAGE
  INT
  ISODATE
  JSON
  MARKDOWN
  RICHTEXT
  STRING
  TIMESTAMP
  URL
  VIDEO
}

type Mutation {
  copyProject(description: String, name: String!, projectId: ID!): Project
  createLongChange(longChange: CreateLongChangeInput!, projectId: ID!): LongChange
  createLongProfile(longProfile: CreateLongProfileInput!, projectId: ID!): LongProfile
  createPointCloud(pointCloud: CreatePointCloudInput!): PointCloud
  createProject(project: ProjectInput!): Project
  createReferencePlane(projectId: ID!, referencePlane: CreateReferencePlaneInput!): ReferencePlane
  createSelection(projectId: ID!, selection: SelectionInput!): Selection
  createSurvey(projectId: ID!, survey: SurveyInput!): Survey
  createXSDefinitions(projectId: ID!, xsDefinitions: [XSDefinitionInput!]!): Int
  createXSInstances(projectId: ID!, surveyId: ID!, userId: ID!, xsInstances: [XSInstanceInput!]!): Int
  deleteLongChange(longChangeId: ID!, projectId: ID!): LongChange
  deleteLongProfile(longProfileId: ID!, projectId: ID!): LongProfile
  deletePointCloud(pointCloudId: ID!): PointCloud
  deleteProject(projectId: ID!): Project
  deleteReferencePlane(projectId: ID!, referencePlaneId: ID!): ReferencePlane
  deleteSelection(projectId: ID!, selectionId: ID!): Selection
  deleteSurvey(projectId: ID!, surveyId: ID!): Survey
  deleteUser(userId: ID!): User
  updateLongChange(fargateInput: FargateStatusInput, genericInput: GenericUpdateInput, longChangeId: ID!, projectId: ID!): LongChange
  updateLongProfile(fargateInput: FargateStatusInput, genericInput: GenericUpdateInput, longProfileId: ID!, projectId: ID!): LongProfile
  updatePointCloud(fargateInput: FargateStatusInput, genericInput: GenericUpdateInput, pointCloudId: ID!): PointCloud
  updateProject(project: ProjectUpdate!, projectId: ID!): Project
  updateProjectCenterline(fargateInput: FargateStatusInput!, projectId: ID!): Project
  updateProjectCrossSections(fargateInput: FargateStatusInput!, projectId: ID!): Project
  updateReferencePlane(fargateInput: FargateStatusInput, genericInput: GenericUpdateInput, projectId: ID!, referencePlaneId: ID!): ReferencePlane
  updateSelection(genericInput: GenericUpdateInput!, projectId: ID!, selectionId: ID!): Selection
  updateSurvey(fargateInput: FargateStatusInput, genericInput: GenericUpdateInput, projectId: ID!, surveyId: ID!): Survey
  uploadPointCloud(pointCloudId: ID!): UploadTarget
  uploadPointCloudComplete(pointCloudId: ID!): PointCloud
  uploadProjectCenterline(projectId: ID!): UploadTarget
  uploadProjectCenterlineComplete(projectId: ID!): Project
  uploadProjectCrossSections(projectId: ID!): UploadTarget
  uploadProjectCrossSectionsComplete(projectId: ID!): Project
  uploadReferencePlane(projectId: ID!, referencePlaneId: ID!): UploadTarget
  uploadReferencePlaneComplete(projectId: ID!, referencePlaneId: ID!): ReferencePlane
  upsertUser(user: UserInput!): User
}

type PaginatedLCBin {
  items: [LCBin!]!
  nextToken: String
}

type PaginatedPoint2D implements Pagination {
  items: [Point2D!]!
  nextToken: String
}

type PaginatedProject implements Pagination {
  items: [Project!]!
  nextToken: String
}

type PaginatedXSDefinition implements Pagination {
  items: [XSDefinition!]!
  nextToken: String
}

type PaginatedXSInstance implements Pagination {
  items: [XSInstance!]!
  nextToken: String
}

"""Generic Pagination Interface"""
interface Pagination {
  nextToken: String
}

"""
A generic 2D point object. The x and y values can mean different things but are always in metres.
When used as a survey point the x value is the station and the y value is the elevation.
When used as a long profile point the x value is the river distance and the y value is the elevation.
"""
type Point2D {
  x: Float!
  y: Float!
}

input Point2DInput {
  x: Float!
  y: Float!
}

"""A collection of 3D points that represent a point cloud."""
type PointCloud implements DBObj & GeoObj {
  createdOn: DateTime!
  description: String
  downloadUrl: URL
  geometry: JSONObject
  id: ID!
  meta: [MetaData!]!
  name: String!
  originalSRS: String
  owner: User!
  processingMessages: [String!]!
  state: FileStateEnum!
  updatedOn: DateTime!
  zField: String!
  zFieldUnits: String!
}

"""
A project represents one or more point clouds combined with a single
centerline and set of cross section definitions.
"""
type Project implements DBObj {
  areaUnits: String!
  centerline: Centerline!
  centroid: JSONObject!
  createdOn: DateTime!
  crossSections: CrossSections!
  description: String
  id: ID!
  isPrivate: Boolean!
  longChanges: [LongChange!]!
  longProfiles: [LongProfile!]!
  meta: [MetaData!]!
  name: String!
  owner: User!
  referencePlanes: [ReferencePlane!]!
  riverDistanceUnits: String!
  selections: [Selection!]!
  startDistance: Float!
  surveyDistanceUnits: String!
  surveys: [Survey!]!
  updatedOn: DateTime!
  volumeUnits: String!
  website: URL
  xsDefinitions(nextToken: String): PaginatedXSDefinition!
}

input ProjectInput {
  description: String
  isPrivate: Boolean!
  meta: [MetaDataInput!]
  name: String!
  referenceStationFields: [String!]
  startDistance: Float!
  units: UnitsInput
  website: String
}

input ProjectUpdate {
  description: String
  isPrivate: Boolean
  meta: [MetaDataInput!]
  name: String
  startDistance: Float
  units: UnitsInput
  website: String
}

type Query {
  getLongChange(longChangeId: ID!, projectId: ID!): LongChange
  getLongProfile(longProfileId: ID!, projectId: ID!): LongProfile
  getPointCloud(pointCloudId: ID!, userId: ID!): PointCloud
  getProject(projectId: ID!): Project
  getProjectsMap(nextToken: String): PaginatedProject!
  getReferencePlane(projectId: ID!, referencePlaneId: ID!): ReferencePlane
  getSelection(projectId: ID!, selectionId: ID!): Selection
  getSurvey(projectId: ID!, surveyId: ID!): Survey
  getUser(userId: ID!): User
  getUserProfile: User
  getXSInstance(projectId: ID!, surveyId: ID!, xsId: Int!): XSInstance
  searchProjects(nextToken: String, search: String!): PaginatedProject!
}

type ReferencePlane implements DBObj {
  createdOn: DateTime!
  description: String
  downloadUrl: String
  id: ID!
  meta: [MetaData!]!
  name: String!
  points(nextToken: String): PaginatedPoint2D!
  processingMessages: [String!]!
  project: Project
  state: FileStateEnum!
  updatedOn: DateTime!
  xField: String!
  xFieldUnits: String!
  zField: String!
  zFieldUnits: String!
}

type ReferenceStation {
  name: String!
  value: Float!
}

type Selection implements DBObj {
  createdOn: DateTime!
  description: String
  id: ID!
  meta: [MetaData!]!
  name: String!
  updatedOn: DateTime!
  xsDefinitions(nextToken: String): PaginatedXSDefinition!
}

input SelectionInput {
  allSurveys: Boolean!
  description: String
  downstream: Float!
  meta: [MetaDataInput!]
  name: String!
  surveyIds: [ID!]!
  upstream: Float!
}

type SpatialDataFile implements GeoObj {
  downloadUrl: URL!
  geometry: JSONObject
  originalSRS: String
  state: FileStateEnum!
}

type Survey implements DBObj {
  createdOn: DateTime!
  description: String
  id: ID!
  meta: [MetaData!]!
  name: String!
  pointCloud: PointCloud!
  processingMessages: [String!]!
  project: Project
  snappingTolerance: Float!
  state: FileStateEnum!
  updatedOn: DateTime!
  xsInstances(nextToken: String, xsId: Int): PaginatedXSInstance!
}

input SurveyInput {
  description: String
  meta: [MetaDataInput!]
  name: String!
  pointCloudId: ID!
  snappingTolerance: Float!
}

scalar URL

input UnitsInput {
  areaUnits: String!
  riverDistanceUnits: String!
  surveyDistanceUnits: String!
  volumeUnits: String!
}

type UploadTarget {
  """
  POST body FormData fields which accompany 'file' field. Note: For S3 uploads, 'file' field must appear last!
  """
  fields: JSONObject!
  """POST target URL."""
  url: String!
}

"""A user of the system"""
type User implements DBObj {
  affiliation: String
  createdOn: DateTime!
  description: String
  id: ID!
  meta: [MetaData!]!
  name: String!
  pointClouds: [PointCloud!]!
  projects: [Project!]!
  socialUrl: String
  updatedOn: DateTime!
}

input UserInput {
  affiliation: String
  description: String
  meta: [MetaDataInput!]
  name: String!
  socialUrl: String
}

type XSDefinition {
  createdOn: DateTime!
  distance: Float!
  lbx: Float!
  lby: Float!
  name: String
  projectId: ID!
  rbx: Float!
  rby: Float!
  referenceStations: [ReferenceStation!]!
  updatedOn: DateTime!
  xsId: Int!
}

input XSDefinitionInput {
  distance: Float!
  lbx: Float!
  lby: Float!
  name: String!
  rbx: Float!
  rby: Float!
  referenceStationNames: [String!]!
  referenceStationValues: [Float!]!
  xsId: Int!
}

"""
A cross section instance is a cross section definition that is associated with
a centerline.
"""
type XSInstance {
  id: ID!
  surveyId: ID!
  surveyPoints(nextToken: String): PaginatedPoint2D!
  xsDefinition: XSDefinition
  xsId: Int!
}

input XSInstanceInput {
  xsId: Int!
}
`