import React from 'react'
import { AgGridReact, AgGridReactProps } from 'ag-grid-react'
import { GridApi, ColumnApi, SelectionChangedEvent, GridReadyEvent } from 'ag-grid-community'
import { extractId } from './dataGridHelpers'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-balham.css'
import { Box } from '@mui/material'
// import './DataGrid.css'

export interface DataGridRow {
  id: number
  // unselectable?: boolean
}

export interface DataGridApis {
  api?: GridApi
  columnApi?: ColumnApi
}

export interface DataGridProps<TRow> extends AgGridReactProps<TRow> {
  // rows: DataGridRow[]
  // columnDefs: (ColDef | ColGroupDef)[]
  onSelectedRowsChanged?: (rows: TRow[]) => void
  rowData: TRow[]
  onGridApisReady?: (params: DataGridApis) => void
}

export function DataGrid<TRow extends { id: string }>({
  // rowData,
  // rows,
  // columnDefs,
  rowData,
  onSelectedRowsChanged,
  onGridApisReady,
  ...restProps
}: DataGridProps<TRow>) {
  // // GRID APIS
  // const [grid, setGrid] = useState<DataGridApis>()

  // // EVENT HANDLERS

  const handleOnSelectionChanged = ({ api }: SelectionChangedEvent<TRow>): void => {
    if (!onSelectedRowsChanged) return
    const selectedRows = api.getSelectedRows()
    onSelectedRowsChanged(selectedRows)
  }

  const handleGridReady = ({ api, columnApi }: GridReadyEvent) => {
    //   setGrid({ api, columnApi })
    if (onGridApisReady) onGridApisReady({ api, columnApi })
  }

  // // CONTEXT AND REFRESH

  // const context = useProject()

  // useEffect(() => {
  //   if (!grid?.api) return

  //   // must force a refresh when context changes
  //   grid.api.refreshCells()
  //   grid.api.refreshHeader()
  // }, [context])

  // // OTHER OPTIONS

  // const gridOptions = useMemo(
  //   // need stable, per instance grid options
  //   () => ({
  //     suppressCellSelection: true,
  //     rowSelection: 'multiple',
  //     defaultColDef: {
  //       sortable: false,
  //       resizable: true,
  //       suppressMovable: true,
  //     },
  //     rowClassRules: {
  //       'DataGrid__row--unselectable': ({ data: { unselectable } }) => unselectable,
  //     },
  //   }),
  //   []
  // )

  // const isRowSelectable = ({ data: { unselectable } }) => !unselectable

  // RENDER

  return (
    <Box component="div" className="ag-theme-balham" style={{ width: '100%', height: '100%' }}>
      <AgGridReact
        onGridReady={handleGridReady}
        getRowId={({ data }) => extractId(data)}
        rowData={rowData}
        onSelectionChanged={handleOnSelectionChanged}
        {...restProps}
      />
    </Box>
  )
}
