import * as React from 'react'
import { PointCloudList } from './PointCloudList'
import { ProjectList } from './ProjectList'
import {
  CreatePointCloudInput,
  PointCloud,
  Project,
  ProjectInput,
  ProjectUpdate,
  UploadTarget,
} from '../../schema/base.types'
import { GenericDBItem } from '../../types'
import { Box, Container, Paper, Typography, alpha, useTheme } from '@mui/material'

export interface DataLibraryProps {
  navigate: (path: string) => void
  projects: Project[]
  pointClouds: PointCloud[]
  onCreateProject: (dbItem: ProjectInput) => void
  onEditProject: (projectId: string, project: ProjectUpdate) => void
  onDeleteProject: (projectId: string) => void
  onCreatePointCloud: (pointCloud: CreatePointCloudInput) => void
  onEditPointCloud: (dbItem: GenericDBItem) => void
  onDeletePointCloud: (pointCloudId: string) => void
  // Point Cloud Upload URL
  getPointCloudUrl: (pointCloudId: string) => Promise<UploadTarget>
  onUploadPointCloudComplete: (pointCloudId: string) => void
}

export const DataLibrary: React.FC<DataLibraryProps> = ({
  projects,
  pointClouds,
  navigate,
  onCreateProject,
  onEditProject,
  onDeleteProject,
  onCreatePointCloud,
  onEditPointCloud,
  onDeletePointCloud,
  getPointCloudUrl,
  onUploadPointCloudComplete,
}) => {
  const theme = useTheme()
  return (
    <Container maxWidth="md">
      <Paper
        sx={{
          overflowY: 'scroll',
          my: 2,
          minHeight: '80vh',
          backgroundColor: alpha(theme.palette.background.paper, 0.8),
          backdropFilter: 'blur(10px)',
        }}
        elevation={4}
      >
        <Box
          sx={{
            background: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            px: 2,
            py: 2,
          }}
        >
          <Typography variant="h5" color="inherit">
            Data Library
          </Typography>
        </Box>
        <Box
          sx={{
            px: 2,
            py: 2,
          }}
        >
          <ProjectList
            projects={projects}
            navigate={navigate}
            onCreateProject={onCreateProject}
            onEditProject={onEditProject}
            onDeleteProject={onDeleteProject}
          />
          <PointCloudList
            pointClouds={pointClouds}
            onCreatePointCloud={onCreatePointCloud}
            onEditPointCloud={onEditPointCloud}
            onDeletePointCloud={onDeletePointCloud}
            getPointCloudUrl={getPointCloudUrl}
            onUploadPointCloudComplete={onUploadPointCloudComplete}
          />
        </Box>
      </Paper>
    </Container>
  )
}
