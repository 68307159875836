import { useMemo, useState } from 'react'
import { sortBy, uniq, without } from 'lodash'
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  useTheme,
} from '@mui/material'

import {
  FileStateEnum,
  GetLongProfileChartOverviewQuery,
  GetLongProfileDataQuery,
  GetReferencePlaneDataQuery,
  Point2D,
  Project,
} from '../../../schema/base.types'
import { useStableColor } from '../../../lib/useStableColor'
import { someValues, useFields } from '../../../lib/useFields'
import { useDebounce } from '../../../lib/useDebounce'
import { ChartableDataSeries, SurveyDataChart } from '../../SurveyDataChart'
import { Chip } from '../../Chip'
import { NumberField } from '../../NumberField'
import { DataGrid, extractId, DataGridApis } from '../../DataGrid'
import { longProfilesColumnDefs, referencePlanesColumnDefs } from './columnDefs'
import { Column, Row } from '../../Layout'
import {
  EMPTY_ARRAY_INPUT,
  addColorFilterFn,
  includePinnedIdsFilterFn,
  includeUnpinnedIdsFilterFn,
  useFilterPipeline,
} from '../../../lib/filtering'
import { densifyReferencePlanesFilterFn, relativeToReferencePlaneFilterFn, riverDistanceFilterFn } from '../filters'
import { UnitsSettings, useFormats } from '../../../lib/formats'
import { downloadDataFile } from '../../../lib/downloadDataFile'
import { DownloadButton } from '../../DownloadButton'
import { ViewerHeader } from '../ViewerHeader'
import { getElevationAtDistance } from '../../../lib/transectAreaChart/mathHelpers'
import { SurveyPoint } from '../../../lib/transectAreaChart/surveyPoint'

export interface LongProfileChartProps {
  project: NonNullable<GetLongProfileChartOverviewQuery['getProject']>
  longProfiles: NonNullable<GetLongProfileDataQuery['getLongProfile']>[]
  referencePlanes: NonNullable<GetReferencePlaneDataQuery['getReferencePlane']>[]
  onActiveLongProfilesChange: (longProfileIds: string[]) => void
  onActiveReferencePlanesChange: (referencePlaneIds: string[]) => void
  onNavigate: (string) => void
}

enum ElevationMode {
  Absolute = 'absolute',
  Relative = 'relative',
}

export const LongProfileChart: React.FC<LongProfileChartProps> = ({
  project,
  longProfiles,
  referencePlanes,
  onActiveLongProfilesChange,
  onActiveReferencePlanesChange,
  onNavigate,
}) => {
  const { convertSurveyDistance, convertRiverDistance, formatRiverDistanceHeader, formatSurveyDistanceHeader } =
    useFormats(project as UnitsSettings)

  const theme = useTheme()
  const [longProfileGridApis, setLongProfileGridApis] = useState<DataGridApis>()
  const [referencePlaneGridApis, setReferencePlaneGridApis] = useState<DataGridApis>()
  // const [chartMethods, setChartMethods] = useState<ChartMethods>()

  const [assignStableLongProfileColor] = useStableColor()
  const [assignStableReferencePlaneColor] = useStableColor('references')

  const [fields, setFields] = useFields({
    riverDistanceMax: null as number | null,
    riverDistanceMin: null as number | null,
    selectedLongProfileIds: [] as string[],
    pinnedLongProfileIds: [] as string[],
    selectedReferencePlaneIds: [] as string[],
    pinnedReferencePlaneIds: [] as string[],
    invertX: true,
    elevationMode: ElevationMode.Absolute,
    elevationRelativeToReferencePlaneId: null as string | null,
  })

  const errors = someValues(() => {
    const riverDistance =
      fields.riverDistanceMax !== null &&
      fields.riverDistanceMin !== null &&
      fields.riverDistanceMax < fields.riverDistanceMin
    const elevationRelativeToReferencePlaneId =
      fields.elevationMode === ElevationMode.Relative && !fields.elevationRelativeToReferencePlaneId

    return {
      riverDistance,
      elevationRelativeToReferencePlaneId,
    }
  })

  const {
    riverDistanceMax,
    riverDistanceMin,
    pinnedLongProfileIds,
    selectedLongProfileIds,
    pinnedReferencePlaneIds,
    selectedReferencePlaneIds,
    invertX,
    elevationMode,
    elevationRelativeToReferencePlaneId,
  } = fields

  const [debouncedRiverDistanceMax] = useDebounce(riverDistanceMax, 250)
  const [debouncedRiverDistanceMin] = useDebounce(riverDistanceMin, 250)

  // PRE-COMPUTATION

  const elevationRelativeToReferencePlane = useMemo(() => {
    if (elevationMode !== 'relative') return undefined
    return referencePlanes.find((plane) => plane.id === elevationRelativeToReferencePlaneId) || null
  }, [elevationMode, elevationRelativeToReferencePlaneId])

  // LONG PROFILE DATA COMPUTATION

  const activeLongProfiles = useFilterPipeline(EMPTY_ARRAY_INPUT)
    .filter(includePinnedIdsFilterFn, {
      source: longProfiles,
      ids: pinnedLongProfileIds,
    })
    .filter(includeUnpinnedIdsFilterFn, {
      source: longProfiles,
      ids: selectedLongProfileIds,
    })
    .filter(addColorFilterFn, {
      assignColorFn: assignStableLongProfileColor,
    })
    .filter(riverDistanceFilterFn, {
      min: debouncedRiverDistanceMin,
      max: debouncedRiverDistanceMax,
      convertRiverDistance,
    })
    .filter(relativeToReferencePlaneFilterFn, {
      referencePlane: elevationRelativeToReferencePlane,
    })
    .output()

  const unpinnedLongProfileIds = useMemo(
    () => selectedLongProfileIds.filter((id) => !pinnedLongProfileIds.includes(id)),
    [pinnedLongProfileIds, selectedLongProfileIds]
  )

  // REFERENCE PLANE DATA COMPUTATION

  const activeReferencePlanes = useFilterPipeline(EMPTY_ARRAY_INPUT)
    .filter(includePinnedIdsFilterFn, {
      source: referencePlanes,
      ids: pinnedReferencePlaneIds,
    })
    .filter(includeUnpinnedIdsFilterFn, {
      source: referencePlanes.map((i) => ({ ...i })),
      ids: selectedReferencePlaneIds,
    })
    .filter(addColorFilterFn, {
      assignColorFn: assignStableReferencePlaneColor,
    })
    .filter(densifyReferencePlanesFilterFn, {
      longProfiles: activeLongProfiles,
    })
    .filter(riverDistanceFilterFn, {
      min: debouncedRiverDistanceMin,
      max: debouncedRiverDistanceMax,
      convertRiverDistance,
    })
    .filter(relativeToReferencePlaneFilterFn, {
      referencePlane: elevationRelativeToReferencePlane,
    })
    .output()

  const unpinnedReferencePlaneIds = useMemo(
    () => selectedReferencePlaneIds.filter((id) => !pinnedReferencePlaneIds.includes(id)),
    [pinnedReferencePlaneIds, selectedReferencePlaneIds]
  )

  // PINNING EVENTS

  const handlePinAllData = () => {
    setFields.$.pinnedLongProfileIds([...pinnedLongProfileIds, ...unpinnedLongProfileIds])
    setFields.$.pinnedReferencePlaneIds([...pinnedReferencePlaneIds, ...unpinnedReferencePlaneIds])
    longProfileGridApis?.api?.deselectAll()
    referencePlaneGridApis?.api?.deselectAll()
  }

  // CHART DATA

  const referencePlanElevationOffset = (riverDistance: number) => {
    if (elevationMode === ElevationMode.Relative && elevationRelativeToReferencePlane) {
      return getElevationAtDistance(
        elevationRelativeToReferencePlane.points.items.map((sp) => {
          return new SurveyPoint(convertRiverDistance(sp.x), convertSurveyDistance(sp.y))
        }),
        riverDistance
      )
    } else {
      return 0
    }
  }

  const [chartData, xScaleMin, xScaleMax] = useMemo<
    [ChartableDataSeries[], number | undefined, number | undefined]
  >(() => {
    if (activeLongProfiles.length === 0) return [[], undefined, undefined]

    const transformToChartData = ({
      id,
      name: label,
      color,
      points,
    }: (typeof activeLongProfiles | typeof activeReferencePlanes)[number]): ChartableDataSeries => ({
      id,
      label,
      color,
      data: points.items
        .map(({ x, y }) => ({
          x: convertRiverDistance(x),
          y: convertSurveyDistance(y + referencePlanElevationOffset(x)),
        }))
        .sort((a, b) => a.x - b.x),
    })

    const longProfilesChartData = activeLongProfiles.map(transformToChartData)
    const referencePlanesChartData = activeReferencePlanes.map(transformToChartData)

    const chartAreaXValues = longProfilesChartData.reduce<number[]>(
      (acc, { data }) => [...acc, ...data.map(({ x }) => x)],
      []
    )
    const xScaleMin = Math.min(...chartAreaXValues)
    const xScaleMax = Math.max(...chartAreaXValues)

    return [[...longProfilesChartData, ...referencePlanesChartData], xScaleMin, xScaleMax]
  }, [activeLongProfiles, activeReferencePlanes, convertSurveyDistance, convertRiverDistance, fields.elevationMode])

  // DOWNLOAD

  const handleDownloadDataFile = (fileType: 'xlsx' | 'csv') => {
    const allLongProfileRiverDistancesSorted = sortBy(
      uniq(
        activeLongProfiles.reduce<number[]>((acc, { points: { items } }) => [...acc, ...items.map(({ x }) => x)], [])
      )
    )

    const getPropertyAtRiverDistance = (points: Point2D[], targetX: number, key: string) => {
      const value = points.find(({ x }) => x === targetX)
      return value?.[key]
    }

    const columnGroups = [
      { headerFormatter: (sourceTitle: string) => formatSurveyDistanceHeader(sourceTitle), propertyKey: 'y' }, // column group for elevation
    ]
    if (elevationRelativeToReferencePlane) {
      columnGroups[0].propertyKey = 'absoluteY' // first column group must be absoluteElevation as added by filter
      columnGroups.push({
        // add second column group for relative elevation
        headerFormatter: (sourceTitle) =>
          formatSurveyDistanceHeader(`${sourceTitle} relative to ${elevationRelativeToReferencePlane.name}`),
        propertyKey: 'y',
      })
    }

    const data = allLongProfileRiverDistancesSorted.map<Record<string, number>>((riverDistance) => {
      const row = { [formatRiverDistanceHeader('River Distance')]: convertRiverDistance(riverDistance) }

      columnGroups.forEach(({ headerFormatter, propertyKey }) => {
        ;[...activeLongProfiles, ...activeReferencePlanes].forEach((activeSource) => {
          const propertyValue = getPropertyAtRiverDistance(activeSource.points.items, riverDistance, propertyKey)
          row[headerFormatter(activeSource.name)] = propertyValue && convertSurveyDistance(propertyValue)
        })
      })

      return row
    })
    downloadDataFile({ data, fileName: 'Long_Profile_Viewer_Export', fileType })
  }

  const CombineReferencePlaneIds = () => {
    // Handle either the selected reference plane changing or
    // when the user selects a different reference plane for
    // relative elevations. Combine all the necessary Reference
    // Plane IDs and pass them to the parent component to load the actual data.

    const allSelectedReferencePlaneIds = elevationRelativeToReferencePlaneId
      ? uniq([...selectedReferencePlaneIds, elevationRelativeToReferencePlaneId].filter(Boolean))
      : selectedReferencePlaneIds

    // Pass the combined reference plane IDs to the parent component
    onActiveReferencePlanesChange(allSelectedReferencePlaneIds)
  }

  // RENDER

  return (
    <>
      <ViewerHeader
        title="Long Profile Viewer"
        helpPage="long_profiles.html#viewer"
        project={project as Project}
        onNavigate={onNavigate}
      />
      <Column height="100%" width="100%" p={2}>
        <Row flex={1} spacing={2}>
          <Column width="20em" spacing={2}>
            <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridGap: '0.5em' }}>
              <NumberField
                value={fields.riverDistanceMax}
                onChange={setFields.$.riverDistanceMax}
                label={formatRiverDistanceHeader('Upstream')}
                error={errors?.riverDistance}
                sx={{ bgcolor: 'white' }}
              />
              <NumberField
                value={fields.riverDistanceMin}
                onChange={setFields.$.riverDistanceMin}
                label={formatRiverDistanceHeader('Downstream')}
                error={errors?.riverDistance}
                sx={{ bgcolor: 'white' }}
              />
            </Box>
            <Box flex={1}>
              <DataGrid<(typeof project.longProfiles)[number]>
                columnDefs={longProfilesColumnDefs}
                rowData={project.longProfiles}
                onSelectedRowsChanged={(rows) => {
                  const rowIds = rows.map(extractId)
                  setFields.$.selectedLongProfileIds(rowIds)
                  onActiveLongProfilesChange(rowIds)
                }}
                rowSelection="multiple"
                onGridApisReady={setLongProfileGridApis}
              />
            </Box>
            <Box flex={1}>
              <DataGrid
                columnDefs={referencePlanesColumnDefs}
                rowData={project.referencePlanes}
                onSelectedRowsChanged={(rows) => {
                  const rowIds = rows.map(extractId)
                  setFields.$.selectedReferencePlaneIds(rowIds)

                  // Combine the selected reference planes with the relative elevation reference plane
                  // and pass them to the parent component
                  CombineReferencePlaneIds()
                }}
                rowSelection="multiple"
                onGridApisReady={setReferencePlaneGridApis}
              />
            </Box>
            <Button
              variant="contained"
              onClick={handlePinAllData}
              disabled={unpinnedLongProfileIds.length + unpinnedReferencePlaneIds.length === 0}
            >
              Pin All
            </Button>
            <Box flex={0} bgcolor={'white'} sx={{ px: 2 }}>
              <FormControl fullWidth>
                <FormControl fullWidth>
                  <RadioGroup
                    value={fields.elevationMode}
                    onChange={(e) => setFields.$.elevationMode(e.target.value as ElevationMode)}
                  >
                    <FormControlLabel control={<Radio value={ElevationMode.Absolute} />} label="Absolute values" />
                    <FormControlLabel
                      control={
                        <Radio
                          value={ElevationMode.Relative}
                          disabled={
                            project.referencePlanes.filter((x) => x.state === FileStateEnum.Complete).length < 1
                          }
                        />
                      }
                      label="Values relative to:"
                    />
                    <Box marginLeft={4}>
                      <FormControl fullWidth disabled={project.referencePlanes.length < 1}>
                        <Select
                          value={elevationRelativeToReferencePlaneId || ''}
                          onChange={(e) => {
                            setFields.$.elevationRelativeToReferencePlaneId(e.target.value || null)
                            // Combine this ID with the selected reference planes and pass them to the parent component
                            CombineReferencePlaneIds()
                          }}
                          disabled={elevationMode !== ElevationMode.Relative}
                          fullWidth
                          error={errors?.elevationRelativeToReferencePlaneId}
                        >
                          {project.referencePlanes.map(({ name, id }) => (
                            <MenuItem key={id} value={id}>
                              {name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </RadioGroup>
                </FormControl>
                <FormControlLabel
                  control={<Checkbox checked={invertX} onChange={(e) => setFields.$.invertX(e.target.checked)} />}
                  label="Y axis represents upstream"
                />
              </FormControl>
            </Box>
            <Box flex={1} />
          </Column>
          <Column flex={2}>
            <Row>
              <Box minHeight="3em" flex={1}>
                {activeReferencePlanes.map(({ id, name, color, pinned }) => (
                  <Chip
                    key={id}
                    variant="reference"
                    label={name}
                    color={color}
                    pinned={pinned}
                    onDelete={
                      pinned
                        ? () => {
                            setFields.$.pinnedReferencePlaneIds(without(pinnedReferencePlaneIds, id))
                          }
                        : undefined
                    }
                  />
                ))}
                {activeLongProfiles.map(({ id, name, color, pinned }) => (
                  <Chip
                    key={id}
                    label={name}
                    color={color}
                    pinned={pinned}
                    onDelete={
                      pinned
                        ? () => {
                            setFields.$.pinnedLongProfileIds(without(pinnedLongProfileIds, id))
                          }
                        : undefined
                    }
                  />
                ))}
              </Box>
              {activeLongProfiles.length > 0 && (
                <DownloadButton
                  sx={{ color: theme.palette.primary.main }}
                  onDownloadXlsx={() => handleDownloadDataFile('xlsx')}
                  onDownloadCsv={() => handleDownloadDataFile('csv')}
                />
              )}
            </Row>
            <Box flex={1}>
              <SurveyDataChart
                data={chartData}
                // onChartMethodsReady={setChartMethods}
                xLegend={formatRiverDistanceHeader('River Distance')}
                yLegend={formatSurveyDistanceHeader('Elevation')}
                xScaleDescends={invertX}
                xScaleMin={xScaleMin}
                xScaleMax={xScaleMax}
              />
            </Box>
          </Column>
        </Row>
      </Column>
    </>
  )
}
