import { GraphQLError } from 'graphql'

type ObjectValues<T> = T[keyof T]

/**
 * This enumeration is used to define the error codes that can be returned by the API
 * Doing it this way allows us to communitcate effectively with our API and means that
 * the person writing REACT doesn't need to string match the error coming back
 */
export const ErrorCode = {
  MAINENANCE_MODE: 'MAINENANCE_MODE',
  PERMISSION_DENIED: 'PERMISSION_DENIED',
  LOGGED_OUT: 'LOGGED_OUT',
} as const
export type ErrorCode = ObjectValues<typeof ErrorCode>

export const ErrorCodeMessages: Record<ErrorCode, string> = {
  MAINENANCE_MODE: 'Down for maintenance',
  PERMISSION_DENIED: 'You do not have permission to do that',
  LOGGED_OUT: 'You must be logged in to do that',
}

export const APIError = (code: ErrorCode, msg?: string): GraphQLError => {
  return new GraphQLError(msg || ErrorCodeMessages[code], {
    extensions: { code },
  })
}
