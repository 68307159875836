import { ColDef } from 'ag-grid-community'
import numeral from 'numeral'
// import { LongProfile, ReferencePlane } from '../../../schema/base.types'

/**
 * Nice number formatting
 * @param params
 * @returns
 */
const numFormatter = (params) => numeral(params.value).format('0,0.00')

export const longProfilesColumnDefs: ColDef[] = [
  { field: 'name', headerName: 'Long Profiles', resizable: false, flex: 1 },
]

export const referencePlanesColumnDefs: ColDef[] = [
  { field: 'name', headerName: 'Reference Planes', resizable: false, flex: 1 },
]

export const surveyColumnDefs: ColDef[] = [{ field: 'name', headerName: 'Surveys', resizable: false, flex: 1 }]

export const referenceStationColumnDefs: ColDef[] = [
  { field: 'name', headerName: 'Reference Stations', resizable: false, flex: 1 },
]

export const riverDistanceColumnDefs: ColDef[] = [
  { field: 'distance', headerName: 'Distance', resizable: false, flex: 1, valueFormatter: numFormatter },
  { field: 'surveyCount', headerName: 'Surveys', resizable: false, flex: 1 },
]

export const crossSectionMetricColumnDefs: ColDef[] = [
  { field: 'survey', headerName: 'Survey', resizable: false, flex: 1 },
  { field: 'xsId', headerName: 'Cross Section', resizable: false, flex: 1 },
  { field: 'distance', headerName: 'Distance', resizable: false, flex: 1, valueFormatter: numFormatter },
  // { field: 'surveyDate', headerName: 'Survey Date', resizable: false, flex: 1 },
  { field: 'pointCount', headerName: 'Point Count', resizable: false, flex: 1 },
  { field: 'straightLength', headerName: 'Straight Length', resizable: false, flex: 1, valueFormatter: numFormatter },
  { field: 'profileArea', headerName: 'Profile Area', resizable: false, flex: 1, valueFormatter: numFormatter },
  { field: 'minElevation', headerName: 'Min Elevation', resizable: false, flex: 1, valueFormatter: numFormatter },
  {
    field: 'maxElevation',
    headerName: 'Max Elevation',
    resizable: false,
    flex: 1,
    valueFormatter: numFormatter,
  },
]
