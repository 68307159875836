import log from 'loglevel'

// This is a test to actually fail to load the app if the environment variables are not set.
const mandatoryEnvVars = [
  'REACT_APP_API_URL',
  'REACT_APP_AUTH0_DOMAIN',
  'REACT_APP_AUTH0_CLIENT_ID',
  'REACT_APP_AUTH0_AUDIENCE',
]
const missingVars = mandatoryEnvVars.filter((envVar) => !process.env[envVar])
if (missingVars.length) {
  throw new Error(`Missing mandatory environment variables: ${missingVars.join(', ')}`)
}

const stringValue = (envValue: string | undefined) => String(envValue || '')
const booleanValue = (envValue: string | undefined) => {
  if (!envValue) return false
  switch (envValue.trim().toLowerCase()) {
    case '1':
    case 'true':
      return true
    case '0':
    case 'false':
      return false
    default:
      log.debug('A config parameter has an unknown value.')
      return false
  }
}

export const apiUrl = stringValue(process.env.REACT_APP_API_URL) // full redirect URL for GraphQL API endpoint
export const basePath = stringValue(process.env.PUBLIC_URL).replace(/^\/?/, '').replace(/\/?$/, '') // ensure no leading or trailing slash
export const redirectUrl = new URL(`/${basePath ? `${basePath}/` : ''}`, window.location.origin).toString() // full redirect URL for Cognito
export const showDevTools = booleanValue(process.env.REACT_APP_SHOW_DEV_TOOLS)
export const showWelcome = booleanValue(process.env.REACT_APP_SHOW_WELCOME)

export const logLevel = stringValue(process.env.REACT_APP_LOG_LEVEL)

const requestedLogLevel = logLevel
switch (requestedLogLevel) {
  case 'trace':
  case 'debug':
  case 'info':
  case 'warn':
  case 'error':
  case 'silent':
    log.setLevel(requestedLogLevel)
    break
  default:
    log.setLevel(process.env.NODE_ENV === 'production' ? 'warn' : 'trace')
}

export const auth0config = {
  domain: stringValue(process.env.REACT_APP_AUTH0_DOMAIN),
  clientId: stringValue(process.env.REACT_APP_AUTH0_CLIENT_ID),
  audience: stringValue(process.env.REACT_APP_AUTH0_AUDIENCE),
}
