import { createTheme } from '@mui/material'

export const logoColor = '#EE3B34'
export const theme = createTheme({
  palette: {
    primary: {
      main: '#1C90ED',
    },
    secondary: {
      main: '#E8ED4C',
    },
  },
  typography: {
    h2: {
      // fontSize: '3rem',
      // fontWeight: 400,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        // 'body *::-webkit-scrollbar': {
        //   display: 'none', // Safari and Chrome scrollbars turn off
        // },
        // 'body *': {
        //   msOverflowStyle: 'none', // IE 10+ scrollbars turn off
        //   scrollbarWidth: 'none', // Firefox scrollbars turn off
        // },
        html: {
          height: '100%',
        },
        body: {
          height: '100%',
        },
        // This is our app container
        'body>#root': {
          height: '100%',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },
    },
    MuiInput: {
      defaultProps: {
        autoComplete: 'off', // this is actually browser auto-fill
      },
    },
    MuiTextField: {
      defaultProps: {
        autoComplete: 'off', // this is actually browser auto-fill
        size: 'small',
      },
    },
    MuiSelect: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiCard: {
      defaultProps: {
        variant: 'outlined',
      },
    },
  },
})
