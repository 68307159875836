import React from 'react'
import { Tab, Tabs } from '@mui/material'
import { Source, LineAxis, TrendingDown, ChangeHistory, ViewInAr } from '@mui/icons-material'

export interface ChartButtonTabsProps {
  projectId: string
  onNavigate: (uri: string) => void
}

export const ChartButtonTabs: React.FC<ChartButtonTabsProps> = ({ projectId, onNavigate }) => {
  return (
    <Tabs value={0} sx={{ mb: 3 }} allowScrollButtonsMobile>
      <Tab label="Project Details" value={0} icon={<Source />} iconPosition="top" />
      <Tab
        label="Cross Section Plot"
        icon={<LineAxis />}
        iconPosition="top"
        onClick={() => onNavigate(`/projects/${projectId}/cross-section`)}
      />
      <Tab
        label="Area Change"
        icon={<ChangeHistory />}
        iconPosition="top"
        onClick={() => onNavigate(`/projects/${projectId}/area-change`)}
      />
      <Tab
        label="Long Profile"
        icon={<TrendingDown />}
        iconPosition="top"
        onClick={() => onNavigate(`/projects/${projectId}/long-profile`)}
      />
      <Tab
        label="Long Change"
        icon={<ViewInAr />}
        iconPosition="top"
        onClick={() => onNavigate(`/projects/${projectId}/long-change`)}
      />
    </Tabs>
  )
}
