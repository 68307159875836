import { GetLongProfileDataQuery } from '../../../schema/base.types'
import { useGetLongProfileDataQuery } from '../../../schema/operations'
import { useNotifications } from '../../AppNotifications'

export interface LongProfileQueryProps {
  projectId: string
  longProfileId: string
  onData: (data: NonNullable<GetLongProfileDataQuery['getLongProfile']>) => void
}

export const LongProfileDataQuery: React.FC<LongProfileQueryProps> = ({ projectId, longProfileId, onData }) => {
  const { show } = useNotifications()

  const longProfileDataQuery = useGetLongProfileDataQuery({
    variables: { projectId, longProfileId },
    onCompleted: (data) => {
      if (data.getLongProfile) onData(data.getLongProfile)
      if (data.getLongProfile?.points.nextToken) {
        longProfileDataQuery.fetchMore({
          variables: {
            nextToken: data.getLongProfile.points.nextToken,
          },
        })
      }
    },
    onError: (error) => {
      console.log('onError GetLongProfileData', error)
      show(`Error retrieving project long profile data: ${error.message}`, { variant: 'error' })
    },
  })

  return null
}
