import React, { RefObject, useEffect, useRef } from 'react'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, useTheme } from '@mui/material'
import { uniq } from 'lodash'
import { ProjectInput } from '../../schema/base.types'
import { HelpButton } from '../HelpButton'
import { ProjectProperties } from './ProjectProperties'
import { someValues, useFields } from '../../lib/useFields'

export interface CreateProjectDialogProps {
  open: boolean
  onCancel: () => void
  onSubmit: (dbItem: ProjectInput) => void
  dialogTitle: string
}

const defaultProject: ProjectInput = {
  name: '',
  isPrivate: true,
  units: {
    surveyDistanceUnits: 'ft',
    riverDistanceUnits: 'mi',
    areaUnits: 'ft2',
    volumeUnits: 'ft3',
  },
  website: '',
  description: '',
  meta: [],
  startDistance: 0,
}

export const CreateProjectDialog: React.FC<CreateProjectDialogProps> = ({ open, onCancel, onSubmit, dialogTitle }) => {
  const theme = useTheme()

  const [fields, setFields, projectInput] = useFields(
    {
      project: defaultProject,
      projectValid: false,
      referenceStationFields: '',
    },
    ({ project, referenceStationFields }): ProjectInput => {
      const referenceStationFieldsArray = referenceStationFields
        .split(',')
        .map((v) => v.trim())
        .filter((v) => v)

      return {
        ...project,
        referenceStationFields: referenceStationFieldsArray.length ? referenceStationFieldsArray : null,
      }
    }
  )

  const errors = someValues(() => {
    const { referenceStationFields } = projectInput
    return {
      project: !fields.projectValid,
      referenceStationFields:
        !!referenceStationFields &&
        (referenceStationFields.some((v) => !v.match(/^[a-zA-Z0-9_]{1,10}$/)) ||
          uniq(referenceStationFields).length !== referenceStationFields.length),
    }
  })

  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle
        sx={{
          backgroundColor: theme.palette.secondary.main,
          color: theme.palette.secondary.contrastText,
          mb: 2,
        }}
        color={theme.palette.primary.main}
      >
        {dialogTitle}
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" sx={{ fontSize: '0.8rem' }} paragraph>
          Each project is represented by a centerline and a set of cross sections. These must be uploaded as separate
          zip files, each containing a single ShapeFile. These ShapeFiles must possess a projection file (*.prj) and at
          least one polyline feature. Only cross sections that intersect the centerline will be imported. Specify the
          units below for displaying the data. These can be different than the units of the centerline and cross
          sections.
        </Typography>
        <ProjectProperties onValidationChange={setFields.$.projectValid} onChange={setFields.$.project} />
      </DialogContent>
      <DialogActions>
        <HelpButton toolTip="Learn more about projects" pageName="projects" />
        <Button onClick={onCancel}>Cancel</Button>
        <Box sx={{ flexGrow: 1 }} />
        <Button
          onClick={() => {
            onSubmit(projectInput)
          }}
          variant="contained"
          disabled={!!errors}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}
