import * as React from 'react'
import { DataLibrary } from './DataLibrary'
import {
  useCreatePointCloudMutation,
  useCreateProjectMutation,
  useDeletePointCloudMutation,
  useDeleteProjectMutation,
  useGetUserProfileLibraryQuery,
  useUpdatePointCloudMutation,
  useUpdateProjectMutation,
  useUploadPointCloudCompleteMutation,
  useUploadPointCloudMutation,
} from '../../schema/operations'
import {
  CreatePointCloudInput,
  FileStateEnum,
  PointCloud,
  Project,
  ProjectInput,
  ProjectUpdate,
  UploadTarget,
} from '../../schema/base.types'
import { useNavigate } from 'react-router-dom'
import { GetProjectDetails, GetUserProfileLibrary } from '@xsonline/common'
import { GenericDBItem } from '../../types'
import { useNotifications } from '../AppNotifications'

const NOPOLLSTATES: FileStateEnum[] = [FileStateEnum.Complete, FileStateEnum.Error]

export const DataLibraryContainer: React.FC = () => {
  const navigate = useNavigate()
  const { show } = useNotifications()
  const [needsPolling, setNeedsPolling] = React.useState(false)

  const userDataLibraryQry = useGetUserProfileLibraryQuery({
    onCompleted: (data) => {
      // Set polling if we need to
      const pointClouds: PointCloud[] = data?.getUserProfile?.pointClouds as PointCloud[]
      const incompletePCs = pointClouds.filter((x) => !NOPOLLSTATES.includes(x.state))
      setNeedsPolling(incompletePCs.length > 0)
    },
    pollInterval: needsPolling ? 5000 : undefined,
    onError: (error) => {
      show(`Error loading data library: ${error.message}`, { variant: 'error' })
    },
  })

  const [createProjectMutation] = useCreateProjectMutation({
    refetchQueries: [GetUserProfileLibrary, GetProjectDetails],
    onError: (error) => {
      show(`Error creating project: ${error.message}`, { variant: 'error' })
    },
    onCompleted: (data) => {
      show(`Project created: ${data.createProject?.name}`, { variant: 'success' })
    },
  })

  const [deleteProjectMutation] = useDeleteProjectMutation({
    refetchQueries: [GetUserProfileLibrary],
    onError: (error) => {
      show(`Error deleting project: ${error.message}`, { variant: 'error' })
    },
    onCompleted: (data) => {
      show('Project deleted', { variant: 'success' })
    },
  })

  const [updateProjectMutation] = useUpdateProjectMutation({
    refetchQueries: [GetUserProfileLibrary],
    onError: (error) => {
      show(`Error editing project: ${error.message}`, { variant: 'error' })
    },
    onCompleted: (data) => {
      show('Project updated', { variant: 'success' })
    },
  })

  const [createPointCloudMutation] = useCreatePointCloudMutation({
    refetchQueries: [GetUserProfileLibrary],
    onError: (error) => {
      show(`Error creating point cloud: ${error.message}`, { variant: 'error' })
    },
    onCompleted: (data) => {
      show(`Point cloud created: ${data.createPointCloud?.name}`, { variant: 'success' })
    },
  })

  const [updatePCMutation] = useUpdatePointCloudMutation({
    refetchQueries: [GetUserProfileLibrary],
    onError: (error) => {
      show(`Error editing point cloud: ${error.message}`, { variant: 'error' })
    },
    onCompleted: (data) => {
      show('Point cloud updated', { variant: 'success' })
    },
  })

  const [deletePCMutation, { data, loading, error }] = useDeletePointCloudMutation({
    refetchQueries: [GetUserProfileLibrary],
    onError: (error) => {
      show(`Error deleting point cloud: ${error.message}`, { variant: 'error' })
    },
    onCompleted: (data) => {
      show('Point cloud deleted', { variant: 'success' })
    },
  })

  const onCreateProject = (dbItem: ProjectInput) => {
    createProjectMutation({
      variables: {
        project: dbItem,
      },
    })
  }

  const onUpdateProject = (projectId: string, project: ProjectUpdate) => {
    updateProjectMutation({
      variables: {
        projectId,
        project,
      },
    })
  }

  const onDeleteProject = (projectId: string) => {
    deleteProjectMutation({
      variables: {
        projectId,
      },
    })
  }

  const onCreatePointCloud = (pointCloud: CreatePointCloudInput) => {
    createPointCloudMutation({
      variables: {
        pointCloud,
      },
    })
  }

  const onEditPointCloud = (dbItem: GenericDBItem) => {
    updatePCMutation({
      variables: {
        pointCloudId: dbItem.id,
        genericInput: {
          name: dbItem.name,
          description: dbItem.description,
        },
      },
    })
  }

  const onDeletePointCloud = (pointCloudId: string) => {
    deletePCMutation({
      variables: {
        pointCloudId,
      },
    })
  }

  const [_getUploadPointCloudUrl] = useUploadPointCloudMutation()

  const onUploadPointCloud = (pointCloudId: string): Promise<UploadTarget> => {
    return _getUploadPointCloudUrl({
      variables: {
        pointCloudId,
      },
    }).then((res) => res?.data?.uploadPointCloud as UploadTarget)
  }

  const [_useUploadPointCloudCompleteMutation] = useUploadPointCloudCompleteMutation()

  const onUploadPointCloudComplete = (pointCloudId: string) =>
    _useUploadPointCloudCompleteMutation({
      variables: {
        pointCloudId,
      },
      refetchQueries: [GetUserProfileLibrary],
    })

  return (
    <DataLibrary
      navigate={navigate}
      projects={(userDataLibraryQry?.data?.getUserProfile?.projects as Project[]) || []}
      pointClouds={(userDataLibraryQry?.data?.getUserProfile?.pointClouds as PointCloud[]) || []}
      onCreateProject={onCreateProject}
      onEditProject={onUpdateProject}
      onDeleteProject={onDeleteProject}
      onCreatePointCloud={onCreatePointCloud}
      onEditPointCloud={onEditPointCloud}
      onDeletePointCloud={onDeletePointCloud}
      getPointCloudUrl={onUploadPointCloud}
      onUploadPointCloudComplete={onUploadPointCloudComplete}
    />
  )
}
