import { SurveyPoint } from './surveyPoint'
import { DepthBinValue } from './depthBinValue'
import { interpolateStation, interpolateElevation, getRawAreaInsideCurve } from './mathHelpers'

type ObjectValues<T> = T[keyof T]

export const StationFilteringEnum = {
  OriginalExtents: 'OriginalExtents',
  CommonExtents: 'CommonExtents',
  ReferenceStations: 'ReferenceStations',
} as const
export type StationFilteringEnum = ObjectValues<typeof StationFilteringEnum>

export const VerticalQualifyingStatusEnum = {
  Above: 'Above',
  Inside: 'Inside',
  Below: 'Below',
} as const
export type VerticalQualifyingStatusEnum = ObjectValues<typeof VerticalQualifyingStatusEnum>

export const HorizQualifyingStatusEnum = {
  Left: 'Left',
  Inside: 'Inside',
  Right: 'Right',
} as const
export type HorizQualifyingStatusEnum = ObjectValues<typeof HorizQualifyingStatusEnum>

export class Transect {
  public readonly surveyPoints: SurveyPoint[]

  constructor(surveyPoints: SurveyPoint[]) {
    if (!surveyPoints) {
      this.surveyPoints = new Array<SurveyPoint>()
    }
    this.surveyPoints = surveyPoints
  }

  hasUncertainty(): boolean {
    return this.surveyPoints.some((sp) => sp.uncertainty != null)
  }

  horizontalLength(): number {
    if (this.surveyPoints.length < 1) {
      return 0
    } else {
      return this.surveyPoints[this.surveyPoints.length - 1].station - this.surveyPoints[0].station
    }
  }

  meanPointSpacing(): number {
    if (this.horizontalLength() === 0 || this.surveyPoints.length === 0) {
      return 0
    } else {
      return this.horizontalLength() / this.surveyPoints.length
    }
  }

  maxElevation(): number {
    if (this.surveyPoints.length < 1) {
      return 0
    } else {
      return Math.max(...this.surveyPoints.map((x) => x.elevation))
    }
  }

  minElevation(): number {
    if (this.surveyPoints.length < 1) {
      return 0
    } else {
      return Math.min(...this.surveyPoints.map((x) => x.elevation))
    }
  }

  maxStation(): number {
    if (this.surveyPoints.length > 0) {
      return this.surveyPoints.reduce(
        (acc, curr) => (curr.station > acc ? curr.station : acc),
        this.surveyPoints[0].station
      )
    } else {
      return 0
    }
  }

  minStation(): number {
    if (this.surveyPoints.length > 0) {
      return this.surveyPoints.reduce(
        (acc, curr) => (curr.station < acc ? curr.station : acc),
        this.surveyPoints[0].station
      )
    } else {
      return 0
    }
  }

  meanElevation(): number {
    if (this.surveyPoints.length < 1) {
      return 0
    }
    // Unit note: Area is always calculated as m ==> m2  (not using the display units)
    // This means we can use it safely here
    const xsArea = this.area()
    if (xsArea == 0) {
      // Horizontal or vertical line
      return this.surveyPoints.reduce((sum, point) => sum + point.elevation, 0) / this.surveyPoints.length
    } else {
      const meanElev = this.maxElevation() - xsArea / this.horizontalLength()
      return meanElev
    }
  }

  minOffset(): number {
    if (this.surveyPoints.length > 0) {
      const soffsets = this.surveyOffsets()
      if (soffsets.length > 0) {
        return Math.min(...soffsets)
      } else {
        return 0
      }
    } else {
      return 0
    }
  }

  maxOffset(): number {
    if (this.surveyPoints.length > 0) {
      const soffsets = this.surveyOffsets()
      if (soffsets.length > 0) return Math.max(...soffsets)
      else return 0
    } else {
      return 0
    }
  }

  avgOffset(): number {
    if (this.surveyPoints.length > 0) {
      const soffsets = this.surveyOffsets()
      if (soffsets.length > 0) {
        return soffsets.reduce((a, b) => a + b) / soffsets.length
      } else {
        return 0
      }
    } else {
      return 0
    }
  }

  surveyOffsets(): number[] {
    const theList: Array<number> = []
    this.surveyPoints.forEach((sp) => {
      if (sp.offset != null) {
        theList.push(sp.offset as number)
      }
    })

    return theList
  }

  area(): number {
    const points: SurveyPoint[] = this.capAtElevation(this.maxElevation()).surveyPoints
    return getRawAreaInsideCurve(points)
  }

  /**
   * Cap the cross section at the specified elevation.
   * @param upperElevation upper elevation
   * @returns a new Transect object with the points capped no higher than the specified elevation
   * @remarks upperElevation is typically a CRP elevation
   */
  capAtElevation(upperElevation: number): Transect {
    const result: SurveyPoint[] = []

    if (this.surveyPoints.length < 2) {
      return new Transect(result)
    }

    let above = this.surveyPoints[0].elevation > upperElevation

    for (let i = 0; i <= this.surveyPoints.length - 1; i++) {
      if (above) {
        if (this.surveyPoints[i].elevation < upperElevation) {
          // Just cross below elevation. Interpolate.
          result.push(
            new SurveyPoint(
              interpolateStation(this.surveyPoints[i], this.surveyPoints[i - 1], upperElevation),
              upperElevation
            )
          )

          // Now add the current point that is below the elevation
          result.push(this.surveyPoints[i])
          above = false
        } else {
          // still above
        }
      } else {
        if (this.surveyPoints[i].elevation > upperElevation) {
          // just moved above. interpolate
          result.push(
            new SurveyPoint(
              interpolateStation(this.surveyPoints[i], this.surveyPoints[i - 1], upperElevation),
              upperElevation
            )
          )
          above = true
        } else {
          // still below upper elevation.
          result.push(this.surveyPoints[i])
        }
      }
    }

    if (result.length > 0) {
      // ensure the cross section starts and ends on the upper elevation
      if (result[0].elevation != upperElevation) {
        result.unshift(new SurveyPoint(result[0].station, upperElevation))
      }

      if (result[result.length - 1].elevation != upperElevation) {
        result.push(new SurveyPoint(result[result.length - 1].station, upperElevation))
      }
    }

    return new Transect(result)
  }

  /**
   * Truncate a cross section between two stations
   * @param leftStation Left station distance
   * @param rightStation Right station distance
   * @returns A new transect object with the points between the two stations
   */
  trimBetweenStations(leftStation: number, rightStation: number): Transect {
    const result: SurveyPoint[] = []
    // We're always outside to start
    let outside = true

    // If the right station is less than the left station then the cross section
    // should be emptied.
    if (leftStation < rightStation) {
      for (let i = 0; i <= this.surveyPoints.length - 1; i++) {
        const lastPointOutside = outside
        outside = this.surveyPoints[i].station < leftStation || this.surveyPoints[i].station > rightStation

        if (outside) {
          if (!lastPointOutside) {
            if (rightStation > this.surveyPoints[i].station && i == this.surveyPoints.length - 1) {
              //Throw New Exception("TrimBetweenTwoStations should not need to add SurveyPoints on the right")
            } else if (rightStation < this.surveyPoints[i].station && i <= this.surveyPoints.length - 1) {
              // This is the only case on the right where we need to interpolate a point
              result.push(
                new SurveyPoint(
                  rightStation,
                  interpolateElevation(this.surveyPoints[i], this.surveyPoints[i - 1], rightStation)
                )
              )
            }
          }
        } else {
          if (lastPointOutside) {
            if (leftStation < this.surveyPoints[i].station && i == 0) {
              //Throw New Exception("TrimBetweenTwoStations should not need to add SurveyPoints at the on the left")
            } else if (leftStation < this.surveyPoints[i].station && i > 0) {
              // This is the only case on the left where we need to interpolate a point
              result.push(
                new SurveyPoint(
                  leftStation,
                  interpolateElevation(this.surveyPoints[i], this.surveyPoints[i - 1], leftStation)
                )
              )
            }
          }
          // Inside This is just a normal point in the middle
          result.push(new SurveyPoint(this.surveyPoints[i].station, this.surveyPoints[i].elevation))
        }
      }
    }

    return new Transect(result)
  }

  /**
   * Remove points between the two stations and replace any points within the two stations with the upper elevation
   * @param leftStation Left station distance
   * @param rightStation Right station distance
   * @param upperElevation Upper elevation
   */
  public trimOutsideReferenceStations(leftStation: number, rightStation: number, upperElevation: number): Transect {
    const result: SurveyPoint[] = []
    let outside = this.surveyPoints[0].station <= leftStation
    let afterSaddle = false

    for (let i = 0; i < this.surveyPoints.length; i++) {
      if (outside) {
        if (this.surveyPoints[i].station >= leftStation && !afterSaddle) {
          outside = false
          if (this.surveyPoints[i].station > leftStation) {
            // If we're a little beyond the point at(i) then we need to interpolate where we're deviating
            result.push(
              new SurveyPoint(
                leftStation,
                interpolateElevation(this.surveyPoints[i], this.surveyPoints[i - 1], leftStation)
              )
            )
          }

          // Also add point at upper elevation so that cross section spans across the top
          result.push(new SurveyPoint(leftStation, upperElevation))
        } else {
          // Still outside the right end of the RCL, simply add the point
          result.push(this.surveyPoints[i])
        }
      }

      if (!outside && this.surveyPoints[i].station >= rightStation) {
        outside = true
        afterSaddle = true

        // Add point at upper elevation so that cross section spans across the top
        result.push(new SurveyPoint(rightStation, upperElevation))

        if (i <= this.surveyPoints.length - 1 && i > 0) {
          // If we're a little short of the point at(i) then we'll need to interpolate where we're deviating
          if (this.surveyPoints[i].station > rightStation) {
            // Now outside RCL again, but this point is outside right RCL. Interpolate.
            result.push(
              new SurveyPoint(
                rightStation,
                interpolateElevation(this.surveyPoints[i], this.surveyPoints[i - 1], rightStation)
              )
            )
          }

          // Finally add the current point that is outside
          result.push(this.surveyPoints[i])
        }
      }
    }

    return new Transect(result)
  }

  /**
   * Returns the Center of mass for a transect
   * @returns A new survey point at the station and elevation of the transect's center of mass
   * @remarks
   * https://en.wikipedia.org/wiki/Centroid
   * http://www.seas.upenn.edu/~sys502/extra_materials/Polygon%20Area%20and%20Centroid.pdf
   */
  centerOfMass(): SurveyPoint | null {
    let sumx = 0
    let sumy = 0
    let area = 0

    // Center of mass requires the first point to be the same as the end point
    // Build a list of the station indices. Then add the first point to the end.
    const indices = Array.from(Array(this.surveyPoints.length).keys())
    indices.push(0)

    for (let i = 0; i <= indices.length - 2; i++) {
      const thisPoint = this.surveyPoints[indices[i]]
      const nextPoint = this.surveyPoints[indices[i + 1]]

      const localarea = thisPoint.station * nextPoint.elevation - nextPoint.station * thisPoint.elevation
      area += localarea
      sumx += (thisPoint.station + nextPoint.station) * localarea
      sumy += (thisPoint.elevation + nextPoint.elevation) * localarea
    }

    area = area / 2

    if (area == 0) return null

    const station = (1 / (6 * area)) * sumx
    const elevation = (1 / (6 * area)) * sumy

    return new SurveyPoint(station, elevation)
  }

  bedLength(): number {
    let result = 0
    for (let i = 1; i < this.surveyPoints.length; i++) {
      const lastPoint: SurveyPoint = this.surveyPoints[i - 1]
      result += this.surveyPoints[i].euclideanDistance(lastPoint.station, lastPoint.elevation)
    }

    return result
  }

  /**
   * Returns the length of the bed that is at least fDepth below the maximum elevation.
   * @param fDepth The depth below the maximum elevation to calculate the length of the bed.
   * @returns This method calculates the true distance between all points that are shallower
   * than the specified depth. See the separate method for calculating the HORIZONTAL distance
   * between points that are shallower than the specified depth
   * @remarks Calculates the DIRECT aka TRUE bed length that is shallower than the specified depth
   */
  public bedLengthLessThanDepth(fDepth: number): number {
    if (fDepth <= 0 || this.maxElevation() - this.minElevation() <= fDepth) {
      return this.bedLength()
    }

    let fBedLength = 0
    let fSegmentLength
    const fMinElevation = this.maxElevation() - fDepth

    let eCurrentStatus: VerticalQualifyingStatusEnum
    let elastStatus: VerticalQualifyingStatusEnum = VerticalQualifyingStatusEnum.Above

    for (let i = 0; i < this.surveyPoints.length; i++) {
      fSegmentLength = 0
      if (this.surveyPoints[i].elevation >= this.maxElevation()) {
        eCurrentStatus = VerticalQualifyingStatusEnum.Above
      } else if (this.surveyPoints[i].elevation < fMinElevation) {
        eCurrentStatus = VerticalQualifyingStatusEnum.Below
      } else {
        eCurrentStatus = VerticalQualifyingStatusEnum.Inside
      }

      if (i > 0) {
        switch (eCurrentStatus) {
          case VerticalQualifyingStatusEnum.Above:
            fSegmentLength = this.processBedLengthAbove(elastStatus, i, fMinElevation)
            break

          case VerticalQualifyingStatusEnum.Inside:
            fSegmentLength = this.processBedLengthInside(elastStatus, i, fMinElevation)
            break

          case VerticalQualifyingStatusEnum.Below:
            fSegmentLength = this.processBedLengthBelow(elastStatus, i, fMinElevation)
            break
        }
        fBedLength += fSegmentLength
      }
      elastStatus = eCurrentStatus
    }

    return fBedLength
  }

  /**
   * Sub method used inside BedLengthLessThanDepth
   */
  private processBedLengthAbove(eLastStatus: VerticalQualifyingStatusEnum, i: number, fMinElevation: number): number {
    switch (eLastStatus) {
      case VerticalQualifyingStatusEnum.Above:
        // Two points above the depth range. Do nothing.
        return 0

      case VerticalQualifyingStatusEnum.Inside: {
        // Went from inside to above depth range. Interpolate where crossed up out of the range
        const ai = interpolateStation(this.surveyPoints[i - 1], this.surveyPoints[i], this.maxElevation())
        return this.surveyPoints[i - 1].euclideanDistance(ai, this.maxElevation())
      }

      case VerticalQualifyingStatusEnum.Below: {
        // Went directly from below to above. Interpolate two crossing points
        const abInterpStationMax = interpolateStation(
          this.surveyPoints[i - 1],
          this.surveyPoints[i],
          this.maxElevation()
        )
        const MaxSurveyPoint = new SurveyPoint(abInterpStationMax, this.maxElevation())
        const abInterpStationMin = interpolateStation(this.surveyPoints[i - 1], this.surveyPoints[i], fMinElevation)
        return MaxSurveyPoint.euclideanDistance(abInterpStationMin, fMinElevation)
      }
    }
  }

  /**
   * Sub method used inside BedLengthLessThanDepth
   */
  private processBedLengthInside(eLastStatus: VerticalQualifyingStatusEnum, i: number, fMinElevation: number): number {
    switch (eLastStatus) {
      case VerticalQualifyingStatusEnum.Above: {
        //Went from above down into the depth range. Interpolate where crossed down into the range
        const ia = interpolateStation(this.surveyPoints[i - 1], this.surveyPoints[i], this.maxElevation())
        return this.surveyPoints[i].euclideanDistance(ia, this.maxElevation())
      }

      case VerticalQualifyingStatusEnum.Inside: {
        // Two points inside the range. Simple euclidean distance
        return this.surveyPoints[i].euclideanDistance(
          this.surveyPoints[i - 1].station,
          this.surveyPoints[i - 1].elevation
        )
      }

      case VerticalQualifyingStatusEnum.Below: {
        //Went from below to inside the depth range. Interpolate where crossed up into the range
        const ib = interpolateStation(this.surveyPoints[i - 1], this.surveyPoints[i], fMinElevation)
        return this.surveyPoints[i].euclideanDistance(ib, fMinElevation)
      }
    }
  }

  /**
   * Sub method used inside BedLengthLessThanDepth
   */
  private processBedLengthBelow(eLastStatus: VerticalQualifyingStatusEnum, i: number, fMinElevation: number): number {
    switch (eLastStatus) {
      case VerticalQualifyingStatusEnum.Above: {
        // Went directly from above to below. Interpolate two crossing points
        const fInterpStationMin = interpolateStation(this.surveyPoints[i - 1], this.surveyPoints[i], fMinElevation)
        const MinSurveyPoint = new SurveyPoint(fInterpStationMin, fMinElevation)
        const fInterpStationMax = interpolateStation(
          this.surveyPoints[i - 1],
          this.surveyPoints[i],
          this.maxElevation()
        )
        return MinSurveyPoint.euclideanDistance(fInterpStationMax, this.maxElevation())
      }
      case VerticalQualifyingStatusEnum.Inside: {
        // Went from inside to below. Interpolate at min elevation.
        const fInterpStation = interpolateStation(this.surveyPoints[i - 1], this.surveyPoints[i], fMinElevation)
        return this.surveyPoints[i - 1].euclideanDistance(fInterpStation, fMinElevation)
      }
      case VerticalQualifyingStatusEnum.Below: {
        // Two points below range. do nothing.
        return 0
      }
    }
  }

  /**
   * Returns the HORIZONTAL aka PLANIMETRIC bed length that is shallower  than the specified depth
   * @param fDepth The depth to use for the calculation
   * @return This method returns the HORIZONTAL bed length. There is a different method that returns
   * the true aka direct bed length
   * @remarks This code should simply accumulate station distances. It should never
   * use euclidean distances
   */
  planimetricBedLengthLessThanDepth(fDepth: number): number {
    // If the depth is greater than the max depth of the cross section then
    // simply return the top width
    if (fDepth >= this.maxElevation() - this.minElevation()) {
      return this.maxStation() - this.minStation()
    }

    let totalLength = 0
    let horizontalLength: number
    const fMinElevation: number = this.maxElevation() - fDepth

    let eCurrentStatus: VerticalQualifyingStatusEnum
    let eLastStatus: VerticalQualifyingStatusEnum = VerticalQualifyingStatusEnum.Above

    for (let i = 0; i < this.surveyPoints.length; i++) {
      horizontalLength = 0

      if (this.surveyPoints[i].elevation >= this.maxElevation()) {
        eCurrentStatus = VerticalQualifyingStatusEnum.Above
      } else if (this.surveyPoints[i].elevation < fMinElevation) {
        eCurrentStatus = VerticalQualifyingStatusEnum.Below
      } else {
        eCurrentStatus = VerticalQualifyingStatusEnum.Inside
      }

      if (i > 0) {
        switch (eCurrentStatus) {
          case VerticalQualifyingStatusEnum.Above: {
            horizontalLength = this.processPlanimetricBedLengthAbove(eLastStatus, i, fMinElevation)
            break
          }

          case VerticalQualifyingStatusEnum.Inside: {
            horizontalLength = this.processPlanimetricBedLengthInside(eLastStatus, i, fMinElevation)
            break
          }

          case VerticalQualifyingStatusEnum.Below: {
            horizontalLength = this.processPlanimetricBedLengthBelow(eLastStatus, i, fMinElevation)
            break
          }
        }
        totalLength += Math.abs(horizontalLength)
      }
      eLastStatus = eCurrentStatus
    }
    return totalLength
  }

  /**
   * Sub method used inside planimetricBedLengthLessThanDepth
   */
  private processPlanimetricBedLengthAbove(
    eLastStatus: VerticalQualifyingStatusEnum,
    i: number,
    fMinElevation: number
  ): number {
    switch (eLastStatus) {
      case VerticalQualifyingStatusEnum.Above: {
        // Two points above the depth range. Do nothing.
        return 0
      }
      case VerticalQualifyingStatusEnum.Inside: {
        // Went from inside to above depth range. Interpolate where crossed up out of the range
        const ai = interpolateStation(this.surveyPoints[i - 1], this.surveyPoints[i], this.maxElevation())
        return ai - this.surveyPoints[i - 1].station
      }
      case VerticalQualifyingStatusEnum.Below: {
        // Went directly from below to above. Interpolate two crossing points
        const abInterpStationMax = interpolateStation(
          this.surveyPoints[i - 1],
          this.surveyPoints[i],
          this.maxElevation()
        )
        const MaxSurveyPoint = new SurveyPoint(abInterpStationMax, this.maxElevation())
        const abInterpStationMin = interpolateStation(this.surveyPoints[i - 1], this.surveyPoints[i], fMinElevation)
        return MaxSurveyPoint.station - abInterpStationMin
      }
    }
  }

  /**
   * Sub method used inside planimetricBedLengthLessThanDepth
   */
  private processPlanimetricBedLengthInside(
    lastStatus: VerticalQualifyingStatusEnum,
    i: number,
    minElevation: number
  ): number {
    switch (lastStatus) {
      case VerticalQualifyingStatusEnum.Above: {
        // Went from above down into the depth range. Interpolate where crossed down into the range
        const ia = interpolateStation(this.surveyPoints[i - 1], this.surveyPoints[i], this.maxElevation())
        return ia - this.surveyPoints[i].station
      }
      case VerticalQualifyingStatusEnum.Inside: {
        // Two points inside the range. Simple distance
        return this.surveyPoints[i].station - this.surveyPoints[i - 1].station
      }
      case VerticalQualifyingStatusEnum.Below: {
        // Went from below to inside the depth range. Interpolate where crossed up into the range
        const ib = interpolateStation(this.surveyPoints[i - 1], this.surveyPoints[i], minElevation)
        return this.surveyPoints[i].station - ib
      }
    }
  }

  /**
   * Sub method used inside planimetricBedLengthLessThanDepth
   */
  private processPlanimetricBedLengthBelow(
    lastStatus: VerticalQualifyingStatusEnum,
    i: number,
    minElevation: number
  ): number {
    switch (lastStatus) {
      case VerticalQualifyingStatusEnum.Above: {
        // Went directly from above to below. Interpolate two crossing points
        const fInterpStationMin = interpolateStation(this.surveyPoints[i - 1], this.surveyPoints[i], minElevation)
        const MinSurveyPoint = new SurveyPoint(fInterpStationMin, minElevation)
        const fInterpStationMax = interpolateStation(
          this.surveyPoints[i - 1],
          this.surveyPoints[i],
          this.maxElevation()
        )
        return MinSurveyPoint.station - fInterpStationMax
      }

      case VerticalQualifyingStatusEnum.Inside: {
        // Went from inside to below. Interpolate at min elevation.
        const fInterpStation = interpolateStation(this.surveyPoints[i - 1], this.surveyPoints[i], minElevation)
        return this.surveyPoints[i - 1].station - fInterpStation
      }

      case VerticalQualifyingStatusEnum.Below: {
        // Two points below range. Do nothing.
        return 0
      }
    }
  }

  public areaBetweenElevations(upperElevation: number, lowerElevation: number): number {
    if (upperElevation < lowerElevation) throw new Error('The upper elevation cannot be below the lower elevation.')

    if (this.surveyPoints.length < 1) return 0

    // This is the actual list of points that will be used to measure the area.
    // If the XS starts or ends below the fUpperElevation elevation then additional points
    // will be interpolated and inserted into the list at either end.
    const lSurveyPoints: SurveyPoint[] = []

    // If the cross section starts under the fUpperElevation then use the left bank
    // point as the start point. Otherwise use the actual start of the XS
    // Note: always the fUpperElevation, the code below does the elevation filtering.
    if (this.surveyPoints[0].elevation < upperElevation) {
      const newStartPoint = new SurveyPoint(this.surveyPoints[0].station, upperElevation)
      lSurveyPoints.push(newStartPoint)
    }

    // Add all the actual survey points to the list.
    this.surveyPoints.forEach((x) => lSurveyPoints.push(x))

    // If the cross section ends under the fUpperElevation then use the right bank
    // point as the start point. Otherwise use the actual end of the XS
    // Note: always the fUpperElevation, the code below does the elevation filtering.
    if (this.surveyPoints[this.surveyPoints.length - 1].elevation < upperElevation) {
      const newEndPoint = new SurveyPoint(this.surveyPoints[this.surveyPoints.length - 1].station, upperElevation)
      lSurveyPoints.push(newEndPoint)
    }

    // '''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''
    // Determine whether the cross section starts (right hand side) above, below or inside the qualifying zone
    let lQPoints: SurveyPoint[] = []

    let eStatus: VerticalQualifyingStatusEnum
    if (lSurveyPoints[lSurveyPoints.length - 1].elevation >= upperElevation)
      eStatus = VerticalQualifyingStatusEnum.Above
    else if (lSurveyPoints[lSurveyPoints.length - 1].elevation <= lowerElevation) {
      eStatus = VerticalQualifyingStatusEnum.Below
      lQPoints.push(new SurveyPoint(lSurveyPoints[lSurveyPoints.length - 1].station, upperElevation)) // Add a top cap point
    } else {
      eStatus = VerticalQualifyingStatusEnum.Inside
      lQPoints.push(lSurveyPoints[lSurveyPoints.length - 1])
    }

    // '''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''
    // Move down the cross section and accumulate the area between the top and bottom elevations and the cross section.
    // Ignore any areas above the top elevation and also those below the bottom elevation depth.
    //
    let calcArea = false
    let fTotalPositiveArea = 0
    for (let i = lSurveyPoints.length - 2; i >= 0; i--) {
      let eNewStatus = eStatus
      const nLasti = i + 1

      // There are 9 possible combinations where two points can be above, below or inside the window:
      switch (eStatus) {
        case VerticalQualifyingStatusEnum.Above: {
          // the last point was above the qualifying zone

          if (lSurveyPoints[i].elevation > upperElevation) {
            // Do Nothing
          } else if (lSurveyPoints[i].elevation < lowerElevation) {
            // spans qualifying zone
            lQPoints = new Array<SurveyPoint>()
            lQPoints.push(SurveyPoint.interpolateAtElevation(lSurveyPoints[nLasti], lSurveyPoints[i], upperElevation))
            lQPoints.push(SurveyPoint.interpolateAtElevation(lSurveyPoints[nLasti], lSurveyPoints[i], lowerElevation)) // lower elevation exit point
            lQPoints.push(new SurveyPoint(lSurveyPoints[i].station, lowerElevation)) // point above exit at lower elevation
            eNewStatus = VerticalQualifyingStatusEnum.Below
          } else {
            // dropped into zone
            lQPoints = new Array<SurveyPoint>()
            lQPoints.push(SurveyPoint.interpolateAtElevation(lSurveyPoints[nLasti], lSurveyPoints[i], upperElevation))
            lQPoints.push(lSurveyPoints[i])
            eNewStatus = VerticalQualifyingStatusEnum.Inside
          }

          break
        }

        case VerticalQualifyingStatusEnum.Below: {
          // the last point was below the qualifying zone

          if (lSurveyPoints[i].elevation > upperElevation) {
            // spans qualifying zone
            lQPoints.push(new SurveyPoint(lSurveyPoints[i].station, lowerElevation)) // entry point
            lQPoints.push(SurveyPoint.interpolateAtElevation(lSurveyPoints[nLasti], lSurveyPoints[i], lowerElevation)) // entry point
            lQPoints.push(SurveyPoint.interpolateAtElevation(lSurveyPoints[nLasti], lSurveyPoints[i], upperElevation)) // exit point at upper elevation
            eNewStatus = VerticalQualifyingStatusEnum.Above
            calcArea = true // Any time we cross the top boundary we calculate an area
          } else if (lSurveyPoints[i].elevation < lowerElevation) {
            lQPoints.push(new SurveyPoint(lSurveyPoints[nLasti].station, lowerElevation)) // upper elevation above entry point
            lQPoints.push(new SurveyPoint(lSurveyPoints[i].station, lowerElevation)) // upper elevation above entry point
          } else {
            // risen into the zone
            lQPoints.push(new SurveyPoint(lSurveyPoints[nLasti].station, lowerElevation)) // upper elevation above entry point
            lQPoints.push(SurveyPoint.interpolateAtElevation(lSurveyPoints[nLasti], lSurveyPoints[i], lowerElevation)) // entry point
            lQPoints.push(lSurveyPoints[i])
            eNewStatus = VerticalQualifyingStatusEnum.Inside
          }

          break
        }

        case VerticalQualifyingStatusEnum.Inside: {
          // the last point was inside the qualifying zone

          if (!lQPoints) lQPoints = new Array<SurveyPoint>()

          if (lSurveyPoints[i].elevation > upperElevation) {
            // risen out of the qualifying zone
            lQPoints.push(lSurveyPoints[nLasti])
            lQPoints.push(SurveyPoint.interpolateAtElevation(lSurveyPoints[nLasti], lSurveyPoints[i], upperElevation))
            eNewStatus = VerticalQualifyingStatusEnum.Above
            calcArea = true // Any time we cross the top boundary we calculate an area
          } else if (lSurveyPoints[i].elevation < lowerElevation) {
            // dropped out of the qualifying zone
            lQPoints.push(lSurveyPoints[nLasti])
            lQPoints.push(SurveyPoint.interpolateAtElevation(lSurveyPoints[nLasti], lSurveyPoints[i], lowerElevation))
            lQPoints.push(new SurveyPoint(lSurveyPoints[i].station, lowerElevation))
            eNewStatus = VerticalQualifyingStatusEnum.Below
          } else {
            // still inside the qualifying zone
            lQPoints.push(lSurveyPoints[nLasti])
            lQPoints.push(lSurveyPoints[i])
          }

          break
        }
      }

      // Last case.
      if (i == 0) {
        // If we're still below the threshold at the end we need to add a capping point
        if (lSurveyPoints[i].elevation < upperElevation)
          lQPoints.push(new SurveyPoint(lSurveyPoints[i].station, upperElevation))
        calcArea = true // Any time we cross the top boundary we calculate an area
      }

      if (calcArea == true && lQPoints instanceof Array && lQPoints.length > 2) {
        // Debug.WriteLine("Calculating area for elevation: " & fUpperElevation.ToString("0.00") & vbTab & fLowerElevation.ToString("0.00") & ", Depth:" & vbTab & (fUpperElevation - fLowerElevation).ToString("0.00"))

        const fArea = getRawAreaInsideCurve(lQPoints)

        if (isNaN(fArea)) throw new Error('Invalid area calculated.')

        if (fArea < 0) {
          //m_bNegativeAreaProblem = true;
          throw new Error('Negative area')
        }

        // Debug.WriteLine("Segment: Area Between elevations: " & fArea.ToString("0.00"))
        // Debug.WriteLine("Segment: Accumulated Area:" & fTotalPositiveArea.ToString("0.00"))
        calcArea = false
        lQPoints = []
        fTotalPositiveArea += fArea // negative areas indicate a counter clockwise qualifying shape
      }

      eStatus = eNewStatus
    }

    return fTotalPositiveArea
  }

  /**
   * Build a list of cross section areas at different depths
   * @param referenceElevation The cross section will first get capped at this elevation
   * @param depthBinSize Depth bin increments that areas will be reported by
   * @returns List of cross section areas organized by bin depth.
   * @remarks This routine first caps the cross section at the argument reference elevation.
   * It then moves down through the cross section in increments of the depth bin size and reports
   * the area in each bin.
   */
  public areaDepthProfile(referenceElevation: number, depthBinSize: number): DepthBinValue[] {
    if (depthBinSize <= 0) {
      throw new Error('The depth bin size must be greater than zero.')
    }

    const result: DepthBinValue[] = []

    // Can't do anything if the entire cross section is above the reference elevation
    if (referenceElevation < this.maxElevation()) {
      return result
    }

    // Cap the cross section at the calculation elevation
    const capped = this.capAtElevation(referenceElevation)
    // Initialize the upper elevation of the first bin at the reference elevation
    let upperElev = referenceElevation

    do {
      const lowerElev = upperElev - depthBinSize
      const avgDepth = referenceElevation - (upperElev + lowerElev) / 2
      const area = capped.areaBetweenElevations(upperElev, lowerElev)
      const cumArea = capped.areaBetweenElevations(referenceElevation, lowerElev)

      result.push(new DepthBinValue(upperElev, lowerElev, avgDepth, area, cumArea))

      upperElev -= depthBinSize
    } while (upperElev > this.minElevation())

    return result
  }

  /**
   * Builds a list of TRUE aka DIAGONAL bed lengths within depth bins
   * @param referenceElevation The cross section will first get capped at this elevation
   * @param depthBinSize Depth bin increments that areas will be reported by
   * @returns List of cross section bed lengths organized by bin depth.
   * @remarks This routine first caps the cross section at the argument reference elevation.
   * It then moves down through the cross section in increments of the depth bin size and reports
   * the bed length within in each bin.
   */
  bedLengthDepthProfile(referenceElevation: number, depthBinSize: number): DepthBinValue[] {
    if (depthBinSize <= 0) {
      throw new Error('The depth bin size must be greater than zero.')
    }

    const result: DepthBinValue[] = []

    if (referenceElevation < this.maxElevation()) {
      return result
    }

    const capped = this.capAtElevation(referenceElevation)

    let upperElev = referenceElevation
    let cumulativeBedLength = 0

    while (upperElev > this.minElevation()) {
      const lowerElev = upperElev - depthBinSize
      const depth = referenceElevation - lowerElev
      const avgDepth = referenceElevation - (upperElev + lowerElev) / 2
      const totalBedLength = capped.bedLengthLessThanDepth(depth)
      const depthBinBedLength = totalBedLength - cumulativeBedLength

      result.push({
        upperElevation: upperElev,
        lowerElevation: lowerElev,
        avgDepth: avgDepth,
        binValue: depthBinBedLength,
        cumValue: totalBedLength,
      })

      cumulativeBedLength = totalBedLength
      upperElev -= depthBinSize
    }

    return result
  }

  /**
   *  Builds a list of HORIZONTAL bed lengths within depth bins
   * @param referenceElevation The cross section will first get capped at this elevation
   * @param depthBinSize Depth bin increments that areas will be reported by
   * @returns List of cross section bed lengths organized by bin depth.
   * @remarks This routine first caps the cross section at the argument reference elevation.
   * It then moves down through the cross section in increments of the depth bin size and reports
   * the bed length within in each bin.
   *
   * This routine is used by the planimetric area/volume tool to calculate the longitudinal/planimetric
   * areas and volumes within depth bins
   */
  public planimetricBedLengthDepthProfile(referenceElevation: number, depthBinSize: number): DepthBinValue[] {
    if (depthBinSize <= 0) {
      throw new Error('The depth bin size must be greater than zero.')
    }
    const result: DepthBinValue[] = []
    if (referenceElevation < this.maxElevation()) {
      return result
    }
    const capped = this.capAtElevation(referenceElevation)
    let upperElev = referenceElevation
    let cumulativeBedLength = 0
    do {
      const lowerElev = upperElev - depthBinSize
      const depth = referenceElevation - lowerElev
      const avgDepth = referenceElevation - (upperElev + lowerElev) / 2
      const totalBedLength = capped.planimetricBedLengthLessThanDepth(depth)
      const depthBinBedLength = totalBedLength - cumulativeBedLength
      result.push(new DepthBinValue(upperElev, lowerElev, avgDepth, depthBinBedLength, totalBedLength))
      cumulativeBedLength = totalBedLength
      upperElev -= depthBinSize
    } while (upperElev > this.minElevation())
    return result
  }

  /**
   * Calculate the average elevation of the cross section between the argument start and end stations
   * @param leftStation Start station distance
   * @param rightStation End station distance
   * @returns The average elevation of the cross section between the argument stations
   * @remarks Uses integration. Calculates the area of the cross section and then divides by the
   * length of the cross section to get the average elevation.
   */
  public averageElevationBetweenStations(leftStation: number, rightStation: number, cappingElevation: number): number {
    const trimmed = this.trimBetweenStations(leftStation, rightStation)
    const capped = trimmed.capAtElevation(cappingElevation)

    // Calculate the average elevation by dividing the area by the distance between the stations
    const avgElevation = cappingElevation - capped.area() / (capped.maxStation() - capped.minStation())

    return avgElevation
  }

  /**
   * Calculates the average elevation of the cross section using integration
   * Note that this method was not in the desktop XSViewer. It was added to the web version
   * to simplify the code when generating long profiles.
   */
  public averageBedElevation(): number {
    const avgElevation = this.maxElevation() - this.area() / (this.maxStation() - this.minStation())
    return avgElevation
  }

  /**
   * Get the sections of the transect that are entirely deeper than the specified depth
   * @param minDepth The upper elevation below which points are to be retained
   * @returns List of sections that are the entirely deeper than the specified depth.
   * Each section is constructed as a separate transect.
   */
  public qualifyingSegments(minDepth: number): Transect[] {
    if (minDepth <= 0) {
      throw new Error('The minimum depth must be greater than zero')
    }

    const qualifyingSegments: Transect[] = []
    const maxElev = this.maxElevation()

    // Find the first point that is deep enough
    let currentPoint: SurveyPoint | undefined
    if (this.surveyPoints.some((x) => maxElev - x.elevation >= minDepth)) {
      currentPoint = this.surveyPoints.find((x) => maxElev - x.elevation >= minDepth)
    } else {
      return qualifyingSegments
    }

    if (currentPoint === undefined) throw new Error('The current point cannot be undefined.')

    const index = this.surveyPoints.indexOf(currentPoint)
    let previousStatus: 'Above' | 'Inside' = 'Above'

    let channelPoints: SurveyPoint[] = []
    for (let i = index; i < this.surveyPoints.length; i++) {
      const p = this.surveyPoints[i]
      const status = maxElev - p.elevation > minDepth ? 'Inside' : 'Above'

      switch (status) {
        case VerticalQualifyingStatusEnum.Inside: {
          switch (previousStatus) {
            case VerticalQualifyingStatusEnum.Above: {
              // Just moved inside - interpolate point at crossing station at max elev and then at cross elevation
              channelPoints = []

              const crossingPoint = SurveyPoint.interpolateAtElevation(this.surveyPoints[i - 1], p, maxElev - minDepth)
              channelPoints.push(new SurveyPoint(crossingPoint.station, maxElev))
              channelPoints.push(crossingPoint)
              channelPoints.push(p)
              break
            }
            default: {
              // Still inside, simply add the point
              channelPoints.push(p)
              break
            }
          }
          break
        }

        case VerticalQualifyingStatusEnum.Above: {
          switch (previousStatus) {
            case VerticalQualifyingStatusEnum.Above: {
              // previously above, still above - do nothing
              break
            }

            case VerticalQualifyingStatusEnum.Inside: {
              // We just moved from inside to above min depth - interpolate final point and then process channel
              const crossingPoint = SurveyPoint.interpolateAtElevation(this.surveyPoints[i - 1], p, maxElev - minDepth)
              channelPoints.push(crossingPoint)
              channelPoints.push(new SurveyPoint(crossingPoint.station, maxElev))

              const segment = new Transect(channelPoints)
              qualifyingSegments.push(segment)
            }
          }
          break
        }
      }

      // Update the status
      previousStatus = status
    }
    return qualifyingSegments
  }

  /**
   * Return a new transect ensuring that there is a survey point at the specified
   * horizontal spacing (both to the left and right) from each existing point
   * @param horizontalSpacing The maximum horizontal spacing between points
   * @returns A new transect where each point is no further than the horizontal spacing apart
   */
  public densifyStations(horizontalSpacing: number): Transect {
    // Build a new list of survey points that interpolates a new point
    // at the segmentWidth distance from each existing point
    const inflectionPoints: SurveyPoint[] = [...this.surveyPoints]

    // Loop through the existing survey points twice. First adding inflection
    // points to the right of the existing points. Then to the left.
    const offsets: number[] = [1, -1]
    for (const offset of offsets) {
      for (const existing of this.surveyPoints) {
        // check if there's a point to the right of this point with the new station
        const newStation: number = existing.station + horizontalSpacing * offset
        // Abort if the new station would be outside of the existing transect
        if (
          newStation <= this.surveyPoints[0].station ||
          newStation >= this.surveyPoints[this.surveyPoints.length - 1].station
        )
          continue

        // Abort if there's already a survey point at the new station
        if (inflectionPoints.some((x) => x.station == newStation)) continue

        // Find the point to the left and right of the new station.
        const left: SurveyPoint | undefined = inflectionPoints
          .slice()
          .reverse()
          .find((x) => x.station < newStation)
        const rght: SurveyPoint | undefined = inflectionPoints.find((x) => x.station > newStation)

        if (left !== undefined && rght !== undefined) {
          // Interpolate the new point and add it to the list
          const newElevation: number = interpolateElevation(left, rght, newStation)
          inflectionPoints.splice(inflectionPoints.indexOf(left) + 1, 0, new SurveyPoint(newStation, newElevation))
        }
      }
    }

    return new Transect(inflectionPoints)
  }

  /**
   * Find the section of the transect with the specified width that has the lowest max elevation
   * @param segmentWidth Horizontal width for the section that is returned<
   * @returns The section of this transect that has the specified width and the deepest depth.
   * @remarks The deepest segment is the section of the transect that is the
   * argument width and has the deepest minimum depth
   *
   * NOTE THAT THIS METHOD DOES NOT CAP THE OUTPUT SEGMENT TRANSECT.
   * It just returns a transect that is the deepest set of survey points
   */
  public deepestSegment(segmentWidth: number): Transect | null {
    // Ensure there are survey points segmentWidth distance to the left
    // and right of all original points.
    const densePoints = this.densifyStations(segmentWidth)

    let deepestSegment: Transect | null = null
    for (const point of densePoints.surveyPoints) {
      const left = point.station
      const right = left + segmentWidth

      if (right > this.maxStation()) {
        continue
      }

      const trimmed = densePoints.trimBetweenStations(left, right)

      // Find the section that is segmentWidth long that has the lowest elevation
      if (deepestSegment === null || deepestSegment.maxElevation > trimmed.maxElevation) {
        deepestSegment = new Transect(densePoints.surveyPoints.filter((x) => x.station >= left && x.station <= right))
      }
    }

    return deepestSegment
  }
}
