import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(utc)
dayjs.extend(relativeTime)

// http://numeraljs.com/
// https://day.js.org/
export function formatISODate(ISODateString: string): string {
  return dayjs(ISODateString).format('YYYY-MM-DD')
}

export function formatISODateFromNow(ISODateString: string): string {
  return dayjs(ISODateString).from(dayjs(Date.now()))
}
