import React from 'react'
import { Box, TextField, Typography } from '@mui/material'

import { UserInput } from '../../schema/base.types'
import { DESCRIPTION_MAX_LENGTH, NAME_MAX_LENGTH } from '../componentHelpers'

export type UserProfileChange = {
  user: UserInput
  valid: Record<string, [boolean, string]>
  allValid: boolean
  allMandatory: boolean
}

export type UserProfileFormProps = {
  profileState: UserProfileChange
  onChange: React.Dispatch<React.SetStateAction<UserProfileChange>>
}

/**
 * This is the presentation component. It should be as dumb as possible.
 */
export const UserProfileForm: React.FC<UserProfileFormProps> = ({ profileState: { user, valid }, onChange }) => {
  const handleChange = (key: string, value: string) => {
    let isValid = true
    let errmsg = ''
    const trimmedVal = value.trim()
    switch (key) {
      case 'name':
        if (trimmedVal.trim().length === 0) {
          isValid = false
          errmsg = 'Name is required'
        }
        if (trimmedVal.length > 50) {
          isValid = false
          errmsg = 'Name must be less than 50 characters'
        }
        // Name regexes are probably a bad idea but I'll leave the example
        if (trimmedVal.match(/[^a-z0-9-,.'"` ]/i)) {
          isValid = false
          errmsg = 'Name must only contain letters, numbers, and punctuation'
        }
        break
      case 'affiliation':
        if (trimmedVal.length > 50) {
          isValid = false
          errmsg = 'Affiliation must be less than 50 characters'
        }
        break
      case 'description':
        if (trimmedVal.length > 500) {
          isValid = false
          errmsg = `Too many characters (${trimmedVal.length}/500)`
        }
        break
      case 'socialUrl':
        if (trimmedVal.length > 100) {
          isValid = false
          errmsg = 'Social URL must be less than 100 characters'
        }
        if (!trimmedVal.match(/^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w.-]*)*\/?$/)) {
          isValid = false
          errmsg = 'Social URL must be a valid URL in the form https://example.com'
        }
        break
      default:
        break
    }
    onChange((userProfile) => {
      const newUser = { ...userProfile.user, [key]: value }
      const newValid: Record<string, [boolean, string]> = { ...userProfile.valid, [key]: [isValid, errmsg] }

      return {
        user: newUser,
        valid: newValid,
        allValid: Object.values(newValid).every((v) => Boolean(v[0])),
        allMandatory: newUser.name.trim().length > 0,
      }
    })
  }

  return (
    <Box>
      <TextField
        fullWidth
        label="Name"
        value={user.name || ''}
        onChange={(e) => handleChange('name', e.target.value)}
        error={valid.name && !valid.name[0]}
        helperText={(valid.name && valid.name[1]) || ' '}
        inputProps={{ maxLength: NAME_MAX_LENGTH }}
        required
      />
      <TextField
        fullWidth
        label="Affiliation"
        value={user.affiliation || ''}
        onChange={(e) => handleChange('affiliation', e.target.value)}
        error={valid.affiliation && !valid.affiliation[0]}
        helperText={(valid.affiliation && valid.affiliation[1]) || ' '}
        inputProps={{ maxLength: NAME_MAX_LENGTH }}
      />
      <TextField
        fullWidth
        label="Bio"
        value={user.description || ''}
        multiline
        onChange={(e) => handleChange('description', e.target.value)}
        error={valid.description && !valid.description[0]}
        helperText={(valid.description && valid.description[1]) || ' '}
        inputProps={{ maxLength: DESCRIPTION_MAX_LENGTH }}
      />
      <TextField
        fullWidth
        label="Social URL"
        value={user.socialUrl || ''}
        onChange={(e) => handleChange('socialUrl', e.target.value)}
        error={valid.socialUrl && !valid.socialUrl[0]}
        helperText={(valid.socialUrl && valid.socialUrl[1]) || ' '}
        inputProps={{ maxLength: 1000 }}
      />
    </Box>
  )
}
