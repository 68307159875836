import { useNavigate, useParams } from 'react-router-dom'
import { useNotifications } from '../../AppNotifications'
import { useState } from 'react'
import { useGetLongChangeChartOverviewQuery } from '../../../schema/operations'
import { LongChangeChart } from './LongChangeChart'
import { GetLongChangeDataQuery } from '../../../schema/base.types'
import { LongChangeDataQuery } from './LongChangeDataQuery'
import { uniq } from 'lodash'
import produce from 'immer'

export const LongChangeChartContainer: React.FC = () => {
  const { projIdParam } = useParams()
  const { show } = useNotifications()
  const navigate = useNavigate()
  const [longChanges, setLongChanges] = useState<NonNullable<GetLongChangeDataQuery['getLongChange']>[]>([])
  const [fetchLongChangeIds, setFetchLongChangeIds] = useState<string[]>([])

  const projectId = projIdParam as string

  const { data } = useGetLongChangeChartOverviewQuery({
    variables: { projectId },
    onCompleted: (data) => {
      console.log('onCompleted GetLongChangeChartOverviewQuery', data)
    },
    onError: (error) => {
      console.log('onError GetLongChangeChartOverviewQuery', error)
      show(`Error retrieving project long change overview data: ${error.message}`, { variant: 'error' })
    },
    skip: !projectId,
  })

  const project = data?.getProject
  if (!project) return null // TODO: loading screen

  return (
    <>
      {fetchLongChangeIds.map((longChangeId) => (
        <LongChangeDataQuery
          key={longChangeId}
          projectId={projectId}
          longChangeId={longChangeId}
          onData={(data) => {
            setLongChanges(
              produce((draft) => {
                const index = draft.findIndex((existing) => existing.id === data.id)
                draft[index === -1 ? draft.length : index] = data
              })
            )
          }}
        />
      ))}

      <LongChangeChart
        project={project}
        longChanges={longChanges}
        onActiveLongChangesChange={(longChangeIds) => {
          setFetchLongChangeIds(uniq([...fetchLongChangeIds, ...longChangeIds]))
        }}
        onNavigate={navigate}
      />
    </>
  )
}
