import React, { useEffect, useState } from 'react'
import TextField, { TextFieldProps } from '@mui/material/TextField'

export type NumberFieldProps = {
  value: number | null
  onChange: (value: number | null) => void
  integer?: boolean
} & Omit<TextFieldProps, 'value' | 'onChange'>

const toString = (n: number | null) => (n === null ? '' : String(n))
const toNumberOrNull = (s: string) => {
  if (!s) return null
  const number = Number(s)
  if (isNaN(number)) return null
  return number
}

export const NumberField: React.FC<NumberFieldProps> = ({ inputProps, value, onChange, ...textFieldProps }) => {
  const [text, setText] = useState(toString(value))

  useEffect(() => {
    if (value !== toNumberOrNull(text)) setText(toString(value))
  }, [value])

  const handleChange = ({ target: { value: textValue } }) => {
    if (!textValue.match(/^-?[0-9]*\.?[0-9]*$/)) return
    setText(textValue)
    const nextValue = toNumberOrNull(textValue)
    if (nextValue !== value) onChange(nextValue)
  }

  return (
    <TextField
      onChange={handleChange}
      inputProps={{ inputMode: 'numeric', ...inputProps }}
      value={text}
      {...textFieldProps}
    />
  )
}
