import { Box, CircularProgress, Stack, Typography, useTheme } from '@mui/material'

export interface AppLoaderProps {
  title?: string
  subtitle?: string
}

export const AppLoader: React.FC<AppLoaderProps> = ({ title, subtitle }) => {
  const theme = useTheme()
  return (
    <>
      <Stack sx={{ height: '100%' }}>
        <Box sx={{ position: 'relative', flexGrow: 1 }}>
          <Box
            sx={{
              position: 'absolute',
              color: theme.palette.text.primary,
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            <Box sx={{ position: 'relative', margin: 'auto', width: 120 }}>
              <CircularProgress
                variant="indeterminate"
                size={120}
                thickness={6}
                sx={{
                  margin: 'auto',
                  color: 'white',
                }}
              />
              <Box
                sx={{
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  position: 'absolute',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <img src="/logo.svg" alt="logo" width="70px" />
              </Box>
            </Box>
            <Typography variant="h4" sx={{ textAlign: 'center' }} color="white">
              {title || 'Loading...'}
            </Typography>
            <Typography variant="body1" sx={{ textAlign: 'center' }} color="white">
              {subtitle || 'Checking authentication parameters'}
            </Typography>
          </Box>
        </Box>
      </Stack>
    </>
  )
}
