import { Component } from 'react'
import { Box, Container, Paper, Typography } from '@mui/material'
import { App } from '../../App'
import { Topbar } from '../Topbar'

interface ErrorBoundaryProps {
  children: React.ReactNode
}

interface ErrorState {
  hasError: boolean
  message: string
  error?: Error
}

const noError = { hasError: false, message: '' }

export class ErrorBoundary extends Component<ErrorBoundaryProps> {
  state: ErrorState

  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = noError

    window.addEventListener('hashchange', () => {
      if (this.state.hasError) this.setState(noError)
    })
    window.addEventListener('popstate', () => {
      if (this.state.hasError) this.setState(noError)
    })
  }

  static getDerivedStateFromError(error: Error): ErrorState {
    return { hasError: true, message: error.toString(), error }
  }

  // componentDidCatch(error, errorInfo) {
  // logErrorToMyService(error, errorInfo)
  // }

  render(): React.ReactNode {
    if (this.state.hasError) {
      return (
        <App
          topBar={<Topbar isAuthenticated={false} />}
          innerChild={
            <Container maxWidth="lg">
              <Typography variant="h1">An error has occurred.</Typography>
              <Box p={2}>
                <Typography variant="h2">An error has occurred.</Typography>
                <Box>{this.state.message}</Box>
                {this.state.error && (
                  <Paper
                    sx={{
                      m: 2,
                      p: 2,
                      fontSize: 10,
                      fontFamily: 'monospace',
                      color: 'white',
                      backgroundColor: 'black',
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    <code>{this.state.error?.stack}</code>
                  </Paper>
                )}
              </Box>
            </Container>
          }
        />
      )
    }

    return this.props.children
  }
}
