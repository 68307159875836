import { Button, CardActions, CardContent, CardHeader, Container, Paper, Typography, useTheme } from '@mui/material'
import { useAuth } from '../api/AuthProvider'

export const AuthenticationRequired: React.FC = () => {
  const { hostedSignIn } = useAuth()
  const theme = useTheme()
  return (
    <Container maxWidth="xs">
      <Paper
        sx={{
          mt: 5,
        }}
      >
        <CardHeader
          title="Sign In Required"
          sx={{
            backgroundColor: theme.palette.primary.dark,
            color: theme.palette.primary.contrastText,
          }}
        />
        <CardContent>
          <Typography>Sign In is required.</Typography>
        </CardContent>
        <CardActions
          sx={{
            p: 2,
            textAlign: 'center',
          }}
        >
          <Button variant="contained" color="secondary" onClick={hostedSignIn}>
            Sign In
          </Button>
        </CardActions>
      </Paper>
    </Container>
  )
}
