import { GetLongChangeDataQuery } from '../../../schema/base.types'
import { useGetLongChangeDataQuery } from '../../../schema/operations'
import { useNotifications } from '../../AppNotifications'

export interface LongChangeQueryProps {
  projectId: string
  longChangeId: string
  onData: (data: NonNullable<GetLongChangeDataQuery['getLongChange']>) => void
}

export const LongChangeDataQuery: React.FC<LongChangeQueryProps> = ({ projectId, longChangeId, onData }) => {
  const { show } = useNotifications()

  const longChangeDataQuery = useGetLongChangeDataQuery({
    variables: { projectId, longChangeId },
    onCompleted: (data) => {
      if (data.getLongChange) onData(data.getLongChange)
      if (data.getLongChange?.points.nextToken) {
        longChangeDataQuery.fetchMore({
          variables: {
            nextToken: data.getLongChange.points.nextToken,
          },
        })
      }
    },
    onError: (error) => {
      console.log('onError GetLongChangeData', error)
      show(`Error retrieving project longitudinal change data: ${error.message}`, { variant: 'error' })
    },
  })

  return null
}
