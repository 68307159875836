import React from 'react'
import { createRoot } from 'react-dom/client'
import log from 'loglevel'

// import './index.css'
import reportWebVitals from './reportWebVitals'
import { CssBaseline, ThemeProvider } from '@mui/material'
import routing from './routing'
import { RecoilRoot } from 'recoil'

// THIS FIX IS NECESSARY FOR PRODUCTION AND WEBPACK
// https://docs.mapbox.com/mapbox-gl-js/api/#transpiling-v2
// https://github.com/mapbox/mapbox-gl-js/issues/10173
import mapboxgl from 'mapbox-gl/dist/mapbox-gl'
import { AuthProvider } from './api/AuthProvider'
import { ApiProvider } from './api/ApiProvider'
import { AuthLink } from './api/apollo/authLink'
import { cache } from './api/apollo/cache'
import { useRoutes, BrowserRouter as Router } from 'react-router-dom'
import { ErrorBoundary } from './components/ErrorBoundary'
import { InitializeUser } from './components/InitializeUser'
import { theme } from './theme'
import { auth0config } from './config'
import { AppNotificationsProvider } from './components/AppNotifications'
import { WrappedAppLoader } from './App'

// eslint-disable-next-line @typescript-eslint/no-var-requires
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default

export const authLink = new AuthLink(auth0config, {
  onError: (error) => {
    log.warn('authLink error', error)
  },
})

const Routes = () => useRoutes(routing)

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const container = document.getElementById('root')!
const root = createRoot(container)
root.render(
  <React.StrictMode>
    <RecoilRoot>
      <ThemeProvider theme={theme}>
        <CssBaseline enableColorScheme />
        <AuthProvider authLink={authLink} loader={<WrappedAppLoader />}>
          <ApiProvider cache={cache} authLink={authLink}>
            <Router>
              <AppNotificationsProvider>
                {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                <ErrorBoundary>
                  <InitializeUser />
                  <Routes />
                </ErrorBoundary>
              </AppNotificationsProvider>
            </Router>
          </ApiProvider>
        </AuthProvider>
      </ThemeProvider>
    </RecoilRoot>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
