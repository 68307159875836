import React from 'react'
import { Point } from '@nivo/line'
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { DataMap } from './SurveyDataChart'

export interface SurveyChartSliceProps {
  points: Point[]
  dataMap: DataMap
  xLegend?: string
  yLegend?: string
}

export const SurveyChartSlice: React.FC<SurveyChartSliceProps> = ({ points, xLegend, yLegend, dataMap }) => {
  const reveresedSlice = [...points].reverse()
  // Filter out any duplicates where the points are identical
  const dedupedPOints = reveresedSlice.filter((point, idx) => {
    if (idx === 0) return true
    const prevPoint = reveresedSlice[idx - 1]
    return !(prevPoint.data.x === point.data.x && prevPoint.data.y === point.data.y)
  })
  return (
    <Box
      style={{
        background: 'white',
        padding: '9px 12px',
        border: '1px solid #ccc',
      }}
    >
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell colSpan={2} padding="none" />
              <TableCell align="right" padding="none">
                {xLegend}
              </TableCell>
              <TableCell align="right" padding="none">
                {yLegend}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {reveresedSlice.map((point, idx) => (
              <TableRow key={idx}>
                <TableCell align="right" padding="none">
                  <Box
                    sx={{
                      width: 12,
                      height: 12,
                      background: dataMap[point.serieId]?.color,
                      borderRadius: '50%',
                      display: 'inline-block',
                      marginRight: 6,
                    }}
                  />
                </TableCell>
                <TableCell padding="none">
                  <Typography>{point.serieId}</Typography>
                </TableCell>
                <TableCell align="right" padding="none">
                  <Typography>{point.data.xFormatted}</Typography>
                </TableCell>
                <TableCell align="right" padding="none">
                  <Typography>{point.data.yFormatted}</Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}
