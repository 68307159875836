import React from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import { PointCloud, SurveyInput } from '../../schema/base.types'
import { validateDescription, validateName, DESCRIPTION_MAX_LENGTH } from '../componentHelpers'
import { HelpButton } from '../HelpButton'

export interface PropertyEditorDialogProps {
  open: boolean
  onClose: () => void
  onSubmit: (dbItem: SurveyInput) => void
  pointClouds: PointCloud[]
  unitsAbbreviation: string
}

export const CreateSurveyDialog: React.FC<PropertyEditorDialogProps> = ({
  open,
  pointClouds,
  unitsAbbreviation,
  onSubmit,
  onClose,
}) => {
  const theme = useTheme()
  const [[name, nameValid], setName] = React.useState<[string, boolean | null]>(['', false])
  const [pointCloudId, setPointCloudId] = React.useState<string | null>(
    pointClouds.length > 0 ? pointClouds[0].id : null
  )
  const [[snappingTolerance, snappingToleranceValid], setSnappingTolerance] = React.useState<[string, boolean | null]>([
    '',
    false,
  ])
  const [description, setDescription] = React.useState('')
  const [validated, setValidated] = React.useState(false)
  const [colors, setColors] = React.useState<[string, string]>(['#000000', '#ffffff'])

  const validateSnappingTolerance = (value: string): boolean => {
    const finalValue = parseFloat(value)
    if (finalValue === null) {
      return false
    }

    return finalValue > 0
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle
        sx={{
          backgroundColor: theme.palette.secondary.main,
          color: theme.palette.secondary.contrastText,
        }}
      >
        Create New Survey
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" sx={{ fontSize: '0.8rem', pt: 1 }}>
          A survey combines a point cloud with the project cross sections. Points that occur within the snapping
          tolerance of a cross section are associated with that cross section. Processing is performed asynchronously
          and may take several minutes to complete before the survey is available for use.
        </Typography>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Name"
          type="text"
          fullWidth
          variant="outlined"
          required
          value={name}
          onChange={(e) => setName([e.target.value, validateName(e.target.value)])}
          error={nameValid === false}
          inputProps={{ maxLength: 255 }}
          sx={{ paddingBottom: 1 }}
        />
        <FormControl fullWidth>
          <InputLabel>Point Cloud</InputLabel>
          <Select
            label="Point Cloud"
            fullWidth
            aria-label="Point Cloud"
            defaultValue={pointCloudId}
            required
            size="small"
            onChange={(e) => setPointCloudId(e.target.value as string)}
          >
            {pointClouds.map((pointCloud) => {
              return (
                <MenuItem value={pointCloud ? pointCloud.id : ''} key={pointCloud ? pointCloud.id : ''}>
                  {pointCloud.name}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
        <TextField
          margin="dense"
          id="name"
          label="Snapping Tolerance"
          type="number"
          fullWidth
          variant="outlined"
          required
          InputProps={{
            endAdornment: <InputAdornment position="end">{unitsAbbreviation}</InputAdornment>,
          }}
          value={snappingTolerance}
          onChange={(e) => setSnappingTolerance([e.target.value, validateSnappingTolerance(e.target.value)])}
          error={snappingToleranceValid === false}
        />
        <TextField
          margin="dense"
          id="outlined-multiline-flexible"
          label="Description"
          multiline
          rows={3}
          fullWidth
          inputProps={{ maxLength: DESCRIPTION_MAX_LENGTH }}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <HelpButton toolTip="Learn more about surveys" pageName="surveys" />
        <Button onClick={onClose}>Cancel</Button>
        <Box sx={{ flexGrow: 1 }} />
        <Button
          onClick={() => {
            onSubmit({
              name: name.trim(),
              description: description.slice(0, DESCRIPTION_MAX_LENGTH),
              pointCloudId: pointCloudId as string,
              snappingTolerance: parseFloat(snappingTolerance),
              meta: [],
            })
          }}
          disabled={!(nameValid && snappingToleranceValid)}
          variant="contained"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}
