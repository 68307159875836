import React from 'react'
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import { CreateLongChangeInput, FileStateEnum, ReferencePlane, Selection, Survey } from '../../schema/base.types'
import { DESCRIPTION_MAX_LENGTH, NAME_MAX_LENGTH, validateName } from '../componentHelpers'
import { UpstreamDownstreamTextFields } from '../UpstreamDownstreamTextFields'
import { ReferenceStations } from '../referenceStations'
import { ObjectValues } from '@xsonline/common'
import { HelpButton } from '../HelpButton'

export interface PropertyEditorDialogProps {
  open: boolean
  onClose: () => void
  onSubmit: (item: CreateLongChangeInput) => void
  surveys: Survey[]
  selections: Selection[]
  referencePlanes: ReferencePlane[]
  referenceStations: string[]
  riverDistanceUnitsAbbr: string
  surveyDistanceUnitsAbbr: string
}

const TabsEnum = {
  INFO: 'INFO',
  FILTERING: 'Filtering',
  OFFSET: 'Offset',
} as const
type TabsEnum = ObjectValues<typeof TabsEnum>

export const CreateLongChangeDialog: React.FC<PropertyEditorDialogProps> = ({
  open,
  onClose,
  onSubmit,
  surveys,
  selections,
  referencePlanes,
  referenceStations,
  riverDistanceUnitsAbbr,
  surveyDistanceUnitsAbbr,
}) => {
  const theme = useTheme()
  const [[name, nameValid], setName] = React.useState<[string, boolean | null]>(['', false])
  const [newSurveyId, setNewSurveyId] = React.useState<string>(surveys[1].id)
  const [[oldSurveyId, oldSurveyIdValid], setOldSurveyId] = React.useState<[string, boolean | null]>([
    surveys[0].id,
    true,
  ])
  const [currentTab, setCurrentTab] = React.useState<TabsEnum>(TabsEnum.INFO)
  const [selectionId, setSelectionId] = React.useState<string>(selections[0].id)
  const [coincident, setCoincident] = React.useState('coincident')
  const [[upstream, downstream], setUpstreamDownstream] = React.useState<[number | null, number | null]>([0, 0])
  const [distancesValid, setDistancesValid] = React.useState(false)

  const [stationExtents, setStationExtents] = React.useState('original')
  const [constrainReferenceStations, setConstrainReferenceStations] = React.useState(false)
  const [insideOutside, setInsideOutside] = React.useState('inside')
  const [leftReferenceStation, setLeftStation] = React.useState('')
  const [rightReferenceStation, setRightStation] = React.useState('')
  const [useVerticalOffset, setUseVerticalOffset] = React.useState(false)
  const [verticalOffset, setVerticalOffset] = React.useState(0)
  const [referencePlaneId, setReferencePlaneId] = React.useState<string>(
    referencePlanes.length > 0 ? referencePlanes[0].id : ''
  )
  const [description, setDescription] = React.useState('')

  const validateSurveys = (oldSurveyValue: string): boolean => {
    return newSurveyId !== oldSurveyValue
  }

  const formValid = nameValid && oldSurveyIdValid && distancesValid

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle
        sx={{
          backgroundColor: theme.palette.secondary.main,
          color: theme.palette.secondary.contrastText,
        }}
      >
        Create New Longitudinal Change
      </DialogTitle>
      <DialogContent sx={{ minHeight: 400 }}>
        <Typography variant="body1" sx={{ fontSize: '0.8rem', pt: 1 }}>
          Longitudinal changes divide a river into control volumes and calculate the change in volume between two
          surveys for each volume. The control volumes are accumulated downstream to produce a longitudinal profile of
          the change in volume.
        </Typography>
        <Tabs value={currentTab} onChange={(e, value) => setCurrentTab(value)}>
          <Tab label="Basic Settings" value={TabsEnum.INFO} />
          <Tab label="Filtering" value={TabsEnum.FILTERING} />
          <Tab label="Vertical Offset" value={TabsEnum.OFFSET} />
        </Tabs>

        {currentTab === TabsEnum.INFO && (
          <Box>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Name"
              type="text"
              fullWidth
              variant="outlined"
              required
              value={name}
              inputProps={{ maxLength: NAME_MAX_LENGTH }}
              onChange={(e) => setName([e.target.value, validateName(e.target.value)])}
              error={nameValid === false}
              sx={{ pb: 1 }}
            />
            <FormControl fullWidth sx={{ pb: 1.5 }}>
              <InputLabel>New survey</InputLabel>
              <Select
                label="New Survey"
                fullWidth
                value={newSurveyId}
                onChange={(e) => setNewSurveyId(e.target.value as string)}
                size="small"
              >
                {surveys
                  .filter((x) => x.state === FileStateEnum.Complete)
                  .map((survey) => {
                    return (
                      <MenuItem value={survey.id} key={survey.id}>
                        {survey.name}
                      </MenuItem>
                    )
                  })}
              </Select>
            </FormControl>
            <FormControl fullWidth sx={{ pb: 1.5 }}>
              <InputLabel>Old Survey</InputLabel>
              <Select
                size="small"
                label="Old Survey"
                fullWidth
                required
                value={oldSurveyId}
                onChange={(e) => setOldSurveyId([e.target.value, validateSurveys(e.target.value)])}
              >
                {surveys
                  .filter((x) => x.state === FileStateEnum.Complete)
                  .map((survey) => {
                    return (
                      <MenuItem value={survey.id} key={survey.id}>
                        {survey.name}
                      </MenuItem>
                    )
                  })}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel>Selection</InputLabel>
              <Select
                size="small"
                label="Selection"
                fullWidth
                required
                value={selectionId}
                onChange={(e) => setSelectionId(e.target.value as string)}
              >
                {selections.map((selection) => {
                  return (
                    <MenuItem value={selection.id} key={selection.id}>
                      {selection.name}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
            <UpstreamDownstreamTextFields
              riverDistanceUnitsAbbr={riverDistanceUnitsAbbr}
              onDistancesChange={(upstream: number | null, downstream: number | null) => {
                setUpstreamDownstream([upstream, downstream])
              }}
              onValidateChange={setDistancesValid}
            />
            <TextField
              id="outlined-multiline-flexible"
              label="Description"
              multiline
              rows={3}
              fullWidth
              margin="normal"
              value={description}
              inputProps={{ maxLength: DESCRIPTION_MAX_LENGTH }}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Box>
        )}
        {currentTab === TabsEnum.FILTERING && (
          <Box>
            <Box border={1} borderColor="primary.main" borderRadius={1} sx={{ px: 2, pb: 2, mt: 2 }}>
              <Typography>Cross Section Filtering</Typography>
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue={coincident}
                  onChange={(e) => setCoincident(e.target.value)}
                  name="radio-buttons-group"
                >
                  <FormControlLabel
                    value="coincident"
                    control={<Radio />}
                    label="Only cross sections that occur in both surveys and the selection"
                  />
                  <FormControlLabel
                    value="either"
                    control={<Radio />}
                    label="Cross sections that occur in either survey and the selection"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
            <Box border={1} borderColor="primary.main" borderRadius={1} sx={{ px: 2, pb: 2, mt: 2 }}>
              <Box>
                <Typography>Station Filtering</Typography>
                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue={stationExtents}
                    onChange={(e) => setStationExtents(e.target.value)}
                    name="radio-buttons-group"
                  >
                    <FormControlLabel value="original" control={<Radio />} label="Original cross section extents" />
                    <FormControlLabel value="common" control={<Radio />} label="Common cross section extents" />
                  </RadioGroup>
                </FormControl>
              </Box>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={referenceStations.length < 2}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setConstrainReferenceStations(event.target.checked)
                    }}
                  />
                }
                label="Constrain with reference stations"
                value={constrainReferenceStations}
              />
              <Box marginLeft={2}>
                <RadioGroup
                  aria-labelledby="filter-stations-radio-group"
                  defaultValue={insideOutside}
                  onChange={(e) => setInsideOutside(e.target.value)}
                  name="radio-buttons-group"
                >
                  <FormControlLabel
                    value="inside"
                    disabled={!constrainReferenceStations}
                    control={<Radio />}
                    label="Inside reference stations"
                  />
                  <FormControlLabel
                    value="outside"
                    disabled={!constrainReferenceStations}
                    control={<Radio />}
                    label="Outside reference stations"
                  />
                </RadioGroup>

                <ReferenceStations
                  fields={referenceStations}
                  disabled={!constrainReferenceStations}
                  leftStation={leftReferenceStation}
                  rightStation={rightReferenceStation}
                  onChangeLeftStation={setLeftStation}
                  onChangeRightStation={setRightStation}
                />
              </Box>
            </Box>
          </Box>
        )}
        {currentTab === TabsEnum.OFFSET && (
          <Box>
            <Box border={0} borderColor="primary.main" borderRadius={1} sx={{ px: 2, pb: 2, mt: 2 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={referencePlanes.filter((x) => x.state === FileStateEnum.Complete).length < 1}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setUseVerticalOffset(event.target.checked)
                    }}
                  />
                }
                label="Vertical offset"
                value={useVerticalOffset}
              />
              <Grid container>
                <Grid item xs={8}>
                  <FormControl fullWidth sx={{ pr: 1 }}>
                    <InputLabel>Reference Plane</InputLabel>
                    <Select
                      label="Reference Plane"
                      fullWidth
                      value={referencePlaneId}
                      onChange={(e) => setReferencePlaneId(e.target.value)}
                      size="small"
                      disabled={referencePlanes.length === 0 || !useVerticalOffset}
                    >
                      {referencePlanes
                        .filter((x) => x.state === FileStateEnum.Complete)
                        .map((referencePlane) => {
                          return (
                            <MenuItem value={referencePlane.id} key={referencePlane.id}>
                              {referencePlane.name}
                            </MenuItem>
                          )
                        })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label="Offset"
                    type="number"
                    fullWidth
                    disabled={referencePlanes.length === 0 || !useVerticalOffset}
                    value={verticalOffset}
                    onChange={(e) => setVerticalOffset(parseFloat(e.target.value))}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">{surveyDistanceUnitsAbbr}</InputAdornment>,
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <HelpButton toolTip="Learn more about longitudinal changes" pageName="long_changes" />
        <Button onClick={onClose}>Cancel</Button>
        <Box sx={{ flexGrow: 1 }} />
        <Button
          onClick={() => {
            onSubmit({
              name: name.trim(),
              meta: [],
              description: description.slice(0, DESCRIPTION_MAX_LENGTH),
              newSurveyId,
              oldSurveyId,
              selectionId,
              coincident: coincident === 'coincident',
              originalExtents: stationExtents === 'original',
              leftReferenceStation,
              rightReferenceStation,
              referenceStationFilterInside: constrainReferenceStations ? insideOutside === 'inside' : null,
              referencePlaneId: useVerticalOffset ? referencePlaneId : null,
              referencePlaneOffset: useVerticalOffset ? verticalOffset : null,
              upstream: parseFloat(upstream as unknown as string),
              downstream: parseFloat(downstream as unknown as string),
              binSize: 1,
            })
          }}
          disabled={!formValid}
          variant="contained"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}
