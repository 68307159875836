import { ColDef } from 'ag-grid-community'
// import { LongProfile, ReferencePlane } from '../../../schema/base.types'

export const longChangesColumnDefs: ColDef[] = [
  { field: 'name', headerName: 'Longitudinal Changes', resizable: false, flex: 1 },
]

export const longChangeAttributeColumnDefs: ColDef[] = [
  { field: 'name', headerName: 'Long Change', resizable: false, flex: 1 },
  { field: 'newSurveyName', headerName: 'New Survey', resizable: false, flex: 1 },
  { field: 'oldSurveyName', headerName: 'Old Survey', resizable: false, flex: 1 },

  { field: 'upstream', headerName: 'Upstream', resizable: false, flex: 1 },
  { field: 'downstream', headerName: 'Downstream', resizable: false, flex: 1 },

  { field: 'selectionName', headerName: 'Selection', resizable: false, flex: 1 },
  { field: 'referencePlaneName', headerName: 'Reference Plane', resizable: false, flex: 1 },
  { field: 'referencePlaneOffset', headerName: 'Offset', resizable: false, flex: 1 },
  { field: 'coincident', headerName: 'Coincident Only', resizable: false, flex: 1 },
  { field: 'leftReferenceStation', headerName: 'Left Reference Station', resizable: false, flex: 1 },
  { field: 'rightReferenceStation', headerName: 'Right Reference Station', resizable: false, flex: 1 },
  { field: 'referenceStationFilterInside', headerName: 'Filter Inside Reference Stations', resizable: false, flex: 1 },
]
