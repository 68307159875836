export class DepthBinValue {
  public readonly upperElevation: number
  public readonly lowerElevation: number
  public readonly avgDepth: number
  public readonly binValue: number
  public readonly cumValue: number

  constructor(upperElevation: number, lowerElevation: number, avgDepth: number, binValue: number, cumValue: number) {
    if (upperElevation < lowerElevation) {
      throw new Error(`The upper elevation must be above the lower elevation`)
    }
    if (avgDepth < 0) {
      throw new Error(`The average depth must be greater than or equal to zero.`)
    }
    if (binValue < 0) {
      throw new Error(`The value must be greater than or equal to zero.`)
    }

    this.upperElevation = upperElevation
    this.lowerElevation = lowerElevation
    this.avgDepth = avgDepth
    this.binValue = binValue
    this.cumValue = cumValue
  }

  toString(): string {
    return `Up ${this.upperElevation.toFixed(2)}, Lr ${this.lowerElevation.toFixed(
      2
    )}, Avg Depth ${this.avgDepth.toFixed(2)}, Bin Value ${this.binValue.toFixed(2)}, Cum Value ${this.cumValue.toFixed(
      2
    )}`
  }
}
