import React from 'react'
import { Box, Container, Paper, alpha, useTheme } from '@mui/material'
import { Topbar, TopbarContainer } from './components/Topbar'

import { useAuth } from './api/AuthProvider'
import { AuthenticationRequired } from './components/AuthenticationRequired'
import { NewUserDialogContainer } from './components/UserProfile/NewUserDialog'
import { useProfile } from './hooks/useProfile'
import { ObjectValues } from '@xsonline/common'
import { useLocation } from 'react-router-dom'
import { AppLoader } from './components/AppLoader'

export const BGImagesEnum = {
  RIVER: 'river.jpg',
  RIVER_SUNSET: 'river_sunset.jpg',
  PROFILE: 'profile.jpg',
} as const
export type BGImagesEnum = ObjectValues<typeof BGImagesEnum>

export interface AppProps {
  // If this is a tool we're going to wrap it up to fit nicely on a screen
  toolWrap?: boolean
  // Only this one goes through to the final App component
  innerChild: React.ReactNode | React.ReactNode[]
  topBar?: React.ReactNode
  bgImage?: BGImagesEnum
  // Everything else is used by the container
  requireAuth?: boolean
  newUserOpen?: boolean
}

export const AppContainer: React.FC<AppProps> = ({ innerChild, requireAuth, toolWrap }): JSX.Element => {
  const { isAuthenticated } = useAuth()
  const { myProfile, loading, error } = useProfile()

  const topBar = <TopbarContainer />

  const { pathname } = useLocation()

  const pathnameRegex = pathname.match(/([^/]+)/gi)
  const allMatches = pathnameRegex && pathnameRegex.length > 0 ? pathnameRegex : ['/']

  let bgImage
  switch (allMatches[0]) {
    case '/':
      bgImage = BGImagesEnum.RIVER_SUNSET
      break
    case 'profile':
      bgImage = BGImagesEnum.PROFILE
      break
    case 'projects':
      // if (allMatches.length > 2 && allMatches[2].length > 0) break
      bgImage = BGImagesEnum.RIVER
      break
  }

  // Return the AuthenticationRequired component if the user is not authenticated
  // This is our main authentication gate in the app
  if (requireAuth && !isAuthenticated) {
    return <App innerChild={<AuthenticationRequired />} topBar={topBar} bgImage={BGImagesEnum.RIVER_SUNSET} />
  }
  // IF the user is authenticated but we don't have their data yet, show an onboarding modal
  else if (requireAuth && isAuthenticated && !loading && !myProfile) {
    return (
      <>
        <App innerChild={<AuthenticationRequired />} topBar={topBar} />
        <NewUserDialogContainer />
      </>
    )
  }

  return <App innerChild={innerChild} topBar={topBar} bgImage={bgImage} toolWrap={toolWrap} />
}

export const WrappedAppLoader: React.FC = () => (
  <App innerChild={<AppLoader />} topBar={<Topbar />} bgImage={BGImagesEnum.RIVER_SUNSET} />
)

/**
 * This is the main presentational component for the app. It's basically just
 * a wrapper for all our other components.
 *
 * It also includes the topbar
 */
export const App: React.FC<Pick<AppProps, 'innerChild' | 'topBar' | 'bgImage' | 'toolWrap'>> = ({
  innerChild,
  topBar,
  bgImage,
  toolWrap,
}) => {
  const theme = useTheme()
  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.palette.background.default,
        backgroundImage: {
          backgroundSize: 'cover',
          backgroundPosition: 'center center',

          sm: bgImage ? `url('${process.env.PUBLIC_URL}/bg/${bgImage}')` : undefined,
        },
      }}
    >
      {topBar}
      {toolWrap ? (
        <Container
          maxWidth="xl"
          sx={{ flex: 1, overflow: 'hidden', display: 'flex', flexDirection: 'column', pb: 2, pt: 2 }}
        >
          <Paper
            sx={{
              // border: '5px solid red',
              backgroundColor: alpha(theme.palette.background.paper, 0.8),
              flex: '1 1 auto',
              display: 'flex',
              flexDirection: 'column',
              overflow: 'hidden',
              minHeight: theme.breakpoints.values.md,
              maxHeight: theme.breakpoints.values.xl,
            }}
          >
            {innerChild}
          </Paper>
        </Container>
      ) : (
        <Box
          sx={{
            overflowX: 'hidden',
            overflowY: 'auto',
          }}
        >
          {innerChild}
        </Box>
      )}
    </Box>
  )
}
