export const DESCRIPTION_MAX_LENGTH = 4000
export const NAME_MAX_LENGTH = 255

export const validateName = (value: string): boolean => {
  const finalValue = value.trim()
  if (finalValue.length === 0) {
    return false
  }

  if (finalValue.length > 0) {
    return true
  }

  return false
}

export const validateDescription = (value: string): boolean => {
  const finalValue = value.trim()

  if (finalValue.length > DESCRIPTION_MAX_LENGTH) {
    return false
  }

  return false
}

export const validateUrl = (value: string): boolean => {
  const finalValue = value.trim()

  const urlRegex = /^(https?):\/\/[^\s/$.?#].[^\s]*$/i
  if (!urlRegex.test(finalValue)) {
    return false
  }

  return true
}

export const validateFieldName = (value: string): boolean => {
  const finalValue = value.trim()
  if (finalValue.length === 0) {
    return false
  }

  // Field names cannot contain spaces
  if (finalValue.includes(' ')) {
    return false
  }

  if (finalValue.length > 0) {
    return true
  }

  return false
}

export const linearUnits = [
  { name: 'Meters', abbreviation: 'm' },
  { name: 'Kilometers', abbreviation: 'km' },
  { name: 'Feet', abbreviation: 'ft' },
  { name: 'Miles', abbreviation: 'mi' },
  {
    name: 'US Survey Feet',
    abbreviation: 'us-ft',
  },
  {
    name: 'Yards',
    abbreviation: 'yd',
  },
]
