import produce from 'immer'
import { PaginatedPoint2D } from '../../schema/base.types'

export interface HasId {
  id: string
}

export interface MutateRowsFilterFnParams {
  input: any
  callback: ({ rows, draft }) => any
}

export const mutateRowsFilterFn = <T>(input: T[], { callback, ...rest }: MutateRowsFilterFnParams) =>
  input.map((row) =>
    produce(row, (draft) => {
      callback({ rows: input, draft, ...rest })
    })
  )

export const includePinnedIdsFilterFn = <TInput extends HasId, TSource extends HasId>(
  input: TInput[],
  args: {
    source: TSource[]
    ids: string[]
  }
) => {
  const { source, ids } = args
  if (!ids.length) return input

  return [
    ...(input as (TInput & { pinned?: boolean })[]),
    ...source.filter(({ id }) => ids.includes(id)).map((item) => ({ ...item, pinned: true })),
  ]
}

export const includeUnpinnedIdsFilterFn = <TInput extends HasId, TSource extends HasId>(
  input: TInput[],
  args: {
    source: TSource[]
    ids: string[]
  }
) => {
  const { source, ids } = args
  if (!ids.length) return input

  return [
    ...(input as (TInput & { pinned?: boolean })[]),
    ...(source.filter(
      ({ id }) =>
        ids.includes(id) && // id is found
        !input.some(({ id: existingId }) => id === existingId) // ...and isn't already in input
    ) as (TSource & { pinned?: boolean })[]),
  ]
}

export const addColorFilterFn = <T extends HasId>(input: T[], args: { assignColorFn: (key: string) => string }) =>
  input.map((row) => ({
    ...row,
    color: args.assignColorFn(row.id),
  }))
