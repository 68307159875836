import React, { ReactNode } from 'react'
import { SnackbarKey } from 'notistack'
import { SnackbarProvider } from 'notistack'
import { atom, useRecoilValue } from 'recoil'
import { createPortal } from 'react-dom'
import { Error } from '@mui/icons-material'

export const elementsAtom = atom<Record<SnackbarKey, ElementProps>>({
  key: 'elementsAtom',
  default: {},
})

interface ElementProps {
  node: ReactNode
  el: HTMLDivElement | null
}

const Element: React.FC<ElementProps> = ({ node, el }) => {
  if (!el) return null
  return createPortal(node, el)
}

export const AppNotificationsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const elements = useRecoilValue(elementsAtom)

  return (
    <SnackbarProvider
      iconVariant={{
        error: <Error style={{ marginRight: '0.25em' }} />,
      }}
    >
      {children}
      {Object.entries(elements).map(([key, props]) => (
        <Element key={key} {...props} />
      ))}
    </SnackbarProvider>
  )
}
