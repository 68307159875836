import { useMemo, useState } from 'react'
import { noop, without } from 'lodash'
import { Box, Button, useTheme } from '@mui/material'
import {
  FileStateEnum,
  GetLongChangeChartOverviewQuery,
  GetLongChangeDataQuery,
  Project,
} from '../../../schema/base.types'
import { Column, Row } from '../../Layout'
import { DataGrid, DataGridApis, extractId } from '../../DataGrid'
import { someValues, useFields } from '../../../lib/useFields'
import { UnitsSettings, useFormats } from '../../../lib/formats'
import { longChangeAttributeColumnDefs, longChangesColumnDefs } from './columnDefs'
import { Chip } from '../../Chip'
import {
  EMPTY_ARRAY_INPUT,
  addColorFilterFn,
  includePinnedIdsFilterFn,
  includeUnpinnedIdsFilterFn,
  useFilterPipeline,
} from '../../../lib/filtering'
import { useDebounce } from '../../../lib/useDebounce'
import { useStableColor } from '../../../lib/useStableColor'
import { DownloadButton } from '../../DownloadButton'
import { ChartableDataSeries, SurveyDataChart } from '../../SurveyDataChart'
import { ViewerHeader } from '../ViewerHeader'
import { downloadDataFile } from '../../../lib/downloadDataFile'
import { ColDef } from 'ag-grid-community'

export interface LongChangeChartProps {
  project: NonNullable<GetLongChangeChartOverviewQuery['getProject']>
  longChanges: NonNullable<GetLongChangeDataQuery['getLongChange']>[]
  onActiveLongChangesChange: (longProfileIds: string[]) => void
  onNavigate: (string) => void
}

export const LongChangeChart: React.FC<LongChangeChartProps> = ({
  project,
  longChanges,
  onActiveLongChangesChange,
  onNavigate,
}) => {
  const theme = useTheme()

  const [longChangeGridApis, setLongProfileGridApis] = useState<DataGridApis>()

  const [assignStableLongChangeColor] = useStableColor()

  const {
    convertRiverDistance,
    formatRiverDistanceHeader,
    convertVolume,
    formatVolumeHeader,
    convertSurveyDistance,
    formatSurveyDistanceHeader,
    convertArea,
    formatAreaHeader,
  } = useFormats(project as UnitsSettings)

  const [fields, setFields] = useFields({
    riverDistanceMax: null as number | null,
    riverDistanceMin: null as number | null,
    selectedLongChangeIds: [] as string[],
    pinnedLongChangeIds: [] as string[],
    invertX: true,
  })

  const errors = someValues(() => {
    const riverDistance =
      fields.riverDistanceMax !== null &&
      fields.riverDistanceMin !== null &&
      fields.riverDistanceMax < fields.riverDistanceMin

    return {
      riverDistance,
    }
  })

  const { riverDistanceMax, riverDistanceMin, pinnedLongChangeIds, selectedLongChangeIds, invertX } = fields

  const [debouncedRiverDistanceMax] = useDebounce(riverDistanceMax, 250)
  const [debouncedRiverDistanceMin] = useDebounce(riverDistanceMin, 250)

  // LONG Change DATA COMPUTATION

  const activeLongChanges = useFilterPipeline(EMPTY_ARRAY_INPUT)
    .filter(includePinnedIdsFilterFn, {
      source: longChanges,
      ids: pinnedLongChangeIds,
    })
    .filter(includeUnpinnedIdsFilterFn, {
      source: longChanges,
      ids: selectedLongChangeIds,
    })
    .filter(addColorFilterFn, {
      assignColorFn: assignStableLongChangeColor,
    })
    // .filter(riverDistanceFilterFn, {
    //   min: debouncedRiverDistanceMin,
    //   max: debouncedRiverDistanceMax,
    //   convertRiverDistance,
    // })
    .output()

  const unpinnedLongChangeIds = useMemo(
    () => selectedLongChangeIds.filter((id) => !pinnedLongChangeIds.includes(id)),
    [pinnedLongChangeIds, selectedLongChangeIds]
  )

  // CHART DATA

  const [chartData, xScaleMin, xScaleMax] = useMemo<
    [ChartableDataSeries[], number | undefined, number | undefined]
  >(() => {
    if (activeLongChanges.length === 0) return [[], undefined, undefined]

    const transformToChartData = ({
      id,
      name: label,
      color,
      points,
    }: (typeof activeLongChanges)[number]): ChartableDataSeries => ({
      id,
      label,
      color,
      data: points.items.map(({ downstreamRiverDistance, controlVolumeChange }) => ({
        x: convertRiverDistance(downstreamRiverDistance),
        y: convertVolume(controlVolumeChange),
      })),
    })

    const longChangesChartData = activeLongChanges.map(transformToChartData)

    const chartAreaXValues = longChangesChartData.reduce<number[]>(
      (acc, { data }) => [...acc, ...data.map(({ x }) => x)],
      []
    )
    const xScaleMin = Math.min(...chartAreaXValues)
    const xScaleMax = Math.max(...chartAreaXValues)
    return [[...longChangesChartData], xScaleMin, xScaleMax]
  }, [activeLongChanges, convertVolume, convertRiverDistance])

  // PINNING EVENTS

  const handlePinAllData = () => {
    setFields.$.pinnedLongChangeIds([...pinnedLongChangeIds, ...unpinnedLongChangeIds])
    longChangeGridApis?.api?.deselectAll()
  }

  // DOWNLOAD

  const handleDownloadDataFile = (fileType: 'xlsx' | 'csv') => {
    const data = activeLongChanges.reduce((acc, longChange) => {
      return [
        ...acc,
        ...longChange.points.items.map<Record<string, unknown>>((point) => {
          return {
            'Longitudinal Change': longChange.name,
            'Downstream River Distance': convertRiverDistance(point.downstreamRiverDistance),
            'Upstream River Distance': convertRiverDistance(point.upstreamRiverDistance),
            'New Area': convertArea(point.newArea),
            'Old Area': convertArea(point.oldArea),
            'New Downstream Top Width': convertSurveyDistance(point.newDownstreamTopWdth),
            'Old Downstream Top Width': convertSurveyDistance(point.oldDownstreamTopWidth),
            'Control Volume Change': convertVolume(point.controlVolumeChange),
            'Downstream Bed Elevation Change': convertSurveyDistance(point.downstreamBedElevationChange),
            'Average Bed Elevation Change': convertSurveyDistance(point.averageBedElevationChange),
            'Weighted Bed Change': convertSurveyDistance(point.weightedBedChange),
          }
        }),
      ]
    }, [] as Record<string, unknown>[])

    downloadDataFile({ data, fileName: 'LongChange_Export', fileType })
  }

  const formattedAttributeColDefs = longChangeAttributeColumnDefs.map<ColDef>((colDef) => {
    if (['upstream', 'downstream'].includes(colDef.field as string)) {
      return {
        ...colDef,
        headerName: formatRiverDistanceHeader(colDef.headerName as string),
      }
    } else if (['referencePlaneOffset'].includes(colDef.field as string)) {
      return {
        ...colDef,
        headerName: formatSurveyDistanceHeader(colDef.headerName as string),
      }
    } else {
      return { ...colDef }
    }
  })

  // RENDER

  return (
    <>
      <ViewerHeader
        title="Longitudinal Change Viewer"
        helpPage="long_profiles.html#viewer"
        project={project as Project}
        onNavigate={onNavigate}
      />
      <Column height="100%" width="100%" p={2}>
        <Row flex={1} spacing={2}>
          <Column width="20em" spacing={2}>
            {/* <UpstreamDownstreamTextFields
              riverDistanceUnitsAbbr={project.riverDistanceUnits}
              onDistancesChange={(upstream: number | null, downstream: number | null) => noop}
              onValidateChange={(boolean) => noop}
            /> */}
            <Box flex={1}>
              <DataGrid<(typeof project.longChanges)[number]>
                columnDefs={longChangesColumnDefs}
                rowData={project.longChanges.filter((lc) => lc.state === FileStateEnum.Complete)}
                onSelectedRowsChanged={(rows) => {
                  const rowIds = rows.map(extractId)
                  setFields.$.selectedLongChangeIds(rowIds)
                  onActiveLongChangesChange(rowIds)
                }}
                rowSelection="multiple"
                onGridApisReady={setLongProfileGridApis}
              />
              <Box paddingTop={1}>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={handlePinAllData}
                  disabled={unpinnedLongChangeIds.length === 0}
                >
                  Pin All Longitudinal Changes
                </Button>
              </Box>
            </Box>
            <Box flex={1} />
          </Column>
          <Column flex={2}>
            <Row>
              <Box minHeight="3em" flex={1}>
                {activeLongChanges.map(({ id, name, color, pinned }) => (
                  <Chip
                    key={id}
                    label={name}
                    color={color}
                    pinned={pinned}
                    onDelete={
                      pinned
                        ? () => {
                            setFields.$.pinnedLongChangeIds(without(pinnedLongChangeIds, id))
                          }
                        : undefined
                    }
                  />
                ))}
              </Box>
              {activeLongChanges.length > 0 && (
                <DownloadButton
                  sx={{ color: theme.palette.primary.main }}
                  onDownloadXlsx={() => handleDownloadDataFile('xlsx')}
                  onDownloadCsv={() => handleDownloadDataFile('csv')}
                />
              )}
            </Row>
            <Box flex={1}>
              <SurveyDataChart
                data={chartData}
                // onChartMethodsReady={setChartMethods}
                xLegend={formatRiverDistanceHeader('River Distance')}
                yLegend={formatVolumeHeader('Cumulative Volume Change')}
                xScaleDescends={invertX}
                xScaleMin={xScaleMin}
                xScaleMax={xScaleMax}
              />
            </Box>
          </Column>
        </Row>
        <Row flex={1} maxHeight={200} paddingTop={2}>
          <DataGrid<(typeof longChanges)[number]> columnDefs={formattedAttributeColDefs} rowData={activeLongChanges} />
        </Row>
      </Column>
    </>
  )
}
