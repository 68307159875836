import React from 'react'
import { IconButton, Stack, Tooltip } from '@mui/material'
import { Source, LineAxis, TrendingDown, ChangeHistory, ViewInAr } from '@mui/icons-material'

export interface ChartButtonbarProps {
  projectId: string
  onNavigate: (uri: string) => void
}

export const ChartButtonBar: React.FC<ChartButtonbarProps> = ({ projectId, onNavigate }) => {
  return (
    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}>
      <Tooltip title="Project Details">
        <IconButton size="small" onClick={() => onNavigate(`/projects/${projectId}`)}>
          <Source />
        </IconButton>
      </Tooltip>
      <Tooltip title="Cross Section Plot">
        <IconButton size="small" onClick={() => onNavigate(`/projects/${projectId}/cross-section`)}>
          <LineAxis />
        </IconButton>
      </Tooltip>
      <Tooltip title="Area Change">
        <IconButton size="small" onClick={() => onNavigate(`/projects/${projectId}/area-change`)}>
          <ChangeHistory />
        </IconButton>
      </Tooltip>
      <Tooltip title="Long Profile">
        <IconButton size="small" onClick={() => onNavigate(`/projects/${projectId}/long-profile`)}>
          <TrendingDown />
        </IconButton>
      </Tooltip>
      <Tooltip title="Long Change">
        <IconButton size="small" onClick={() => onNavigate(`/projects/${projectId}/long-change`)}>
          <ViewInAr />
        </IconButton>
      </Tooltip>
    </Stack>
  )
}
