import { ArrowBack } from '@mui/icons-material'
import { Box, Button, CircularProgress, Stack, Typography, useTheme } from '@mui/material'
import { HelpButton } from '../HelpButton'
import { Project } from '../../schema/base.types'

export interface ViewerHeaderProps {
  title: string
  loading?: boolean
  helpPage: string
  project: Project
  onNavigate: (string) => void
}

export const ViewerHeader: React.FC<ViewerHeaderProps> = ({ title, helpPage, project, onNavigate, loading }) => {
  const theme = useTheme()
  return (
    <Stack
      direction={'row'}
      component="header"
      padding={2}
      sx={{ backgroundColor: theme.palette.primary.light, color: theme.palette.primary.contrastText }}
    >
      <Stack flexGrow={1}>
        <Box>
          <Typography
            variant="overline"
            sx={{
              fontWeight: 'bold',
              cursor: 'pointer',
              '&:hover': { textDecoration: 'underline' },
            }}
            onClick={() => onNavigate(`/projects/${project.id}`)}
          >
            {project?.name}
          </Typography>
        </Box>
        <Typography variant="h4" flexGrow={1} component="div">
          {title}
        </Typography>
      </Stack>
      <Stack>
        <Stack direction="row">
          <Button
            startIcon={<ArrowBack />}
            variant="text"
            size="small"
            color="inherit"
            onClick={() => onNavigate(`/projects/${project.id}`)}
            sx={{ my: 1 }}
          >
            {'Back to Project'}
          </Button>
          <HelpButton toolTip={`Learn more about the ${title}`} pageName={helpPage} color="inherit" />
        </Stack>
        {loading && (
          <Box flexGrow={0} textAlign={'right'}>
            <Typography variant="caption" color="inherit">
              Loading...
            </Typography>
            <CircularProgress color="inherit" size={20} />
          </Box>
        )}
      </Stack>
    </Stack>
  )
}
