import React from 'react'
import { Avatar, Box, Button, Container, Divider, Link, Paper, Stack, Typography, useTheme } from '@mui/material'

import { useNavigate } from 'react-router-dom'
import { useUpsertUserMutation } from '../../schema/operations'
import { User, UserInput } from '../../schema/base.types'
import { GetUserProfileLibrary } from '@xsonline/common'
import { UserProfileForm, UserProfileChange } from './UserProfileForm'
import { useProfile } from '../../hooks/useProfile'
import { useNotifications } from '../AppNotifications'
import { Edit, Person } from '@mui/icons-material'
import { useAuth0 } from '@auth0/auth0-react'
import { AppLoader } from '../AppLoader'

export interface UserProfileProps {
  myProfile: User
  navigate: (uri: string) => void
  onSubmit: (user: UserInput) => void
  isLoading: boolean
  pictureUrl?: string
}

/**
 * The container component. This is where we handle all the logic for the component.
 * @returns
 */
export const UserProfileContainer: React.FC = () => {
  const navigate = useNavigate()
  const { show } = useNotifications()
  const { user } = useAuth0()
  const { loading: loadingQuery, myProfile } = useProfile()
  const [upsertUserMutation, { loading: loadingMutation }] = useUpsertUserMutation({
    refetchQueries: [GetUserProfileLibrary],
    onCompleted: () => {
      show(`User Updated!`, { variant: 'success' })
    },
  })

  // TODO: we're not handling errors here
  const onSubmit = (user: UserInput) => {
    upsertUserMutation({
      variables: {
        user,
      },
    })
  }

  return (
    <UserProfile
      navigate={navigate}
      onSubmit={onSubmit}
      isLoading={loadingMutation || loadingQuery}
      myProfile={myProfile}
      pictureUrl={user?.picture}
    />
  )
}

/**
 * This is the presentation component. It should be as dumb as possible.
 */
export const UserProfile: React.FC<UserProfileProps> = ({ onSubmit, isLoading, myProfile, pictureUrl }) => {
  const theme = useTheme()
  const [isEditing, setIsEditing] = React.useState(false)
  const [editProfileState, setEditProfileState] = React.useState<UserProfileChange>({
    user: myProfile,
    valid: {},
    allValid: false,
    allMandatory: false,
  })

  React.useEffect(() => {
    if (!myProfile) return
    const { name, description, meta, affiliation, socialUrl } = myProfile
    setEditProfileState({
      user: { name, description, meta, affiliation, socialUrl },
      valid: {},
      allValid: false,
      allMandatory: false,
    })
  }, [myProfile])

  if (isLoading)
    return <AppLoader title="Loading user details" subtitle="Please wait while we load the the user details." />
  if (isEditing && editProfileState) {
    return (
      <Container maxWidth="sm">
        <Paper sx={{ my: 4, minHeight: '60vh', display: 'flex', flexDirection: 'column', height: '100%' }}>
          <Stack
            direction="row"
            sx={{ p: 2, backgroundColor: theme.palette.primary.light, color: theme.palette.primary.contrastText }}
          >
            <Typography variant="h5">Edit User Profile</Typography>
          </Stack>
          <Stack sx={{ p: 2, my: 2, flexGrow: 1 }}>
            <UserProfileForm onChange={setEditProfileState} profileState={editProfileState} />
            <Box sx={{ flexGrow: 1 }} />
            <Stack direction="row" spacing={2}>
              <Button onClick={() => setIsEditing(false)}>Cancel</Button>
              <div style={{ flexGrow: 1 }} />
              <Button
                disabled={!editProfileState.allMandatory || !editProfileState.allValid || isLoading}
                onClick={() => {
                  onSubmit(editProfileState.user)
                  setIsEditing(false)
                }}
              >
                {isLoading ? 'Working...' : 'Submit'}
              </Button>
            </Stack>
          </Stack>
        </Paper>
      </Container>
    )
  }

  return (
    <Container maxWidth="sm">
      <Paper sx={{ my: 4, pb: 4, minHeight: '60vh' }}>
        <Stack
          direction="row"
          sx={{ p: 2, backgroundColor: theme.palette.primary.light, color: theme.palette.primary.contrastText }}
        >
          <Stack flexGrow={1}>
            <Typography variant="overline">User Profile</Typography>
            <Typography variant="h5">{myProfile.name}</Typography>
            {myProfile.affiliation && myProfile.affiliation.trim().length > 0 && (
              <Typography variant="caption">{myProfile.affiliation}</Typography>
            )}
          </Stack>
          <Stack>
            <Stack direction="row">
              <Button startIcon={<Edit />} onClick={() => setIsEditing(true)} color="inherit">
                Edit
              </Button>
            </Stack>
          </Stack>
        </Stack>
        <Stack direction="row" spacing={2} sx={{ p: 2, my: 2 }}>
          <Stack>
            <Avatar src={pictureUrl} sx={{ width: 100, height: 100 }}>
              <Person />
            </Avatar>
          </Stack>
          <Stack>
            {myProfile.socialUrl && (
              <>
                <Typography variant="overline" component="div">
                  Social URL
                </Typography>
                <Typography>
                  <Link href={myProfile.socialUrl} target="_blank">
                    {myProfile.socialUrl}
                  </Link>
                </Typography>
              </>
            )}
            <Typography variant="overline" component="div">
              Bio
            </Typography>
            <Typography sx={{ whiteSpace: 'pre-line' }}>{myProfile.description}</Typography>
          </Stack>
        </Stack>
      </Paper>
    </Container>
  )
}
