import { FieldPolicy, InMemoryCache } from '@apollo/client'
import { StrictTypedTypePolicies } from '../../schema/cache'
/*
  Use paginatedItemsFieldPolicy where field is paginated items which are serially retrieved until complete. e.g. { items: T[], ...rest }
  
  Use paginatedKeyedItemsFieldPolicy where the field is an object and each property is paginated items which are serially retrieved until complete. e.g. Record<string, { items: T[], ...rest }>

  In both cases don't forget to get the root item `id` when querying the paged items!
*/

const paginatedItemsFieldPolicy: FieldPolicy = {
  keyArgs: false,
  merge: (existing = {}, incoming) => {
    const merged = { ...existing, ...incoming }
    if (
      existing.items &&
      incoming.items &&
      (typeof incoming.total === 'undefined' || incoming.items.length < incoming.total)
    ) {
      /*
        We have existing items and incoming items, and total is less than incoming count.
        Having incoming item count which matches the a total indicates we should skip the
        merge (very possibly because it is a cache update, but should be safe regardless.)
      */
      merged.items = [...existing.items, ...incoming.items]
    }
    return merged
  },
}

// const paginatedKeyedItemsFieldPolicy: FieldPolicy = {
//   keyArgs: false,
//   merge: (existing = {}, incoming) => {
//     const merged = { ...existing, ...incoming }
//     Object.keys(incoming).forEach((key) => {
//       if (existing[key] && existing[key].items && incoming[key].items)
//         merged[key].items = [...existing[key].items, ...incoming[key].items]
//     })
//     return merged
//   },
// }

const typePolicies: StrictTypedTypePolicies = {
  User: {
    keyFields: ['id'],
  },
  PaginatedPoint2D: {
    fields: {
      items: paginatedItemsFieldPolicy,
    },
  },
}

export const cache = new InMemoryCache({
  possibleTypes: {
    DBObj: [
      'LongChange',
      'LongProfile',
      'PointCloud',
      'Project',
      'ReferencePlane',
      'Selection',
      'Survey',
      'User',
      'XSInstance',
    ],
    GeoObj: ['SpatialDataFile'],
    Pagination: ['PagniatedPoint2D'],
  },
  typePolicies,
})
