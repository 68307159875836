import { uniq, sortBy } from 'lodash'
import { LongProfile, PaginatedPoint2D, Point2D, ReferencePlane } from '../../schema/base.types'
import produce from 'immer'
import { HasId } from '../../lib/filtering'

export interface ChartableValue {
  x: number
  y: number
  absoluteY?: number
}

export interface HasPoints {
  points: PaginatedPoint2D
}

const getInterpolatedElevation = (items: ChartableValue[], targetRiverDistance: number): number => {
  if (targetRiverDistance === items[items.length - 1].x) return items[items.length - 1].y // last value exactly, no interpolation

  const lowerIndex = items.findIndex(
    (value, index) => targetRiverDistance >= value.x && targetRiverDistance < items[index + 1].x
  )

  const lowerValue = items[lowerIndex]
  const upperValue = items[lowerIndex + 1]

  const distanceRange = upperValue.x - lowerValue.x
  const elevationRange = upperValue.y - lowerValue.y

  const relativePosition = (targetRiverDistance - lowerValue.x) / distanceRange

  return relativePosition * elevationRange + lowerValue.y
}

export const relativeToReferencePlaneFilterFn = <TInput extends HasId & HasPoints>(
  input: TInput[],
  args: {
    referencePlane: HasPoints | null | undefined
  }
) => {
  const { referencePlane } = args
  if (typeof referencePlane === 'undefined') return input // do not apply filter
  if (referencePlane === null) return [] // relative mode, but plane is unspecified
  if (referencePlane.points.items.length < 2) return [] // should not happen, but guard against too few reference plane values

  // TODO: is data pre-sort required?

  const referencePlaneValues = referencePlane.points.items
  const minX = referencePlaneValues[0].x
  const maxX = referencePlaneValues[referencePlaneValues.length - 1].x

  return input.map((planeOrProfile) => {
    const adjustedValues: (Point2D & { absoluteY: number })[] = []

    planeOrProfile.points.items.forEach(({ y, x, ...restValue }) => {
      if (x < minX || x > maxX) return // exclude points outside of reference plane limits

      adjustedValues.push({
        x,
        y: y - getInterpolatedElevation(referencePlaneValues, x),
        absoluteY: y, // keep property for possible export
        ...restValue,
      })
    })

    return {
      ...planeOrProfile,
      points: { ...planeOrProfile.points, items: adjustedValues },
    }
  })
}

export const riverDistanceFilterFn = <T extends HasId & HasPoints>(
  input: T[],
  args: {
    min: number | null
    max: number | null
    convertRiverDistance: (number) => number
  }
): T[] => {
  const { min, max, convertRiverDistance } = args

  if (min === null && max === null) return input

  const unconvertedMin = min === null ? -Infinity : min / convertRiverDistance(1)
  const unconvertedMax = max === null ? Infinity : max / convertRiverDistance(1)

  return input.map((row) =>
    produce(row, (draft) => {
      draft.points.items = row.points.items.filter(({ x }) => x > unconvertedMin && x < unconvertedMax)
    })
  )
}

export const densifyReferencePlanesFilterFn = <TInput extends HasId & HasPoints>(
  input: TInput[],
  args: {
    longProfiles: HasPoints[]
  }
) => {
  const { longProfiles } = args

  const allLongProfileRiverDistances = uniq(
    longProfiles.reduce<number[]>((acc, { points: { items } }) => [...acc, ...items.map(({ x }) => x)], [])
  )

  return input.map((referencePlane) => {
    const referencePlaneRiverDistances = referencePlane.points.items.map(({ x }) => x)
    const minX = referencePlane.points.items[0].x
    const maxX = referencePlane.points.items[referencePlane.points.items.length - 1].x

    const computeRiverDistances = allLongProfileRiverDistances.filter(
      (x) => x < maxX && x > minX && !referencePlaneRiverDistances.includes(x)
    )

    const itemTypename = referencePlane.points.items[0].__typename
    const items = sortBy(
      [
        ...referencePlane.points.items,
        ...computeRiverDistances.map((x) => ({
          __typename: itemTypename,
          x,
          y: getInterpolatedElevation(referencePlane.points.items, x),
        })),
      ],
      ({ x }) => x
    )

    return {
      ...referencePlane,
      points: {
        __typename: referencePlane.points.__typename,
        items,
      },
    }
  })
}
