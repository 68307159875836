import XLSX from 'xlsx'

export interface SaveDataFileParams {
  data: Record<string, unknown>[]
  fileName: string
  sheetName?: string
  fileType: 'xlsx' | 'csv'
}

export const downloadDataFile = ({ data, fileName, sheetName = 'Export', fileType }: SaveDataFileParams): void => {
  const wb = XLSX.utils.book_new()
  const ws = XLSX.utils.json_to_sheet(data)
  XLSX.utils.book_append_sheet(wb, ws, sheetName)
  XLSX.writeFile(wb, `${fileName}.${fileType}`)
}
