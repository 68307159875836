import React, { useState } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import { CreateLongProfileInput, Selection, Survey } from '../../schema/base.types'
import { DESCRIPTION_MAX_LENGTH, NAME_MAX_LENGTH, validateName } from '../componentHelpers'
import { ReferenceStations } from '../referenceStations'
import { HelpButton } from '../HelpButton'

export interface LongProfileProps {
  surveys: Survey[]
  selections: Selection[]
  onSubmit: (item: CreateLongProfileInput) => void
  onClose: () => void
  open: boolean
  referenceStations: string[]
}

export const CreateLongProfileDialog: React.FC<LongProfileProps> = ({
  surveys,
  selections,
  onSubmit,
  onClose,
  open,
  referenceStations,
}) => {
  const theme = useTheme()

  const [[name, nameValid], setName] = React.useState<[string, boolean | null]>(['', false])
  const [elevationType, setElevationType] = useState('minimum')
  const [surveyId, setSurveyId] = useState<string>(surveys.length > 0 ? surveys[0].id : '')
  const [allCrossSections, setAllCrossSections] = useState('allCrossSections')
  const [allStations, setAllStations] = useState('allStations')
  const [leftStation, setLeftStation] = useState('')
  const [rightStation, setRightStation] = useState('')
  const [selectionId, setSelectionId] = useState<string>(selections.length > 0 ? selections[0].id : '')
  const [description, setDescription] = useState('')
  const [colors, setColors] = useState<[string, string]>(['#000000', '#ffffff'])
  const [validated, setValidated] = useState(false)

  const validateAllCrossSections = (value: string): boolean | null => {
    if (value !== 'allCrossSections') {
      return selectionId !== ''
    }

    return false
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle
        sx={{
          backgroundColor: theme.palette.secondary.main,
          color: theme.palette.secondary.contrastText,
        }}
      >
        Create New Long Profile
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" sx={{ fontSize: '0.8rem', pt: 1 }}>
          Long profiles are used to visualize the elevation of the river bed along a series of cross sections. Select a
          survey as well as a method for selection elevation along the cross sections. The entire cross sections can be
          considered, or only a subset between a left and right station. Long profiles are calculated asynchronously.
          Once you click save, it might take several minutes before the long profile is available to use.
        </Typography>
        <TextField
          sx={{ pb: 1 }}
          autoFocus
          value={name}
          required
          margin="normal"
          id="name"
          label="Name"
          type="text"
          fullWidth
          inputProps={{ maxLength: NAME_MAX_LENGTH }}
          onChange={(e) => setName([e.target.value, validateName(e.target.value)])}
          error={nameValid === false}
        />
        <FormControl fullWidth sx={{ pb: 2 }}>
          <InputLabel>Long Profile Elevation Type</InputLabel>
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            value={elevationType}
            onChange={(e) => setElevationType(e.target.value as string)}
            label="Long Profile Elevation Type"
            required
            size="small"
          >
            <MenuItem value={'averagebedelevation'}>Average Bed Elevation</MenuItem>
            <MenuItem value={'centreofmass'}>Centre Of Mass</MenuItem>
            <MenuItem value={'maximum'}>Maximum Elevation</MenuItem>
            <MenuItem value={'minimum'}>Minimum Elevation (Thalweg)</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel>Survey</InputLabel>
          <Select
            label="Survey"
            value={surveyId}
            onChange={(e) => setSurveyId(e.target.value)}
            fullWidth
            size="small"
            required
          >
            {surveys.map((item, idx) => {
              return (
                <MenuItem value={item.id} key={`menuItem${idx}`}>
                  {item.name}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
        <Box border={1} marginTop={1} borderColor="primary.main" borderRadius={1} pl={2} pr={2} pb={2}>
          <FormControl component="fieldset">
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue={allCrossSections}
              name="radio-buttons-group"
              onChange={(e) => setAllCrossSections(e.target.value)}
            >
              <FormControlLabel
                value="allCrossSections"
                control={<Radio />}
                label="Include all cross sections from the selected survey"
              />
              <FormControlLabel
                value="selectedCrossSections"
                control={<Radio />}
                label="Only include cross sections that also occur in the selection:"
                disabled={selections.length < 1}
              />
            </RadioGroup>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel>Selection</InputLabel>
            <Select
              value={selectionId}
              onChange={(e) => setSelectionId(e.target.value)}
              disabled={allCrossSections === 'allCrossSections'}
              size="small"
              label="Selection"
            >
              {selections.map((item, idx) => {
                return (
                  <MenuItem key={`menuItem-${idx}`} value={item.id}>
                    {item.name}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </Box>
        <Box border={1} marginTop={1} borderColor="primary.main" borderRadius={1} pl={2} pr={2} pb={2}>
          <FormControl component="fieldset">
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue={allStations}
              name="station-filtering-group"
              onChange={(e) => setAllStations(e.target.value)}
            >
              <FormControlLabel
                value="allStations"
                control={<Radio />}
                label="Include all stations along the cross sections"
                disabled={referenceStations.length < 2}
              />
              <FormControlLabel
                value="selectedStations"
                control={<Radio />}
                label="Only include cross sections between the stations:"
                disabled={referenceStations.length < 2}
              />
            </RadioGroup>
          </FormControl>
          <ReferenceStations
            disabled={allStations === 'allStations'}
            fields={referenceStations}
            leftStation={leftStation}
            rightStation={rightStation}
            onChangeLeftStation={(value) => setLeftStation(value)}
            onChangeRightStation={(value) => setRightStation(value)}
          />
        </Box>
        <TextField
          id="outlined-multiline-flexible"
          label="Description"
          multiline
          rows={3}
          fullWidth
          margin="normal"
          inputProps={{ maxLength: DESCRIPTION_MAX_LENGTH }}
          onChange={(e) => setDescription(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <HelpButton toolTip="Learn more about long profiles" pageName="long_profiles" />
        <Button onClick={onClose}>Cancel</Button>
        <Box sx={{ flexGrow: 1 }} />
        <Button
          onClick={() => {
            onSubmit({
              name: name.trim(),
              description: description.slice(0, DESCRIPTION_MAX_LENGTH),
              elevationType,
              surveyId,
              selectionId: allCrossSections === 'allCrossSections' ? null : selectionId,
              leftStation: allStations === 'allStations' ? null : leftStation,
              rightStation: allStations === 'allStations' ? null : rightStation,
            })
          }}
          variant="contained"
          disabled={!nameValid}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}
