import configureMeasurements, {
  length,
  area,
  volume,
  Measure,
  VolumeSystems,
  VolumeUnits,
  AreaSystems,
  AreaUnits,
  Unit,
} from 'convert-units'

const FEET_PER_MILE = length.systems.imperial?.mi?.to_anchor as number // 5280
const US_SURVEY_FEET_PER_FOOT = length.systems.imperial?.['ft-us']?.to_anchor as number // 1.000002
const SQUARE_FEET_PER_ACRE = area.systems.imperial?.ac?.to_anchor as number // 43560
const FL_OZ_PER_CUBIC_FOOT = volume.systems.imperial?.ft3?.to_anchor as number // 957.506

const SQUARE_FEET_PER_US_SURVEY_ACRE = SQUARE_FEET_PER_ACRE * US_SURVEY_FEET_PER_FOOT ** 2

type NewAreaUnits = AreaUnits | 'ft2-us' | 'ac-us'
const extendedArea: Measure<AreaSystems, NewAreaUnits> = {
  systems: {
    metric: {
      ...area.systems.metric,
    },
    imperial: {
      ...area.systems.imperial,
      'ft2-us': {
        name: {
          singular: 'US Survey Square Foot',
          plural: 'US Survey Square Feet',
        },
        to_anchor: US_SURVEY_FEET_PER_FOOT ** 2, // anchor is square feet
      },
      'ac-us': {
        name: {
          singular: 'US Survey Acre',
          plural: 'US Survey Acres',
        },
        to_anchor: SQUARE_FEET_PER_US_SURVEY_ACRE, // anchor is square feet
      },
    },
  },
  anchors: {
    ...area.anchors,
  },
}

type NewVolumeUnits = VolumeUnits | 'ft3-us' | 'af' | 'af-us' | 'mi3'
const extendedVolume: Measure<VolumeSystems, NewVolumeUnits> = {
  systems: {
    metric: {
      ...volume.systems.metric,
    },
    imperial: {
      ...volume.systems.imperial,
      'ft3-us': {
        name: {
          singular: 'US Survey Cubic Foot',
          plural: 'US Survey Cubic Feet',
        },
        to_anchor: FL_OZ_PER_CUBIC_FOOT * US_SURVEY_FEET_PER_FOOT ** 3, // anchor is FL oz
      },
      af: {
        name: {
          singular: 'Acre-Foot',
          plural: 'Acre-Feet',
        },
        to_anchor: FL_OZ_PER_CUBIC_FOOT * SQUARE_FEET_PER_ACRE, // anchor is FL oz
      },
      'af-us': {
        name: {
          singular: 'US Survey Acre-Foot',
          plural: 'US Survey Acre-Feet',
        },
        to_anchor: FL_OZ_PER_CUBIC_FOOT * SQUARE_FEET_PER_US_SURVEY_ACRE, // anchor is FL oz
      },
      mi3: {
        name: {
          singular: 'Cubic Mile',
          plural: 'Cubic Miles',
        },
        to_anchor: FL_OZ_PER_CUBIC_FOOT * FEET_PER_MILE ** 3, // anchor is FL oz
      },
    },
  },
  anchors: {
    ...volume.anchors,
  },
}

const convert = configureMeasurements({
  length,
  area: extendedArea as any,
  volume: extendedVolume as any,
})

const getUnitPlural = (unit) => convert().describe(unit).plural

export { convert, getUnitPlural }
