import { JSONObject } from './types'

/**
 * Turn a string and a guid into a safe (ish) filename the user can download
 * Note: this is just the basename. You need to add your own suffix
 * @param input
 * @param guid
 * @returns
 */
export function createSafeFileName(input: string, guid: string): string {
  // Remove unwanted characters
  const cleanInput = input.replace(/[^a-zA-Z0-9-_]/g, '')

  // Add the GUID to the end of the file name
  return `${cleanInput}-${guid}`
}

/**
 * Recursively remove a key from an object. Useful for stripping out the __typename from graphql results
 * @param obj
 * @param removeKey
 * @returns
 */
export function removeKeyRecursive(obj: unknown, removeKey: string): unknown {
  if (obj === null || obj === undefined) return obj

  if (Array.isArray(obj)) {
    return obj.map((item) => removeKeyRecursive(item, removeKey))
  } else if (obj.constructor === Object) {
    const newObj = {}
    Object.keys(obj).forEach((k) => {
      if (k !== removeKey && typeof obj[k] !== 'undefined') {
        newObj[k] = removeKeyRecursive(obj[k], removeKey)
      }
    })
    return newObj
  } else {
    return obj
  }
}

/**
 * Explicitly strip all the __typename fields from a graphql result
 * @param input
 * @returns
 */
export function stripTypeNames(input: unknown): unknown {
  // if it's not an object just return it
  if (typeof input !== 'object') {
    return input
  }
  const result = removeKeyRecursive(input, '__typename')
  return result
}

/**
 * Encode a json object as a base64 string
 * @param {*} text
 */
export function jsonToBase64(json: JSONObject): string {
  const jsonString = JSON.stringify(json)
  return Buffer.from(jsonString).toString('base64')
}
/**
 * Decode a base64 string back to a json object
 * @param {*} b64String
 */
export function base64ToJson(base64: string): JSONObject {
  return JSON.parse(Buffer.from(base64, 'base64').toString())
}
