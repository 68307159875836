import { RouteObject } from 'react-router-dom'
import { AppContainer } from './App'
import { DataLibraryContainer } from './components/DataLibrary/DataLibrary.container'
import { UserProfileContainer } from './components/UserProfile/UserProfile'
import { ProjectDetailsContainer } from './components/Project/ProjectDetails.container'
import { LongProfileContainer } from './components/charts/long_profile/LongProfileContainer'
import { LongChangeChartContainer } from './components/charts/long_change/LongChangeChartContainer'
import { AreaChangeChartContainer } from './components/charts/area_change/AreaChangeChartContainer'
import { CrossSectionChartContainer } from './components/charts/cross_section/CrossSectionChartContainer'
import { MapPageContainer } from './components/Map/MapPage'

// https://reactrouter.com/en/main/hooks/use-routes
const routes: RouteObject[] = [
  {
    path: '/',
    element: <AppContainer requireAuth innerChild={<DataLibraryContainer />} />,
  },
  { path: 'map', element: <AppContainer innerChild={<MapPageContainer />} /> },
  { path: 'projects', element: <AppContainer innerChild={<DataLibraryContainer />} /> },
  // These all get the project context wrapper
  { path: 'projects/:projIdParam', element: <AppContainer innerChild={<ProjectDetailsContainer />} /> },
  {
    path: 'projects/:projIdParam/long-profile',
    element: <AppContainer toolWrap innerChild={<LongProfileContainer />} />,
  },
  {
    path: 'projects/:projIdParam/long-change',
    element: <AppContainer toolWrap innerChild={<LongChangeChartContainer />} />,
  },
  {
    path: 'projects/:projIdParam/area-change',
    element: <AppContainer toolWrap innerChild={<AreaChangeChartContainer />} />,
  },
  {
    path: 'projects/:projIdParam/cross-section',
    element: <AppContainer toolWrap innerChild={<CrossSectionChartContainer />} />,
  },
  // These are not wrapped in the project context
  { path: 'profile', element: <AppContainer innerChild={<UserProfileContainer />} /> },
]

export default routes
