import React, { useState } from 'react'
import Box from '@mui/material/Box'
import {
  CreateLongProfileInput,
  CreateReferencePlaneInput,
  FileStateEnum,
  Project,
  ProjectUpdate,
  SelectionInput,
  SurveyInput,
  UploadTarget,
} from '../../schema/base.types'
import { CreateLongChangeInput } from '../../schema/base.types'
import { useNavigate, useParams } from 'react-router-dom'
import {
  useCopyProjectMutation,
  useCreateLongChangeMutation,
  useCreateLongProfileMutation,
  useCreateReferencePlaneMutation,
  useCreateSelectionMutation,
  useCreateSurveyMutation,
  useDeleteLongChangeMutation,
  useDeleteLongProfileMutation,
  useDeleteProjectMutation,
  useDeleteReferencePlaneMutation,
  useDeleteSelectionMutation,
  useDeleteSurveyMutation,
  useGetProjectDetailsQuery,
  useUpdateLongChangeMutation,
  useUpdateLongProfileMutation,
  useUpdateProjectMutation,
  useUpdateReferencePlaneMutation,
  useUpdateSelectionMutation,
  useUpdateSurveyMutation,
  useUploadProjectCenterlineCompleteMutation,
  useUploadProjectCenterlineMutation,
  useUploadProjectCrossSectionsCompleteMutation,
  useUploadProjectCrossSectionsMutation,
  useUploadReferencePlaneCompleteMutation,
  useUploadReferencePlaneMutation,
} from '../../schema/operations'
import { GenericDBItem } from '../../types'
import { useNotifications } from '../AppNotifications'
import { GetProjectDetails, GetUserProfileLibrary } from '@xsonline/common'
import { ProjectDetails } from './ProjectDetails'
import { AppLoader } from '../AppLoader'
import { useProfile } from '../../hooks/useProfile'

export interface ProjectDetailsListProps {
  project: Project
  onDeleteProject: () => void
  onUpdateProject: (dbItem: ProjectUpdate) => void
  onCopyProject: (name: string, description: string) => void
  onCreateSurvey(dbItem: SurveyInput): void
  onEditSurvey: (dbItem: GenericDBItem) => void
  onDeleteSurvey: (dbItem: GenericDBItem) => void
  onCreateSelection: (dbItem: SelectionInput) => void
  onEditSelection: (dbItem: GenericDBItem) => void
  onDeleteSelection: (dbItem: GenericDBItem) => void
  onCreateReferencePlane: (dbItem: CreateReferencePlaneInput) => void
  onEditReferencePlane: (dbItem: GenericDBItem) => void
  onDeleteReferencePlane: (dbItem: GenericDBItem) => void
  onCreateLongProfile: (dbItem: CreateLongProfileInput) => void
  onEditLongProfile: (dbItem: GenericDBItem) => void
  onDeleteLongProfile: (dbItem: GenericDBItem) => void
  onCreateLongChange: (dbItem: CreateLongChangeInput) => void
  onEditLongChange: (dbItem: GenericDBItem) => void
  onDeleteLongChange: (dbItem: GenericDBItem) => void
}

const NOPOLLSTATES: FileStateEnum[] = [FileStateEnum.Complete, FileStateEnum.Error]

export const ProjectDetailsContainer: React.FC = () => {
  const { projIdParam } = useParams()
  const navigate = useNavigate()
  const { show } = useNotifications()
  const { myProfile } = useProfile()
  const [needsPolling, setNeedsPolling] = useState(false)
  const projectId = projIdParam as string

  //--------------------------------------------------------------------------------
  // Project Queries and Mutations

  const [deleteProjectMutation] = useDeleteProjectMutation({
    refetchQueries: [GetProjectDetails, GetUserProfileLibrary],
  })

  const projectDetailsQuery = useGetProjectDetailsQuery({
    variables: { projectId },
    onCompleted: (data) => {
      console.log('onCompleted projectDetailsQuery', data)
      // Set polling if we need to
      setNeedsPolling(
        !data?.getProject?.centerline ||
          !data?.getProject?.crossSections ||
          !NOPOLLSTATES.includes(data.getProject.centerline.state) ||
          !NOPOLLSTATES.includes(data.getProject.crossSections.state) ||
          data.getProject.referencePlanes.some((x) => !NOPOLLSTATES.includes(x.state)) ||
          data.getProject.longProfiles.some((x) => !NOPOLLSTATES.includes(x.state)) ||
          data.getProject.longChanges.some((x) => !NOPOLLSTATES.includes(x.state)) ||
          data.getProject.surveys.some((x) => !NOPOLLSTATES.includes(x.state))
      )
    },
    pollInterval: needsPolling ? 5000 : undefined,
    onError: (error) => {
      console.log('onError projectDetailsQuery', error)
    },
    skip: !projectId,
  })

  const onDeleteProject = () => {
    deleteProjectMutation({
      variables: {
        projectId,
      },
      onCompleted: (data) => {
        console.log('onCompleted deleteProjectMutation', data)
        navigate('/projects/')
      },
      onError: (error) => {
        show(`Error deleting project: ${error.message}`, { variant: 'error' })
      },
    })
  }

  const [copyProjectMutation] = useCopyProjectMutation({
    refetchQueries: [GetProjectDetails, GetUserProfileLibrary],
    onCompleted: (data) => {
      console.log('onCompleted copyProjectMutation', data)
      navigate(`/projects/${data?.copyProject?.id}`)
    },
    onError: (error) => {
      show(`Error copying project: ${error.message}`, { variant: 'error' })
    },
  })

  const onCopyProject = (name: string, description: string) => {
    copyProjectMutation({
      variables: {
        projectId,
        name,
        description,
      },
    })
  }

  const [updateProjectMutation] = useUpdateProjectMutation({
    refetchQueries: [GetProjectDetails, GetUserProfileLibrary],
    onCompleted: (data) => {
      show(`Project updated`, { variant: 'success', preventDuplicate: true, autoHideDuration: 3000 })
    },
    onError: (error) => {
      show(`Error updating project: ${error.message}`, { variant: 'error' })
    },
  })

  const onUpdateProject = (dbItem: ProjectUpdate) => {
    updateProjectMutation({
      variables: {
        projectId,
        project: dbItem,
      },
    })
  }

  //--------------------------------------------------------------------------------
  // Survey Mutations

  const [createSurveyMutation] = useCreateSurveyMutation({
    refetchQueries: [GetProjectDetails],
    onCompleted: (data) => {
      show(`Survey created`, { variant: 'success', preventDuplicate: true, autoHideDuration: 3000 })
    },
    onError: (error) => {
      show(`Error creating survey: ${error.message}`, { variant: 'error' })
    },
  })

  const [updateSurveyMutation] = useUpdateSurveyMutation({
    refetchQueries: [GetProjectDetails],
    onCompleted: (data) => {
      show(`Survey updated`, { variant: 'success', preventDuplicate: true, autoHideDuration: 3000 })
    },
    onError: (error) => {
      show(`Error updating survey: ${error.message}`, { variant: 'error' })
    },
  })

  const [deleteSurveyMutation] = useDeleteSurveyMutation({
    refetchQueries: [GetProjectDetails],
    onError: (error) => {
      show(`Error deleting survey: ${error.message}`, { variant: 'error' })
    },
  })

  const onCreateSurvey = (item: SurveyInput) => {
    createSurveyMutation({
      variables: {
        projectId,
        survey: item,
      },
    })
  }

  const onEditSurvey = (dbItem: GenericDBItem) => {
    updateSurveyMutation({
      variables: {
        projectId,
        surveyId: dbItem.id,
        genericInput: {
          name: dbItem.name,
          description: dbItem.description,
        },
      },
    })
  }

  const onDeleteSurvey = (dbItem: GenericDBItem) => {
    deleteSurveyMutation({
      variables: {
        projectId,
        surveyId: dbItem.id,
      },
    })
  }

  //--------------------------------------------------------------------------------
  // Selection Mutations

  const [createSelectionMutation] = useCreateSelectionMutation({
    refetchQueries: [GetProjectDetails],
    onCompleted: (data) => {
      show(`Selection created`, { variant: 'success', preventDuplicate: true, autoHideDuration: 3000 })
    },
    onError: (error) => {
      show(`Error creating selection: ${error.message}`, { variant: 'error' })
    },
  })

  const [updateSelectionMutation] = useUpdateSelectionMutation({
    refetchQueries: [GetProjectDetails],
    onCompleted: (data) => {
      show(`Selection updated`, { variant: 'success', preventDuplicate: true, autoHideDuration: 3000 })
    },
    onError: (error) => {
      show(`Error updating selection: ${error.message}`, { variant: 'error' })
    },
  })

  const [deleteSelectionMutation] = useDeleteSelectionMutation({
    refetchQueries: [GetProjectDetails],
    onError: (error) => {
      show(`Error deleting selection: ${error.message}`, { variant: 'error' })
    },
  })

  const onCreateSelection = (item: SelectionInput) => {
    createSelectionMutation({
      variables: {
        projectId,
        selection: item,
      },
    })
  }

  const onEditSelection = (dbItem: GenericDBItem) => {
    updateSelectionMutation({
      variables: {
        projectId,
        selectionId: dbItem.id,
        genericInput: {
          name: dbItem.name,
          description: dbItem.description,
        },
      },
    })
  }

  const onDeleteSelection = (dbItem: GenericDBItem) => {
    deleteSelectionMutation({
      variables: {
        projectId,
        selectionId: dbItem.id,
      },
    })
  }

  //--------------------------------------------------------------------------------
  // Reference Plane Mutations

  const [createReferencePlaneMutation] = useCreateReferencePlaneMutation({
    refetchQueries: [GetProjectDetails],
    onCompleted: (data) => {
      show(`Reference plane created`, { variant: 'success', preventDuplicate: true, autoHideDuration: 3000 })
    },
    onError: (error) => {
      show(`Error creating reference plane: ${error.message}`, { variant: 'error' })
    },
  })

  const [updateReferencePlaneMutation] = useUpdateReferencePlaneMutation({
    refetchQueries: [GetProjectDetails],
    onCompleted: (data) => {
      show(`Reference plane updated`, { variant: 'success', preventDuplicate: true, autoHideDuration: 3000 })
    },
    onError: (error) => {
      show(`Error updating reference plane: ${error.message}`, { variant: 'error' })
    },
  })

  const [deleteReferencePlaneMutation] = useDeleteReferencePlaneMutation({
    refetchQueries: [GetProjectDetails],
    onError: (error) => {
      show(`Error deleting reference plane: ${error.message}`, { variant: 'error' })
    },
  })

  const onCreateReferencePlane = (item: CreateReferencePlaneInput) => {
    createReferencePlaneMutation({
      variables: {
        projectId,
        referencePlane: item,
      },
    })
  }

  const onEditReferencePlane = (dbItem: GenericDBItem) => {
    updateReferencePlaneMutation({
      variables: {
        projectId,
        referencePlaneId: dbItem.id,
        genericInput: {
          name: dbItem.name,
          description: dbItem.description,
        },
      },
    })
  }

  const onDeleteReferencePlane = (dbItem: GenericDBItem) => {
    deleteReferencePlaneMutation({
      variables: {
        projectId,
        referencePlaneId: dbItem.id,
      },
    })
  }

  //--------------------------------------------------------------------------------
  // Long Profile Mutations

  const [createLongProfileMutation] = useCreateLongProfileMutation({
    refetchQueries: [GetProjectDetails],
    onCompleted: (data) => {
      show(`Long profile created`, { variant: 'success', preventDuplicate: true, autoHideDuration: 3000 })
    },
    onError: (error) => {
      show(`Error creating long profile: ${error.message}`, { variant: 'error' })
    },
  })

  const [updateLongProfileMutation] = useUpdateLongProfileMutation({
    refetchQueries: [GetProjectDetails],
    onCompleted: (data) => {
      show(`Long profile updated`, { variant: 'success', preventDuplicate: true, autoHideDuration: 3000 })
    },
    onError: (error) => {
      show(`Error updating long profile: ${error.message}`, { variant: 'error' })
    },
  })

  const [deleteLongProfileMutation] = useDeleteLongProfileMutation({
    refetchQueries: [GetProjectDetails],
    onError: (error) => {
      show(`Error deleting long profile: ${error.message}`, { variant: 'error' })
    },
  })

  const onCreateLongProfile = (item: CreateLongProfileInput) => {
    createLongProfileMutation({
      variables: {
        projectId,
        longProfile: item,
      },
    })
  }

  const onEditLongProfile = (dbItem: GenericDBItem) => {
    updateLongProfileMutation({
      variables: {
        projectId,
        longProfileId: dbItem.id,
        genericInput: {
          name: dbItem.name,
          description: dbItem.description,
        },
      },
    })
  }

  const onDeleteLongProfile = (dbItem: GenericDBItem) => {
    deleteLongProfileMutation({
      variables: {
        projectId,
        longProfileId: dbItem.id,
      },
    })
  }

  //--------------------------------------------------------------------------------
  // Long Change Mutations

  const [createLongChangeMutation] = useCreateLongChangeMutation({
    refetchQueries: [GetProjectDetails],
    onCompleted: (data) => {
      show(`Longitudinal change created`, { variant: 'success', preventDuplicate: true, autoHideDuration: 3000 })
    },
    onError: (error) => {
      show(`Error creating longitudinal change: ${error.message}`, { variant: 'error' })
    },
  })

  const [updateLongChangeMutation] = useUpdateLongChangeMutation({
    refetchQueries: [GetProjectDetails],
    onCompleted: (data) => {
      show(`Longitudinal change updated`, { variant: 'success', preventDuplicate: true, autoHideDuration: 3000 })
    },
    onError: (error) => {
      show(`Error updating longitudinal change: ${error.message}`, { variant: 'error' })
    },
  })

  const [deleteLongChangeMutation] = useDeleteLongChangeMutation({
    refetchQueries: [GetProjectDetails],
    onError: (error) => {
      show(`Error deleting longitudinal change: ${error.message}`, { variant: 'error' })
    },
  })

  const onCreateLongChange = (item: CreateLongChangeInput) => {
    createLongChangeMutation({
      variables: {
        projectId,
        longChange: item,
      },
    })
  }

  const onEditLongChange = (dbItem: GenericDBItem) => {
    updateLongChangeMutation({
      variables: {
        projectId,
        longChangeId: dbItem.id,
        genericInput: {
          name: dbItem.name,
          description: dbItem.description,
        },
      },
    })
  }

  const onDeleteLongChange = (dbItem: GenericDBItem) => {
    deleteLongChangeMutation({
      variables: {
        projectId,
        longChangeId: dbItem.id,
      },
    })
  }

  // --------------------------------------------------------------------------------
  // Upload mutations
  const [updateProjectCenterlineDone] = useUploadProjectCenterlineCompleteMutation({
    variables: {
      projectId,
    },
    refetchQueries: [GetProjectDetails],
  })

  const [updateProjectCrossSectionsDone] = useUploadProjectCrossSectionsCompleteMutation({
    variables: {
      projectId,
    },
    refetchQueries: [GetProjectDetails],
  })

  const [getUploadCenterlineUrl] = useUploadProjectCenterlineMutation({
    variables: {
      projectId,
    },
  })
  const [getUploadCrossSectionUrl] = useUploadProjectCrossSectionsMutation({
    variables: {
      projectId,
    },
  })

  const [_getUploadReferencePlaneUrl] = useUploadReferencePlaneMutation()

  const onUploadReferencePlane = (projectId: string, referencePlaneId: string): Promise<UploadTarget> => {
    return _getUploadReferencePlaneUrl({
      variables: {
        projectId,
        referencePlaneId,
      },
    }).then((res) => res?.data?.uploadReferencePlane as UploadTarget)
  }

  const [_useUploadReferencePlaneCompleteMutation] = useUploadReferencePlaneCompleteMutation()

  const onUploadReferencePlaneComplete = (projectId: string, referencePlaneId: string) =>
    _useUploadReferencePlaneCompleteMutation({
      variables: {
        projectId,
        referencePlaneId,
      },
      refetchQueries: [GetProjectDetails],
    })

  //  Make sure we have a project. Otherwise show loading
  if (projectDetailsQuery.loading || !projectDetailsQuery.data) {
    return <AppLoader title="Loading project details" subtitle="Please wait while we load the project details." />
  }

  const canEdit = myProfile?.id === projectDetailsQuery.data.getProject?.owner.id

  return (
    <Box>
      <ProjectDetails
        project={projectDetailsQuery?.data?.getProject as Project}
        canEdit={canEdit}
        onNavigate={navigate}
        onUpdateProject={onUpdateProject}
        onDeleteProject={onDeleteProject}
        onCopyProject={onCopyProject}
        onCreateSurvey={onCreateSurvey}
        onCreateSelection={onCreateSelection}
        onEditSurvey={onEditSurvey}
        onDeleteSurvey={onDeleteSurvey}
        onEditSelection={onEditSelection}
        onDeleteSelection={onDeleteSelection}
        onCreateReferencePlane={onCreateReferencePlane}
        onEditReferencePlane={onEditReferencePlane}
        onDeleteReferencePlane={onDeleteReferencePlane}
        getReferencePlaneUploadUrl={onUploadReferencePlane}
        onReferencePlaneUploadComplete={onUploadReferencePlaneComplete}
        onCreateLongProfile={onCreateLongProfile}
        onEditLongProfile={onEditLongProfile}
        onDeleteLongProfile={onDeleteLongProfile}
        onCreateLongChange={onCreateLongChange}
        onEditLongChange={onEditLongChange}
        onDeleteLongChange={onDeleteLongChange}
        onUploadCenterlineComplete={() => updateProjectCenterlineDone().then()}
        onUploadCrossSectionsComplete={() => updateProjectCrossSectionsDone().then()}
        getUploadCenterlineUrl={() =>
          getUploadCenterlineUrl().then(({ data }) => data?.uploadProjectCenterline as UploadTarget)
        }
        getUploadCrossSectionUrl={() =>
          getUploadCrossSectionUrl().then(({ data }) => data?.uploadProjectCrossSections as UploadTarget)
        }
      />
    </Box>
  )
}
