import { GetReferencePlaneDataQuery } from '../../schema/base.types'
import { useGetReferencePlaneDataQuery } from '../../schema/operations'
import { useNotifications } from '../AppNotifications'

export interface ReferencePlaneQueryProps {
  projectId: string
  referencePlaneId: string
  onData: (data: NonNullable<GetReferencePlaneDataQuery['getReferencePlane']> | null) => void
  onFinished?: () => void
}

export const ReferencePlaneDataQuery: React.FC<ReferencePlaneQueryProps> = ({
  projectId,
  referencePlaneId,
  onData,
}) => {
  const { show } = useNotifications()

  const referencePlaneDataQuery = useGetReferencePlaneDataQuery({
    variables: { projectId, referencePlaneId },
    onCompleted: (data) => {
      onData(data.getReferencePlane || null)

      // Ensure the survey points are sorted by station ascending
      if (data.getReferencePlane?.points.items)
        data.getReferencePlane.points.items = (data.getReferencePlane?.points.items || []).sort((a, b) => a.x - b.x)

      if (data.getReferencePlane?.points.nextToken) {
        referencePlaneDataQuery.fetchMore({
          variables: {
            nextToken: data.getReferencePlane.points.nextToken,
          },
        })
      }
    },
    onError: (error) => {
      console.log('onError GetReferencePlaneData', error)
      show(`Error retrieving project reference plane data: ${error.message}`, { variant: 'error' })
    },
  })

  return null
}
