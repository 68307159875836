import { ApolloLink, from, Operation } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { ErrorCode } from '@xsonline/common'
import log from 'loglevel'

// Some errors we handle in other ways
const ExceptList: ErrorCode[] = [ErrorCode.LOGGED_OUT, ErrorCode.MAINENANCE_MODE]

const makeLogLink = (logFn: (...args: unknown[]) => void): ApolloLink => {
  const logOperation = (operation: Operation, response: unknown, errors: unknown) => {
    const queryBody = operation.query.loc?.source?.body
    const queryMatch = queryBody?.match(/(query|mutation) (\w+).+/)

    if (queryMatch) {
      const [_wholeMatch, type, name] = queryMatch
      const logObject: Record<string, unknown> = {
        query: queryBody?.trim(),
        variables: operation.variables,
        context: operation.getContext(),
      }
      if (response) logObject.response = response
      // Let's not log the request. the response contains everyhting we need
      else return
      if (errors) logObject.errors = errors

      logObject.summary = `

Operation:
\`\`\`gql
${logObject.query}
\`\`\`
\`\`\`json
${JSON.stringify(operation.variables, undefined, 2)}
\`\`\`
Returns:
\`\`\`json
${JSON.stringify(response, undefined, 2)}
\`\`\`

`.trim()

      logFn(
        `%c${type} ${name}%c %c${errors ? 'ERROR' : ''}`,
        type === 'mutation' ? 'background-color: #ff8c00; color: white' : 'background-color: grey; color: white',
        'background-color: unset, color: unset',
        'background-color: red; color: white',
        logObject
      )
    }
    // show('Apollo Error', { variant: 'error', persist: true })
  }
  return from([
    new ApolloLink((operation, forward) => {
      return forward(operation).map((data) => {
        logOperation(operation, data, data.errors)
        return data
      })
    }),
    onError(({ operation, networkError }) => {
      logOperation(operation, undefined, networkError)
    }),
  ])
}

export const logLink = makeLogLink(log.debug)
