import { interpolateStation } from './mathHelpers'

export class SurveyPoint {
  public readonly station: number
  public readonly elevation: number
  public readonly offset?: number
  public readonly uncertainty?: number
  public readonly easting?: number
  public readonly northing?: number

  constructor(
    station: number,
    elevation: number,
    offset?: number,
    uncertainty?: number,
    easting?: number,
    northing?: number
  ) {
    ;(this.station = station), (this.elevation = elevation), (this.offset = offset)
    this.uncertainty = uncertainty
    this.easting = easting
    this.northing = northing
  }

  compareTo(other: SurveyPoint): number {
    if (this.station < other.station) {
      return -1
    } else if (this.station > other.station) {
      return 1
    } else {
      return 0
    }
  }

  public static isSame(a: SurveyPoint, b: SurveyPoint): boolean {
    if (a && b) {
      return a.station == b.station && a.elevation == b.elevation
    } else {
      return false
    }
  }

  euclideanDistance(station: number, elevation: number): number {
    let result = Math.pow(this.station - station, 2) + Math.pow(this.elevation - elevation, 2)

    if (result != 0) {
      result = Math.sqrt(result)
    } else {
      result = 0
    }

    return result
  }

  static interpolateAtElevation(p1: SurveyPoint, p2: SurveyPoint, elevation: number): SurveyPoint {
    return new SurveyPoint(interpolateStation(p1, p2, elevation), elevation, 0, 0, 0)
  }
}
