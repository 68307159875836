import React, { useState } from 'react'
import { IconButton, Tooltip, Menu, MenuItem } from '@mui/material'

import DownloadIcon from '@mui/icons-material/Download'
import { IconButtonProps } from '@mui/material/IconButton'

export interface DownloadButtonProps extends IconButtonProps {
  onDownloadXlsx?: () => void
  onDownloadCsv?: () => void
  onDownloadPng?: () => void
  onDownloadJpg?: () => void
  disabled?: boolean
}

export const DownloadButton: React.FC<DownloadButtonProps> = ({
  onDownloadXlsx,
  onDownloadCsv,
  onDownloadPng,
  onDownloadJpg,
  disabled,
  ...restProps
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const isOpen = !!anchorEl
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Tooltip title="Download">
        <span>
          <IconButton onClick={handleClick} disabled={disabled} {...restProps}>
            <DownloadIcon />
          </IconButton>
        </span>
      </Tooltip>
      <Menu anchorEl={anchorEl} open={isOpen} onClose={handleClose}>
        {onDownloadXlsx && <MenuItem onClick={onDownloadXlsx}>Download XLSX file</MenuItem>}
        {onDownloadCsv && <MenuItem onClick={onDownloadCsv}>Download CSV file</MenuItem>}
        {onDownloadPng && <MenuItem onClick={onDownloadPng}>Download PNG image</MenuItem>}
        {onDownloadJpg && <MenuItem onClick={onDownloadJpg}>Download JPEG image</MenuItem>}
      </Menu>
    </>
  )
}
