import { SurveyPoint } from './surveyPoint'

/**
 * Calculate a new y value based on the x value of two points and the x value of the new point.
 * @param x1 survey point 1 station
 * @param y1  survey point 1 elevation
 * @param x2  survey point 2 station
 * @param y2  survey point 2 elevation
 * @param x3  The station of the new point
 * @returns The elevation of the new point that corresponds to station x3
 */
export function interpolateYValue(x1: number, y1: number, x2: number, y2: number, x3: number): number {
  let y3 = 0
  if (x1 === x2) {
    y3 = (y1 + y2) / 2
  } else {
    y3 = y1 + (y1 - y2) * ((x3 - x1) / (x1 - x2))
  }

  if (isNaN(y3)) {
    const ex = new Error('Failed to interpolate value.')
    ex['data'] = {}
    ex['data']['Point 1'] = x1.toString() + ', ' + y1.toString()
    ex['data']['Point 2'] = x2.toString() + ', ' + y2.toString()
    ex['data']['New x'] = x3.toString()
    throw ex
  }

  return y3
}

/**
 * Calculate a new x value based on the y value of two points and the y value of the new point.
 * @param x1 survey point 1 station
 * @param y1  survey point 1 elevation
 * @param x2  survey point 2 station
 * @param y2  survey point 2 elevation
 * @param y3  The elevation of the new point
 * @returns The station of the new point that corresponds to elevation y3
 * @remarks This is the same as interpolateYValue, but with the x and y values swapped.
 */
export function interpolateXValue(x1: number, y1: number, x2: number, y2: number, y3: number): number {
  return interpolateYValue(y1, x1, y2, x2, y3)
}

/**
 * Interpolate a station value based on the elevation of two points and the elevation of the new point.
 * @param p1 survey point 1
 * @param p2 survey point 2
 * @param fInterpolationElevation The elevation of the new point
 * @returns The station of the new point that corresponds to elevation fInterpolationElevation
 * @remarks This is the same as interpolateYValue, but with the x and y values swapped.
 */
export function interpolateStation(p1: SurveyPoint, p2: SurveyPoint, fInterpolationElevation: number): number {
  return interpolateXValue(p1.station, p1.elevation, p2.station, p2.elevation, fInterpolationElevation)
}

/**
 * Interpolate an elevation value based on the station of two points and the station of the new point.
 * @param p1 survey point 1
 * @param p2 survey point 2
 * @param fInterpolationStation The station of the new point
 * @returns The elevation of the new point that corresponds to station fInterpolationStation
 * @remarks This is the same as interpolateStation, but with the x and y values swapped.
 */
export function interpolateElevation(p1: SurveyPoint, p2: SurveyPoint, fInterpolationStation: number): number {
  return interpolateYValue(p1.station, p1.elevation, p2.station, p2.elevation, fInterpolationStation)
}

/**
 * Calculate the area of a triangle.
 * @param points An array of three or more survey points
 * @returns The area of the triangle
 * @remarks The raw area and makes no attempt to put a level,
 * horizontal cap on top of the list of survey points.</returns>
 * This method does sort the cross sections to ensure that they are in station order.
 * http://www.mathopenref.com/coordpolygonarea2.html
 */
export function getRawAreaInsideCurve(points: SurveyPoint[]): number {
  if (points.length < 3) {
    return 0
  }

  // This method requires that the points are sorted in ascending station order.
  //points.sort();

  let fArea = 0
  for (let i = points.length - 1, j = 0; i >= 0; i--) {
    fArea += (points[j].station + points[i].station) * (points[j].elevation - points[i].elevation)
    j = i
  }

  fArea /= 2
  fArea = Math.abs(fArea)

  return fArea
}

/**
 * Gets the value from a list that is just less than a threshold value.
 * @param dValues A list of values
 * @param fThreshold The threshold value
 * @returns The value from the list that is just less than the threshold value
 * @remarks If there are multiple values that are just less than the threshold value, the largest value is returned.
 * If there are no values that are less than the threshold value, NaN is returned.
 */
export function getClosestValueJustLessThan(dValues: number[], fThreshold: number): number {
  let fClosestValue = 0
  let fMinDifference = NaN
  for (const fValue of dValues) {
    if (fThreshold > fValue) {
      if (fThreshold - fValue < fMinDifference || isNaN(fMinDifference)) {
        fClosestValue = fValue
        fMinDifference = fThreshold - fValue
      }
    }
  }
  return fClosestValue
}

/**
 * Gets the value from a list that is just greater than a threshold value.
 * @param dValues A list of values
 * @param fThreshold The threshold value
 * @returns The value from the list that is just greater than the threshold value
 * @remarks If there are multiple values that are just greater than the threshold value, the smallest value is returned.
 * If there are no values that are greater than the threshold value, NaN is returned.
 */
export function getClosestValueJustGreaterThan(dValues: number[], fThreshold: number): number {
  let fClosestValue = 0
  let fMinDifference = NaN
  for (const fValue of dValues) {
    if (fValue > fThreshold) {
      if (fValue - fThreshold < fMinDifference || isNaN(fMinDifference)) {
        fClosestValue = fValue
        fMinDifference = fValue - fThreshold
      }
    }
  }
  return fClosestValue
}

/**
 * Gets the elevation of a point of interest based on the river distance.
 * @param inflectionPoints List of reference plane inflection points. Station is river distance. Elevation is elevation above sea level.
 * @param distance River distance of the point of interest
 * @returns Elevation of the point of interest
 * @remarks This method is a static method and does not require an instance of the ReferencePlane class.
 */
export function getElevationAtDistance(inflectionPoints: SurveyPoint[], distance: number): number {
  // interpolate between the two points closest to the distance
  let lowerIndex = 0
  let upperIndex = 0
  let lowerDistance = 0
  let upperDistance = 0
  let lowerElevation = 0
  let upperElevation = 0
  let elevation = 0
  if (inflectionPoints.length < 2) {
    throw new Error('There must be at least two inflection points to interpolate.')
  }
  for (let i = 0; i < inflectionPoints.length; i++) {
    if (inflectionPoints[i].station > distance) {
      upperIndex = i
      lowerIndex = i - 1
      break
    }
  }
  if (upperIndex === 0) {
    // distance is beyond the last point
    lowerIndex = inflectionPoints.length - 2
    upperIndex = inflectionPoints.length - 1
  }
  lowerDistance = inflectionPoints[lowerIndex].station
  upperDistance = inflectionPoints[upperIndex].station
  lowerElevation = inflectionPoints[lowerIndex].elevation
  upperElevation = inflectionPoints[upperIndex].elevation
  elevation =
    lowerElevation + ((upperElevation - lowerElevation) * (distance - lowerDistance)) / (upperDistance - lowerDistance)

  return elevation
}
